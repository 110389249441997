/* eslint-disable react/jsx-pascal-case */

import { LoKationButton } from '@components/button/button'
import { IconButton } from '@components/icon-button/icon-button'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import { TextInput } from '@components/text-input/text-input'
import { ToggleButton } from '@components/toggle-button/toggle-button'
import { Tooltip } from '@components/tooltip/tooltip'
import { AdvertisingTypes } from 'src/services/advertising/advertising.types'
import { Utilities } from 'src/services/utilities.service'

import { useAdEditor, useAdEditorDispatch } from '../../state/ad-editor__state'
import { AdImageFileUploader } from './ad-image-file-uploader'

export function Ad__SideSheet__Placement__HeaderConfig() {
	const adEditorState = useAdEditor()
	const adEditorDispatch = useAdEditorDispatch()

	const canUserRemoveAd = getCanUserRemoveAd()

	/** =================================== */
	/** Methods */

	function getCanUserRemoveAd(): boolean {
		if (adEditorState.modifiedAd.placement !== 'HUB_HEADER') {
			return false
		}
		return adEditorState.modifiedAdImages.length > 1
	}

	function createNewImage(): void {
		const newAdImage: AdvertisingTypes.AdvertisementImage = {
			link: '',
			base64Image: '',
			platform: 'DESKTOP',
			advertisementImageId: 0 - Utilities.generateRandomInt(1, 1000000),
			impressions: 0,
		}
		adEditorDispatch({ type: 'create-ad-image', payload: newAdImage })
	}

	function createAdContentsEditor(
		adImage: AdvertisingTypes.AdvertisementImage,
		isRemovable: boolean,
		index: number,
	): React.ReactElement {
		return (
			<div className="border-thin p-10 mb-10" key={adImage.advertisementImageId}>
				<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween mb-10">
					<div className="flex flex-alignItems-center">
						<strong>Slot {index + 1}</strong>
						<Tooltip
							body={
								<div style={{ textAlign: 'left' }}>
									<strong>Desktop images</strong>
									<ul>
										<li>1000px x 250px</li>
									</ul>
									<p>
										<strong>Mobile images</strong>
										<ul>
											<li>800px x 465px</li>
										</ul>
									</p>
								</div>
							}
							className="ml-5"
						/>
					</div>
					<div className="flex">
						{isRemovable && (
							<IconButton
								icon="trash"
								className="ml-10"
								tooltip="Remove Slot"
								onClick={() => {
									adEditorDispatch({ type: 'delete-ad-image', payload: adImage })
								}}
							/>
						)}
					</div>
				</div>

				<AdImageFileUploader adImage={adImage} />

				<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween mb-10">
					<strong>Target URL</strong>
					<TextInput
						dataType="text"
						placeholder="http://www.lokationre.com"
						value={adImage.link}
						onChange={(updatedValue) => {
							const updatedAdImage: AdvertisingTypes.AdvertisementImage = {
								...adImage,
								link: updatedValue,
							}
							adEditorDispatch({ type: 'update-ad-image', payload: updatedAdImage })
						}}
						width={`100%`}
					/>
				</div>

				<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween">
					<strong>Platform</strong>
					<ToggleButton<AdvertisingTypes.AdImagePlatform>
						options={[
							{ label: 'Desktop', value: 'DESKTOP' },
							{ label: 'Mobile', value: 'MOBILE' },
						]}
						selectedOption={adImage.platform}
						onSelect={(selectedOption) => {
							const updatedAdImage: AdvertisingTypes.AdvertisementImage = {
								...adImage,
								platform: selectedOption,
							}
							adEditorDispatch({ type: 'update-ad-image', payload: updatedAdImage })
						}}
					/>
				</div>
			</div>
		)
	}

	/** =================================== */
	/** Render Component */

	if (adEditorState.modifiedAd.placement === 'SPONSORED_RESOURCE') {
		return <></>
	}

	return (
		<SideSheet__Section icon="image" label="Content" mode="expandable" sectionKey="account__design__content">
			<div className="mb-10">
				<strong>
					You can have up to 6 images for a single header. A different image may be displayed each time the
					user views the advertisement
				</strong>
			</div>
			{adEditorState.modifiedAdImages
				.sort((a, b) => (a.advertisementImageId > b.advertisementImageId ? -1 : 1))
				.map((adImage, index) => {
					return createAdContentsEditor(adImage, canUserRemoveAd, index)
				})}
			<LoKationButton
				className="mt-10 col-xs-12"
				variant="outlined"
				size="sm"
				disabled={adEditorState.modifiedAdImages.length >= 6}
				label="Add Variant"
				onClick={createNewImage}
			/>
		</SideSheet__Section>
	)
}
