import './switch.scss'

import { useState } from 'react'

import { Utilities } from '../../services/utilities.service'

interface SwitchProp {
	checked: boolean
	onChange: (checked: boolean) => void
	className?: string
	disabled?: boolean
}

function Switch(props: SwitchProp) {
	const [generateId] = useState(Utilities.generateRandomInt(0, 10000))
	const inputId = generateId
	return (
		<>
			<input
				id={`switch__${inputId}`}
				className={`react-switch-checkbox`}
				type="checkbox"
				checked={props.checked}
				onChange={(e) => {
					props.onChange(e.target.checked)
				}}
				disabled={props.disabled}
			/>
			<label className={`react-switch-label  ${props.className}`} htmlFor={`switch__${inputId}`}>
				<span className={`react-switch-button`} />
			</label>
		</>
	)
}

export default Switch
