import { TextInput } from '@components/text-input/text-input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DebouncedFunc } from 'lodash'

interface SitewideSearchEmptyStateProps {
	handleUpdateSearch: DebouncedFunc<any>
	searchString: string
}

export function SitewideSearchEmptyState(props: SitewideSearchEmptyStateProps) {
	return (
		<>
			<div className="height__fill flex flex-column flex-alignItems-center flex-justifyContent-center">
				<FontAwesomeIcon icon={['far', 'search']} size="6x" className="mb-20 opacity-40" />
				<h1>Search LoKation</h1>
				<div className="col-12 col-md-4">
					<TextInput
						width={'100%'}
						dataType={'text'}
						onChange={props.handleUpdateSearch}
						value={props.searchString}
						className="my-20 col-xs-12"
						placeholder="Search"
					/>
				</div>
			</div>
		</>
	)
}
