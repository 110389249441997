import { ResourceSelectorDropdown } from '@components/resource/resource-selector-dropdown'
import { SortableCard } from '@components/sortable-card/sortable-card'
import {
	closestCenter,
	DndContext,
	DragEndEvent,
	MouseSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { ResourcesAPI } from '../../../../services/resources/resources.api'
import { LokationResource } from '../../../../services/resources/resources.types'
import { useDomainAdmin, useDomainAdminDispatch } from '../state/domain-admin__state'

interface FeaturedResourceEditorProps {
	className?: string
}

const PAGE_SIZE = 20

/** Documentation: https://docs.dndkit.com/presets/sortable/usesortable */
export function FeaturedResourceEditor(props: FeaturedResourceEditorProps) {
	/** ============================= */
	/** Props and State */
	const domainAdminState = useDomainAdmin()
	const domainAdminDispatch = useDomainAdminDispatch()
	const [allResources, setAllResources] = useState<LokationResource[]>([])
	const [orderedResourceIds, setOrderedResourceIds] = useState<number[]>([])
	const selectedResources = getSelectedResources()

	/** ============================= */
	/** Hooks */

	useEffect(() => {
		getResourcesPage(0, [])
	}, [])

	useEffect(() => {
		const sortedResources = domainAdminState.modified.landingPageResources.sort((a, b) =>
			a.displayOrder > b.displayOrder ? 1 : -1,
		)
		setOrderedResourceIds(sortedResources.map((resourceRef) => resourceRef.landingPageResourceId))
	}, [domainAdminState.modified.landingPageResources])

	const mouseSensor = useSensor(MouseSensor, {
		activationConstraint: {
			distance: 40,
		},
	})
	const pointerSensor = useSensor(PointerSensor)

	const sensors = useSensors(pointerSensor, mouseSensor)

	/** ============================= */
	/** Methods */

	/** Recursively searches for resources until all resources in the domain have been found */
	function getResourcesPage(page: number, foundItems: LokationResource[]): void {
		ResourcesAPI.getResources({
			page,
			size: PAGE_SIZE,
			sort: [{ property: 'title', direction: 'asc' }],
		}).then((res) => {
			foundItems = [...foundItems, ...res.data.items]
			if (res.data.items.length === PAGE_SIZE) {
				getResourcesPage(page + 1, foundItems)
			} else {
				setAllResources(foundItems)
			}
		})
	}

	function handleRemoveResource(resourceId: number): void {
		domainAdminDispatch({ type: 'remove-landing-page-resource', payload: resourceId })
	}

	function handleAddResource(resource: LokationResource): void {
		domainAdminDispatch({ type: 'create-landing-page-resource', payload: resource })
	}

	function getSelectedResources(): LokationResource[] {
		const selectedResources: LokationResource[] = []

		domainAdminState.modified.landingPageResources.forEach((resourceRef) => {
			if (!resourceRef.resource) {
				return
			}
			selectedResources.push(resourceRef.resource)
		})
		return selectedResources
	}

	function getSelectedResourceIds(): number[] {
		const resourceIds: number[] = []
		domainAdminState.modified.landingPageResources.forEach((resourceRef) => {
			if (resourceRef.resource) {
				resourceIds.push(resourceRef.resource.resourceId)
			}
		})
		return resourceIds
	}

	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event
		let updatedResourceRefs = _.cloneDeep(domainAdminState.modified.landingPageResources)

		if (!over) {
			return
		}

		if (active.id !== over.id) {
			const oldIndex = updatedResourceRefs.findIndex(
				(resourceRef) => resourceRef.landingPageResourceId === active.id,
			)
			const newIndex = updatedResourceRefs.findIndex(
				(resourceRef) => resourceRef.landingPageResourceId === over.id,
			)

			updatedResourceRefs = arrayMove(updatedResourceRefs, oldIndex, newIndex)
			updatedResourceRefs.forEach((ref, index) => {
				updatedResourceRefs[index].displayOrder = index
			})
			setOrderedResourceIds(updatedResourceRefs.map((ref) => ref.landingPageResourceId))
			domainAdminDispatch({ type: 'update-landing-page-resource', payload: updatedResourceRefs })
		}
	}

	/** ============================= */
	/** Render Component */

	return (
		<div className={`${props.className ? props.className : ''}`}>
			<div className="col-xs-12 mb-20">
				<ResourceSelectorDropdown
					selectedResources={selectedResources}
					options={allResources}
					onSelect={(options) => {
						const existingResourceIds = getSelectedResourceIds()
						const newResources = options.filter(
							(selectedOption) => !existingResourceIds.includes(selectedOption.resourceId),
						)
						newResources.forEach((resource) => {
							handleAddResource(resource)
						})
					}}
				/>
			</div>

			<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
				<SortableContext items={orderedResourceIds} strategy={verticalListSortingStrategy}>
					{domainAdminState.modified.landingPageResources.map((resourceRef) => {
						let label = 'Loading'

						const resourceProps = resourceRef.resource
						if (resourceProps) {
							label = resourceProps.title
						}

						return (
							<SortableCard
								key={resourceRef.landingPageResourceId}
								label={label}
								id={resourceRef.landingPageResourceId}
								onDelete={() => {
									handleRemoveResource(resourceRef.landingPageResourceId)
								}}
							/>
						)
					})}
				</SortableContext>
			</DndContext>
			{domainAdminState.modified.landingPageResources.length === 0 && (
				<div className="bg-color__adjust-alpha-5 p-10 mb-10">No resources have been added</div>
			)}
		</div>
	)
}
