import { LokationSpecialty } from '../../services/specialties/specialty.types'
import { MultiSelectDropdown } from '../multiselect-dropdown/multiselect-dropdown'

export interface SpecialtySelectorDropdownProps {
	selectedSpecialties: Array<LokationSpecialty>
	options: LokationSpecialty[]
	onSelect: (selectedOptions: LokationSpecialty[]) => void
	disabled?: boolean
}

export function SpecialtySelectorDropdown(props: SpecialtySelectorDropdownProps) {
	return (
		<MultiSelectDropdown<LokationSpecialty>
			selectedOptions={props.selectedSpecialties}
			options={props.options}
			onSelect={props.onSelect}
			optionIdKey={'specialtyId'}
			optionLabelKey={'specialtyName'}
			disabled={props.disabled}
		/>
	)
}
