import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { UserAPI } from '../../services/user/user.api'

const initialState = ''

export const getUserProfilePhoto = createAsyncThunk('user-profile-photo/get', async (props: { userId: number }) => {
	const res = await UserAPI.getUserPhotoURL(props.userId)
	return res
})

export const userProfilePhotoSlice = createSlice({
	name: 'user-profile-photo',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getUserProfilePhoto.fulfilled, (state, action) => {
			return action.payload
		})
	},
})

export default userProfilePhotoSlice.reducer
