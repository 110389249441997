import { BatchProcessorService } from './batch-processor.types'

const MAX_CYCLES_BEFORE_EXIT = 20
const CYCLE_TIMEOUT = 20000

/** Service that allows a large number of requests to be made synchronously, efficiently executing each request when the requirements in
 * order to call it are met
 */
export function BatchProcessor() {
	const steps: BatchProcessorService.Step[] = []
	let numCompletedSteps: number = 0
	let handleOnStepResolve: ((numCompleted: number, totalSteps: number) => void) | null = null

	function registerStep(step: BatchProcessorService.Step): void {
		steps.push(step)
	}

	function onStepResolve(onResolveFn: ((numCompleted: number, totalSteps: number) => void) | null): void {
		handleOnStepResolve = onResolveFn
	}

	/** Run all initalization steps */
	function runAllSteps(): Promise<void> {
		return new Promise((resolve, reject) => {
			const runCycle = (remainingSteps: BatchProcessorService.Step[], numCycles: number) => {
				takePassAtRemainingSteps(remainingSteps)
					.then((remainingAfterCycle) => {
						if (remainingAfterCycle.length === 0) {
							resolve()
						} else {
							if (numCycles >= MAX_CYCLES_BEFORE_EXIT) {
								reject(`Batch processor exceeded maximum cycles`)
							} else {
								runCycle(remainingAfterCycle, numCycles + 1)
							}
						}
					})
					.catch(() => {
						console.warn(`Batch processor failed to complete all steps`)
						reject()
					})
			}

			runCycle(steps, 1)
		})
	}

	/** Check all steps, executing any steps that have met all prequisite and returning any that are not ready to be tried again */
	function takePassAtRemainingSteps(
		remainingSteps: BatchProcessorService.Step[],
	): Promise<BatchProcessorService.Step[]> {
		return new Promise((resolve, reject) => {
			const stepsNotReady: BatchProcessorService.Step[] = []
			const stepsReadyToExecute: Promise<void>[] = []

			/** Throw warning if a step is not completed in time */
			const timeout = setTimeout(() => {
				console.warn(`Batch processor cannot resolve some promises`, stepsReadyToExecute)
				reject()
			}, CYCLE_TIMEOUT)

			remainingSteps.forEach((initializationStep) => {
				const isReady = isStepReadyForExecution(initializationStep)
				if (isReady) {
					stepsReadyToExecute.push(
						initializationStep
							.execution()
							.then(() => {
								/** Call the onStepResolve event */
								numCompletedSteps++
								if (handleOnStepResolve) {
									handleOnStepResolve(numCompletedSteps, remainingSteps.length)
								}
							})
							.catch((err) => {
								if (initializationStep.onFail) {
									initializationStep.onFail()
								}
								numCompletedSteps++
								if (handleOnStepResolve) {
									handleOnStepResolve(numCompletedSteps, remainingSteps.length)
								}
							}),
					)
				} else {
					/** If the prequisite data is not ready, this promise should be returned so that it can be checked again in the future */
					stepsNotReady.push(initializationStep)
				}
			})

			Promise.all(stepsReadyToExecute).then((res) => {
				clearTimeout(timeout)
				resolve(stepsNotReady)
			})
		})
	}

	/** Check whether all of the prequisite data in order for this step to be executed is available */
	function isStepReadyForExecution(step: BatchProcessorService.Step): boolean {
		if (!step.isReadyForExecution) {
			return true
		}
		return step.isReadyForExecution()
	}

	return { registerStep, runAllSteps, onStepResolve }
}
