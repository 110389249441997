import { getDomainProps } from '@redux/reducers/domain-reducer'
import { getLanguageSpecialties } from '@redux/reducers/language-specialties-reducer'
import { getLicensedRegions } from '@redux/reducers/licensed-regions-reducer'
import { getLicensedStates } from '@redux/reducers/licensed-states-reducer'
import { getLoginCarousel } from '@redux/reducers/login-carousel-reducer'
import { getSpecialties } from '@redux/reducers/specialties-reducer'
import { getTags } from '@redux/reducers/tags-reducer'
import { getTheme } from '@redux/reducers/theme-reducer'
import { store } from '@redux/store'

import { AnalyticsService } from './services/analytics/analytics.service'
import { BatchProcessorService } from './services/batch-processor/batch-processor.types'
import { GoogleAPI } from './services/google-api/google-api'
import { DomainThemeService } from './services/theme/theme.service'

export class DomainInitializationSteps {
	static getLicensedStates(): BatchProcessorService.Step {
		return {
			isReadyForExecution: null,
			execution: () => {
				return new Promise<void>((resolve) => {
					store.dispatch(getLicensedStates()).then(() => {
						resolve()
					})
				})
			},
		}
	}

	static getLicensedRegions(): BatchProcessorService.Step {
		return {
			isReadyForExecution: null,
			execution: () => {
				return new Promise<void>((resolve) => {
					store.dispatch(getLicensedRegions()).then(() => {
						resolve()
					})
				})
			},
		}
	}

	static getDomainTheme(): BatchProcessorService.Step {
		return {
			isReadyForExecution: null,
			execution: () => {
				return new Promise<void>((resolve) => {
					store.dispatch(getTheme()).then(() => {
						resolve()
					})
				})
			},
		}
	}

	static getTags(): BatchProcessorService.Step {
		return {
			isReadyForExecution: null,
			execution: () => {
				return new Promise<void>((resolve) => {
					store.dispatch(getTags()).then(() => {
						resolve()
					})
				})
			},
		}
	}

	static getSpecialties(): BatchProcessorService.Step {
		return {
			isReadyForExecution: null,
			execution: () => {
				return new Promise<void>((resolve) => {
					store.dispatch(getSpecialties()).then(() => {
						resolve()
					})
				})
			},
		}
	}

	static getLanguageSpecialties(): BatchProcessorService.Step {
		return {
			isReadyForExecution: null,
			execution: () => {
				return new Promise<void>((resolve) => {
					store.dispatch(getLanguageSpecialties()).then(() => {
						resolve()
					})
				})
			},
		}
	}

	static getDomainProps(): BatchProcessorService.Step {
		return {
			isReadyForExecution: null,
			execution: () => {
				return new Promise<void>((resolve) => {
					store.dispatch(getDomainProps()).then(() => {
						resolve()
					})
				})
			},
		}
	}

	static getLoginCarousel(): BatchProcessorService.Step {
		return {
			isReadyForExecution: null,
			execution: () => {
				return new Promise<void>((resolve) => {
					store.dispatch(getLoginCarousel()).then(() => {
						resolve()
					})
				})
			},
		}
	}

	static loadFonts(): BatchProcessorService.Step {
		return {
			isReadyForExecution: null,
			execution: () => {
				return new Promise<void>((resolve) => {
					GoogleAPI.loadFontToDom('Hurricane')
					resolve()
				})
			},
		}
	}

	static loadCachedTheme(): BatchProcessorService.Step {
		return {
			isReadyForExecution: null,
			execution: () => {
				return new Promise<void>((resolve) => {
					const cachedTheme = DomainThemeService.getCachedStyles()
					DomainThemeService.writeThemePropsToCSS(cachedTheme)
					resolve()
				})
			},
		}
	}

	static initializeAnalytics(): BatchProcessorService.Step {
		return {
			isReadyForExecution: null,
			execution: () => {
				return new Promise<void>((resolve) => {
					AnalyticsService().initializeGA4TrackingId(`G-9BXPYYLMDY`)
					resolve()
				})
			},
		}
	}
}
