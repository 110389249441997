import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { LicensedStatesAPI } from '../../services/licensed-states/licensed-states.api'
import { LicensedState } from '../../services/licensed-states/licenses-states.types'

const initialState: LicensedState[] = []

export const getLicensedStates = createAsyncThunk('licensedStates/get', async () => {
	const res = await LicensedStatesAPI.getLicensedStates()
	return res.data
})

export const licensedStatesSlice = createSlice({
	name: 'licensed-states',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getLicensedStates.fulfilled, (state, action) => {
			return action.payload
		})
	},
})

export default licensedStatesSlice.reducer
