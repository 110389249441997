import { LoKationButton } from '@components/button/button'
import { LoginRouteBackground } from '@components/login-route-wrapper/login-route-background/login-route-background'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'
import { useNavigate, useRouteError } from 'react-router-dom'

import { EndUserProps } from '../services/user/user.types'
import { AuthenticatedRouteChrome } from './authenticated-root/authenticated-route-chrome'

function ErrorPagePrototype(props: { user: EndUserProps | null }) {
	const error = useRouteError() as { statusText: string; message: string }
	console.error(error)
	const navigate = useNavigate()

	if (props.user) {
		return (
			<AuthenticatedRouteChrome routeSection="HUBS">
				<RouteWrapperV2>
					<RouteBodyV2 className="flex flex-column flex-alignItems-center flex-justifyContent-center">
						<FontAwesomeIcon icon={['far', 'face-frown']} size="4x" className="mb-40" />
						<h1 className="text-center">Sorry, an unexpected error has occurred</h1>
						<p className="text-center mb-20">
							<strong>If you continue to see this error, please contact LoKation for help</strong>
						</p>
						<p className="text-center opacity-50">
							<i>Error: {error.statusText || error.message}</i>
						</p>
					</RouteBodyV2>
				</RouteWrapperV2>
			</AuthenticatedRouteChrome>
		)
	} else {
		return (
			<LoginRouteBackground image={''}>
				<div className="login__inner-wrapper p-40 flex flex-column flex-alignItems-center flex-justifyContent-center ">
					<h1>Sorry, an unexpected error has occurred</h1>
					<p className="text-center mb-20">
						<strong>If you continue to see this error, please contact LoKation for help</strong>
					</p>
					<p>
						<i>{error.statusText || error.message}</i>
					</p>
					<LoKationButton
						variant="contained"
						label="Back to Login"
						size="lg"
						onClick={() => {
							navigate('/')
						}}
					/>
				</div>
			</LoginRouteBackground>
		)
	}
}

function mapStateToProps(state: RootState) {
	return {
		user: state.user,
	}
}

export const ErrorPage = connect(mapStateToProps)(ErrorPagePrototype)
