import { useLoaderData } from 'react-router-dom'

import { EndUserProps } from '../../../services/user/user.types'
import { UserProfileProvider } from './state/user-profile__state'
import { UserProfileEditor } from './user-profile'

export function UserProfileRoute() {
	const data = useLoaderData() as EndUserProps | null

	return (
		<UserProfileProvider userToEdit={data}>{data && <UserProfileEditor userToEdit={data} />}</UserProfileProvider>
	)
}
