import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function LoKationResourceLuxuryMembershipBenefits() {
	function createBenefit(props: { title: string; body: string; icon: IconName }): React.ReactNode {
		return (
			<div style={{ width: '300px' }} className="mr-40 mb-40">
				<FontAwesomeIcon icon={['fas', props.icon]} style={{ height: '30px' }} className="mb-10 opacity-30" />
				<h5>{props.title}</h5>
				<p>{props.body}</p>
			</div>
		)
	}

	return (
		<div className="flex flex-column overflow-y__scroll">
			<div className="flex flex-wrap">
				<div className="col-xs-12 pr-20-sm flex flex-column flex-alignItems-start">
					<h2 className="mb-20">
						<strong>Luxury Membership Benefits</strong>
					</h2>

					<div className="flex flex-wrap flex-justifyContent-start col-xs-12">
						{createBenefit({
							title: 'A 360° Online Digital Audit and Consultation',
							body: 'A comprehensive evaluation of an agent’s online presence, including its website, social media accounts, and other digital marketing efforts. This can include analyzing current digital marketing strategies, identifying any areas for improvement, and developing a plan to address them.',
							icon: '360-degrees',
						})}

						{createBenefit({
							title: 'Do More with kvCORE!',
							body: 'A complete productivity platform includes a Smart CRM, personal IDX website, and AI-Driven Lead Nurturing to streamline your business. Supercharge your ability to engage prospects! FB & Google Ads, BombBomb videos, Text, VM drops, and much more. Grow your Sphere, all from the palm of your hand!',
							icon: 'toolbox',
						})}

						{createBenefit({
							title: 'Bespoke Luxury Marketing Materials',
							body: 'Custom marketing materials, such as brochures, flyers, postcards, and social media posts that can be designed specifically with luxury in mind. You can print/ship directly from our elite marketing platform to targeted areas in less than 24 hours.',
							icon: 'book-blank',
						})}

						{createBenefit({
							title: 'White Glove Marketing',
							body: 'LoKation Luxury has partnered with a full-service marketing agency that specializes in the success of your business through brilliant marketing strategies and creative excellence. Integrated marketing for consumer engagement resulting in high-end buyer & seller leads through both custom digital and print campaigns.',
							icon: 'megaphone',
						})}

						{createBenefit({
							title: 'Tailored Listing Presentations',
							body: 'We’ve partnered with a full-service PR & Marketing Firm to create your own custom listing presentations that highlight the unique features and benefits you and LoKation will deliver. This includes saturation marketing options for properties via social media, print, and advertising.',
							icon: 'presentation',
						})}

						{createBenefit({
							title: 'Exclusive use of LoKation Luxury® Logos',
							body: 'Exclusive use of LoKation Luxury® logos refers to the use of specific logos or branding elements that are associated with the LoKation Luxury brand. This can distinguish you as a luxury-specific Realtors * only for properties above $1mm',
							icon: 'crown',
						})}

						{createBenefit({
							title: 'Professionally Designed Social Media Content',
							body: 'Social media personalization boosts engagement and impact of posts and ads with customizable templates. Easily adjust them to fit your brand or listing’s needs. Streamline the process by creating professional-looking templates with image capture and text message. Access a library of high-quality content and save time delivering it to your target audience.',
							icon: 'thumbs-up',
						})}

						{createBenefit({
							title: 'Luxury Company Overview Booklet',
							body: 'This serves as a guide to highlight our expertise in the luxury home marketing arena. It emphasizes the synergistic partnership that LoKation Luxury has established between our luxury agents and your valued clients. This booklet features a comprehensive overview of the company’s products, services, and core values.',
							icon: 'book-open',
						})}

						{createBenefit({
							title: 'Certified Luxury Home Marketing Specialist',
							body: 'Lokation Luxury is a Premier Partner of the Institute of Home Luxury Marketing. In addition to Live Stream Training, we highly suggest earning the Certified Luxury Home Marketing Specialist™ designation. Becoming a member grants exclusive access to the network via a Member’s Portal with add’l tools and resources as well as a proprietary members-only social network',
							icon: 'user-tie',
						})}
					</div>
				</div>
			</div>
		</div>
	)
}
