import './mobile-app-header.scss'

import { UIState } from '@redux/reducers/ui-reducer'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'

import { MobileAppTray } from '../mobile-app-tray/mobile-app-tray'
import { MobileAppHeaderInner } from './mobile-app-header__inner'

export interface MobileAppHeaderProps {
	headerStyle: 'NORMAL' | 'REVERSED'
}

function MobileAppHeaderPrototype(props: MobileAppHeaderProps & { ui: UIState }) {
	return (
		<>
			<div className={`mobile-app-header ${props.headerStyle === 'NORMAL' ? 'normal' : 'reversed'}`}>
				<MobileAppHeaderInner headerStyle="NORMAL" />
			</div>
			{props.ui.isMobileTrayOpen && <MobileAppTray />}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		ui: state.ui,
	}
}

export const MobileAppHeader = connect(mapStateToProps)(MobileAppHeaderPrototype)
