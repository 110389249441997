import { LicensedState } from './licenses-states.types'

export namespace LicensedStatesService {
	export function getStateFromId(allLocations: LicensedState[], locationId: number): LicensedState | undefined {
		return allLocations.find((state) => state.licensedStateId === locationId)
	}

	export function getStatesFromIds(allLocations: LicensedState[], locationIds: number[]): LicensedState[] {
		const foundLocations: LicensedState[] = []

		locationIds.forEach((locationId) => {
			const foundLocation = allLocations.find((state) => state.licensedStateId === locationId)
			if (foundLocation) {
				foundLocations.push(foundLocation)
			}
		})

		return foundLocations
	}
}
