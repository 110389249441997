interface ModalBodyStickyHeaderProps {
	children: React.ReactNode
	className?: string
	style?: React.CSSProperties
}

/** Surface for displaying content in a modal that sticks to the top */
export function ModalBodyStickyHeader(props: ModalBodyStickyHeaderProps) {
	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`modal-body__sticky-header`)

		if (props.className) {
			classes.push(props.className)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	return (
		<div className={getClass()} style={props.style}>
			{props.children}
		</div>
	)
}
