import { LoKationButton } from '@components/button/button'
import { ListItem } from '@components/list-item/list-item'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { ModalHeader } from '@components/modal/modal-header'
import _ from 'lodash'
import React, { useState } from 'react'

interface GenericItemSelectorModalProps<T> {
	title: string
	description: string
	items: T[]
	/** A list of selected items to pre-select when the modal is opened */
	selectedItems?: T[]
	/** The key of T that represents the items label */
	itemLabelKey: keyof T & string
	/** The key of T that represents the items ID */
	itemIdKey: keyof T & string
	/** Event to call when the user clicks the modals "Select" button */
	onSelect: (selectedItems: T[]) => void
	/** Event to call when the modal is closed (via cancellation OR selection) */
	onClose: () => void
	/** Manual z-index override of the modal */
	zIndex?: number
}

/** Generic modal for selecting items from a list */
export function GenericItemSelectorModal<T>(props: GenericItemSelectorModalProps<T>) {
	const [selectedItems, setSelectedItems] = useState<T[]>(props.selectedItems ? props.selectedItems : [])
	const selectedItemIds = selectedItems.map((item) => item[props.itemIdKey])

	/** ========================= */
	/** Methods */

	function toggleItemInclusion(item: T, action: 'ADD' | 'REMOVE'): void {
		let updatedItems = _.cloneDeep(selectedItems)
		if (action === 'ADD') {
			updatedItems.push(item)
		} else {
			updatedItems = updatedItems.filter((thisItem) => thisItem[props.itemIdKey] !== item[props.itemIdKey])
		}
		setSelectedItems(updatedItems)
	}

	function createItemSelector(item: T): React.ReactNode {
		const isSelected = selectedItemIds.includes(item[props.itemIdKey])
		return (
			<ListItem
				key={String(item[props.itemIdKey])}
				body={`${item[props.itemLabelKey]}`}
				onClick={(updatedState) => {
					toggleItemInclusion(item, updatedState ? 'ADD' : 'REMOVE')
				}}
				selected={isSelected}
				includeCheckbox={true}
			/>
		)
	}

	/** ========================= */
	/** Return Component */

	return (
		<Modal
			maxWidth={600}
			maxHeight={600}
			fixedHeight={false}
			onClose={props.onClose}
			zIndex={props.zIndex}
			className="flex flex-column"
		>
			<ModalHeader title={props.title}>
				<></>
			</ModalHeader>
			<ModalBody className="overflow-y__scroll">
				<strong>{props.description}</strong>
				{props.items.map((item) => {
					return createItemSelector(item)
				})}
			</ModalBody>
			<ModalFooter>
				<LoKationButton
					variant="outlined"
					size="sm"
					primary={false}
					label="Cancel"
					className="mr-10"
					onClick={props.onClose}
				/>
				<LoKationButton
					variant="contained"
					size="sm"
					label="Select"
					disabled={selectedItems.length === 0}
					onClick={() => {
						props.onSelect(selectedItems)
						props.onClose()
					}}
				/>
			</ModalFooter>
		</Modal>
	)
}
