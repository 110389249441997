import './login-carousel.scss'

import { useEffect, useState } from 'react'

let transitionTimer: NodeJS.Timeout | undefined

export function LoginCarouselText(props: { className?: string; text?: string }) {
	const [isTransitioning, setIsTransitioning] = useState(false)

	/** Update Slide on interval */
	useEffect(() => {
		clearTimeout(transitionTimer)

		setIsTransitioning(true)

		transitionTimer = setTimeout(() => {
			setIsTransitioning(false)
		}, 100)

		return () => {
			clearTimeout(transitionTimer)
		}
	}, [props.text])

	if (!props.text) {
		return <div>No login carousel defined</div>
	}

	if (isTransitioning) {
		return <div></div>
	}

	return (
		<div
			className={`login-carousel ${props.className ? props.className : ''}`}
			dangerouslySetInnerHTML={{ __html: props.text }}
		/>
	)
}
