import { Utilities } from '../utilities.service'

export interface TokenProps {
	/** User ID for user logged into application */
	id: number
	exp: number
	iat: number
	login_token: string
	sub: string
	jwt: string
}

export function TokenService() {
	function getToken(): string | null {
		const token = localStorage.getItem(`jwt`)
		return token
	}

	function parseToken(token: string): TokenProps | null {
		const parsedToken = Utilities.parseJwt(token)
		if (
			parsedToken &&
			'exp' in parsedToken &&
			'iat' in parsedToken &&
			'login_token' in parsedToken &&
			'sub' in parsedToken
		) {
			return {
				jwt: token,
				id: typeof parsedToken.id === 'number' ? parsedToken.id : 0,
				exp: typeof parsedToken.exp === 'number' ? parsedToken.exp : 0,
				iat: typeof parsedToken.iat === 'number' ? parsedToken.iat : 0,
				login_token: typeof parsedToken.login_token === 'string' ? parsedToken.login_token : '',
				sub: typeof parsedToken.sub === 'string' ? parsedToken.sub : '',
			}
		} else {
			console.error(`Invalid JWT token stored in cache. Token is being ignored`)
			return null
		}
	}

	function isTokenExpired(token: TokenProps): boolean {
		const currentTime = Date.now()
		const msToExpiration = token.exp * 1000 - currentTime
		if (msToExpiration < 0) {
			return true
		}
		return false
	}

	function clear(): void {
		localStorage.removeItem(`jwt`)
	}

	function update(jwtString: string): void {
		localStorage.setItem(`jwt`, jwtString)
		localStorage.setItem(`jwt-issue-time`, String(new Date().getTime()))
	}

	return { getToken, clear, update, parseToken, isTokenExpired }
}
