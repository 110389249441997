import './ad__card.scss'

import { AdvertisingTypes } from 'src/services/advertising/advertising.types'
import { Utilities } from 'src/services/utilities.service'

interface AccountCardProps {
	onClick: () => void
}

export function AdvertisingAccount__AdCard(props: AdvertisingTypes.Advertisement & AccountCardProps) {
	return (
		<div className="ad__card" onClick={props.onClick}>
			<strong>{Utilities.truncateString(props.name, 100)}</strong>
		</div>
	)
}
