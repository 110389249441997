import { LoKationButton } from '@components/button/button'
import { IconButton } from '@components/icon-button/icon-button'
import { TextInput } from '@components/text-input/text-input'
import _ from 'lodash'

import { LoginCarouselNode } from '../../../services/login-carousel/login-carousel.types'
import { Utilities } from '../../../services/utilities.service'

interface LoginCarouselEditorProps {
	nodes: LoginCarouselNode[]
	updateCarousel: (carousel: LoginCarouselNode[]) => void
	className?: string
}

export function LoginCarouselEditor(props: LoginCarouselEditorProps) {
	function handleRemoveBlock(index: number): void {
		let updatedState = _.cloneDeep(props.nodes)
		updatedState.splice(index, 1)
		props.updateCarousel(updatedState)
	}

	function handleUpdateBlockText(index: number, text: string): void {
		let updatedState = _.cloneDeep(props.nodes)
		updatedState[index].body = text
		props.updateCarousel(updatedState)
	}

	function handleUpdateBlockImage(index: number, image: string): void {
		let updatedState = _.cloneDeep(props.nodes)
		updatedState[index].imageUrl = image
		props.updateCarousel(updatedState)
	}

	function handleAddBlock(): void {
		let updatedState = _.cloneDeep(props.nodes)
		updatedState.push({
			body: '',
			imageUrl: '',
			loginCarouselNodeId: 0 - Utilities.generateRandomInt(1, 1000000),
		})
		props.updateCarousel(updatedState)
	}

	function createNode(node: LoginCarouselNode, index: number): React.ReactElement {
		return (
			<div className={`carousel-text-block`} key={node.loginCarouselNodeId}>
				{/* <ImageUploadTile
                    image={node.imageUrl}
                    onUpload={(image) => {
                        handleUpdateBlockImage(index, image)
                    }}
                    onRemove={() => {
                        handleUpdateBlockImage(index, '')
                    }}
                /> */}

				<div className="col-xs-12 p-10 flex flex-column">
					<strong className="ml-10">Text</strong>
					<TextInput
						placeholder="Enter text here"
						dataType="text"
						className="mb-10"
						rows={3}
						value={node.body}
						width="100%"
						onChange={(value) => {
							handleUpdateBlockText(index, value)
						}}
					/>

					<strong className="ml-10">Image URL</strong>
					<TextInput
						placeholder="Enter URL here"
						dataType="text"
						rows={1}
						value={node.imageUrl}
						width="100%"
						onChange={(value) => {
							handleUpdateBlockImage(index, value)
						}}
					/>
				</div>
				<IconButton
					className="carousel-block__delete-btn"
					icon={'trash'}
					onClick={() => {
						handleRemoveBlock(index)
					}}
				/>
			</div>
		)
	}

	return (
		<div className={`${props.className ? props.className : ''}`}>
			{props.nodes.map((node, index) => {
				return createNode(node, index)
			})}
			{props.nodes.length === 0 && (
				<div className="bg-color__adjust-alpha-5 p-10 mb-10">No text blocks have been defined</div>
			)}
			<LoKationButton
				icon={'plus'}
				iconPosition="left"
				label="Add text block"
				variant="outlined"
				size="sm"
				onClick={() => {
					handleAddBlock()
				}}
			/>
		</div>
	)
}
