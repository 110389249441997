import { LoKationButton } from '@components/button/button'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownOptionProps } from '@components/dropdown/dropdown.types'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { FileBaseURL } from '../../services/axios/http-common.service'
import { LicensedStateEnums } from '../../services/licensed-states/licenses-states.types'
import { EndUserProps } from '../../services/user/user.types'
import Thumbnail from './policy-procedures-thumb.png'

function PolicyProceduresRoutePrototype(props: { user: EndUserProps | null }) {
	const [selectedState, setSelectedState] = useState<LicensedStateEnums | null>(null)

	useEffect(() => {
		if (props.user && props.user.licenseInformation.length > 0) {
			const firstLicensedState = props.user.licenseInformation[0].licensedState.abbreviation
			setSelectedState(firstLicensedState)
		}
	}, [])

	function getDocumentURLByState(state: LicensedStateEnums): string {
		switch (state) {
			case 'AL':
				return `${FileBaseURL}/pdf/Policy and Procedures - AL.pdf`
			case 'CO':
				return `${FileBaseURL}/pdf/Policy and Procedures - CO.pdf`
			case 'FL':
				return `${FileBaseURL}/pdf/Policy and Procedures - FL.pdf`
			case 'GA':
				return `${FileBaseURL}/pdf/Policy and Procedures - GA.pdf`
			case 'SC':
				return `${FileBaseURL}/pdf/Policy and Procedures - SC.pdf`
			case 'TX':
				return `${FileBaseURL}/pdf/Policy and Procedures - TX.pdf`
		}
	}

	function getStateOptions(): DropdownOptionProps<LicensedStateEnums>[] {
		const options: DropdownOptionProps<LicensedStateEnums>[] = []

		options.push({
			label: 'Alabama',
			value: 'AL',
		})

		options.push({
			label: 'Colorado',
			value: 'CO',
		})

		options.push({
			label: 'Florida',
			value: 'FL',
		})

		options.push({
			label: 'Georgia',
			value: 'GA',
		})

		options.push({
			label: 'South Carolina',
			value: 'SC',
		})

		options.push({
			label: 'Texas',
			value: 'TX',
		})

		return options
	}

	return (
		<div className="flex-lg overflow-y__scroll">
			<div className="col-xs-12 col-lg-8 pr-20-sm flex flex-column flex-alignItems-start flex-justifyContent-center overflow-y__scroll">
				<h3>Policy and Procedures Manual</h3>
				<p>Select the manual that applies to the state you are licensed in</p>

				<div className="flex flex-column">
					<Dropdown<LicensedStateEnums>
						style={{ width: '200px' }}
						options={getStateOptions()}
						value={selectedState ? [selectedState] : []}
						onSelect={(options) => {
							if (options && options.length > 0) {
								setSelectedState(options[0])
							}
						}}
					/>

					<LoKationButton
						onClick={() => {
							if (selectedState) {
								window.open(getDocumentURLByState(selectedState))
							}
						}}
						className="mt-20"
						variant={'outlined'}
						label={'Download Manual'}
						size={'sm'}
						disabled={selectedState === null}
					/>
				</div>
			</div>
			<div className="col-xs-12 col-lg-4 p-20 flex flex-alignItems-center">
				<img src={Thumbnail} className="col-xs-12" />
			</div>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		user: state.user,
	}
}

export const PolicyProceduresRoute = connect(mapStateToProps)(PolicyProceduresRoutePrototype)
