import { LoKationButton } from '@components/button/button'
import { Paper } from '@components/paper/paper'
import { PhoneInput } from '@components/text-input/phone-input'
import { TextInput } from '@components/text-input/text-input'
import { useState } from 'react'
import { ToastService } from 'src/services/toast/toast.service'

import { EmailNotificationAPI } from '../../services/email-notification/email-notification.api'
import { EmailNotificationTypes } from '../../services/email-notification/email-notification.types'
import { Utilities } from '../../services/utilities.service'
import CoverImg from './pre-approvals.jpg'

export function LoKationResourceMortgagePreApprove() {
	const [submitted, setSubmitted] = useState(false)
	const [formFields, setFormFields] = useState<EmailNotificationTypes.EmailMortgagePreApproval>({
		askingPrice: '',
		amountToFinance: '',
		applicantName: '',
		applicantPhone: null,
		applicantEmail: '',
	})

	const [errors, setErrors] = useState({
		askingPrice: '',
		amountToFinance: '',
		applicantName: '',
		applicantPhone: '',
		applicantEmail: '',
	})

	function validateSimpleString(value: string): boolean {
		return value.length > 0
	}

	function validatePhone(value: number | null): boolean {
		if (value === null) {
			return false
		}
		return Utilities.isPhoneNumberValid(value, 'us')
	}

	function validateEmail(value: string): boolean {
		return Utilities.isEmailValid(value)
	}

	// Validation function
	const validateFormFields = () => {
		let formErrors = { ...errors }

		formErrors.askingPrice = validateSimpleString(formFields.askingPrice) ? '' : 'Asking Price is required'
		formErrors.amountToFinance = validateSimpleString(formFields.amountToFinance)
			? ''
			: 'Amount to Finance is required'
		formErrors.applicantName = validateSimpleString(formFields.applicantName) ? '' : 'Applicant Name is required'
		formErrors.applicantPhone = validatePhone(formFields.applicantPhone) ? '' : 'Phone number is not valid'
		formErrors.applicantEmail = validateEmail(formFields.applicantEmail) ? '' : 'Email is not valid'

		setErrors(formErrors)
		return Object.values(formErrors).every((x) => x === '')
	}

	const handleChange = (name: string) => (value: string | number | null) => {
		if ((name === 'askingPrice' || name === 'amountToFinance') && typeof value === 'string') {
			// Strip out any non-digit characters and convert to a number
			const numericValue = Number(value.replace(/[^\d]/g, ''))

			// Format as a dollar amount
			value = isNaN(numericValue)
				? ''
				: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(numericValue / 100)
		}
		setFormFields((prevState) => ({ ...prevState, [name]: value ? value.toString() : '' }))
	}

	async function sendNotification() {
		if (!validateFormFields()) {
			ToastService().create({
				type: 'ERROR',
				body: 'Please fill out all fields',
			})
			return
		}

		const formData: EmailNotificationTypes.EmailMortgagePreApproval = {
			askingPrice: formFields.askingPrice,
			amountToFinance: formFields.amountToFinance,
			applicantName: formFields.applicantName,
			applicantPhone: formFields.applicantPhone,
			applicantEmail: formFields.applicantEmail,
		}

		console.log(formData)

		EmailNotificationAPI.mortgagePreApprovalNotification(formData)
			.then((res) => {
				setSubmitted(true)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	return (
		<div className="flex flex-column overflow-y__scroll">
			<div className="flex flex-wrap">
				<div className="col-xs-12 pr-20-sm flex flex-column flex-alignItems-start">
					{/* <h2>Refer an Agent to LoKation</h2> */}
				</div>
			</div>
			<>
				<Paper bgColor="primary" padding={['all']} margins={['top', 'bottom']}>
					<div className="col-12 flex flex-column-xs-down flex-columnReverse-xs-down">
						<div className="col-12 col-md-7">
							<h2 className="mb-20">Get your client pre-approved!</h2>
							<>
								{!submitted ? (
									<>
										<div className="registration__field-wrapper">
											<TextInput
												label={'$ Asking Price'}
												dataType="text"
												value={formFields.askingPrice}
												width="100%"
												className="mb-20"
												onChange={handleChange('askingPrice')}
											/>

											<TextInput
												label={'$ Amount to Finance'}
												dataType="text"
												value={formFields.amountToFinance}
												width="100%"
												className="mb-20"
												onChange={handleChange('amountToFinance')}
											/>

											<TextInput
												label={'Full Name'}
												dataType="text"
												value={formFields.applicantName}
												width="100%"
												className="mb-20"
												onChange={handleChange('applicantName')}
											/>

											<PhoneInput
												width={`100%`}
												dataType={'number'}
												label="Phone number"
												placeholder="Phone number"
												value={formFields.applicantPhone}
												onChange={handleChange('applicantPhone')}
											/>

											<div className="col-xs-12 mb-20" style={{ gridColumn: '1 / span 2' }}>
												<TextInput
													label={'Email'}
													dataType="text"
													value={formFields.applicantEmail}
													width="100%"
													className="mb-20"
													onChange={handleChange('applicantEmail')}
												/>
											</div>
										</div>
										<div>
											<LoKationButton
												variant="contained"
												size="lg"
												primary={true}
												label={'Submit'}
												onClick={sendNotification}
												className="mb-20"
											/>
										</div>
									</>
								) : (
									<div className="mb-20">Your request has been submitted.</div>
								)}
							</>
						</div>
						<div className="col-12 col-md-5">
							<img src={CoverImg} className="mb-20 col-xs-12" />
						</div>
					</div>
				</Paper>
			</>
		</div>
	)
}
