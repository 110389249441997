import { AxiosResponse } from 'axios'

import { httpRequest, httpRequestNoErrors, RequestBaseURL } from '../axios/http-common.service'
import { AgentLicense } from './user.types'

class LicenseInformationAPIPrototype {
	addLicense(
		userId: number,
		license: AgentLicense.CompleteEntry,
	): Promise<AxiosResponse<AgentLicense.CompleteEntry>> {
		const headers: { [key: string]: any } = {}

		const requestPromise = httpRequest.post(
			`${RequestBaseURL}/api/v1/endUsers/${userId}/licenseInformations/`,
			license,
			{
				headers,
			},
		)
		return requestPromise
	}

	deleteLicense(userId: number, licenseId: number): Promise<AxiosResponse<AgentLicense.CompleteEntry>> {
		const headers: { [key: string]: any } = {}

		const requestPromise = httpRequest.delete(
			`${RequestBaseURL}/api/v1/endUsers/${userId}/licenseInformations/${licenseId}`,
			{
				headers,
			},
		)
		return requestPromise
	}

	patchLicense(
		userId: number,
		license: AgentLicense.CompleteEntry,
	): Promise<AxiosResponse<AgentLicense.CompleteEntry>> {
		const headers: { [key: string]: any } = {}

		const requestPromise = httpRequest.patch(
			`${RequestBaseURL}/api/v1/endUsers/${userId}/licenseInformations/${license.licenseInformationId}`,
			license,
			{
				headers,
			},
		)
		return requestPromise
	}

	getLicenseAgreement(userId: number): Promise<AxiosResponse<ArrayBuffer>> {
		const headers: { [key: string]: any } = {}

		const requestPromise = httpRequestNoErrors.get(
			`${RequestBaseURL}/api/v1/endUsers/${userId}/licenseInformations/${userId}/licenseAgreement`,
			{
				headers,
				responseType: 'arraybuffer',
			},
		)
		return requestPromise
	}
}

export const LicenseInformationAPI = new LicenseInformationAPIPrototype()
