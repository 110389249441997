import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { UIState } from '@redux/reducers/ui-reducer'
import { UserState } from '@redux/reducers/user-reducer'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { FAQAPI } from '../../services/faq/faq.api'
import { FAQ } from '../../services/faq/faq.types'
import { useDebounce } from '../../services/hooks/use-debounce'
import { NewsFeedAPI } from '../../services/news-feed/news-feed.api'
import { NewsFeed } from '../../services/news-feed/news-feed.types'
import { ResourcesAPI } from '../../services/resources/resources.api'
import { LokationResource, LokationResourceSearchParams } from '../../services/resources/resources.types'
import { LokationTag } from '../../services/tags/tag.types'
import { SitewideSearchEmptyState } from './sitewide-search__empty-state'
import { SitewideSearchFilters } from './sitewide-search__filters'
import { SitewideSearchResults } from './sitewide-search__results'

export type SitewideSearchTypes = 'RESOURCE' | 'NEWS' | 'FAQ'
export type SitewideSearchResultTypes = FAQ | NewsFeed.Story | LokationResource

const SITEWIDE_SEARCH_SEARCH_SIZE = 10

function SitewideSearchRoutePrototype(props: { user: UserState; ui: UIState }) {
	/** Search params */
	const [searchString, updateSearchString] = useState('')
	const [selectedTags, updateSelectedTags] = useState<LokationTag[]>([])
	const [selectedType, setSelectedType] = useState<SitewideSearchTypes>('RESOURCE')
	const [hasMore, setHasMore] = useState(false)
	const [resultsPage, setResultsPage] = useState<number | null>(null)

	/** Results in state */
	const [results, setResults] = useState<SitewideSearchResultTypes[]>([])

	const appViewMode = props.ui.viewMode

	/** Fetch results whenever page changes */
	useEffect(() => {
		if (canUserStartSearch()) {
			sendSearchQuery()
		}
	}, [resultsPage])

	/** Reset results when search params change */
	useEffect(() => {
		setResults([])
		setHasMore(true)

		if (canUserStartSearch() && resultsPage === null) {
			setResultsPage(0)
			sendSearchQuery()
		} else {
			setResultsPage(null)
		}
	}, [searchString, selectedTags, selectedType])

	function canUserStartSearch(): boolean {
		return searchString.length > 0 || selectedTags.length > 0
	}

	function mapTagsToNumbers(tags: LokationTag[]): number[] {
		return tags.map((tag) => tag.tagId)
	}

	function getLicensedStateIds(): number[] | undefined {
		const userProps = props.user
		if (userProps && userProps.licenseInformation.length > 0) {
			return userProps.licenseInformation.map((license) => {
				return license.licensedState.licensedStateId
			})
		} else {
			return undefined
		}
	}

	function getResourceSearchParams(): LokationResourceSearchParams {
		const searchParams: LokationResourceSearchParams = {}

		searchParams['tags'] = mapTagsToNumbers(selectedTags)
		searchParams['title'] = searchString.length > 0 ? searchString : undefined
		searchParams['description'] = searchString.length > 0 ? searchString : undefined
		searchParams['specialtyIds'] = props.user
			? props.user.specialties.map((specialty) => specialty.specialtyId)
			: []
		searchParams['licensedStateIds'] = getLicensedStateIds()

		/** This is done to guarantee that we are sending something in the 'specialtyIds' param. If the param were empty, the results would not filter out any resources
		 * according to their specialties
		 */
		if (searchParams.specialtyIds.length === 0) {
			searchParams.specialtyIds.push(-1)
		}

		return searchParams
	}

	function sendSearchQuery(): void {
		const searchPage = typeof resultsPage === 'number' ? resultsPage : 0

		if (selectedType === 'FAQ') {
			FAQAPI.findFAQs(
				{ page: searchPage, size: SITEWIDE_SEARCH_SEARCH_SIZE },
				{
					tags: mapTagsToNumbers(selectedTags),
					title: searchString.length > 0 ? searchString : undefined,
				},
			).then((res) => {
				setResults([...results, ...res.data.items])
				if (res.data.items.length < SITEWIDE_SEARCH_SEARCH_SIZE) {
					setHasMore(false)
				}
			})
		}

		if (selectedType === 'RESOURCE') {
			ResourcesAPI.findResources(
				{ page: searchPage, size: SITEWIDE_SEARCH_SEARCH_SIZE },
				getResourceSearchParams(),
			).then((res) => {
				setResults([...results, ...res.data.items])
				if (res.data.items.length < SITEWIDE_SEARCH_SEARCH_SIZE) {
					setHasMore(false)
				}
			})
		}

		if (selectedType === 'NEWS') {
			NewsFeedAPI.findStories(
				{
					page: searchPage,
					size: SITEWIDE_SEARCH_SEARCH_SIZE,
				},
				{
					tags: mapTagsToNumbers(selectedTags),
					title: searchString.length > 0 ? searchString : undefined,
				},
			).then((res) => {
				setResults([...results, ...res.data.items])
				if (res.data.items.length < SITEWIDE_SEARCH_SEARCH_SIZE) {
					setHasMore(false)
				}
			})
		}
	}

	const showSearchResults = canUserStartSearch() || results.length > 0

	const handleUpdateSearch = useDebounce((updatedValue: string) => {
		updateSearchString(updatedValue)
	}, 1000)

	return (
		<>
			{appViewMode === 'FULL' && (
				<RouteWrapperV2>
					<RouteSubnavigation title={`Search Lokation`} isCustom={true}>
						<SitewideSearchFilters
							handleUpdateSearch={handleUpdateSearch}
							selectedTags={selectedTags}
							searchString={searchString}
							selectedType={selectedType}
							updateSelectedType={(value) => {
								setSelectedType(value)
							}}
							updateSelectedTags={(value) => {
								updateSelectedTags(value)
							}}
						/>
					</RouteSubnavigation>

					<RouteBodyV2 id="search__route-body">
						<div className="flex-md height__fill">
							<div className="hub__column__main pl-20 overflow-y__scroll flex flex-column">
								{showSearchResults && (
									<SitewideSearchResults
										results={results}
										searchString={searchString}
										hasMore={hasMore}
										loadNextPageOfResults={() => {
											if (typeof resultsPage === 'number') {
												setResultsPage(resultsPage + 1)
											}
										}}
										updateSearchString={updateSearchString}
										updateSelectedTags={updateSelectedTags}
									/>
								)}
								{!showSearchResults && (
									<SitewideSearchEmptyState
										handleUpdateSearch={handleUpdateSearch}
										searchString={searchString}
									/>
								)}
							</div>
						</div>
					</RouteBodyV2>
				</RouteWrapperV2>
			)}
			{appViewMode === 'MOBILE' && (
				<RouteWrapperV2>
					<RouteBodyV2
						className={`flex flex-column flex-fillSpace flex-alignItems-center ${showSearchResults ? '' : 'flex-justifyContent-center'}`}
					>
						<div className="col-xs-12">
							{!showSearchResults && (
								<SitewideSearchEmptyState
									handleUpdateSearch={handleUpdateSearch}
									searchString={searchString}
								/>
							)}
						</div>

						{showSearchResults && (
							<SitewideSearchResults
								results={results}
								searchString={searchString}
								hasMore={hasMore}
								loadNextPageOfResults={() => {
									if (typeof resultsPage === 'number') {
										setResultsPage(resultsPage + 1)
									}
								}}
								updateSearchString={updateSearchString}
								updateSelectedTags={updateSelectedTags}
							/>
						)}
					</RouteBodyV2>
				</RouteWrapperV2>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		ui: state.ui,
		user: state.user,
	}
}

export const SitewideSearchRoute = connect(mapStateToProps)(SitewideSearchRoutePrototype)
