import { ToastService } from '../toast/toast.service'

type ClipboardType = 'HTML' | 'STRING'

export function CopyToClipboard(content: string, type: ClipboardType, toast?: string) {
	let text = content
	if (type === 'HTML') {
		// Convert HTML back to plain text for clipboard
		const div = document.createElement('div')
		div.innerHTML = content
		text = div.textContent || div.innerText || ''

		text = text.replace(/\n/g, '\r\n')
	}
	navigator.clipboard
		.writeText(text)
		.then(() => {
			ToastService().create({ type: 'SUCCESS', body: toast || `Text copied to clipboard` })
		})
		.catch((err) => {
			ToastService().create({
				type: 'ERROR',
				body: `Failed to copy text`,
			})
			console.error('Failed to copy text: ', err)
		})
}
