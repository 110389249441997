import { AxiosResponse } from 'axios'

// import _ from 'lodash'
import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { GetItemsParams } from '../utility.types'
import { AdvertisingTypes } from './advertising.types'

export namespace AdvertisementAPI {
	export function getAds(
		accountId: number,
		props: GetItemsParams<AdvertisingTypes.Advertisement>,
	): Promise<AxiosResponse<AdvertisingTypes.Advertisement[]>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props, accountId })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/advertisements/`, {
			headers,
			params,
		})
		return requestPromise
	}

	export function findAds(
		adPlacement: AdvertisingTypes.AdPlacement,
		applicableHubIds: number[],
		platform: AdvertisingTypes.AdImagePlatform,
	): Promise<AxiosResponse<AdvertisingTypes.FindAdsResponse | null>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ adPlacement, applicableHubIds, platform })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/advertisements/search`, {
			headers,
			params,
		})
		return requestPromise
	}

	export function getAdById(id: number): Promise<AxiosResponse<AdvertisingTypes.Advertisement | null>> {
		const headers = {}

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/advertisements/${id}`, {
			headers,
		})
		return requestPromise
	}

	export function updateAd(
		adToSave: AdvertisingTypes.Advertisement,
	): Promise<AxiosResponse<AdvertisingTypes.Advertisement>> {
		const headers = {}

		const requestPromise = httpRequest.put(
			`${RequestBaseURL}${apiVersionURL}/advertisements/${adToSave.advertisementId}`,
			adToSave,
			{
				headers,
			},
		)
		return requestPromise
	}

	export function createAd(
		adToCreate: AdvertisingTypes.Advertisement,
	): Promise<AxiosResponse<AdvertisingTypes.Advertisement>> {
		const headers = {}

		const payload: Partial<AdvertisingTypes.UpdateAdDTO> = {
			...adToCreate,
		}
		delete payload.advertisementId

		const requestPromise = httpRequest.post(`${RequestBaseURL}${apiVersionURL}/advertisements/`, payload, {
			headers,
		})
		return requestPromise
	}

	export function deleteAd(adToDelete: AdvertisingTypes.Advertisement): Promise<AxiosResponse<null>> {
		const headers = {}

		const requestPromise = httpRequest.delete(
			`${RequestBaseURL}${apiVersionURL}/advertisements/${adToDelete.advertisementId}`,
			{
				headers,
			},
		)
		return requestPromise
	}
}
