/* eslint-disable react/jsx-pascal-case */
import { SideSheet__Row } from '@components/side-sheet/side-sheet__row'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import { EditCalendarEventService } from 'src/modals/admin-editors/edit-calendar-event/edit-calendar.event.service'

import { useAdEditor, useAdEditorDispatch } from '../../state/ad-editor__state'

export function Ad__SideSheet__Tab__Budget() {
	const adEditorState = useAdEditor()
	const adEditorDispatch = useAdEditorDispatch()

	const startDateAsString = adEditorState.modifiedAd.startDate.toString()
	const endDateAsString = adEditorState.modifiedAd.endDate.toString()

	return (
		<>
			<strong>Create a budget for this advertisement</strong>

			<SideSheet__Section label="Duration" icon="timer" mode="expandable" sectionKey="account__duration">
				<SideSheet__Row label="Start date">
					<input
						type="date"
						className="text-input"
						style={{ width: '200px' }}
						value={startDateAsString}
						onChange={(evt) => {
							const utcTime = EditCalendarEventService().convertLocalDateToUTC({
								localDate: evt.target.value,
								localTime: '01:00:00',
							})
							if (!utcTime) {
								return
							}

							adEditorDispatch({
								type: 'update-property',
								payload: { startDate: utcTime.utcDate },
							})
						}}
					/>
				</SideSheet__Row>
				<SideSheet__Row label="End date">
					<input
						type="date"
						className="text-input"
						style={{ width: '200px' }}
						value={endDateAsString}
						onChange={(evt) => {
							const utcTime = EditCalendarEventService().convertLocalDateToUTC({
								localDate: evt.target.value,
								localTime: '01:00:00',
							})
							if (!utcTime) {
								return
							}

							adEditorDispatch({
								type: 'update-property',
								payload: { endDate: utcTime.utcDate },
							})
						}}
					/>
				</SideSheet__Row>
			</SideSheet__Section>
		</>
	)
}
