import './toast.scss'

import { LoKationButton, LoKationButtonProps } from '@components/button/button'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { toast, ToastOptions, UpdateOptions } from 'react-toastify'

import { ToastServiceTypes } from './toast.types'

export function ToastService() {
	function update(props: {
		toastId: string
		type: ToastServiceTypes.ToastType
		body: string | React.ReactNode
		buttons?: LoKationButtonProps[]
		options?: ToastOptions
	}): ToastServiceTypes.ToastInstance {
		const toastBody = createToastBody({
			type: props.type,
			body: props.body,
			buttons: props.buttons,
		})

		toast.update(props.toastId, {
			render: toastBody,
		})

		return {
			id: props.toastId,
			dismiss: () => {
				toast.dismiss(props.toastId)
			},
			update: (options: UpdateOptions) => toast.update(props.toastId, options),
		}
	}

	function create(props: {
		type: ToastServiceTypes.ToastType
		body: string | React.ReactNode
		buttons?: LoKationButtonProps[]
		options?: ToastOptions
	}): ToastServiceTypes.ToastInstance {
		const toastBody = createToastBody({
			type: props.type,
			body: props.body,
			buttons: props.buttons,
		})

		const toastOptions: ToastOptions = {
			position: 'bottom-right',
			hideProgressBar: true,
			...props.options,
		}

		const toastId = toast(toastBody, toastOptions)

		return {
			id: toastId,
			dismiss: () => {
				toast.dismiss(toastId)
			},
			update: (options: UpdateOptions) => toast.update(toastId, options),
		}
	}

	function createToastBody(props: {
		type: ToastServiceTypes.ToastType
		body: string | React.ReactNode
		buttons?: LoKationButtonProps[]
	}): React.ReactNode {
		let icon: IconName
		let color: string
		switch (props.type) {
			case 'INFO':
				icon = 'circle-info'
				color = 'var(--colorAdjust40)'
				break
			case 'SUCCESS':
				icon = 'circle-check'
				color = 'var(--paletteColorSuccessLight)'
				break
			case 'WARNING':
				icon = 'triangle-exclamation'
				color = 'var(--paletteColorWarningLight)'
				break
			case 'ERROR':
				icon = 'circle-exclamation'
				color = 'var(--paletteColorWarningLight)'
				break
			case 'SPINNER':
				icon = 'spinner'
				color = 'var(--colorAdjust40)'
				break
		}

		return (
			<div className="toast__body">
				<div className="toast__icon">
					<FontAwesomeIcon icon={['far', icon]} spin={props.type === 'SPINNER'} color={color} />
				</div>
				<div className="flex-fillSpace">
					<div>{props.body}</div>
					{props.buttons && (
						<div className="flex flex-wrap mt-10">
							{props.buttons.map((buttonProps) => {
								return <LoKationButton className={'low-contrast-with-theme mr-10'} {...buttonProps} />
							})}
						</div>
					)}
				</div>
			</div>
		)
	}

	return { create, update }
}
