import { useEffect, useState } from 'react'

import { GoogleMapAPI } from '../google-map/google-map.api'
import { TextInput } from '../text-input/text-input'
import { GoogleAutocompleteTypes } from './google-autocomplete.types'

interface LocationAutocompleteProps {
	onPlaceSelected: (place: GoogleAutocompleteTypes.PlaceDetails) => void
}

export function LocationAutocomplete({ onPlaceSelected }: LocationAutocompleteProps) {
	const [value, setValue] = useState('')
	const [listVisible, setListVisible] = useState(true)
	const [predictions, setPredictions] = useState<GoogleAutocompleteTypes.AutocompletePrediction[]>([])
	const [debouncedValue, setDebouncedValue] = useState(value)
	const debounceDelay = 250

	// Debounce function
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value)
		}, debounceDelay)

		// Clear timeout if value changes
		return () => {
			clearTimeout(handler)
		}
	}, [value, debounceDelay])

	// Fetch suggestions using debounced value
	useEffect(() => {
		if (debouncedValue) {
			fetchAutocompleteSuggestions(debouncedValue)
		}
	}, [debouncedValue])

	async function fetchAutocompleteSuggestions(input: string) {
		GoogleMapAPI.fetchAutocompleteSuggestions(input).then((res) => {
			if (res) {
				setPredictions(res.data.predictions)
			}
		})
	}

	async function getPlaceDetails(placeId: string, itemDescription: string) {
		GoogleMapAPI.fetchPlaceDetails(placeId).then((res) => {
			if (res) {
				res.data.result.formatted_address = itemDescription
				onPlaceSelected(res.data.result)
				setListVisible(false)
			}
		})
	}

	return (
		<>
			<div className="mt-20">
				<TextInput
					dataType="text"
					type="text"
					label="Location"
					labelPlacement="top"
					value={typeof value === 'string' ? value : ''}
					width={'100%'}
					onChange={(updatedValue: string) => {
						setListVisible(true)
						// fetchAutocompleteSuggestions(updatedValue);
						setValue(updatedValue)
					}}
				/>
				<div
					style={{
						display: 'flex',
						flex: '1',
						flexDirection: 'column',
					}}
				>
					{listVisible && (
						<div>
							{predictions.map((item: GoogleAutocompleteTypes.AutocompletePrediction) => (
								<div
									key={item.description}
									onClick={() => {
										getPlaceDetails(item.place_id, item.description)
										setValue(item.description)
										setListVisible(false)
									}}
									style={{
										borderBottom: '1px solid #ccc',
										borderLeft: '1px solid #ccc',
										borderRight: '1px solid #ccc',
										padding: '5px',
										// marginBottom: "5px",
										cursor: 'pointer',
									}}
								>
									{item.description}
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		</>
	)
}
