import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { Modal } from '@components/modal/modal'
import Switch from '@components/switch/switch'
import { useEffect, useState } from 'react'
import { DateService } from 'src/services/date.service'
import { ToastService } from 'src/services/toast/toast.service'

import { AgentContractCard } from '../../../../../components/agent-contract-card/agent-contract-card'
import { AgentContractSignatureCard } from '../../../../../components/agent-contract-signature-card/agent-contract-signature-card'
import { AgentLicenseCard } from '../../../../../components/agent-license-card/agent-license-card'
import { LoKationButton } from '../../../../../components/button/button'
import { CollapsibleContent } from '../../../../../components/collapsible-content/collapsible-content'
import { ModalBody } from '../../../../../components/modal/modal-body'
import { ModalFooter } from '../../../../../components/modal/modal-footer'
import { ModalHeader } from '../../../../../components/modal/modal-header'
import { Paper } from '../../../../../components/paper/paper'
import { StripeAPI } from '../../../../../services/stripe/stripe.api'
import { LicenseInformationAPI } from '../../../../../services/user/license-information.api'
import { AgentLicense, EndUserProps } from '../../../../../services/user/user.types'
import { useUserProfileDispatch } from '../../state/user-profile__state'
import { UserProfileService } from '../../user-profile.service'
import ManagePaymentMethods from '../billing-components/manage-payment-methods'
import { LicensePlanManagement } from './add-new-agent-license.service'
import { LicensePlanMangementTypes } from './add-new-agent-license.types'

interface AddNewAgentLicenseModalProps {
	dismissModal: () => void
	canUserEdit: boolean
	userToEdit: EndUserProps
	onAddLicense: (license: AgentLicense.CompleteEntry) => void
	onUpdateLicense: (license: AgentLicense.CompleteEntry) => void
	existingLicenses: AgentLicense.Entry[]
	currentUser: EndUserProps | null
	licenseToEdit?: number
	rejoin: boolean
}

export function AddNewAgentLicenseModal(props: AddNewAgentLicenseModalProps) {
	const userProfileDispatch = useUserProfileDispatch()

	const [submitted, setSubmitted] = useState(false)
	const [licenseWasUpdated, setLicenseWasUpdated] = useState(false)
	const [subscriptionLoading, setSubscriptionLoading] = useState(false)
	const [openCollapsible, setOpenCollapsible] = useState<LicensePlanMangementTypes.AddNewLicenseSections>('LICENSE')
	const [newLicense, setNewLicense] = useState<AgentLicense.Entry>(
		LicensePlanManagement.getDefaultLicenseProps(props.userToEdit, props.existingLicenses),
	)
	const [disableFields, setDisableFields] = useState<boolean>(false)
	const [hasUserClickedNavButton, setHasUserClickedNavButton] = useState(false)
	let showSignatureError = false
	const [isAgreementChecked, setIsAgreementChecked] = useState(false)
	const [signature, setSignature] = useState('')
	const [oldPlan, setOldPlan] = useState<AgentLicense.PlanType | null>(null)
	const [oldPlanTimestamp, setOldPlanTimestamp] = useState<string | null>(null)
	const [quarterlyFees, setQuarterlyFees] = useState(0)
	const [existingQuarterlyFees, setExistingQuarterlyFees] = useState(0)
	const [prorationCost, setProrationCost] = useState(0)
	const hasBluePlan = LicensePlanManagement.getUserHasBluePlan(props.existingLicenses)
	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<string | undefined>('')
	const [newDefaultPaymentMethod, setNewDefaultPaymentMethod] = useState<string | undefined>('')
	const [isUserAdmin, setIsUserAdmin] = useState(false)
	const [billCustomer, setBillCustomer] = useState(true)
	const [userToBeBilled, setUserToBeBilled] = useState<number | undefined>(undefined)

	/** =================================== */
	/** Effect */

	useEffect(() => {
		if (props.currentUser && props.userToEdit) {
			const isAdminEditing = props.currentUser.endUserId !== props.userToEdit.endUserId

			// Set the user to be billed if an admin is editing
			if (isAdminEditing) {
				setUserToBeBilled(props.userToEdit.endUserId)
			} else {
				fetchUserDefaultPaymentMethod()
			}
		}

		if (props.currentUser) {
			const isAdminCheck = props.currentUser.roles.some((role) => role.roleType === 'SUPER_ADMIN')
			setIsUserAdmin(isAdminCheck)
		}
	}, [props.currentUser, props.userToEdit])

	useEffect(() => {
		// Check if admin is accessing user's profile to add license - fetch correct default payment method
		if (userToBeBilled) {
			fetchUserDefaultPaymentMethod(userToBeBilled)
		}
	}, [userToBeBilled])

	useEffect(() => {
		const matchingLicense = props.existingLicenses.find(
			(license) => license.licenseInformationId === props.licenseToEdit,
		)
		if (matchingLicense) {
			setOldPlan(matchingLicense.plan)
			setOldPlanTimestamp(matchingLicense.planChangedTimestamp as string)
			setDisableFields(true)
			setNewLicense({
				licenseInformationId: matchingLicense.licenseInformationId,
				licenseNumber: matchingLicense.licenseNumber,
				licensedState: matchingLicense.licensedState,
				ppUserId: matchingLicense.ppUserId,
				region: matchingLicense.region,
				endUser: props.userToEdit,
				plan: matchingLicense.plan,
				licenseAgreement: matchingLicense.licenseAgreement,
			})
		} else {
			setDisableFields(false)
			setNewLicense(LicensePlanManagement.getDefaultLicenseProps(props.userToEdit, props.existingLicenses))
		}
	}, [props.existingLicenses, props.licenseToEdit, props.userToEdit])

	useEffect(() => {
		if (newLicense) {
			const combinedLicenses = [
				...(props.existingLicenses as AgentLicense.Entry[]),
				newLicense as AgentLicense.Entry,
			]

			setQuarterlyFees(
				LicensePlanManagement.calculateTotalComplianceFees(combinedLicenses as AgentLicense.Entry[]),
			)
			setExistingQuarterlyFees(
				LicensePlanManagement.calculateExistingComplianceFees(props.existingLicenses as AgentLicense.Entry[]),
			)
		}
	}, [openCollapsible])

	useEffect(() => {
		setProrationCost(LicensePlanManagement.calculateCompliaceFeeDifference(existingQuarterlyFees))
	}, [quarterlyFees])

	/** =================================== */
	/** Methods */

	/** Changes which step within the modal is currently open */
	function toggleCollapsible(type: LicensePlanMangementTypes.AddNewLicenseSections) {
		setOpenCollapsible((prevState) => (prevState === type ? 'NONE' : type))
	}

	async function runSubscription() {
		if (!newLicense.licensedState) {
			throw new Error('Licensed state is not set')
		}

		if (!billCustomer) {
			setSubmitted(true)
			return
		}

		const { priceIds, futurePriceIds } = LicensePlanManagement.calculatePriceIds(newLicense)
		const updatedFuturePriceIds = oldPlan && !props.rejoin ? [] : futurePriceIds
		let promotionCode = null

		if (props.rejoin) {
		} else if (
			oldPlan &&
			((DateService.billPlanChangeCheck(oldPlanTimestamp as string) && newLicense.plan === 'BLUE') ||
				newLicense.plan === 'SILVER')
		) {
			promotionCode = 'PLANCHANGEPROMO'
		}

		if (priceIds.length > 0) {
			if (userToBeBilled) {
				await StripeAPI.createNewSubscription(priceIds, updatedFuturePriceIds, userToBeBilled, promotionCode)
			} else {
				await StripeAPI.createNewSubscription(priceIds, updatedFuturePriceIds, null, promotionCode)
			}

			console.log('Subscription was created')
			setSubmitted(true)
		} else {
			throw new Error('No price IDs to create a subscription')
		}
	}

	function getIsLicenseValid(): boolean {
		const { priceIds } = LicensePlanManagement.calculatePriceIds(newLicense)
		console.log(priceIds)

		/** Validate whether the license is ready to be submitted or not */
		if (!signature) {
			ToastService().create({
				type: 'ERROR',
				body: `You must sign the agreement`,
			})
			setSubscriptionLoading(false)
			return false
		}
		if (!isAgreementChecked) {
			ToastService().create({
				type: 'ERROR',
				body: `You must agree to the terms on the agreement`,
			})
			setSubscriptionLoading(false)
			return false
		}
		if (!UserProfileService().validateLicenses([newLicense])) {
			ToastService().create({
				type: 'ERROR',
				body: `Your licenses are not valid`,
			})
			setSubscriptionLoading(false)
			return false
		}
		if (
			props.existingLicenses?.some(
				(license) => license.licensedState?.licensedStateId === newLicense.licensedState?.licensedStateId,
			) &&
			newLicense.licenseInformationId < 0
		) {
			ToastService().create({
				type: 'ERROR',
				body: `You cannot have multiple licenses within the same state`,
			})
			setSubscriptionLoading(false)
			return false
		}

		if (disableFields && oldPlan === newLicense.plan && !props.rejoin) {
			ToastService().create({
				type: 'ERROR',
				body: `Your plan must change`,
			})
			setSubscriptionLoading(false)
			return false
		}

		if (!newDefaultPaymentMethod && !defaultPaymentMethod && billCustomer) {
			ToastService().create({
				type: 'ERROR',
				body: `You must select a payment method`,
			})
			setSubscriptionLoading(false)
			return false
		}

		return true
	}

	async function submitNewLicense(): Promise<void> {
		return new Promise(async (resolve, reject) => {
			setSubscriptionLoading(true)

			const isLicenseValid = getIsLicenseValid()

			if (!isLicenseValid) {
				reject()
				return
			}

			try {
				const completeLicense = newLicense as AgentLicense.CompleteEntry
				const licenseWithContract = await UserProfileService().attachContractToLicense(
					completeLicense,
					signature,
					props.userToEdit,
				)

				if (disableFields) {
					LicenseInformationAPI.deleteLicense(
						props.userToEdit.endUserId,
						licenseWithContract.licenseInformationId,
					)
					userProfileDispatch({ type: 'remove-license', payload: licenseWithContract.licenseInformationId })
					const randomNegativeNumber = Math.floor(Math.random() * -1000)
					licenseWithContract.licenseInformationId = randomNegativeNumber
					setLicenseWasUpdated(true)
				}

				const addedLicense = await LicenseInformationAPI.addLicense(
					props.userToEdit.endUserId,
					licenseWithContract,
				)
				const formattedAddedLicense = addedLicense.data as AgentLicense.CompleteEntry
				await runSubscription()
				props.onAddLicense(formattedAddedLicense)
				resolve()
			} catch (error) {
				console.error('Error in submitNewLicense: ', error)
				reject()
			}
		})
	}

	// Function to handle payment method change
	const handlePaymentMethodChange = (paymentMethodId: string) => {
		if (paymentMethodId !== 'add-new') {
			setNewDefaultPaymentMethod(paymentMethodId)
			StripeAPI.setDefaultPaymentMethod(paymentMethodId, userToBeBilled)
				.then((res) => {
					console.log('new payment method set as default')
				})
				.catch((error) => {
					console.log('error setting default', error)
				})
		}
	}

	function fetchUserDefaultPaymentMethod(endUserId?: number) {
		StripeAPI.fetchCustomerDefaultPaymentMethod(endUserId)
			.then((res) => {
				setDefaultPaymentMethod(res.id)
			})
			.catch((error) => {
				setDefaultPaymentMethod('')
				console.log('Error fetching default payment method:', error)
				return null
			})
	}

	const handleBillCustomerSwitchChange = () => {
		setBillCustomer((prevState) => !prevState)
	}

	/** =================================== */
	/** Render Component */

	if (!props.userToEdit) {
		return <>ERROR</>
	}

	return (
		<Modal
			onClose={props.dismissModal}
			maxWidth={800}
			maxHeight={850}
			fixedHeight={false}
			className="overflow-y__scroll"
		>
			<ModalHeader
				title={
					submitted
						? licenseWasUpdated
							? 'Your license has been updated!'
							: 'Your new license has been added!'
						: `${oldPlan ? 'Your Plan Change Information' : 'Add your new license information'} `
				}
			>
				<></>
			</ModalHeader>
			<ModalBody>
				<div className="col-12 flex flex-wrap pb-20">
					{submitted && !licenseWasUpdated && (
						<div>
							Thank you for subscribing to a LoKation license plan! You will receive a confirmation email
							shortly.
						</div>
					)}

					{submitted && licenseWasUpdated && !props.rejoin && (
						<div>
							Thank you for updating your LoKation license plan! You will receive a confirmation email
							shortly.
						</div>
					)}

					{submitted && licenseWasUpdated && props.rejoin && (
						<div>Thank you for re-activating your LoKation account - we're glad to have you back!</div>
					)}

					{!submitted && (
						<>
							<Paper
								bgColor="primary"
								padding={['all']}
								marginSize="section"
								margins={['bottom']}
								style={{ width: '100%' }}
								elevate={true}
							>
								<CollapsibleContent
									label={'Add your license info'}
									state={openCollapsible === 'LICENSE' ? 'OPEN' : 'CLOSED'}
									setState={() => toggleCollapsible('LICENSE')}
									className="mt-10 px-10"
								>
									<>
										<AgentLicenseCard
											license={newLicense}
											canUserEdit={true}
											userToEdit={props.userToEdit}
											paymentFlow={true}
											updateFlow={disableFields}
											onUpdateLicense={(license) => {
												setNewLicense(license)
											}}
											onDeleteLicense={() => {
												/** empty */
											}}
											userEditPlan={() => {
												/** empty */
											}}
											rejoin={props.rejoin}
											showLicenseNumberTooltip={!oldPlan}
										/>

										<div className="flex flex-justifyContent-end">
											<LoKationButton
												icon={'plus'}
												size="sm"
												variant={'outlined'}
												label="Next"
												className="mt-10"
												onClick={() => {
													setOpenCollapsible('AGREEMENT')
												}}
											/>
										</div>
									</>
								</CollapsibleContent>
							</Paper>

							<Paper
								bgColor="primary"
								padding={['all']}
								marginSize="section"
								margins={['bottom']}
								style={{ width: '100%' }}
								elevate={true}
							>
								<CollapsibleContent
									label={'Sign your License Agreement'}
									state={openCollapsible === 'AGREEMENT' ? 'OPEN' : 'CLOSED'}
									setState={() => toggleCollapsible('AGREEMENT')}
									className="mt-10 px-10"
								>
									<>
										<div>
											<AgentContractCard
												license={newLicense}
												firstName={props.userToEdit.firstName}
												lastName={props.userToEdit.lastName}
												signature={signature}
												hasUserClickedNavButton={hasUserClickedNavButton}
												hasAgreed={isAgreementChecked}
												onChange={(newState) => {
													setIsAgreementChecked(newState)
												}}
											/>
										</div>

										<AgentContractSignatureCard
											firstName={props.userToEdit.firstName}
											lastName={props.userToEdit.lastName}
											hasUserClickedNavButton={hasUserClickedNavButton}
											signature={signature}
											onChange={(newState) => {
												setSignature(newState)
											}}
											showSignatureError={showSignatureError}
										/>

										<div className="flex flex-justifyContent-end">
											<LoKationButton
												icon={'plus'}
												size="sm"
												variant={'outlined'}
												label="Proceed to Payment"
												className="mt-10"
												onClick={() => {
													setHasUserClickedNavButton(true)
													setOpenCollapsible('PAYMENT')
												}}
											/>
										</div>
									</>
								</CollapsibleContent>
							</Paper>

							<Paper
								bgColor="primary"
								padding={['all']}
								marginSize="section"
								margins={['bottom']}
								style={{ width: '100%' }}
								elevate={true}
							>
								<CollapsibleContent
									label={'Choose your payment method'}
									state={openCollapsible === 'PAYMENT' ? 'OPEN' : 'CLOSED'}
									setState={() => toggleCollapsible('PAYMENT')}
									className="mt-10 px-10"
								>
									<>
										<div>
											{isUserAdmin && (
												<>
													<div className="flex flex-alignItems-center mb-20">
														<strong>Should the customer be billed?</strong>
														<Switch
															className="mx-5"
															checked={billCustomer}
															onChange={handleBillCustomerSwitchChange}
														/>
														<span>{billCustomer ? 'Yes' : 'No'}</span>
													</div>
													<p>
														Note: If this is set to "Yes", then the customer's payment
														method will be charged and a subscription will be created in
														Stripe
													</p>
												</>
											)}
											{billCustomer && (
												<ManagePaymentMethods
													key={defaultPaymentMethod}
													defaultPaymentMethod={defaultPaymentMethod}
													onPaymentMethodChange={handlePaymentMethodChange}
													endUserId={userToBeBilled}
												/>
											)}
										</div>

										<div className="flex flex-justifyContent-end">
											<LoKationButton
												icon={'plus'}
												size="sm"
												variant={'outlined'}
												label="Next"
												className="mt-10"
												onClick={() => {
													setHasUserClickedNavButton(true)
													setOpenCollapsible('PURCHASE')
												}}
												// disabled={!newDefaultPaymentMethod && !defaultPaymentMethod}
												disabled={
													!newDefaultPaymentMethod && !defaultPaymentMethod && billCustomer
												}
											/>
										</div>
									</>
								</CollapsibleContent>
							</Paper>

							<Paper
								bgColor="primary"
								padding={['all']}
								marginSize="section"
								margins={['bottom']}
								style={{ width: '100%' }}
								elevate={true}
							>
								<CollapsibleContent
									label={'Confirm your subscription'}
									state={openCollapsible === 'PURCHASE' ? 'OPEN' : 'CLOSED'}
									setState={() => toggleCollapsible('PURCHASE')}
									className="mt-10 px-10"
								>
									<>
										{!submitted && subscriptionLoading ? (
											<GenericContentLoader height={200} width={'fill'} />
										) : (
											<div className="p-20 mt-10">
												<>
													{isUserAdmin && !billCustomer ? (
														<>
															<div className="col-xs-12">
																<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween border-bottom-thin py-10">
																	<div>
																		<strong>
																			{newLicense.licensedState?.stateName}{' '}
																			{newLicense.plan} Plan
																		</strong>
																	</div>
																	{newLicense.plan === 'BLUE' && (
																		<div>
																			<em>$0/month</em>
																		</div>
																	)}
																	{newLicense.plan === 'SILVER' && (
																		<div>
																			<em>$0/year</em>
																		</div>
																	)}
																</div>
																{newLicense.plan === 'BLUE' && (
																	<div className="flex flex-column">
																		<div className="flex flex-justifyContent-end col-xs-12 border-bottom-thin py-10">
																			<strong className="mr-10">
																				Monthly cost:
																			</strong>{' '}
																			<div className="cost-num">$0</div>
																		</div>
																	</div>
																)}
																{newLicense.plan === 'SILVER' && (
																	<div className="flex flex-column">
																		<div className="flex flex-justifyContent-end col-xs-12 border-bottom-thin py-10">
																			<strong className="mr-10">
																				Yearly cost:
																			</strong>{' '}
																			<div className="cost-num">$0</div>
																		</div>
																	</div>
																)}
															</div>

															<div className="flex flex-justifyContent-end col-xs-12 border-bottom-thin py-10">
																<div className="mr-10">
																	<strong>Quarterly Compliance Fee:</strong>
																</div>{' '}
																<div className="cost-num">$0</div>
															</div>

															<div className="flex flex-justifyContent-end col-xs-12 border-bottom-thin py-10">
																<div className="mr-10">
																	<strong>
																		Customer will not be billed for this transaction
																	</strong>
																</div>
															</div>
														</>
													) : (
														<>
															<div className="col-xs-12">
																<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween py-10">
																	<div>
																		<strong>
																			{newLicense.licensedState?.stateName}{' '}
																			{newLicense.plan} Plan
																		</strong>
																	</div>
																	{newLicense.plan === 'BLUE' && (
																		<div>
																			<em>{hasBluePlan ? '$20' : '$99'}/month</em>
																		</div>
																	)}
																	{newLicense.plan === 'SILVER' && (
																		<div>
																			<em>$99/year</em>
																		</div>
																	)}
																</div>
																<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween border-bottom-thin pb-10">
																	<div>
																		<strong>
																			Quarterly Compliance Fee (this includes all
																			licenses):
																		</strong>
																	</div>
																	<div className="cost-num">
																		{quarterlyFees > 50
																			? '$50'
																			: '$' + quarterlyFees}
																	</div>
																</div>

																{newLicense.plan === 'BLUE' && (
																	<div className="flex flex-column">
																		<div className="flex flex-justifyContent-end col-xs-12 border-bottom-thin py-10">
																			<strong className="mr-10">
																				Monthly cost:
																			</strong>{' '}
																			<div className="cost-num">
																				{hasBluePlan ? '$20' : '$99'}
																			</div>
																		</div>
																	</div>
																)}
																{newLicense.plan === 'SILVER' && (
																	<div className="flex flex-column">
																		<div className="flex flex-justifyContent-end col-xs-12 border-bottom-thin py-10">
																			<strong className="mr-10">
																				Yearly cost:
																			</strong>{' '}
																			<div className="cost-num">$99</div>
																		</div>
																	</div>
																)}
															</div>

															<div className="flex flex-justifyContent-end col-xs-12 border-bottom-thin py-10">
																<div className="mr-10">
																	<strong>Prorated Quarterly Compliance Fee:</strong>
																</div>{' '}
																<div className="cost-num">
																	${oldPlan && !props.rejoin ? 0 : prorationCost}
																</div>
															</div>

															<div className="flex flex-justifyContent-end py-10">
																<strong className="mr-10">Total due:</strong>{' '}
																<div className="cost-num">
																	{newLicense.plan === 'SILVER'
																		? '$' +
																			((oldPlan && !props.rejoin ? 0 : 99) +
																				(oldPlan && !props.rejoin
																					? 0
																					: prorationCost))
																		: newLicense.plan === 'BLUE'
																			? hasBluePlan
																				? '$' +
																					(
																						(oldPlan &&
																						!props.rejoin &&
																						DateService.billPlanChangeCheck(
																							oldPlanTimestamp as string,
																						)
																							? 0
																							: 20) +
																						(oldPlan && !props.rejoin
																							? 0
																							: prorationCost)
																					).toFixed(2)
																				: '$' +
																					(
																						(oldPlan && !props.rejoin
																							? 0
																							: 99) +
																						(oldPlan && !props.rejoin
																							? 0
																							: prorationCost)
																					).toFixed(2)
																			: 'N/A'}
																</div>
															</div>

															<div
																className="flex flex-justifyContent-end col-xs-12  py-10"
																style={{ maxWidth: '860px' }}
															>
																<em>
																	* The quarterly compliance fee is billed on January
																	15, April 15, July 15, and October 15. If you join
																	the company mid-quarter, the first charge is
																	prorated based on the remaining days until the next
																	billing date.
																</em>{' '}
															</div>
														</>
													)}
												</>
											</div>
										)}

										<div className="flex flex-justifyContent-end">
											<div className="flex flex-alignItems-center">
												<LoKationButton
													size="sm"
													variant={'outlined'}
													className="mt-10"
													label={
														!submitted && subscriptionLoading
															? 'Completing Purchase...'
															: 'Complete Purchase'
													}
													disabled={!submitted && subscriptionLoading}
													onClick={submitNewLicense}
												/>
											</div>
										</div>
									</>
								</CollapsibleContent>
							</Paper>
						</>
					)}
				</div>
			</ModalBody>
			<ModalFooter>
				{submitted && !props.rejoin && (
					<LoKationButton
						icon={'plus'}
						size="sm"
						variant={'outlined'}
						label="Close"
						className="mt-10"
						onClick={() => {
							props.dismissModal()
						}}
					/>
				)}
				{submitted && props.rejoin && (
					<LoKationButton
						icon={'plus'}
						size="sm"
						variant={'outlined'}
						label="Go To The Sphere"
						className="mt-10"
						onClick={() => {
							props.dismissModal()
						}}
					/>
				)}
			</ModalFooter>
		</Modal>
	)
}
