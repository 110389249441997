import { httpRequest, httpRequestNoErrors, RequestBaseURL } from '../axios/http-common.service'
import { StripeTypes } from './stripe.types'

class StripeCouponAPIPrototype {
	async validateCoupon(couponCode: string): Promise<StripeTypes.CouponValidatedResponse> {
		try {
			const url = `${RequestBaseURL}/api/v1/stripe/coupon/validate`

			const response = await httpRequest.post(url, couponCode, {
				headers: {
					'Content-Type': 'text/plain',
				},
			})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to fetch coupon.')
		}
	}

	async couponSearch(): Promise<StripeTypes.FetchCouponsResponse> {
		try {
			const url = `${RequestBaseURL}/api/v1/stripe/coupon/search`

			const response = await httpRequest.get(url)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to fetch coupon coupons.')
		}
	}

	async getAssociatedPromotions(couponId: string): Promise<StripeTypes.FetchPromotionResponse> {
		try {
			const url = `${RequestBaseURL}/api/v1/stripe/coupon/promotions`

			const response = await httpRequestNoErrors.get(url, {
				params: {
					couponId: couponId,
				},
			})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to fetch coupon.')
		}
	}

	async updatePromotionCode(promotionId: string, archive: boolean): Promise<StripeTypes.Promotion> {
		try {
			const url = `${RequestBaseURL}/api/v1/stripe/coupon/promotions/${promotionId}?archive=${archive}`

			const response = await httpRequest.put(url, {
				params: {
					archive: archive,
				},
			})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to archive or unarchive promotion code.')
		}
	}

	async createCoupon(couponObj: StripeTypes.CouponData): Promise<StripeTypes.CouponData> {
		try {
			const url = `${RequestBaseURL}/api/v1/stripe/coupon/create`

			const response = await httpRequest.post(url, couponObj, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to create coupon.')
		}
	}

	async createPromotionCode(promoCodeObj: StripeTypes.PromotionCodeCreation): Promise<StripeTypes.Promotion> {
		try {
			const url = `${RequestBaseURL}/api/v1/stripe/coupon/promotions/create`

			const response = await httpRequest.post(url, promoCodeObj, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to create promotion code.')
		}
	}

	async deleteCoupon(couponId: string): Promise<StripeTypes.DeleteCouponResponse> {
		try {
			const response = await httpRequest.delete(`${RequestBaseURL}/api/v1/stripe/coupon/delete/${couponId}`)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to delete subscription.')
		}
	}

	// findUsersInDomainAdmin(couponObj: StripeTypes.CouponData): Promise<AxiosResponse<StripeTypes.CouponData>> {
	// 	let url = `${RequestBaseURL}/api/v1/stripe/coupon/create`

	// 	/** Set params */
	// 	const params = Utilities.createSearchParamsFromObj({ ...couponObj })

	// 	if (couponObj.amountOff !== null) {
	// 		params.append('amount_off', couponObj.amountOff.toString())
	// 	}

	// 	if (couponObj.percentOff !== null) {
	// 		params.append('percent_off', couponObj.percentOff.toString())
	// 	}

	// 	if (couponObj.duration) {
	// 		params.append('duration', couponObj.duration)
	// 	}

	// 	const headers = {}
	// 	const requestPromise = httpRequest.get(url, {
	// 		headers,
	// 		params,
	// 	})
	// 	return requestPromise
	// }
}

export const StripeCouponAPI = new StripeCouponAPIPrototype()
