import { RootState } from '@redux/store'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { ColorService } from '../../../services/color/color.service'
import { DomainTheme } from '../../../services/theme/theme.types'

interface ConnectedProps {
	theme: DomainTheme.CompleteTheme
}

interface DesktopAppTrayHomeButtonProps {
	isTrayOpen: boolean
}

function DesktopAppTrayHomeButtonPrototype(props: DesktopAppTrayHomeButtonProps & ConnectedProps) {
	return (
		<Link className="flex flex-alignItems-center app-tray__home-btn" to={`/hubs/`}>
			{ColorService.getColorTone(props.theme.appTrayBgColor) === 'dark' && (
				<img src={props.theme.logoMarkWhite} alt="logo" className="app-tray__logo__mark" />
			)}
			{ColorService.getColorTone(props.theme.appTrayBgColor) === 'light' && (
				<img src={props.theme.logoMark} alt="logo" className="app-tray__logo__mark" />
			)}

			{props.isTrayOpen && (
				<>
					{ColorService.getColorTone(props.theme.appTrayBgColor) === 'dark' && (
						<img src={props.theme.logoWhite} alt="logo" className="app-tray__logo__word" />
					)}
					{ColorService.getColorTone(props.theme.appTrayBgColor) === 'light' && (
						<img src={props.theme.logo} alt="logo" className="app-tray__logo__word" />
					)}
				</>
			)}
		</Link>
	)
}

function mapStateToProps(state: RootState, ownProps: DesktopAppTrayHomeButtonProps) {
	return {
		theme: state.theme,
	}
}

export const DesktopAppTrayHomeButton = connect(mapStateToProps)(DesktopAppTrayHomeButtonPrototype)
