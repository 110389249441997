import { SideSheet__RowProps } from '@components/side-sheet/side-sheet.types'
import { Tooltip } from '@components/tooltip/tooltip'
import React from 'react'

/** Building block of a side sheet. The Side Sheet Row is used to display a single property within a side sheet section. */
export function SideSheet__Row(props: SideSheet__RowProps) {
	const direction = props.direction ? props.direction : 'row'

	/** ============================== */
	/** Methods */

	function getRowLabel(): React.ReactNode {
		if (props.variant === 'header') {
			return <strong>{props.label}</strong>
		}
		return (
			<strong className=" text-semibold color__adjust-80">
				{props.label}
				{props.tooltip && <Tooltip body={props.tooltip} className="ml-5" />}
			</strong>
		)
	}

	/** ============================== */
	/** Render Component */

	switch (direction) {
		case 'row':
			return (
				<div
					className="flex flex-alignItems-center flex-justifyContent-spaceBetween"
					style={{ minHeight: '40px' }}
				>
					<div className="flex flex-alignItems-center mr-20">{getRowLabel()}</div>
					<div className="flex flex-alignItems-center pl-20 text-right">{props.children}</div>
				</div>
			)
		case 'column':
			return (
				<div style={{ minHeight: '40px' }}>
					<div className="flex flex-alignItems-center mb-5">{getRowLabel()}</div>
					<div className="flex flex-alignItems-center">{props.children}</div>
				</div>
			)
	}
}
