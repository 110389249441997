import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserAPI } from 'src/services/user/user.api'
import { EndUserLanguageSpecialty } from 'src/services/user/user.types'

const initialState: EndUserLanguageSpecialty[] = []

export const getLanguageSpecialties = createAsyncThunk('languageSpecialties/getLanguageSpecialties', async () => {
	const res = await UserAPI.getLanguageSpecialties()
	return res.data
})

export const languageSpecialtiesSlice = createSlice({
	name: 'languageSpecialties',
	initialState,
	reducers: {
		setLanguageSpecialties: (state, action: PayloadAction<EndUserLanguageSpecialty[]>) => {
			return action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getLanguageSpecialties.fulfilled, (state, action) => {
			return action.payload
		})
	},
})

export const { setLanguageSpecialties } = languageSpecialtiesSlice.actions

export default languageSpecialtiesSlice.reducer
