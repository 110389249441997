import { LicensedState } from '../../services/licensed-states/licenses-states.types'
import { AgentLicense } from '../../services/user/user.types'
import { Utilities } from '../../services/utilities.service'

export function AgentLicenseCardService() {
	function createNewLicenseProps(props: {
		endUserId?: number
		licensedState?: LicensedState
		plan?: AgentLicense.PlanType
	}): AgentLicense.IncompleteEntry {
		const newLicense: AgentLicense.IncompleteEntry = {
			licensedState: null,
			licenseNumber: '',
			region: null,
			plan: null,
			licenseInformationId: 0 - Utilities.generateRandomInt(1, 10000000),
			ppUserId: '',
			endUser: null,
			licenseAgreement: null,
		}

		if (props.endUserId) {
			newLicense.endUser = {
				endUserId: props.endUserId,
			}
		}

		if (props.licensedState) {
			newLicense.licensedState = props.licensedState
		}

		if (props.plan) {
			newLicense.plan = props.plan
		}

		return newLicense
	}

	return { createNewLicenseProps }
}
