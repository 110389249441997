import './news-feed__story.scss'

import { NewsFeed } from '../../services/news-feed/news-feed.types'

export function NewsFeedStory(props: { story: NewsFeed.Story; className?: string }) {
	return (
		<div className={`news-feed__story ${props.className}`}>
			<h1 className="mb-20">{props.story.title}</h1>
			<div dangerouslySetInnerHTML={{ __html: props.story.description }}></div>
		</div>
	)
}
