import { EmailNotificationComponent } from '@components/email-notification/email-notification'

import Opcity from './opcity.png'

export function LoKationResourceOpCity() {
	return (
		<div className="flex flex-column overflow-y__scroll">
			<div className="flex flex-wrap">
				<div className="col-xs-12 col-sm-6 pr-20-sm">
					<img src={Opcity} style={{ height: '60px' }} />
					<p>
						Opcity is a preferred vendor for LoKation® providing pre-qualified leads to agents. With Inside
						Sales Agents calling every lead and providing a live handoff, Opcity delivers close rates 3-5x's
						the industry average.
					</p>
					<p>
						By clicking on sign-up link, LoKation® will initiate the account creation for you and be back
						in touch to finalize your Lead Generation Account.
					</p>
					<em>
						"I love how quickly I am connected to the customer with Opcity. As an agent I am not paying
						upfront for leads and only pay the referral fee when the transaction closes. Whenever I have a
						question I can always talk to support." — Frank A
					</em>
				</div>
				<div className="col-xs-12 col-sm-6 pl-20-sm"></div>
			</div>
			<EmailNotificationComponent serviceToNotify={'opcity'} title="Register for Opcity" />
		</div>
	)
}
