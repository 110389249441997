import './option-list.scss'

import { OptionListProps } from './option-list.types'

export function OptionList<T>(props: OptionListProps.Component<T>) {
	function renderOption(option: OptionListProps.Option<T>): React.ReactNode {
		let optionKey = 'UNKNOWN'
		if (typeof option.value === 'string') {
			optionKey = option.value
		} else if (typeof option.value === 'number') {
			optionKey = String(option.value)
		}

		return (
			<div
				key={optionKey}
				className={`option-list__option ${props.persistent && props.value === option.value ? 'selected' : ''}`}
				onClick={() => {
					props.onClick(option.value)
				}}
			>
				{option.label}
			</div>
		)
	}

	return (
		<div className="col-xs-12">
			{props.options.map((option) => {
				return renderOption(option)
			})}
		</div>
	)
}
