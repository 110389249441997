import { modalSlice } from '@redux/reducers/modal-reducer'
import { useEffect, useReducer } from 'react'
import { useDispatch as useReduxDispatch } from 'react-redux'

import { StripeAPI } from '../../../../services/stripe/stripe.api'

const FETCH_LIMIT = 50

interface MyState {
	activeProductIds: string[]
	productToSubscribe: string | null
	optionalProductToSubscribe: string | null
	productInitialPriceId: string | null
	productTitle: string | null
	productPrice: string | null
	subscriptionType: string | null
	productInitialTotal: number | null
	productRecurringTotal: number | null
	modalId: string | null
}

type Action =
	| { type: 'setActiveProductIds'; payload: string[] }
	| { type: 'setProductToSubscribe'; payload: string | null }
	| { type: 'setOptionalProductToSubscribe'; payload: string | null }
	| { type: 'setProductInitialPriceId'; payload: string | null }
	| { type: 'setProductTitle'; payload: string | null }
	| { type: 'setProductPrice'; payload: string | null }
	| { type: 'setSubscriptionType'; payload: string | null }
	| { type: 'setProductInitialTotal'; payload: number | null }
	| { type: 'setProductRecurringTotal'; payload: number | null }
	| { type: 'setModalId'; payload: string | null }

const initialState: MyState = {
	activeProductIds: [''],
	productToSubscribe: null,
	optionalProductToSubscribe: null,
	productInitialPriceId: null,
	productTitle: '',
	productPrice: '',
	subscriptionType: '',
	productInitialTotal: null,
	productRecurringTotal: null,
	modalId: null,
}

const reducer = (state: MyState, action: Action): MyState => {
	switch (action.type) {
		case 'setActiveProductIds':
			return { ...state, activeProductIds: action.payload }
		case 'setOptionalProductToSubscribe':
			return { ...state, optionalProductToSubscribe: action.payload }
		case 'setProductToSubscribe':
			return { ...state, productToSubscribe: action.payload }
		case 'setProductInitialPriceId':
			return { ...state, productInitialPriceId: action.payload }
		case 'setProductTitle':
			return { ...state, productTitle: action.payload }
		case 'setProductPrice':
			return { ...state, productPrice: action.payload }
		case 'setSubscriptionType':
			return { ...state, subscriptionType: action.payload }
		case 'setProductInitialTotal':
			return { ...state, productInitialTotal: action.payload }
		case 'setProductRecurringTotal':
			return { ...state, productRecurringTotal: action.payload }
		case 'setModalId':
			return { ...state, modalId: action.payload }
		default:
			throw new Error()
	}
}

export const useAddOnService = (endUserId: number | undefined) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const reduxDispatch = useReduxDispatch()

	const setActiveProductIds = (ids: string[]) => dispatch({ type: 'setActiveProductIds', payload: ids })
	const setProductToSubscribe = (id: string | null) => dispatch({ type: 'setProductToSubscribe', payload: id })
	const setOptionalProductToSubscribe = (id: string | null) =>
		dispatch({ type: 'setOptionalProductToSubscribe', payload: id })
	const setProductInitialPriceId = (id: string | null) => dispatch({ type: 'setProductInitialPriceId', payload: id })
	const setProductTitle = (ids: string) => dispatch({ type: 'setProductTitle', payload: ids })
	const setProductPrice = (id: string) => dispatch({ type: 'setProductPrice', payload: id })
	const setSubscriptionType = (id: string) => dispatch({ type: 'setSubscriptionType', payload: id })
	const setProductInitialTotal = (ids: number | null) => dispatch({ type: 'setProductInitialTotal', payload: ids })
	const setProductRecurringTotal = (id: number | null) => dispatch({ type: 'setProductRecurringTotal', payload: id })
	const setModalId = (id: string | null) => dispatch({ type: 'setModalId', payload: id })

	function fetchActiveSubscriptions(endUserId: number | undefined) {
		return StripeAPI.fetchSubscriptions(FETCH_LIMIT, undefined, endUserId)
			.then((res) => {
				const subscriptions = res
				const activeProductIds = subscriptions
					.flatMap((subscription) => {
						if (subscription.status === 'active') {
							return subscription.items.data.map((item) => item.plan.productIdentifier)
						}
						return [] // Return an empty array for inactive subscriptions
					})
					.filter((id): id is string => id !== null && id !== undefined)
				if (activeProductIds.length > 0) {
					setActiveProductIds(activeProductIds)
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	useEffect(() => {
		if (endUserId !== undefined) {
			fetchActiveSubscriptions(endUserId)
		}
	}, [endUserId])

	const manageSubscription = (
		productTitle: string,
		productPrice: string,
		subscriptionType: string,
		productId: string,
		productInitialCost: number,
		productRecurringCost: number,
		recurringPriceId: string,
		initialPriceId?: string,
		optionalRecurringPriceId?: string,
	) => {
		const id = `manageSubscriptionModal_${productId}`
		setModalId(id)
		setProductTitle(productTitle)
		setProductPrice(productPrice)
		setSubscriptionType(subscriptionType)
		setProductInitialTotal(productInitialCost)
		setProductRecurringTotal(productRecurringCost)
		setProductToSubscribe(recurringPriceId)

		if (initialPriceId) {
			setProductInitialPriceId(initialPriceId)
		}

		if (optionalRecurringPriceId) {
			setOptionalProductToSubscribe(optionalRecurringPriceId)
		}

		reduxDispatch(modalSlice.actions.updateVisibility({ id, state: true }))
	}

	const handleDismissModal = (endUserId: number | undefined) => {
		const currentModalId = state.modalId
		fetchActiveSubscriptions(endUserId)
		setProductToSubscribe(null)
		setProductInitialPriceId(null)
		setModalId(null)
		if (!currentModalId) {
			return
		}
		reduxDispatch(modalSlice.actions.updateVisibility({ id: currentModalId, state: false }))
	}

	return { ...state, fetchActiveSubscriptions, manageSubscription, handleDismissModal }
}
