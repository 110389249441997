import { httpRequest, RequestBaseURL } from '../axios/http-common.service'

class LeadExportPrototype {
	async getLeadExport(): Promise<void> {
		try {
			const response = await httpRequest({
				method: 'get',
				url: `${RequestBaseURL}/api/v1/leads/export`,
				responseType: 'blob', // set the response type to blob
			})

			const url = window.URL.createObjectURL(response.data)
			const formattedDate = this.getFormattedDate()
			const filename = `lead_list_${formattedDate}.xlsx`

			const a = document.createElement('a')
			a.href = url
			a.download = filename
			document.body.appendChild(a)
			a.click()
			a.remove()

			window.URL.revokeObjectURL(url)
		} catch (error) {
			console.log(error)
			throw new Error('Failed to get user list.')
		}
	}

	getFormattedDate(): string {
		const today = new Date()
		const year = today.getFullYear()
		const month = String(today.getMonth() + 1).padStart(2, '0')
		const day = String(today.getDate()).padStart(2, '0')

		return `${year}-${month}-${day}`
	}
}

export const LeadExport = new LeadExportPrototype()
