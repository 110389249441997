import './news-feed__card.scss'

import { NewsFeed } from '../../../../services/news-feed/news-feed.types'
import { Utilities } from '../../../../services/utilities.service'

interface NewsFeedCardProps {
	onClick: () => void
}

export function NewsFeedCard(props: NewsFeed.Story & NewsFeedCardProps) {
	return (
		<div className="news-feed__card" onClick={props.onClick}>
			<strong>{Utilities.truncateString(props.title, 100)}</strong>
		</div>
	)
}
