import { PDFPageDrawImageOptions, PDFPageDrawTextOptions } from 'pdf-lib'

import { FileBaseURL } from '../../../../services/axios/http-common.service'
import { LicensedStateEnums } from '../../../../services/licensed-states/licenses-states.types'
import { AgentLicense } from '../../../../services/user/user.types'

export interface ContractTemplate {
	basePDF: string
	additions: ContractTemplateAddition[]
}

export type ContractTemplateAddition = TextAddition | ImageAddition

interface TextAddition {
	page: number
	text: string
	options: PDFPageDrawTextOptions
}

interface ImageAddition {
	page: number
	image: Blob | null
	options: PDFPageDrawImageOptions
}

export function RegistrationContractsService() {
	function getContractTemplate(
		abbreviation: LicensedStateEnums,
		plan: AgentLicense.PlanType,
		name: string,
		date: string,
		signature: Blob | null,
	): ContractTemplate | undefined {
		switch (abbreviation) {
			case 'AL':
				return getAlabamaContract(plan, name, date, signature)
			case 'CO':
				return getColoradoContract(plan, name, date, signature)
			case 'FL':
				return getFloridaContract(plan, name, date, signature)
			case 'GA':
				return getGeorgiaContract(plan, name, date, signature)
			case 'SC':
				return getSouthCarolinaContract(plan, name, date, signature)
			case 'TX':
				return getTexasContract(plan, name, date, signature)
			default:
				return undefined
		}
	}

	function getAlabamaContract(
		plan: AgentLicense.PlanType,
		name: string,
		date: string,
		signature: Blob | null,
	): ContractTemplate {
		switch (plan) {
			case 'BLUE':
				return {
					basePDF: `${FileBaseURL}/pdf/Independent_Contractor_Agreement_Alabama_Blue_RECAD_Updated.pdf`,
					additions: [
						{
							page: 4,
							text: name,
							options: {
								x: 85,
								y: 670,
								size: 12,
							},
						},
						{
							page: 4,
							text: date,
							options: {
								x: 320,
								y: 670,
								size: 12,
							},
						},
						{
							page: 4,
							image: signature,
							options: {
								x: 85,
								y: 745,
								width: 200,
								height: 50,
							},
						},
					],
				}
			case 'SILVER':
				return {
					basePDF: `${FileBaseURL}/pdf/Independent_Contractor_Agreement_Alabama_Silver_RECAD.pdf`,
					additions: [
						{
							page: 4,
							text: name,
							options: {
								x: 85,
								y: 670,
								size: 12,
							},
						},
						{
							page: 4,
							text: date,
							options: {
								x: 320,
								y: 670,
								size: 12,
							},
						},
						{
							page: 4,
							image: signature,
							options: {
								x: 85,
								y: 745,
								width: 200,
								height: 50,
							},
						},
					],
				}
		}
	}

	function getColoradoContract(
		plan: AgentLicense.PlanType,
		name: string,
		date: string,
		signature: Blob | null,
	): ContractTemplate {
		switch (plan) {
			case 'BLUE':
				return {
					basePDF: `${FileBaseURL}/pdf/Independent_Contractor_Agreement_Colorado_Blue.pdf`,
					additions: [
						{
							page: 4,
							text: name,
							options: {
								x: 85,
								y: 340,
								size: 12,
							},
						},
						{
							page: 4,
							text: date,
							options: {
								x: 320,
								y: 340,
								size: 12,
							},
						},
						{
							page: 4,
							image: signature,
							options: {
								x: 85,
								y: 405,
								width: 200,
								height: 50,
							},
						},
					],
				}
			case 'SILVER':
				return {
					basePDF: `${FileBaseURL}/pdf/Independent_Contractor_Agreement_Colorado_Silver.pdf`,
					additions: [
						{
							page: 4,
							text: name,
							options: {
								x: 85,
								y: 150,
								size: 12,
							},
						},
						{
							page: 4,
							text: date,
							options: {
								x: 320,
								y: 150,
								size: 12,
							},
						},
						{
							page: 4,
							image: signature,
							options: {
								x: 85,
								y: 215,
								width: 200,
								height: 50,
							},
						},
					],
				}
		}
	}

	function getFloridaContract(
		plan: AgentLicense.PlanType,
		name: string,
		date: string,
		signature: Blob | null,
	): ContractTemplate {
		const gridAdditions: ContractTemplateAddition[] = []

		switch (plan) {
			case 'BLUE':
				return {
					basePDF: `${FileBaseURL}/pdf/Independent_Contractor_Agreement_Florida_Blue.pdf`,
					additions: [
						...gridAdditions,
						{
							page: 3,
							text: name,
							options: {
								x: 85,
								y: 680,
								size: 12,
								height: 30,
							},
						},
						{
							page: 3,
							text: date,
							options: {
								x: 320,
								y: 680,
								size: 12,
								height: 30,
							},
						},
						{
							page: 3,
							image: signature,
							options: {
								x: 85,
								y: 755,
								width: 200,
								height: 50,
							},
						},
					],
				}
			case 'SILVER':
				return {
					basePDF: `${FileBaseURL}/pdf/Independent_Contractor_Agreement_Florida_Silver.pdf`,
					additions: [
						{
							page: 3,
							text: name,
							options: {
								x: 85,
								y: 660,
								size: 12,
							},
						},
						{
							page: 3,
							text: date,
							options: {
								x: 320,
								y: 660,
								size: 12,
							},
						},
						{
							page: 3,
							image: signature,
							options: {
								x: 85,
								y: 745,
								width: 200,
								height: 50,
							},
						},
					],
				}
		}
	}

	function getGeorgiaContract(
		plan: AgentLicense.PlanType,
		name: string,
		date: string,
		signature: Blob | null,
	): ContractTemplate {
		switch (plan) {
			case 'BLUE':
				return {
					basePDF: `${FileBaseURL}/pdf/Independent_Contractor_Agreement_Georgia_Blue.pdf`,
					additions: [
						{
							page: 6,
							text: name,
							options: {
								x: 85,
								y: 70,
								size: 12,
							},
						},
						{
							page: 6,
							text: date,
							options: {
								x: 320,
								y: 70,
								size: 12,
							},
						},
						{
							page: 6,
							image: signature,
							options: {
								x: 85,
								y: 140,
								width: 200,
								height: 50,
							},
						},
					],
				}
			case 'SILVER':
				return {
					basePDF: `${FileBaseURL}/pdf/Independent_Contractor_Agreement_Georgia_Silver.pdf`,
					additions: [
						{
							page: 6,
							text: name,
							options: {
								x: 85,
								y: 195,
								size: 12,
							},
						},
						{
							page: 6,
							text: date,
							options: {
								x: 320,
								y: 195,
								size: 12,
							},
						},
						{
							page: 6,
							image: signature,
							options: {
								x: 85,
								y: 270,
								width: 200,
								height: 50,
							},
						},
					],
				}
		}
	}

	function getSouthCarolinaContract(
		plan: AgentLicense.PlanType,
		name: string,
		date: string,
		signature: Blob | null,
	): ContractTemplate {
		switch (plan) {
			case 'BLUE':
				return {
					basePDF: `${FileBaseURL}/pdf/Independent_Contractor_Agreement_South_Carolina_Blue.pdf`,
					additions: [
						{
							page: 5,
							text: name,
							options: {
								x: 125,
								y: 550,
								size: 12,
							},
						},
						{
							page: 5,
							text: date,
							options: {
								x: 350,
								y: 550,
								size: 12,
							},
						},
						{
							page: 5,
							image: signature,
							options: {
								x: 125,
								y: 630,
								width: 200,
								height: 50,
							},
						},
					],
				}
			case 'SILVER':
				return {
					basePDF: `${FileBaseURL}/pdf/Independent_Contractor_Agreement_South_Carolina_Silver.pdf`,
					additions: [
						{
							page: 5,
							text: name,
							options: {
								x: 115,
								y: 545,
								size: 12,
							},
						},
						{
							page: 5,
							text: date,
							options: {
								x: 340,
								y: 545,
								size: 12,
							},
						},
						{
							page: 5,
							image: signature,
							options: {
								x: 115,
								y: 625,
								width: 200,
								height: 50,
							},
						},
					],
				}
		}
	}

	function getTexasContract(
		plan: AgentLicense.PlanType,
		name: string,
		date: string,
		signature: Blob | null,
	): ContractTemplate {
		switch (plan) {
			case 'BLUE':
				return {
					basePDF: `${FileBaseURL}/pdf/Independent_Contractor_Agreement_Texas_Blue.pdf`,
					additions: [
						{
							page: 5,
							text: name,
							options: {
								x: 115,
								y: 475,
								size: 12,
							},
						},
						{
							page: 5,
							text: date,
							options: {
								x: 340,
								y: 475,
								size: 12,
							},
						},
						{
							page: 5,
							image: signature,
							options: {
								x: 115,
								y: 550,
								width: 200,
								height: 50,
							},
						},
					],
				}
			case 'SILVER':
				return {
					basePDF: `${FileBaseURL}/pdf/Independent_Contractor_Agreement_Texas_Silver.pdf`,
					additions: [
						{
							page: 5,
							text: name,
							options: {
								x: 115,
								y: 475,
								size: 12,
							},
						},
						{
							page: 5,
							text: date,
							options: {
								x: 340,
								y: 475,
								size: 12,
							},
						},
						{
							page: 5,
							image: signature,
							options: {
								x: 115,
								y: 550,
								width: 200,
								height: 50,
							},
						},
					],
				}
		}
	}

	return { getContractTemplate }
}
