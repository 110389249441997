import { DomainTheme } from '../theme.types'
import { lokationMark } from './lokation__mark'
import { lokationMarkWhite } from './lokation__mark__white'
import { lokationWord } from './lokation__word'
import { lokationWordWhite } from './lokation__word__white'

export const defaultTheme: DomainTheme.Theme = {
	themeId: -1,
	appHeaderBgColor: '#083e63',
	appTrayBgColor: '#1E1E1E',
	pageBgColor: '#EFF0F2',
	modalBgColor: '#EFF0F2',
	contentBlockBgColor: '#ffffff',
	mode: 'LIGHT',
	borderStyle: 'ROUND',
	resourceCardBgColor: '#ffffff',
	buttonBgColor: '#083e63',
	colorAccent: '#667181',
	colorWarning: '#b83434',
	h1FontSize: '30pt',
	bodyFontWeight: '500',
	bodyFontSize: '10.5pt',
	h1FontWeight: '600',
	headerFontFamily: 'Inter',
	bodyFontFamily: 'Inter',
	borderWidth: 'THIN',
	logo: lokationWord,
	logoWhite: lokationWordWhite,
	logoMark: lokationMark,
	logoMarkWhite: lokationMarkWhite,
}
