import { ToastOptions } from 'react-toastify'
import { ToastService } from 'src/services/toast/toast.service'

export namespace SideSheetServices {
	/** Creates a toast confirming that the user wants to dismiss or save their changes */
	export function showDismissConfirmationToast(props: {
		onDismiss: () => Promise<void>
		onSaveAndClose: () => Promise<void>
		isEscapable?: boolean
	}): void {
		const toastOptions: ToastOptions<{}> = {}

		if (typeof props.isEscapable === 'boolean' && !props.isEscapable) {
			toastOptions.autoClose = false
			toastOptions.closeButton = false
			toastOptions.draggable = false
			toastOptions.closeOnClick = false
		}

		const toastInstance = ToastService().create({
			type: 'INFO',
			body: `Would you like to save your changes?`,
			options: toastOptions,
			buttons: [
				{
					label: `Cancel`,
					variant: 'text',
					size: `sm`,
					onClick: () => {
						return new Promise<void>((resolve) => {
							props.onDismiss().then(() => {
								toastInstance.dismiss()
								resolve()
							})
						})
					},
				},
				{
					label: `Save Changes`,
					variant: 'contained',
					size: `sm`,
					onClick: () => {
						return new Promise<void>((resolve) => {
							props.onSaveAndClose().then(() => {
								toastInstance.dismiss()
								resolve()
							})
						})
					},
				},
			],
		})
		return
	}
}
