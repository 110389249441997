import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SignatureField } from '../signature-field/signature-field'

interface AgentContractSignatureCardProps {
	hasUserClickedNavButton: boolean
	signature: string
	firstName: string
	lastName: string
	onChange: (newState: string) => void
	showSignatureError: boolean
}

export function AgentContractSignatureCard(props: AgentContractSignatureCardProps) {
	const showSignatureError = props.showSignatureError

	return (
		<div className="mt-50 mb-20 flex flex-column col-xs-12">
			<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween mb-5">
				<strong>Signature</strong>
				{showSignatureError && props.hasUserClickedNavButton && (
					<div className="flex flex-alignItems-center color__warning ml-20">
						<FontAwesomeIcon icon={['fas', 'triangle-exclamation']} className="mr-10" />
						<strong>Please provide a signature</strong>
					</div>
				)}
			</div>

			<SignatureField
				signature={props.signature}
				onChange={(newState) => {
					props.onChange(newState)
				}}
				fullName={`${props.firstName} ${props.lastName}`}
				isValid={showSignatureError === true && props.hasUserClickedNavButton === true}
			/>
		</div>
	)
}
