import { httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { FindUsersInDomainQueryFilters } from '../user/user.types'
import { Utilities } from '../utilities.service'

class UserExportPrototype {
	async getUserExport(): Promise<void> {
		try {
			const response = await httpRequest({
				method: 'get',
				url: `${RequestBaseURL}/api/v1/endUsers/export`,
				responseType: 'blob', // set the response type to blob
			})

			const url = window.URL.createObjectURL(response.data)
			const formattedDate = this.getFormattedDate()
			const filename = `user_list_${formattedDate}.xlsx`

			const a = document.createElement('a')
			a.href = url
			a.download = filename
			document.body.appendChild(a)
			a.click()
			a.remove()

			window.URL.revokeObjectURL(url)
		} catch (error) {
			console.log(error)
			throw new Error('Failed to get user list.')
		}
	}

	async getAdminUserExport(props: FindUsersInDomainQueryFilters): Promise<void> {
		try {
			/** Set params */
			const params = Utilities.createSearchParamsFromObj({ ...props })

			const response = await httpRequest({
				method: 'get',
				url: `${RequestBaseURL}/api/v1/endUsers/admin_search/export`,
				params: params,
				responseType: 'blob', // set the response type to blob
			})

			const url = window.URL.createObjectURL(response.data)
			const formattedDate = this.getFormattedDate()
			const filename = `user_list_${formattedDate}.xlsx`

			const a = document.createElement('a')
			a.href = url
			a.download = filename
			document.body.appendChild(a)
			a.click()
			a.remove()

			window.URL.revokeObjectURL(url)
		} catch (error) {
			console.log(error)
			throw new Error('Failed to get mls board user list.')
		}
	}

	async getMlsBoardUserExport(mlsBoardId: number): Promise<void> {
		try {
			const response = await httpRequest({
				method: 'get',
				url: `${RequestBaseURL}/api/v1/mlsBoards/${mlsBoardId}/endUsers/export`,
				responseType: 'blob', // set the response type to blob
			})

			const url = window.URL.createObjectURL(response.data)
			const formattedDate = this.getFormattedDate()
			const filename = `user_list_${formattedDate}.xlsx`

			const a = document.createElement('a')
			a.href = url
			a.download = filename
			document.body.appendChild(a)
			a.click()
			a.remove()

			window.URL.revokeObjectURL(url)
		} catch (error) {
			console.log(error)
			throw new Error('Failed to get mls board user list.')
		}
	}

	getFormattedDate(): string {
		const today = new Date()
		const year = today.getFullYear()
		const month = String(today.getMonth() + 1).padStart(2, '0')
		const day = String(today.getDate()).padStart(2, '0')

		return `${year}-${month}-${day}`
	}
}

export const UserExport = new UserExportPrototype()
