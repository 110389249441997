import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { useLoaderData } from 'react-router-dom'
import { AdvertisingTypes } from 'src/services/advertising/advertising.types'

import { AdvertisingAccountIndexRoute } from './ad-account__index'
import { AdvertisingAccountProvider } from './state/ad-account__state'

export function AdvertisingAccountRouteWrapper() {
	const data = useLoaderData() as AdvertisingTypes.Account | null

	if (!data) {
		return (
			<RouteWrapperV2>
				<RouteBodyV2>Cannot load account</RouteBodyV2>
			</RouteWrapperV2>
		)
	}

	return (
		<AdvertisingAccountProvider accountToEdit={data}>
			<AdvertisingAccountIndexRoute />
		</AdvertisingAccountProvider>
	)
}
