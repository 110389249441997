import './tag__card.scss'

import { ColorBlock } from '@components/color-block/color-block'

import { LokationTag } from '../../../../services/tags/tag.types'
import { Utilities } from '../../../../services/utilities.service'

interface TagCardProps {
	onClick: () => void
}

export function TagCard(props: LokationTag & TagCardProps) {
	return (
		<div className="tag__card" onClick={props.onClick}>
			<ColorBlock color={props.color} className="mr-10" />
			<strong>{Utilities.truncateString(props.label, 100)}</strong>
		</div>
	)
}
