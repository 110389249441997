export function RegistrationLuxuryDesc() {
	return (
		<div className="col-xs-12">
			<h1>
				<strong>LoKation Luxury® Membership</strong>
			</h1>
			<h6>
				As a LoKation Luxury® agent, you have access to an array of specialized resources that are tailored to
				meet the demands of high-end clientele.{' '}
			</h6>
			<p>
				These include exclusive use of our Lokation Luxury® logos followed by a personal onboarding call with a
				full-service marketing firm to develop your upscale Listing & Branding package. You will receive
				comprehensive marketing and professionally designed branding materials.
			</p>
			<p>
				Luxury agents also receive a cutting-edge technology platform complete with personalized websites, CRM,
				and AI-driven lead nurturing resources. There is a separate a world-class marketing platform to directly
				print and ship to an area as well as on-the-spot social media templates in seconds.
			</p>
			<p>
				As a member of LoKation Luxury, you will have access to global real estate-specific websites syndicated
				to over 750 websites in 44 countries. You can further attract buyers and sellers both domestically and
				internationally with our full lead generation white-glove services amplifying your reach in luxury
				geographies. The Luxury program requires a 12-month commitment and is to be used only with properties
				above $1,000,000 (otherwise marketed as LoKation Real Estate).
			</p>
		</div>
	)
}
