import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function DotloopIconGrid() {
	return (
		<>
			<div className="flex flex-wrap text-center col-xs-12 my-20">
				<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'edit']} size="3x" className="color__accent mb-10" />
					<strong>Edit</strong>
				</div>
				<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'signature']} size="3x" className="color__accent mb-10" />
					<strong>eSign</strong>
				</div>
				<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'share']} size="3x" className="color__accent mb-10" />
					<strong>Share</strong>
				</div>
				<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'cloud-upload']} size="3x" className="color__accent mb-10" />
					<strong>Store</strong>
				</div>
				<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'check-square']} size="3x" className="color__accent mb-10" />
					<strong>Compliance</strong>
				</div>
				<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'chart-bar']} size="3x" className="color__accent mb-10" />
					<strong>Analyze and Grow</strong>
				</div>
			</div>
		</>
	)
}
