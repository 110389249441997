import { KVCoreIconGrid } from '../../../../../lokation-routes/kvcore-platform/kvcore-grid'
import kvCoreLogo from './kv-core__logo.png'
export function RegistrationKVCoreDesc() {
	return (
		<div className="col-xs-12">
			<img src={kvCoreLogo} style={{ width: '200px' }} className="mb-20" />
			<h6>
				kvCORE, the #1 rated real estate platform, is built to power your entire brokerage with next generation
				technology your agents will use and love.
			</h6>
			<p>
				Grow your business with the real estate platform that allows you to manage your work from a centralized
				location. kvCore includes a powerful Customer Relationship Management (CRM) platform, as well as tools
				to assist in lead generation and marketing.
			</p>
			<KVCoreIconGrid />
		</div>
	)
}
