import { HubHeader } from '@components/hub-header/hub-header'

import { EditHubSectionProps } from './edit-hub'

export function EditHubPreviewSection(props: EditHubSectionProps) {
	return (
		<>
			<div
				className="col-xs-12 border-thin overflow-y__scroll"
				style={{ height: '100%', backgroundColor: 'var(--pageBgColor)' }}
			>
				<HubHeader
					isEditorPreview={true}
					title={props.mergedHubProps.title}
					description={props.mergedHubProps.description}
					imageUrl={props.mergedHubProps.headerBgImage}
					backgroundColor={props.mergedHubProps.color}
					bodyContent=""
				/>
				<div className="mt-40 mx-40">
					<div dangerouslySetInnerHTML={{ __html: props.mergedHubProps.body }} />
				</div>
			</div>
		</>
	)
}
