import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'
import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import QuickSocialImg from './QuickSocial.png'

export function QuickSocial() {
	return (
		<div className="flex-lg overflow-y__scroll flex-justifyContent-center">
			<div className="col-xs-12 col-lg-8 pr-20-sm mb-20 flex flex-column flex-alignItems-start overflow-y__scroll flex-justifyContent-center">
				<div className="text-center col-xs-12">
					<img src={QuickSocialImg} style={{ maxWidth: '400px' }} className="col-xs-12" />
				</div>
				<HorizontalRule className="mt-20" />
				<p>
					Create content, instantly, and automate your social media, today! As part of the luxury division,
					enjoy exclusive access to our luxury templates.
				</p>
				<div className="col-12 flex flex-justifyContent-center">
					<h3>Try Quick Social, Send an Email or Text Us!</h3>
				</div>
				<div className="col-12 flex flex-justifyContent-center">
					<div className="flex flex-wrap mt-20">
						<a
							href="mailto:lokationluxury@myagenticon.com"
							target="_blank"
							className="button outlined sm primary mb-10 mr-10-md"
							rel="noreferrer"
						>
							<FontAwesomeIcon icon={faEnvelope} className="mr-10" /> lokationluxury@myagenticon.com
						</a>
						<a
							href="tel:954-371-2220"
							target="_blank"
							className="button outlined sm primary mb-10"
							rel="noreferrer"
						>
							<FontAwesomeIcon icon={faPhone} className="mr-10" /> 954-371-2220
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}
