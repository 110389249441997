import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { DomainTheme } from './theme.types'

export class DomainThemeAPI {
	public static getThemes(): Promise<AxiosResponse<{ items: DomainTheme.Theme[] }>> {
		const headers = {}
		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/themes/`, {
			headers,
		})
		return requestPromise
	}

	public static createTheme(
		themeDTO: Omit<DomainTheme.Theme, 'themeId'>,
	): Promise<AxiosResponse<{ items: DomainTheme.Theme }>> {
		const headers = {}
		const requestPromise = httpRequest.post(`${RequestBaseURL}${apiVersionURL}/themes/`, themeDTO, {
			headers,
		})
		return requestPromise
	}

	public static updateTheme(
		themeDTO: Partial<DomainTheme.Theme>,
		themeId: number,
	): Promise<AxiosResponse<{ items: DomainTheme.Theme }>> {
		const headers = {}
		const requestPromise = httpRequest.patch(`${RequestBaseURL}${apiVersionURL}/themes/${themeId}`, themeDTO, {
			headers,
		})
		return requestPromise
	}
}
