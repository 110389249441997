import libphonenumber from 'google-libphonenumber'

type ISOCountryCode =
	| 'ad'
	| 'ae'
	| 'af'
	| 'ag'
	| 'ai'
	| 'al'
	| 'am'
	| 'ao'
	| 'ar'
	| 'as'
	| 'at'
	| 'au'
	| 'aw'
	| 'ax'
	| 'az'
	| 'ba'
	| 'bb'
	| 'bd'
	| 'be'
	| 'bf'
	| 'bg'
	| 'bh'
	| 'bi'
	| 'bj'
	| 'bm'
	| 'bn'
	| 'bo'
	| 'br'
	| 'bs'
	| 'bt'
	| 'bv'
	| 'bw'
	| 'by'
	| 'bz'
	| 'ca'
	| 'cc'
	| 'cf'
	| 'cg'
	| 'ch'
	| 'ci'
	| 'ck'
	| 'cl'
	| 'cm'
	| 'cn'
	| 'co'
	| 'cr'
	| 'cu'
	| 'cx'
	| 'cy'
	| 'cz'
	| 'de'
	| 'dj'
	| 'dk'
	| 'dm'
	| 'do'
	| 'dz'
	| 'ec'
	| 'ee'
	| 'eg'
	| 'er'
	| 'es'
	| 'et'
	| 'fi'
	| 'fj'
	| 'fk'
	| 'fm'
	| 'fo'
	| 'fr'
	| 'ga'
	| 'gb'
	| 'gd'
	| 'ge'
	| 'gf'
	| 'gg'
	| 'gh'
	| 'gi'
	| 'gl'
	| 'gm'
	| 'gn'
	| 'gp'
	| 'gq'
	| 'gr'
	| 'gs'
	| 'gt'
	| 'gu'
	| 'gw'
	| 'gy'
	| 'hk'
	| 'hm'
	| 'hn'
	| 'hr'
	| 'ht'
	| 'hu'
	| 'id'
	| 'ie'
	| 'il'
	| 'im'
	| 'in'
	| 'io'
	| 'iq'
	| 'ir'
	| 'is'
	| 'it'
	| 'je'
	| 'jm'
	| 'jo'
	| 'jp'
	| 'ke'
	| 'kg'
	| 'kh'
	| 'ki'
	| 'km'
	| 'kn'
	| 'kp'
	| 'kw'
	| 'ky'
	| 'kz'
	| 'la'
	| 'lb'
	| 'lc'
	| 'li'
	| 'lk'
	| 'lr'
	| 'ls'
	| 'lt'
	| 'lu'
	| 'lv'
	| 'ly'
	| 'ma'
	| 'mc'
	| 'md'
	| 'me'
	| 'mg'
	| 'mh'
	| 'mk'
	| 'ml'
	| 'mm'
	| 'mn'
	| 'mo'
	| 'mp'
	| 'mq'
	| 'mr'
	| 'ms'
	| 'mt'
	| 'mu'
	| 'mv'
	| 'mw'
	| 'mx'
	| 'my'
	| 'mz'
	| 'na'
	| 'nc'
	| 'ne'
	| 'nf'
	| 'ng'
	| 'ni'
	| 'nl'
	| 'nl'
	| 'no'
	| 'np'
	| 'nr'
	| 'nu'
	| 'nz'
	| 'om'
	| 'pa'
	| 'pe'
	| 'pf'
	| 'pg'
	| 'ph'
	| 'pk'
	| 'pl'
	| 'pm'
	| 'pn'
	| 'pr'
	| 'pt'
	| 'pw'
	| 'py'
	| 'qa'
	| 're'
	| 'ro'
	| 'rs'
	| 'ru'
	| 'rw'
	| 'sa'
	| 'sb'
	| 'sc'
	| 'sd'
	| 'se'
	| 'sg'
	| 'sh'
	| 'si'
	| 'sj'
	| 'sk'
	| 'sl'
	| 'sm'
	| 'sn'
	| 'so'
	| 'sr'
	| 'sr'
	| 'st'
	| 'sv'
	| 'sy'
	| 'sz'
	| 'tc'
	| 'td'
	| 'tg'
	| 'th'
	| 'tj'
	| 'tk'
	| 'tl'
	| 'tm'
	| 'tn'
	| 'to'
	| 'tr'
	| 'tt'
	| 'tv'
	| 'tw'
	| 'tz'
	| 'ua'
	| 'ug'
	| 'um'
	| 'us'
	| 'uy'
	| 'uz'
	| 'va'
	| 'vc'
	| 've'
	| 'vg'
	| 'vi'
	| 'vn'
	| 'vu'
	| 'wf'
	| 'ws'
	| 'ye'
	| 'yt'
	| 'za'
	| 'zm'
	| 'zw'

class UtilitiesPrototype {
	generateRandomInt(min: number, max: number): number {
		return Math.floor(Math.random() * (max + 1)) + min
	}

	roundToXDigits(value: number, digits: number): number {
		value = value * Math.pow(10, digits)
		value = Math.round(value)
		value = value / Math.pow(10, digits)
		return value
	}

	parseJwt(token: string): { [key: string]: string | boolean | number } | null {
		try {
			var base64Url = token.split('.')[1]
			var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
			var jsonPayload = decodeURIComponent(
				window
					.atob(base64)
					.split('')
					.map(function (c) {
						return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
					})
					.join(''),
			)

			return JSON.parse(jsonPayload)
		} catch (err) {
			return null
		}
	}

	truncateString(theString: string, atLength: number): string {
		if (theString.length > atLength) {
			return theString.substring(0, atLength) + '...'
		} else {
			return theString
		}
	}

	toTitleCase(str: string): string {
		return str.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
		})
	}

	formatPhoneNum(value: string): string {
		// if input value is falsy eg if the user deletes the input, then just return
		if (!value) return value

		// clean the input for any non-digit values.
		const phoneNumber = value.replace(/[^\d]/g, '')

		// phoneNumberLength is used to know when to apply our formatting for the phone number
		const phoneNumberLength = phoneNumber.length

		// we need to return the value with no formatting if its less then four digits
		// this is to avoid weird behavior that occurs if you  format the area code to early
		if (phoneNumberLength < 4) return phoneNumber

		// if phoneNumberLength is greater than 4 and less the 7 we start to return
		// the formatted number
		if (phoneNumberLength < 7) {
			return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
		}

		// finally, if the phoneNumberLength is greater then seven, we add the last
		// bit of formatting and return it.
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
	}

	isPhoneNumberValid(phoneNumber: string | number, countryISOCode: ISOCountryCode): boolean {
		if (typeof phoneNumber === 'string' && phoneNumber.trim().length < 3) {
			return false
		}

		const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

		try {
			const num = phoneUtil.parseAndKeepRawInput(String(phoneNumber), countryISOCode)
			return phoneUtil.isValidNumber(num)
		} catch (err) {
			return false
		}
	}

	isZipValid(value: string | number): boolean {
		if (value === null) {
			return false
		}
		const regex = new RegExp(/^\d{5}(?:[-\s]\d{4})?$/gm)
		const isValid = regex.test(String(value))
		return isValid
	}

	stripHTMLTagsFromString(htmlString: string): string {
		const strippedString = htmlString.replace(/<(.|\n)*?>/g, '')
		return strippedString
	}

	isEmailValid(email: string): boolean {
		const lowerCaseEmail = email.toLowerCase()
		const re =
			/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
		return re.test(lowerCaseEmail)
	}

	createSearchParamsFromObj(props: Record<string, unknown>): URLSearchParams {
		const searchParams: URLSearchParams = new URLSearchParams()

		Object.keys(props).forEach((key) => {
			const propValue = props[key]

			/** if property is special key, 'sort' */
			if (key === 'sort') {
				try {
					if (Array.isArray(propValue)) {
						propValue.forEach((sortValue) => {
							if ('property' in sortValue && 'direction' in sortValue) {
								searchParams.append(`sort`, `${sortValue.property},${sortValue.direction}`)
							}
						})
					}
				} catch (err) {
					console.warn(`Malformed search param, 'sort'`, propValue)
				}
			} else if (typeof propValue === 'string') {
				/** if property is string */
				searchParams.append(key, propValue)
			} else if (typeof propValue === 'number') {
				/** If property is number */
				searchParams.append(key, String(propValue))
			} else if (typeof propValue === 'object' && Array.isArray(propValue)) {
				/** If property is array */
				propValue.forEach((propValueNode) => {
					if (typeof propValueNode === 'string') {
						searchParams.append(key, propValueNode)
					} else if (typeof propValueNode === 'number') {
						searchParams.append(key, String(propValueNode))
					} else {
						console.warn(`Could not append search param '${key}'. Property value is unknown`, propValueNode)
					}
				})
			} else {
				console.warn(`Could not append search param '${key}'. Property value is unknown`, propValue)
			}
		})

		return searchParams
	}
}

export const Utilities = new UtilitiesPrototype()
