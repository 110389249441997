import './route-navigation-link.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { RouteNavigationLinkTypes } from './route-navigation-link.types'

export function RouteNavigationLink(props: RouteNavigationLinkTypes.Component) {
	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`route-navigation-link`)

		if (props.isActive) {
			classes.push(`is-active`)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	return (
		<button className={getClass()} style={props.style} onClick={props.onClick}>
			{props.icon && <FontAwesomeIcon icon={['fas', props.icon]} />}
			<div className="route-navigation-link__label">{props.label}</div>
		</button>
	)
}
