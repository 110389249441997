import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { LoKationButton } from '../../../components/button/button'
import { EventCalendar } from '../../../components/event-calendar/event-calendar'
import { ItemSearchCalendarFilter } from '../../../components/item-search__calendar-filter/item-search__calendar-filter'
import { ItemSearchTagFilter } from '../../../components/item-search__tag-filter/item-search__tag-filter'
import Switch from '../../../components/switch/switch'
import { EditCalendarModal } from '../../../modals/admin-editors/edit-calendar/edit-calendar'
import { CalendarAPI } from '../../../services/calendar/calendar.api'
import { Calendar } from '../../../services/calendar/calendar.types'
import useAdminRedirect from '../../../services/hooks/user-admin-redirect'
import { LokationTag } from '../../../services/tags/tag.types'
import { EndUserProps } from '../../../services/user/user.types'
import { CalendarCard } from './calendar-event__card/calendar__card'

interface ConnectedProps {
	userProfile: EndUserProps | null
	tags: LokationTag[]
}

function CalendarAdministrationRoutePrototype(props: ConnectedProps) {
	const navigate = useNavigate()
	const [calendarIdToShow, setCalendarIdToShow] = useState<number | null>(null)
	const [calendars, setCalendars] = useState<Calendar.Calendar[] | null>(null)

	const [selectedCalendar, setSelectedCalendar] = useState<Calendar.Calendar | 'NEW' | null>(null)
	const [viewMode, setViewMode] = useState<'EVENTS' | 'CALENDARS'>('EVENTS')
	const [selectedTags, setSelectedTags] = useState<LokationTag[]>(props.tags)

	useAdminRedirect(props.userProfile)

	/** ================================================== */
	/** Effects */

	/** Get a list of calendars */
	useEffect(() => {
		CalendarAPI.getCalendars({ page: 0, size: 50 }).then((res) => {
			setCalendars(res.data.items)
			return res
		})
	}, [])

	/** ================================================== */
	/** Methods */

	function refreshCalendar(): void {
		CalendarAPI.getCalendars({ page: 0, size: 50 }).then((res) => {
			setCalendars(res.data.items)
			setCalendarIdToShow(res.data.items[0].calendarId)
			return res
		})
	}

	function returnCreateCalendarPrompt(): React.ReactNode {
		return (
			<div className="flex flex-column flex-fillSpace flex-justifyContent-center flex-alignItems-center">
				<FontAwesomeIcon icon={['far', 'calendar']} size="4x" className="mb-20" />
				<h3>Create a calendar first</h3>
			</div>
		)
	}

	function returnCalendars(): React.ReactNode {
		if (!calendars) {
			return <></>
		}

		if (calendars.length === 0) {
			return (
				<div className="flex flex-column flex-fillSpace flex-justifyContent-center flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'newspaper']} size="4x" className="mb-20" />
					<h3>We cannot find any calendars matching your criteria</h3>
				</div>
			)
		}

		return calendars.map((calendar) => {
			return (
				<CalendarCard
					{...calendar}
					key={calendar.calendarId}
					onClick={() => {
						setSelectedCalendar(calendar)
					}}
				/>
			)
		})
	}

	/** ================================================== */
	/** Render Component */

	if (!calendars) {
		return <>LOADING</>
	}

	const ELEMENT_TYPE_TOGGLE = (
		<div className="flex flex-alignItems-center">
			<strong>Events</strong>
			<Switch
				className="mx-5"
				checked={viewMode === 'CALENDARS'}
				onChange={() => {
					if (viewMode === 'CALENDARS') {
						setViewMode('EVENTS')
					} else {
						setViewMode('CALENDARS')
					}
				}}
			/>
			<strong>Calendars</strong>
		</div>
	)

	return (
		<RouteWrapperV2>
			<RouteSubnavigation
				isCustom={true}
				title={`Calendar Administration`}
				backButton={{
					label: 'Administration',
					onClick: () => {
						navigate('/administration')
					},
				}}
			>
				<ItemSearchCalendarFilter
					allCalendars={calendars}
					selectedCalendarId={calendarIdToShow}
					onChange={(calendarId) => {
						setCalendarIdToShow(calendarId)
					}}
				/>

				<ItemSearchTagFilter
					selectedTags={selectedTags}
					onChange={(value) => {
						setSelectedTags(value)
					}}
				/>
			</RouteSubnavigation>

			<RouteBodyV2>
				<>
					<div className="flex flex-column-md-down">
						<div className="flex flex-column flex-fillSpace pl-20">
							{viewMode === 'CALENDARS' && (
								<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween mb-10">
									{ELEMENT_TYPE_TOGGLE}
									<LoKationButton
										variant="outlined"
										label="Create Calendar"
										size={'sm'}
										onClick={() => {
											setSelectedCalendar('NEW')
										}}
									/>
								</div>
							)}

							{calendars.length === 0 && <>{returnCreateCalendarPrompt()}</>}
							{viewMode === 'EVENTS' && (
								<>
									<EventCalendar
										initialView={'listMonth'}
										calendarId={calendarIdToShow ? calendarIdToShow : 'all'}
										filterByTags={selectedTags}
										filterByLicensedStates={[]}
										includeCreateButton={true}
										onEventClick="open-editor"
										headerBody={ELEMENT_TYPE_TOGGLE}
										style={{ height: 'auto' }}
									/>
								</>
							)}
							{viewMode === 'CALENDARS' && calendars.length > 0 && <>{returnCalendars()}</>}
						</div>
					</div>
					{selectedCalendar === 'NEW' && (
						<EditCalendarModal
							dismissModal={() => {
								setSelectedCalendar(null)
							}}
							onCreate={refreshCalendar}
							onDelete={refreshCalendar}
						/>
					)}
					{selectedCalendar && selectedCalendar !== 'NEW' && (
						<EditCalendarModal
							item={selectedCalendar}
							dismissModal={() => {
								setSelectedCalendar(null)
							}}
							onUpdate={refreshCalendar}
							onDelete={refreshCalendar}
						/>
					)}
				</>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
		tags: state.tags,
	}
}

export const CalendarAdministrationRoute = connect(mapStateToProps)(CalendarAdministrationRoutePrototype)
