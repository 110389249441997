import { useEffect } from 'react'

export function InstagramFeed() {
	/** Insert Powr.io (Instagram Feed) script into document body */
	useEffect(() => {
		const newScript = document.createElement('script')
		newScript.setAttribute('is-powrio', 'true')
		newScript.src = `https://www.powr.io/powr.js?platform=html`

		const existingScripts = document.body.querySelector(`script[is-powrio]`)
		if (!existingScripts) {
			document.body.appendChild(newScript)
		}
	}, [])

	return <div className="powr-instagram-feed" id="4ba5b4b1_1677076787" style={{ minHeight: `60px` }}></div>
}
