import { RootState } from '@redux/store'
import _ from 'lodash'
import React, { useState } from 'react'
import { connect } from 'react-redux'

import { LicensedState } from '../../services/licensed-states/licenses-states.types'
import { LoKationButton } from '../button/button'
import { Checkbox } from '../checkbox/checkbox'
import { CollapsibleContent, CollapsibleContentStates } from '../collapsible-content/collapsible-content'

export interface StateFilterProps {
	selectedStates: Array<LicensedState>
	onSelect: (selectedStates: LicensedState[]) => void
	style?: React.CSSProperties
	className?: string
	isMulti: boolean
	expanded?: true
}

function StateFilterPrototype(props: StateFilterProps & { licensedStates: LicensedState[] }) {
	const [state, setState] = useState<CollapsibleContentStates>(props.expanded ? 'OPEN' : 'CLOSED')

	function handleUpdateSelectedTags(newState: LicensedState, isStateSelected: boolean): void {
		let updatedState = _.cloneDeep(props.selectedStates)
		if (isStateSelected === true) {
			if (props.isMulti) {
				updatedState.push(newState)
				props.onSelect(updatedState)
			} else {
				props.onSelect([newState])
			}
		}
		if (isStateSelected === false) {
			if (props.isMulti) {
				props.onSelect(
					updatedState.filter((existingTag) => {
						return existingTag !== newState
					}),
				)
			} else {
				props.onSelect([])
			}
		}
	}

	function createFilterState(state: LicensedState): JSX.Element {
		const isChecked = props.selectedStates.includes(state)
		return (
			<div className="search-filter__option" key={state.licensedStateId}>
				<Checkbox
					checked={isChecked}
					onChange={(checkedState) => {
						handleUpdateSelectedTags(state, checkedState)
					}}
				>
					{state.stateName}
				</Checkbox>
			</div>
		)
	}

	const allStates = props.licensedStates

	return (
		<div>
			<CollapsibleContent label="States" state={state} setState={setState}>
				<>
					<div className={`base-search-filter ${props.className ? props.className : ''}`} style={props.style}>
						{allStates.map((state) => {
							return createFilterState(state)
						})}
						{props.isMulti && (
							<div>
								<LoKationButton
									variant="text"
									size="sm"
									label="Select None"
									className="mr-20"
									onClick={() => {
										props.onSelect([])
									}}
								/>
								<LoKationButton
									variant="text"
									size="sm"
									label="Select All"
									onClick={() => {
										props.onSelect(allStates)
									}}
								/>
							</div>
						)}
					</div>
				</>
			</CollapsibleContent>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		licensedStates: state.licensedStates,
	}
}

export const StateFilter = connect(mapStateToProps)(StateFilterPrototype)
