import { AxiosResponse } from 'axios'

import { httpRequest, httpRequestNoErrors, RequestBaseURL } from '../axios/http-common.service'
import { StripeTypes } from './stripe.types'
class StripeAPIPrototype {
	async getPaymentIntent(priceIds: string[]): Promise<StripeTypes.PaymentIntentResponse> {
		try {
			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/stripe/payment-intents`, {
				priceIds: priceIds,
				currency: 'usd', // Set the currency to USD
				setup_future_usage: 'off_session',
				automatic_payment_methods: {
					enabled: true,
				},
			})
			console.log(response.data)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to get payment intent.')
		}
	}

	async getSetupIntent(endUserId?: number): Promise<StripeTypes.SetupIntentResponse> {
		try {
			let url = `${RequestBaseURL}/api/v1/stripe/setup-intents`

			if (endUserId) {
				url = `${RequestBaseURL}/api/v1/stripe/setup-intents/${endUserId}`
			}

			const response = await httpRequest.post(url)

			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to get setup intent.')
		}
	}

	async attachPaymentMethod(
		paymentMethodId: string,
		endUserId?: number,
	): Promise<StripeTypes.AttachPaymentMethodResponse> {
		try {
			let url = `${RequestBaseURL}/api/v1/stripe/attach-payment-method`

			if (endUserId != null) {
				url = `${RequestBaseURL}/api/v1/stripe/attach-payment-method/${endUserId}`
			}

			const payload = {
				paymentMethodId,
			}

			const response = await httpRequest.post(url, payload)

			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to attach a payment method.')
		}
	}

	async detachPaymentMethod(paymentMethodId: string): Promise<StripeTypes.DetachPaymentMethodResponse> {
		try {
			const response = await httpRequest.delete(
				`${RequestBaseURL}/api/v1/stripe/detach-payment-method/${paymentMethodId}`,
			)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to detach a payment method.')
		}
	}

	async setDefaultPaymentMethod(
		paymentMethodId: string,
		endUserId?: number,
	): Promise<StripeTypes.SetDefaultPaymentMethodResponse> {
		try {
			console.log('EndUser: ', endUserId)
			let url = `${RequestBaseURL}/api/v1/stripe/set-default-payment-method`

			if (endUserId != null) {
				url = `${RequestBaseURL}/api/v1/stripe/set-default-payment-method/${endUserId}`
			}

			const payload = {
				paymentMethodId,
			}

			const response = await httpRequest.post(url, payload)

			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to set default payment method.')
		}
	}

	async updateSubscriptionPaymentMethod(
		paymentMethodId: string,
		subscriptionId: string,
	): Promise<StripeTypes.UpdateSubscriptionPaymentMethodResponse> {
		try {
			const response = await httpRequest.post(
				`${RequestBaseURL}/api/v1/stripe/update-subscription-payment-method`,
				{
					paymentMethodId: paymentMethodId,
					subscriptionId: subscriptionId,
				},
			)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to set default payment method.')
		}
	}

	async fetchPaymentMethod(paymentMethodId: string): Promise<StripeTypes.PaymentMethodData> {
		try {
			const response = await httpRequest.get(
				`${RequestBaseURL}/api/v1/stripe/fetch-payment-method/${paymentMethodId}`,
			)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to fetch customer payment methods.')
		}
	}

	async fetchCustomerPaymentMethods(endUserId?: number): Promise<StripeTypes.FetchPaymentMethodsResponse> {
		try {
			let url = `${RequestBaseURL}/api/v1/stripe/fetch-payment-methods/`
			if (endUserId != null) {
				url = `${RequestBaseURL}/api/v1/stripe/fetch-payment-methods/${endUserId}`
			}
			const response = await httpRequestNoErrors.get(url)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to fetch customer payment methods.')
		}
	}

	async fetchCustomerDefaultPaymentMethod(
		endUserId?: number,
	): Promise<StripeTypes.FetchDefaultPaymentMethodResponse> {
		try {
			let url = `${RequestBaseURL}/api/v1/stripe/fetch-default-payment-method/`

			if (endUserId != null) {
				url = `${RequestBaseURL}/api/v1/stripe/fetch-default-payment-method/${endUserId}`
			}

			const response = await httpRequestNoErrors.get(url)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error(`Failed to fetch customer's default payment method.`)
		}
	}

	async fetchCustomerInvoices(endUserId?: number): Promise<StripeTypes.Invoice> {
		try {
			let url = `${RequestBaseURL}/api/v1/stripe/fetch-customer-invoices/`

			if (endUserId != null) {
				url = `${RequestBaseURL}/api/v1/stripe/fetch-customer-invoices/${endUserId}`
			}
			const response = await httpRequestNoErrors.get(url)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to fetch customer invoices.')
		}
	}

	async fetchProductById(productId: string): Promise<StripeTypes.Product> {
		try {
			const response = await httpRequest.get(`${RequestBaseURL}/api/v1/stripe/fetch-product/${productId}`)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to fetch product details.')
		}
	}

	async fetchCustomerId(email: string): Promise<StripeTypes.FetchEmailResponse> {
		try {
			const response = await httpRequest.get(`${RequestBaseURL}/api/v1/stripe/fetch-customer/${email}`)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to fetch customer payment methods.')
		}
	}

	async createOneTimePayment(
		oneTimePriceIds: string[],
	): Promise<AxiosResponse<StripeTypes.CreateOneTimePaymentResponse>> {
		const response = await httpRequest.post(`${RequestBaseURL}/api/v1/stripe/one-time-payment`, { oneTimePriceIds })
		return response
	}

	async createNewSubscription(
		priceIds: string[],
		futurePriceIds: string[],
		endUserId?: number | null,
		promotionCode?: string | null,
	): Promise<AxiosResponse<StripeTypes.CreateNewSubscriptionResponse>> {
		let url = `${RequestBaseURL}/api/v1/stripe/create-subscription`

		if (endUserId != null) {
			url = `${RequestBaseURL}/api/v1/stripe/create-subscription/${endUserId}`
		}

		const payload = {
			priceIds,
			futurePriceIds,
			...(promotionCode && { promotionCode }), // Only include promotionCode in payload if it's not null or empty
		}

		console.log('Sending priceIds:', priceIds)
		console.log('Sending futurePriceIds:', futurePriceIds)
		console.log('Coupon code: ', promotionCode)

		let response = await httpRequest.post(url, payload)

		return response
	}

	// Temporary for disabling compliance fees
	// async createNewSubscription(priceIds: string[], futurePriceIds?: string[]): Promise<AxiosResponse<StripeTypes.CreateNewSubscriptionResponse>> {
	//     const params: any = { priceIds };

	//     // Conditionally add futurePriceIds to params
	//     if (futurePriceIds && futurePriceIds.length > 0) {
	//         params.futurePriceIds = futurePriceIds;
	//     }

	//     const response = await httpRequest.post(
	//         `${RequestBaseURL}/api/v1/stripe/create-subscription`,
	//         params
	//     );

	//     console.log('Sending priceIds:', priceIds);
	//     if (futurePriceIds && futurePriceIds.length > 0) {
	//         console.log('Sending futurePriceIds:', futurePriceIds);
	//     }

	//     return response;
	// }

	async fetchSubscriptions(
		fetchLimit: number,
		startingAfter?: string | null,
		endUserId?: number,
	): Promise<StripeTypes.FetchSubscriptionsResponse> {
		try {
			let url = `${RequestBaseURL}/api/v1/stripe/fetch-customer-subscriptions?limit=${fetchLimit.toString()}`

			if (endUserId != null) {
				url = `${RequestBaseURL}/api/v1/stripe/fetch-customer-subscriptions/${endUserId}?limit=${fetchLimit.toString()}`
			}

			if (startingAfter) {
				url += `&startingAfter=${startingAfter}`
			}

			const response = await httpRequestNoErrors.get(url)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to fetch customer subscriptions.')
		}
	}

	async fetchSubscriptionSchedules(
		fetchLimit: number,
		startingAfter?: string | null,
		endUserId?: number,
	): Promise<StripeTypes.SubscriptionSchedule> {
		try {
			let url = `${RequestBaseURL}/api/v1/stripe/fetch-customer-subscription-schedules?limit=${fetchLimit.toString()}`

			if (endUserId != null) {
				url = `${RequestBaseURL}/api/v1/stripe/fetch-customer-subscription-schedules/${endUserId}?limit=${fetchLimit.toString()}`
			}

			if (startingAfter) {
				url += `&startingAfter=${startingAfter}`
			}

			const response = await httpRequestNoErrors.get(url)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to fetch customer future subscriptions.')
		}
	}

	async fetchSubscriptionDetails(subscriptionId: string): Promise<StripeTypes.FetchSubscriptionsDetailsResponse> {
		try {
			const response = await httpRequest.get(
				`${RequestBaseURL}/api/v1/stripe/fetch-subscription-details/${subscriptionId}`,
			)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to fetch subscription details.')
		}
	}

	async deleteSubscription(subscriptionId: string): Promise<StripeTypes.DeleteSubscriptionResponse> {
		try {
			const response = await httpRequest.delete(
				`${RequestBaseURL}/api/v1/stripe/delete-subscription/${subscriptionId}`,
			)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to delete subscription.')
		}
	}

	async createCustomerStripeInformation(endUserId: number): Promise<AxiosResponse<void>> {
		const response = await httpRequest.post(`${RequestBaseURL}/api/v1/endUsers/${endUserId}/stripeInformation`)
		return response
	}
}

export const StripeAPI = new StripeAPIPrototype()
