import { UIState } from '@redux/reducers/ui-reducer'
import { RootState } from '@redux/store'
import Joyride, { Step, Styles } from 'react-joyride'
import { connect } from 'react-redux'

interface ConnectedProps {
	ui: UIState
}

function HomeRouteTutorialPrototype(props: ConnectedProps) {
	function getSteps(): Step[] {
		return [
			{
				content: <h2>Welcome to LoKation Sphere!</h2>,
				placement: 'center',
				target: 'body',
			},
			{
				target: '#desktop-app-tray__hub-links',
				placement: 'right-start',
				placementBeacon: 'top',
				content:
					'Content in the Sphere is organized into various hubs. Navigate between these hubs to find resources related to them',
			},
			{
				target: '#desktop-app-tray__agent-search-btn',
				placement: 'bottom',
				content: 'Find agents by searching inside the Agent Referral Network',
			},
			{
				target: '#desktop-app-header__search__btn',
				placement: 'bottom',
				content: `Can't find something you are looking for? Search for resources, stories, and FAQs here`,
			},
			{
				target: '#desktop-app-header__user__btn',
				placement: 'bottom',
				content: `Click here to send feedback, request support or update your user profile`,
			},
		]
	}

	function getTutorialStyles(): Styles {
		return {
			buttonNext: {
				backgroundColor: 'var(--buttonBgColor)',
				color: 'var(--buttonContainedTextColor)',
				borderRadius: 'var(--buttonBorderRadius)',
				fontSize: 'inherit',
			},
			buttonBack: {
				color: 'var(--buttonTextTextColor)',
				fontSize: 'inherit',
			},
			tooltip: {
				fontSize: 'inherit',
			},
			tooltipContent: {
				padding: '10px 10px',
			},
			beacon: {},
			beaconInner: {},
			beaconOuter: {},
			buttonClose: {},
			buttonSkip: {},
			overlayLegacy: {},
			overlay: {},
			overlayLegacyCenter: {},
			spotlight: {},
			spotlightLegacy: {},
			tooltipContainer: {},
			tooltipFooter: {},
			tooltipFooterSpacer: {},
			tooltipTitle: {},
			options: {},
		}
	}

	if (!props.ui.showUserOnboardingTutorial) {
		return <></>
	}

	return (
		<Joyride
			steps={getSteps()}
			continuous={true}
			run={true}
			showProgress={true}
			hideCloseButton={true}
			styles={getTutorialStyles()}
		/>
	)
}

function mapStateToProps(state: RootState) {
	return {
		ui: state.ui,
	}
}

export const HomeRouteTutorial = connect(mapStateToProps)(HomeRouteTutorialPrototype)
