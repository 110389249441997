import { UserOnboardingTypes } from './user-onboarding.types'

export function UserOnboardingService() {
	function generateUserTasksAndActions(onboardingConfig: UserOnboardingTypes.Config): {
		tasks: UserOnboardingTypes.Task[]
		actions: UserOnboardingTypes.Action[]
	} {
		let tasks: UserOnboardingTypes.Task[] = []
		let actions: UserOnboardingTypes.Action[] = []

		/** Iterate through each question to see what options the user had selected. For each selected option, append tasks and actions associated with that option */
		onboardingConfig.questions.forEach((question) => {
			question.options.forEach((option) => {
				if (question.userResponse.includes(option.value)) {
					tasks = [...tasks, ...option.resultingTasks]
					actions = [...actions, ...option.resultingActions]
				}
			})
		})

		return { tasks, actions }
	}

	return { aggregateUserTasksAndActions: generateUserTasksAndActions }
}
