import { Checkbox } from '../checkbox/checkbox'

interface MultiSelectOptionProps<T> {
	option: T
	label: string
	isSelected: boolean
	onClick: (resource: T) => void
}

export function MultiSelectOption<T>(props: MultiSelectOptionProps<T>) {
	return (
		<div
			className="dropdown-option flex flex-alignItems-center p-10"
			onClick={() => {
				props.onClick(props.option)
			}}
		>
			<Checkbox checked={props.isSelected} className="mr-10" />
			<div>
				<strong className="mr-10">{props.label}</strong>
			</div>
		</div>
	)
}
