import { store } from '@redux/store'
import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../../services/axios/http-common.service'
import { LicensedState } from '../licensed-states/licenses-states.types'
import { LokationTag } from '../tags/tag.types'
import { Utilities } from '../utilities.service'
import { GetItemsParams, GetItemsResponse } from '../utility.types'
import { NewsFeed } from './news-feed.types'

interface GetStoriesResponse {
	size: number
	totalElements: number
	totalPages: number
	number: number
	items: NewsFeed.Story[]
}

interface CreateStoryDTO {
	createdTimestamp: number
	description: string
	endUserId: number
	licensedStates: Array<LicensedState>
	title: string
	stateAgnostic: boolean
	tags: LokationTag[]
}

class NewsFeedAPIPrototype {
	getStory(storyId: number): Promise<AxiosResponse<NewsFeed.Story>> {
		const headers = {}
		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/newsStories/${storyId}`, {
			headers,
		})
		return requestPromise
	}

	getStories(props: GetItemsParams<NewsFeed.Story>): Promise<AxiosResponse<GetStoriesResponse>> {
		const headers = {}
		const params = Utilities.createSearchParamsFromObj({ ...props })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/newsStories`, {
			headers,
			params,
		})
		return requestPromise
	}

	findStories(
		props: GetItemsParams<NewsFeed.Story>,
		itemTypeProps: NewsFeed.SearchParams,
	): Promise<AxiosResponse<GetItemsResponse<NewsFeed.Story>>> {
		const headers = {}

		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props, ...itemTypeProps })
		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/newsStories/search`, {
			headers,
			params,
		})
		return requestPromise
	}

	createStory(story: Omit<NewsFeed.Story, 'newsStoryId'>): Promise<AxiosResponse<NewsFeed.Story>> {
		const userProps = store.getState().user
		if (!userProps) {
			throw new Error(`User not found. Story cannot be created`)
		}

		/** Generate DTO */
		const createStoryDTO: CreateStoryDTO = {
			createdTimestamp: Date.now(),
			description: story.description,
			licensedStates: story.licensedStates,
			title: story.title,
			endUserId: userProps.endUserId,
			stateAgnostic: story.stateAgnostic,
			tags: story.tags,
		}

		const headers = {}
		const requestPromise = httpRequest.post(`${RequestBaseURL}${apiVersionURL}/newsStories`, createStoryDTO, {
			headers,
		})
		return requestPromise
	}

	updateStory(storyId: number, updateStoryDTO: Partial<NewsFeed.Story>): Promise<AxiosResponse<NewsFeed.Story>> {
		const userProps = store.getState().user
		if (!userProps) {
			throw new Error(`User not found. Story cannot be created`)
		}

		const headers = {}
		const requestPromise = httpRequest.patch(
			`${RequestBaseURL}${apiVersionURL}/newsStories/${storyId}`,
			updateStoryDTO,
			{
				headers,
			},
		)
		return requestPromise
	}

	deleteStory(storyId: number): Promise<AxiosResponse<NewsFeed.Story>> {
		const headers = {}
		const requestPromise = httpRequest.delete(`${RequestBaseURL}${apiVersionURL}/newsStories/${storyId}`, {
			headers,
		})
		return requestPromise
	}
}

export const NewsFeedAPI = new NewsFeedAPIPrototype()
