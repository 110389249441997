import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { ChatGptSettings } from './chat-gpt-settings.types'

export const DOMAIN_ID = 1

export class ChatGptSettingsAPI {
	public static get(domainId: number): Promise<AxiosResponse<ChatGptSettings.CompleteResponse>> {
		const headers = {}
		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/domains/${domainId}/openaiprops/`, {
			headers,
		})
		return requestPromise
	}

	public static update(
		domainId: number,
		chatGptSettings: ChatGptSettings.CompleteResponse,
	): Promise<AxiosResponse<void>> {
		const headers = {}
		const requestPromise = httpRequest.put(
			`${RequestBaseURL}${apiVersionURL}/domains/${domainId}/openaiprops/`,
			chatGptSettings,
			{
				headers,
			},
		)
		return requestPromise
	}
}
