import { EventCalendarEvent } from '@components/event-calendar/event-calendar__event'
import { IconButton } from '@components/icon-button/icon-button'
import { Modal } from '@components/modal/modal'

import { Calendar } from '../../services/calendar/calendar.types'

export function CalendarEventModal(props: {
	event: Calendar.Event
	instanceStartDate: Date | null
	instanceEndDate: Date | null
	dismissModal: () => void
}) {
	function closeModal(): void {
		props.dismissModal()
	}

	return (
		<Modal
			maxHeight={800}
			maxWidth={800}
			fixedHeight={false}
			className="flex flex-column p-20"
			onClose={closeModal}
		>
			<>
				<IconButton
					icon={'xmark'}
					style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 100 }}
					onClick={closeModal}
				/>
				<EventCalendarEvent
					event={props.event}
					instanceStartDate={props.instanceStartDate}
					instanceEndDate={props.instanceEndDate}
					dismissModal={closeModal}
				/>
			</>
		</Modal>
	)
}
