import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SpecialtyAPI } from '../../services/specialties/specialty.api'
import { LokationSpecialty } from '../../services/specialties/specialty.types'

const initialState: LokationSpecialty[] = []

export const getSpecialties = createAsyncThunk('specialties/getSpecialties', async () => {
	const res = await SpecialtyAPI.getAllSpecialties({ page: 0, size: 100 })
	return res.data
})

export const specialtiesSlice = createSlice({
	name: 'specialties',
	initialState,
	reducers: {
		setSpecialties: (state, action: PayloadAction<LokationSpecialty[]>) => {
			return action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getSpecialties.fulfilled, (state, action) => {
			return action.payload
		})
	},
})

export const { setSpecialties } = specialtiesSlice.actions

export default specialtiesSlice.reducer
