import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { LicensedRegion } from './regions.types'

class LicensedStatesAPIPrototype {
	getLicensedRegions(): Promise<AxiosResponse<LicensedRegion[]>> {
		const headers = {}
		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/regions`, {
			headers,
		})
		return requestPromise
	}
}

export const LicensedRegionsAPI = new LicensedStatesAPIPrototype()
