import './sortable-card.scss'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Utilities } from '../../services/utilities.service'
import { IconButton } from '../icon-button/icon-button'

interface SortableCardProps {
	id: number
	label: string
	onDelete: () => void
}

export function SortableCard(props: SortableCardProps) {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id })

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	}

	return (
		<div className="featured-hub__card" ref={setNodeRef} style={style}>
			<strong>{Utilities.truncateString(props.label, 100)}</strong>
			<div className="flex flex-alignItems-center">
				<IconButton
					icon={'trash'}
					className="mr-20"
					onClick={() => {
						props.onDelete()
					}}
				/>
				<div {...attributes} {...listeners} style={{ cursor: 'grab' }}>
					<FontAwesomeIcon icon={['fas', 'grip-lines']} />
				</div>
			</div>
		</div>
	)
}
