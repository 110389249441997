import ThinkificExternalSSO from './thinkific-external-sso'

export function ThinkificRealEstateRoute() {
	return (
		<div className="flex-lg overflow-y__scroll" style={{ width: '100%' }}>
			<div className="col-xs-12 col-lg-12 pr-20-sm flex flex-wrap flex-alignItems-center flex-justifyContent-center overflow-y__scroll">
				<div className="col-12 col-md-8">
					<ThinkificExternalSSO
						returnTo={'realEstate'}
						heading="Real Estate 101"
						description="This is a follow up course to the New Agent Kick Start that furthers your knowledge into specific niches of the industry. This class is broken down by category to only watch what you would like to learn more about or refresh your knowledge"
					/>
				</div>
			</div>
		</div>
	)
}
