import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import HealthAdvantage from './Health_Advantage_Post.jpg'

export function LoKationResourceHealthAdvantage() {
	return (
		<div className="flex flex-fillSpace overflow-y__scroll">
			<div className="col-xs-12 col-lg-8 overflow-y__scroll pr-20-sm flex flex-column flex-alignItems-start">
				<div className="text-center col-xs-12">
					<img src={HealthAdvantage} style={{ height: '80px' }} />
					<h2>Better care. Better cost.</h2>
				</div>
				<HorizontalRule className="my-20" />
				<strong>
					LoKation Health Advantage Powered by Clearwater offers a wide range of solutions to ensure you get
					the right plan that fits your needs.
				</strong>
				<p>
					Clearwater has a blend of traditional and non insurance products to provide world-class benefits for
					dramatically lower costs. Their benefits experts will work with you 1-on-1 to determine the best
					plan for the best price that fits your unique healthcare needs.
				</p>
				<strong>LoKation&apos;s partnership with Clearwater affords our agents a 20% discount.</strong>

				<div className="flex flex-wrap text-center my-20">
					<div className="col-xs-12 col-lg-2 p-20-lg flex flex-column flex-alignItems-center">
						<FontAwesomeIcon icon={['far', 'shield']} size="3x" className="color__accent mb-10" />
						<strong>24/7 access to telemedicine</strong>
					</div>
					<div className="col-xs-12 col-lg-2 p-20-lg flex flex-column flex-alignItems-center">
						<FontAwesomeIcon icon={['far', 'heart']} size="3x" className="color__accent mb-10" />
						<strong>Free in-network preventative care</strong>
					</div>
					<div className="col-xs-12 col-lg-2 p-20-lg flex flex-column flex-alignItems-center">
						<FontAwesomeIcon icon={['far', 'diagram-venn']} size="3x" className="color__accent mb-10" />
						<strong>HSA & Copay payment options</strong>
					</div>
					<div className="col-xs-12 col-lg-2 p-20-lg flex flex-column flex-alignItems-center">
						<FontAwesomeIcon icon={['far', 'umbrella']} size="3x" className="color__accent mb-10" />
						<strong>Open network for major incidents</strong>
					</div>
					<div className="col-xs-12 col-lg-2 p-20-lg flex flex-column flex-alignItems-center">
						<FontAwesomeIcon icon={['far', 'heart']} size="3x" className="color__accent mb-10" />
						<strong>Single card for all your benefits</strong>
					</div>
					<div className="col-xs-12 col-lg-2 p-20-lg flex flex-column flex-alignItems-center">
						<FontAwesomeIcon icon={['far', 'shield']} size="3x" className="color__accent mb-10" />
						<strong>Expert second opinion services</strong>
					</div>
				</div>

				<iframe
					width="440"
					height="400"
					src="https://www.youtube.com/embed/JESYWq-g3NM"
					title="How Clearwater gives you a concierge experience to save money &amp; improve your healthcare benefits"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				></iframe>

				<div className="flex mt-40">
					<button
						className="button contained lg primary mr-10"
						onClick={() => {
							window.open('https://myagenthealth.com/', '_blank')
						}}
					>
						Explore Plans
					</button>
				</div>
			</div>

			<div className="col-xs-12 col-lg-4 border-left-thin flex flex-column flex-justifyContent-center text-center bg-color__adjust-alpha-10">
				<div className="col-xs-12 p-20-lg flex flex-column flex-alignItems-center">
					<h2 className="color__accent">$10,000</h2>
					<strong>Average Annual Savings</strong>
					<div>Save 30% (or more) on fixed medical costs when you make the switch</div>
				</div>
				<div className="col-xs-12 p-20-lg flex flex-column flex-alignItems-center">
					<h2 className="color__accent">PPO</h2>
					<strong>Network Upgrade</strong>
					<div>Access the largest network of doctors & diagnostics for day-to-day needs</div>
				</div>
				<div className="col-xs-12 p-20-lg flex flex-column flex-alignItems-center">
					<h2 className="color__accent">80%</h2>
					<strong>Out-of-Pocket Savings</strong>
					<div>Dramatically decrease your financial risk when unexpected medical issues arise</div>
				</div>
			</div>
		</div>
	)
}
