import { EndUserProps } from '../user/user.types'
import { ValidationResult } from '../validation/validation.types'
import { CustomForms } from './custom-forms.types'

export function CustomFormsService() {
	function submitForm(form: CustomForms.FormSumbittalDTO, user: EndUserProps): void {
		console.info(form, user)
		alert('SUBMITTED')
	}

	function validate(form: Partial<CustomForms.Form>): ValidationResult {
		let result: ValidationResult = {
			isValid: true,
			messages: [],
		}

		if (!form.displayLabel || (typeof form.displayLabel === 'string' && form.displayLabel.length === 0)) {
			result.isValid = false
			result.messages.push(`Displayed title cannot be empty`)
		}
		if (!form.formName || (typeof form.formName === 'string' && form.formName.length === 0)) {
			result.isValid = false
			result.messages.push(`Forn name cannot be empty`)
		}
		if (!form.submitEmail || (form.submitEmail && form.submitEmail.length === 0)) {
			result.isValid = false
			result.messages.push(`Must contain at least one email`)
		}
		if (!form.fields || (form.fields && form.fields.length === 0)) {
			result.isValid = false
			result.messages.push(`Must contain at least one field`)
		}

		return result
	}

	function getLabelForFieldType(fieldType: CustomForms.FieldTypes): string {
		switch (fieldType) {
			case 'CHECKBOX':
				return 'Checkbox'
			case 'EMAIL':
				return 'Email'
			case 'NUMBER':
				return 'Number'
			case 'PHONE':
				return 'Phone'
			case 'SINGLE_SELECT_DROPDOWN':
				return 'Dropdown'
			case 'SINGLE_SELECT_RADIO':
				return 'Radio Group'
			case 'STRING':
				return 'String'
		}
	}

	return { submitForm, validate, getLabelForFieldType }
}
