import './route-subnavigation.scss'

import { LoKationButton } from '@components/button/button'
import { RouteNavigationLink } from '@components/route-navigation-link/route-navigation-link'

import { RouteSubnavigationTypes } from './route-subnavigation.types'

export function RouteSubnavigation(props: RouteSubnavigationTypes.Component) {
	/** ================================ */
	/** Methods */

	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`route-subnavigation`)

		switch (props.variant) {
			case 'wide':
				classes.push(`variant__wide`)
				break
			case 'standard':
			default:
				classes.push(`variant__standard`)
				break
		}

		if (props.className) {
			classes.push(props.className)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	/** ================================ */
	/** Render Component */

	if (props.isCustom) {
		return (
			<div className={getClass()}>
				{props.backButton && (
					<LoKationButton
						label={props.backButton.label}
						icon="angle-left"
						iconPosition="left"
						className="mb-5"
						onClick={props.backButton.onClick}
						variant={'text'}
						size={'lg'}
					/>
				)}
				<h5 className="mb-10">{props.title}</h5>
				{props.children}
			</div>
		)
	}

	return (
		<div className={getClass()}>
			{props.backButton && (
				<LoKationButton
					label={props.backButton.label}
					icon="angle-left"
					iconPosition="left"
					className="mb-5"
					onClick={props.backButton.onClick}
					variant={'text'}
					size={'lg'}
				/>
			)}

			<h5 className="mb-10">{props.title}</h5>
			{props.routes.map((route) => {
				if (typeof route.isVisible === 'boolean' && !route.isVisible) {
					return <></>
				}
				return <RouteNavigationLink {...route} />
			})}
		</div>
	)
}
