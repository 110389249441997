import { LoKationButton } from '@components/button/button'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { EmailNotificationAPI } from '../../services/email-notification/email-notification.api'
import { EndUserProps } from '../../services/user/user.types'
import { PaperlessPipelineRoute } from '../paperless-pipeline/paperless-pipeline'

interface ManageTransactionsProps {
	currentUser: EndUserProps | null
}

interface State {
	paperlessPipline: boolean
	newTransaction: boolean
	transactionType: 'listingSideSale' | 'buySideSale' | 'rental' | 'other' | null
	closingTeam: 'Yes' | 'No' | 'NotApplicable' | null
	isItFinanced: 'Yes' | 'No' | null
	transactionCoordinator: boolean
	makeEscrowDeposit: boolean
	skip: boolean
	isSubmitted: boolean
	emailSubmittedMessage: boolean
}

const initialState: State = {
	paperlessPipline: false,
	newTransaction: false,
	transactionType: null,
	closingTeam: 'NotApplicable',
	isItFinanced: null,
	transactionCoordinator: false,
	makeEscrowDeposit: false,
	skip: false,
	isSubmitted: false,
	emailSubmittedMessage: false,
}

function ManageTransactionsPrototype({ currentUser }: ManageTransactionsProps) {
	const [state, setState] = useState<State>(initialState)
	const [floridaLicense, setFloridaLicense] = useState(false)

	const handleTransactionType = (type: State['transactionType']) => {
		setState((prevState) => ({
			...prevState,
			transactionType: type,
			paperlessPipline: false,
		}))
	}

	useEffect(() => {
		if (state.closingTeam != null && state.isItFinanced != null) {
			EmailNotificationAPI.manageTransactionsNotification(state.closingTeam, state.isItFinanced)
				.then((res) => {
					console.log('email sent')
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}, [state.closingTeam, state.isItFinanced])

	useEffect(() => {
		const isFloridaLicense =
			currentUser?.licenseInformation.some((license) => license.licensedState.abbreviation === 'FL') || false
		setFloridaLicense(isFloridaLicense)
	}, [currentUser])

	return (
		<div className="flex-lg overflow-y__scroll" style={{ width: '100%' }}>
			<div className="col-12 pr-20-sm flex flex-wrap flex-justifyContent-center flex-column flex-alignItems-center pr-10-md-down">
				{!state.isSubmitted && (
					<>
						<h4 className="mb-20">Let's get started! What would you like to achieve today?</h4>
						<div className="col-12 col-md-6 flex flex-justifyContent-center flex-column-md-down">
							<LoKationButton
								variant="outlined"
								className="mr-10 mr-0-md-down mb-10-md-down"
								size="lg"
								primary={true}
								label={`Add New Transaction`}
								onClick={() => {
									setState((prevState) => ({
										...prevState,
										newTransaction: true,
										paperlessPipline: false,
									}))
								}}
								disabled={state.newTransaction}
							/>

							<LoKationButton
								variant="outlined"
								size="lg"
								primary={true}
								label={`Manage Existing Transaction`}
								onClick={() => {
									setState((prevState) => ({
										...prevState,
										newTransaction: false,
										paperlessPipline: true,
									}))
								}}
								disabled={state.paperlessPipline}
							/>
						</div>
					</>
				)}

				{state.newTransaction && (
					<>
						<div className="col-12 col-md-8 text-center my-20">
							<h4>What type of Transaction are you submitting?</h4>
						</div>
						<div className="col-12 col-md-8 flex flex-justifyContent-center flex-column-md-down">
							<LoKationButton
								variant="outlined"
								className="mr-10 mr-0-md-down mb-10-md-down"
								size="lg"
								primary={true}
								label={`Listing Side Sale`}
								onClick={() => handleTransactionType('listingSideSale')}
								disabled={state.transactionType === 'listingSideSale'}
							/>
							<LoKationButton
								variant="outlined"
								className="mr-10 mr-0-md-down mb-10-md-down"
								size="lg"
								primary={true}
								label={`Buy Side Sale`}
								onClick={() => handleTransactionType('buySideSale')}
								disabled={state.transactionType === 'buySideSale'}
							/>
							<LoKationButton
								variant="outlined"
								className="mr-10 mr-0-md-down mb-10-md-down"
								size="lg"
								primary={true}
								label={`Rental`}
								onClick={() => handleTransactionType('rental')}
								disabled={state.transactionType === 'rental'}
							/>
							<LoKationButton
								variant="outlined"
								className="mr-10 mr-0-md-down mb-10-md-down"
								size="lg"
								primary={true}
								label={`Other`}
								onClick={() => {
									setState((prevState) => ({
										...prevState,
										paperlessPipline: true,
										transactionType: 'other',
									}))
								}}
								disabled={state.transactionType === 'other'}
							/>
						</div>
						{/* render parts depending on the transaction type */}
						{state.transactionType === 'listingSideSale' && (
							<>
								<div className="col-12 col-md-8 flex flex-wrap flex-justifyContent-center mt-20">
									<span className="mb-10 text-center">
										<strong>Empower Your Seller</strong> - Our Closing Team can advocate for your
										Seller, even if they lack the authority to select the Closing Agent in the
										Contract. Curious about a quote for our in-house team to stand up for your
										Seller?
									</span>
									<div className="col-12 col-md-8 flex flex-justifyContent-center  flex-column-md-down">
										<div className="col-12 col-md-4 flex flex-column text-center flex-justifyContent-center pr-20 pr-0-md-down">
											<LoKationButton
												variant="outlined"
												className="mr-10 mr-0-md-down mb-10"
												size="lg"
												primary={true}
												label={`Yes`}
												onClick={() => {
													EmailNotificationAPI.freeTransactionCoordinator()
														.then((res) => {
															setState((prevState) => ({
																...prevState,
																isSubmitted: true,
																newTransaction: false,
																paperlessPipline: false,
															}))
														})
														.catch((error) => {
															console.log(error)
														})
												}}
												// disabled={newTransactionState}
											/>
										</div>
										<div className="col-12 col-md-4 flex flex-column text-center">
											<LoKationButton
												variant="outlined"
												className="mr-10 mr-0-md-down"
												size="lg"
												primary={true}
												label={`Skip This Step`}
												onClick={() => {
													setState((prevState) => ({
														...prevState,
														paperlessPipline: true,
													}))
												}}
												disabled={state.paperlessPipline}
											/>
										</div>
									</div>
								</div>
							</>
						)}
						{state.transactionType === 'buySideSale' && (
							<>
								{floridaLicense && (
									<>
										<div className="col-12 col-md-8 text-center my-20">
											<h4>Are you using The Closing Team / in-house Title Team?</h4>
										</div>
										<div className="col-12 col-md-8 flex flex-justifyContent-center flex-column-md-down">
											<LoKationButton
												variant="outlined"
												className="mr-10 mr-0-md-down mb-10-md-down"
												size="lg"
												primary={true}
												label={`Yes`}
												onClick={() => {
													setState((prevState) => ({
														...prevState,
														emailSubmittedMessage: true,
														closingTeam: 'Yes',
													}))
												}}
												disabled={state.closingTeam === 'Yes'}
											/>
											<LoKationButton
												variant="outlined"
												className="mr-10 mr-0-md-down mb-10-md-down"
												size="lg"
												primary={true}
												label={`No`}
												onClick={() => {
													setState((prevState) => ({
														...prevState,
														closingTeam: 'No',
													}))
												}}
												disabled={state.closingTeam === 'No'}
											/>
										</div>
									</>
								)}
								{(state.closingTeam !== 'NotApplicable' ||
									(state.closingTeam === 'NotApplicable' && !floridaLicense)) && (
									<>
										<div className="col-12 col-md-8 text-center my-20">
											<h4>Is this transaction being financed?</h4>
										</div>
										<div className="col-12 col-md-8 flex flex-justifyContent-center flex-column-md-down">
											<LoKationButton
												variant="outlined"
												className="mr-10 mr-0-md-down mb-10-md-down"
												size="lg"
												primary={true}
												label={`Yes`}
												onClick={() => {
													setState((prevState) => ({
														...prevState,
														isSubmitted: true,
														emailSubmittedMessage: true,
														newTransaction: false,
														isItFinanced: 'Yes',
														paperlessPipline: true,
													}))
												}}
												disabled={state.isItFinanced === 'Yes'}
											/>
											<LoKationButton
												variant="outlined"
												className="mr-10 mr-0-md-down mb-10-md-down"
												size="lg"
												primary={true}
												label={`No`}
												onClick={() => {
													setState((prevState) => ({
														...prevState,
														paperlessPipline: true,
														isItFinanced: 'No',
													}))
												}}
												disabled={state.isItFinanced === 'No'}
											/>
										</div>
									</>
								)}
							</>
						)}
						{state.transactionType === 'rental' && (
							<>
								<div className="col-12 col-md-8 text-center mt-20">
									<strong>
										Discover the Convenience of The Closing Team's Online Escrow Payment Solution
									</strong>{' '}
									– Enable your customers to swiftly submit their deposit, at just a third of the cost
									of a bank wire transfer. Click below to access the Escrow portal and initiate a
									request to your customer for their Escrow Deposit transfer.
								</div>
								<div className="col-12 col-md-6 flex flex-justifyContent-center mt-20">
									<LoKationButton
										variant="outlined"
										className="mr-10 mr-0-md-down"
										size="lg"
										primary={true}
										label={`Make Escrow Deposit`}
										onClick={() => {
											setState((prevState) => ({
												...prevState,
												paperlessPipline: false,
											}))
											window.open('https://www.depositlink.com/pay/the-closing-team', '_blank')
										}}
										// disabled={makeEscrowDepositState}
									/>
									<LoKationButton
										variant="outlined"
										className="mr-10 mr-0-md-down"
										size="lg"
										primary={true}
										label={`Skip This Step`}
										onClick={() => {
											setState((prevState) => ({
												...prevState,
												paperlessPipline: true,
												skip: true,
											}))
										}}
										disabled={state.paperlessPipline}
									/>
								</div>
							</>
						)}
					</>
				)}

				{(state.isSubmitted || state.paperlessPipline) && (
					<>
						{/* {state.isSubmitted && state.emailSubmittedMessage &&
                            <div className="mt-20">A member of TCT will reach out to you and set you up with one of our Transaction Coordinators</div>
                        }
                        {state.isSubmitted && !state.emailSubmittedMessage &&
                            <div className="mt-20">A member of TCT will reach out to you and provide you with a quote</div>
                        } */}
						<PaperlessPipelineRoute />
					</>
				)}
			</div>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const ManageTransactionsRoute = connect(mapStateToProps)(ManageTransactionsPrototype)
