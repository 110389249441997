import { LoKationButton } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { ModalHeader } from '@components/modal/modal-header'
import { TextInput } from '@components/text-input/text-input'
import { getCalendars } from '@redux/reducers/calendar-reducer'
import { store } from '@redux/store'
import { useState } from 'react'
import { ToastService } from 'src/services/toast/toast.service'

import { CalendarAPI } from '../../../services/calendar/calendar.api'
import { Calendar } from '../../../services/calendar/calendar.types'
import { AdminItemEditorProps } from '../../../services/utility.types'
import { DeleteCalendarConfirmationModal } from './delete-calendar-confirmation'

export function EditCalendarModal(props: AdminItemEditorProps<Calendar.Calendar>) {
	const [modifiedCalendarProps, setModifiedCalendarProps] = useState<Partial<Calendar.Calendar>>({})
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const mergedCalendarProps: Calendar.Calendar | Omit<Calendar.Calendar, 'calendarId'> = {
		...getOriginalCalendarProps(),
		...modifiedCalendarProps,
	}

	/** ========================================== */
	/** Methods */

	function getOriginalCalendarProps(): Calendar.Calendar | Omit<Calendar.Calendar, 'calendarId'> {
		return props.item ? props.item : newCalendarProps()
	}

	function updateCalendarProp(key: keyof Calendar.Calendar, value: any): void {
		const updatedState = { ...modifiedCalendarProps, [key]: value }
		setModifiedCalendarProps(updatedState)
	}

	function newCalendarProps(): Omit<Calendar.Calendar, 'calendarId'> {
		return {
			name: '',
		}
	}

	function createCalendar(): void {
		CalendarAPI.createCalendar(mergedCalendarProps).then((newCalendarRes) => {
			store.dispatch(getCalendars())
			ToastService().create({ type: 'SUCCESS', body: `Calendar has been created` })
			if (props.onCreate) {
				props.onCreate(newCalendarRes.data)
			}
			props.dismissModal()
		})
	}

	function updateCalendar(): void {
		if ('calendarId' in mergedCalendarProps) {
			const calendarId = mergedCalendarProps.calendarId
			CalendarAPI.updateCalendar(calendarId, modifiedCalendarProps).then((updatedCalendarRes) => {
				store.dispatch(getCalendars())
				ToastService().create({ type: 'SUCCESS', body: `Calendar has been updated` })
				if (props.onUpdate) {
					props.onUpdate(updatedCalendarRes.data)
				}
				props.dismissModal()
			})
		}
	}

	/** ========================================== */
	/** Render Component */

	return (
		<>
			<Modal
				maxWidth={500}
				maxHeight={400}
				fixedHeight={false}
				className="flex flex-column"
				onClose={props.dismissModal}
			>
				<>
					<ModalHeader title={'Calendar'}>
						{props.item && (
							<LoKationButton
								variant="outlined"
								size="sm"
								label="Delete"
								onClick={() => {
									return new Promise((resolve) => {
										setShowDeleteModal(true)
										resolve()
									})
								}}
							/>
						)}
					</ModalHeader>

					<ModalBody>
						<TextInput
							label="Name"
							dataType="text"
							value={mergedCalendarProps.name}
							width="100%"
							className="mb-20"
							onChange={(updatedValue) => {
								updateCalendarProp('name', updatedValue)
							}}
						/>
					</ModalBody>

					<ModalFooter>
						<LoKationButton
							variant="outlined"
							size="sm"
							primary={false}
							label="Cancel"
							className="mr-10"
							onClick={props.dismissModal}
						/>
						{props.item && (
							<LoKationButton variant="contained" size="sm" label="Update" onClick={updateCalendar} />
						)}
						{!props.item && (
							<LoKationButton variant="contained" size="sm" label="Save" onClick={createCalendar} />
						)}
					</ModalFooter>
				</>
			</Modal>
			{showDeleteModal && props.item && (
				<DeleteCalendarConfirmationModal
					calendar={props.item}
					onClose={() => {
						setShowDeleteModal(false)
					}}
					onDelete={() => {
						if (!props.item || !props.onDelete) {
							return
						}
						setShowDeleteModal(false)
						props.dismissModal()
						props.onDelete(props.item)
					}}
				/>
			)}
		</>
	)
}
