import { EmailNotificationComponent } from '@components/email-notification/email-notification'
import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'

import CoverImg from './cynosure-logo.png'

export function LoKationResourceCynosure() {
	return (
		<div className="flex flex-column overflow-y__scroll">
			<div className="flex flex-wrap">
				<div className="col-xs-12 flex flex-column flex-alignItems-start pr-20">
					<div className="text-center col-xs-12">
						<img src={CoverImg} style={{ maxWidth: '300px' }} className="col-xs-12 mb-20" />
						<h2>KvCore Professional Set-up Assistance</h2>
					</div>
					<HorizontalRule className="my-20" />
					<p>
						We have negotiated a significantly reduced rate for one of the top kvCore programmers
						around…Cynosure Real Estate Solutions won’t only help you set-up your entire kvCore platform,
						they can super-charge it! Eliminate the time and concern of getting kvCore maximized to
						exponentially grow your Lead Generation abilities. They have successfully improved the use-case
						for over 1,500 kvCore users to date. Let Technology work for you, not the other way around.
						Normal fees start at $750, LoKation Agents start at only $300.
					</p>
					<div className="col-xs-12 flex-lg">
						<div className="col-xs-12 col-lg-4 pr-20-lg">
							<strong>$300 Gold Package Set-Up Includes:</strong>
							<div>
								Designed for an agent that is more Buyer-Centric or does not have plans to expand into a
								more listing focused business.
							</div>
							<ul>
								<li>Template design for front end website</li>
								<li>Uploading of custom logo if applicable (agent must provide)</li>
								<li>Background Image Set Up</li>
								<li>Color Scheme Set Up (will match as close as KV Core will allow)</li>
								<li>Implementation of smart campaigns (currently in use- up to 5)</li>
								<li>
									Current Lead Source Integration- standard websites (No ZAP or API Nation
									integrations)
								</li>
							</ul>
						</div>
						<div className="col-xs-12 col-lg-4 pr-20-lg">
							<strong>$500 Platinum Package Set-Up Includes:</strong>
							<div>
								Designed for an agent that wants to make listings a highger priority in their business
								(this package has more strategic processes for listing leads and digital farming).
							</div>
							<ul>
								<li>Template design for front end website</li>
								<li>Uploading of custom logo if applicable (agent must provide)</li>
								<li>
									Importing of Database (agent must provide CSV file that has already been configured
									to KvCore Specs). If Database is not configured, additional fees will apply.
								</li>
								<li>Background Image Set Up</li>
								<li>Color Scheme Set Up (will match as close as KV Core will allow)</li>
								<li>Implementation of smart campaigns (currently in use- up to 5)</li>
								<li>
									Current Lead Source Integration- standard websites (No ZAP or API Nation
									integrations)
								</li>
							</ul>
						</div>
						<div className="col-xs-12 col-lg-4 pr-20-lg">
							<strong>A La Carte Services:</strong>
							<ul>
								<li>Custom Campaign Set up $150</li>
								<li>Lead Integration - $100/ Hour</li>
								<li>
									Database Configuration and Integration- $100/Hour (PDF to Excel/CSV conversion is
									custom configuration and subject to separate fee schedule)
								</li>
								<li>Misc. Website Services- $100/Hour</li>
								<li>3rd Party Website Configuration</li>
								<li>
									KV Core Squeeze Page Package- $150.00 (10 predefined squeeze page links ready to use
									w/ follow up sequence)
								</li>
								<li>
									Customized Landing Page and Squeeze Page Package: $225.00 (10 predefined squeeze
									page links ready to use w/ follow up sequence and 1 template landing page)
								</li>
							</ul>
						</div>
					</div>

					<div className="mt-20">
						<strong>For questions or a la carte services, please contact:</strong>
						<br />
						Mike Pritchard
						<br />
						Cynosure Real Estate Solutions
						<br />
						CEO/OWNER
						<br />
						512-750-2294
					</div>

					<EmailNotificationComponent
						serviceToNotify={'cynosure'}
						title="Register for Cynosure"
						description="Additional Information: Rescheduling Policy: Agent is allowed 1 reschedule with less than 24 hour notice due to unforeseen issues, after one reschedule (within 24 hr deadline) there will be a $50.00 rescheduling fee Refund Policy: Once invoice payment has been made and KvCore Login Information has been delivered to Cynosure Real Estate Solutions, no refunds will be issued."
					/>
				</div>
			</div>
		</div>
	)
}
