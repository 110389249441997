import { EndUserLocation, EndUserProps } from '../../services/user/user.types'
import { GoogleMapTypes } from './google-map.types'

export function GoogleMapService() {
	function convertUsersToMapMarkers(endUsers: (EndUserLocation | EndUserProps)[]): GoogleMapTypes.MarkerData {
		const markers: GoogleMapTypes.Feature[] = []

		endUsers.forEach((endUser) => {
			if (!endUser.addressLat || !endUser.addressLon) {
				console.warn(`Coordinates for user are missing`, endUser)
				return
			}

			try {
				markers.push({
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: [endUser.addressLat, endUser.addressLon],
					},
					properties: {
						name: String(endUser.firstName + ' ' + endUser.lastName),
						endUserId: endUser.endUserId,
					},
				})
			} catch (err) {
				console.error(`Could not add marker for user`)
			}
		})

		const markerData: GoogleMapTypes.MarkerData = {
			features: markers,
		}

		return markerData
	}

	return { convertUsersToMapMarkers }
}
