import { useState } from 'react'

import { Caret } from '../caret/caret'
import { Checkbox } from '../checkbox/checkbox'
import { DropdownChildOption, DropdownOptionProps, DropdownParentOption } from './dropdown.types'

export function DropdownOption<T>(
	props: DropdownOptionProps<T> & {
		onOptionClick: (option: T) => void
		onHide: () => void
		multiselect?: boolean
		selectedOptions: T[]
		isOnlyParent?: boolean
	},
) {
	const [isExpanded, setIsExpanded] = useState(props.isOnlyParent ?? false)
	const isSelected = getIsSelected()

	function getIsSelected(): boolean {
		const foundOption = props.selectedOptions.find((option) => {
			return option === props.value
		})
		if (foundOption) {
			return true
		} else {
			return false
		}
	}

	function renderChildOption(option: DropdownChildOption<T>): React.ReactElement {
		return (
			<div
				className="dropdown-option"
				tabIndex={0}
				onClick={() => {
					const selectedOption = option.value
					props.onOptionClick(selectedOption)
				}}
				onKeyDown={(evt) => {
					if (evt.key === 'Escape') {
						props.onHide()
					}
					if (evt.key === 'Enter') {
						props.onOptionClick(option.value)
					}
				}}
			>
				{props.multiselect && (
					<Checkbox
						checked={isSelected}
						className="mr-10"
						onChange={() => {
							props.onOptionClick(option.value)
						}}
					/>
				)}
				{option.label}
			</div>
		)
	}

	function renderParentOption(option: DropdownParentOption<T>): React.ReactElement {
		const isThisOpen = isExpanded

		return (
			<>
				<div
					className={`dropdown-option`}
					tabIndex={0}
					onClick={() => {
						setIsExpanded(!isExpanded)
					}}
					onKeyDown={(evt) => {
						if (evt.key === 'Escape') {
							props.onHide()
						}
						if (evt.key === 'Enter') {
							setIsExpanded(!isExpanded)
						}
					}}
				>
					<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween">
						{option.label}
						<Caret state={isExpanded ? 'opened' : 'closed'} />
					</div>
				</div>
				{isThisOpen && (
					<div>
						{option.children.map((childOption) => {
							return (
								<DropdownOption
									{...childOption}
									multiselect={props.multiselect}
									selectedOptions={props.selectedOptions}
									onOptionClick={props.onOptionClick}
									onHide={() => {
										props.onHide()
									}}
								/>
							)
						})}
					</div>
				)}
			</>
		)
	}

	if ('children' in props) {
		return renderParentOption(props)
	} else {
		return renderChildOption(props)
	}
}
