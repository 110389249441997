import { DateService } from '../../services/date.service'
import { NewsFeed } from '../../services/news-feed/news-feed.types'

export function NewsFeedStoryTimestamp(props: { story: NewsFeed.Story; className?: string }) {
	return (
		<div className={`color__adjust-60 ${props.className ? props.className : ''}`}>
			<span>{DateService.getFormattedDateFromISO8601(props.story.createdTimestamp)}</span>
			<span className="mx-10">·</span>
			{props.story.licensedStates.map((state, index) => {
				const isLast = index === props.story.licensedStates.length - 1
				return (
					<span key={state.abbreviation}>
						{state.stateName}
						{!isLast ? ', ' : ''}
					</span>
				)
			})}
		</div>
	)
}
