import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function KVCoreIconGrid() {
	return (
		<>
			<div className="flex flex-wrap text-center col-xs-12">
				<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'megaphone']} size="3x" className="color__accent mb-10" />
					<strong>AI Drip Campaigns</strong>
				</div>
				<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'address-book']} size="3x" className="color__accent mb-10" />
					<strong>Powerful CRM</strong>
				</div>
				<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'funnel-dollar']} size="3x" className="color__accent mb-10" />
					<strong>Lead Generation Aggregator</strong>
				</div>
				<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'laptop']} size="3x" className="color__accent mb-10" />
					<strong>IDX Website</strong>
				</div>
			</div>

			<div className="flex flex-wrap text-center mb-10  col-xs-12">
				<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'person']} size="3x" className="color__accent mb-10" />
					<strong>Social Media Ad Automation</strong>
				</div>
				<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'phone']} size="3x" className="color__accent mb-10" />
					<strong>Mobile Auto Dialer</strong>
				</div>
				<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'toolbox']} size="3x" className="color__accent mb-10" />
					<strong>Listing / Seller Tools</strong>
				</div>
				<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'home']} size="3x" className="color__accent mb-10" />
					<strong>Open House Lead Capture</strong>
				</div>
			</div>
		</>
	)
}
