import { httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { ThinkificTypes } from './thinkific.types'

class ThinkificAPIPrototype {
	async getSsoUrl(returnTo: ThinkificTypes.returnToOptions): Promise<ThinkificTypes.getSsoUrl> {
		try {
			const params = {
				returnTo: returnTo,
			}
			const response = await httpRequest.get(`${RequestBaseURL}/api/v1/getThinkificSsoUrl`, { params: params })
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to get SSO URL.')
		}
	}
}

export const ThinkificAPI = new ThinkificAPIPrototype()
