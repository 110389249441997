import HappyFoxChatWidget from '@components/happy-fox-chat/happy-fox-chat-widget'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'

import { EndUserProps } from '../services/user/user.types'
import { GlobalHistory } from './history-component'
import { ModalsWrapper } from './modals-wrapper'

interface AppWrapperProps {
	currentUser: EndUserProps | null
}

export function AppWrapperPrototype({ currentUser }: AppWrapperProps) {
	const [hideHappyFox, setHideHappyFox] = useState(true)

	const location = useLocation()

	useEffect(() => {
		document.documentElement.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		})
	}, [location.pathname])

	useEffect(() => {
		if (currentUser) {
			setHideHappyFox(currentUser ? currentUser.roles.some((role) => role.roleType === 'ADMIN') : false)
		}
	}, [currentUser])

	return (
		<div className="route__wrapper">
			<GlobalHistory />
			<Outlet />
			{!hideHappyFox && currentUser && <HappyFoxChatWidget />}
			<ModalsWrapper />
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const AppWrapper = connect(mapStateToProps)(AppWrapperPrototype)
