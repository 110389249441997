import { store } from '@redux/store'
import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { GetItemsParams, GetItemsResponse } from '../utility.types'
import { LokationTag } from './tag.types'

export class TagAPI {
	static getAllTags(props: GetItemsParams<LokationTag>): Promise<AxiosResponse<GetItemsResponse<LokationTag>>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/tags/`, {
			headers,
			params,
		})
		return requestPromise
	}

	static createTag(tag: Omit<LokationTag, 'tagId'>): Promise<AxiosResponse<LokationTag>> {
		const userProps = store.getState().user
		if (!userProps) {
			throw new Error(`User not found. Tag cannot be created`)
		}

		const headers = {}
		const requestPromise = httpRequest.post(`${RequestBaseURL}${apiVersionURL}/tags/`, tag, {
			headers,
		})
		return requestPromise
	}

	static updateTag(tagId: number, updateTagDTO: Partial<LokationTag>): Promise<AxiosResponse<LokationTag>> {
		const userProps = store.getState().user
		if (!userProps) {
			throw new Error(`User not found. Tag cannot be updated`)
		}

		const headers = {}
		const requestPromise = httpRequest.patch(`${RequestBaseURL}${apiVersionURL}/tags/${tagId}`, updateTagDTO, {
			headers,
		})
		return requestPromise
	}

	static deleteTag(tagId: number): Promise<AxiosResponse<void>> {
		const headers = {}
		const requestPromise = httpRequest.delete(`${RequestBaseURL}${apiVersionURL}/tags/${tagId}`, {
			headers,
		})
		return requestPromise
	}
}
