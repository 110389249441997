import { DragAndDropFileUploader } from '@components/drag-and-drop-file-uploader/drag-and-drop-file-uploader'
import { useEffect, useState } from 'react'
import { AdvertisementImageAPI } from 'src/services/advertising/advertisement-image.api'
import { AdvertisingTypes } from 'src/services/advertising/advertising.types'
import { FileManagementService } from 'src/services/file-management/file-management.service'

import { useAdEditor, useAdEditorDispatch } from '../../state/ad-editor__state'

type ComponentProps = {
	adImage: AdvertisingTypes.AdvertisementImage
}

export function AdImageFileUploader(props: ComponentProps) {
	const adEditorState = useAdEditor()
	const adEditorDispatch = useAdEditorDispatch()
	const [imgUrl, setImgUrl] = useState<string | null | 'NEW'>(null)

	useEffect(() => {
		if (props.adImage.advertisementImageId < 0) {
			setImgUrl('NEW')
			return
		}
		AdvertisementImageAPI.getAdvertisementImage(
			adEditorState.modifiedAd.advertisementId,
			props.adImage.advertisementImageId,
		).then((res) => {
			setImgUrl(res.data)
		})
	}, [])

	if (imgUrl === null) {
		return <></>
	}

	return (
		<DragAndDropFileUploader
			style={{ width: '100%', minHeight: '75px' }}
			heightVariant="SHORT"
			allowMultiple={false}
			className="mb-10"
			imgUrl={imgUrl && imgUrl !== 'NEW' ? imgUrl : undefined}
			onFileAdded={(files) => {
				FileManagementService.convertFileToBase64(files[0]).then((res) => {
					const updatedAdImage: AdvertisingTypes.AdvertisementImage = {
						...props.adImage,
						base64Image: res,
					}
					adEditorDispatch({ type: 'update-ad-image', payload: updatedAdImage })
				})
			}}
			isFilePreviouslyUploaded={props.adImage.advertisementImageId > 0}
			supportedFormats={['gif', 'jpg', 'png']}
		/>
	)
}
