import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Calendar } from '../../services/calendar/calendar.types'
import { DateService } from '../../services/date.service'

interface EventCalendarEventDateRangeProps {
	event: Calendar.Event
	instanceStartDate: Date | null
	instanceEndDate: Date | null
	className?: string
	style?: React.CSSProperties
}

export function EventCalendarEventDateRange(props: EventCalendarEventDateRangeProps) {
	function getDaysOfWeek(): string {
		if (!props.event.days || props.event.days.length === 0) {
			return ''
		}
		let daysString = 'on '
		props.event.days.forEach((day, index) => {
			switch (day) {
				case 'MO':
					daysString += 'Monday'
					break
				case 'TU':
					daysString += 'Tuesday'
					break
				case 'WE':
					daysString += 'Wednesday'
					break
				case 'TH':
					daysString += 'Thursday'
					break
				case 'FR':
					daysString += 'Friday'
					break
				case 'SA':
					daysString += 'Saturday'
					break
				case 'SU':
					daysString += 'Sunday'
					break
			}
			if (index < props.event.days.length - 2) {
				daysString += ', '
			}
			if (index === props.event.days.length - 2) {
				daysString += ', and '
			}
		})
		return daysString
	}

	function getOrdinalSuffix(dayOfMonth: number): string {
		switch (dayOfMonth) {
			case 1:
			case 21:
			case 31:
				return 'st'
			case 2:
			case 22:
				return 'nd'
			case 3:
			case 23:
				return 'rd'
			default:
				return 'th'
		}
	}

	function getRecurrenceRate(): string {
		switch (props.event.frequency) {
			case 'DAILY':
				return props.event.recurranceInterval === 1 ? 'Daily' : `Every ${props.event.recurranceInterval} days`
			case 'WEEKLY':
				return props.event.recurranceInterval === 1 ? 'Weekly' : `Every ${props.event.recurranceInterval} weeks`
			case 'MONTHLY':
				let startDate = new Date(props.event.startDate)
				let dayOfMonth = startDate.getDate()
				return props.event.recurranceInterval === 1
					? `Monthly on the ${dayOfMonth}${getOrdinalSuffix(dayOfMonth)}`
					: `Every ${props.event.recurranceInterval} months on the ${dayOfMonth}${getOrdinalSuffix(dayOfMonth)}`
			case 'YEARLY':
				return props.event.recurranceInterval === 1 ? 'Yearly' : `Every ${props.event.recurranceInterval} years`
		}
	}

	function getRecurrenceString(): React.ReactNode {
		return (
			<div className={`flex flex-alignItems-center opacity-50`}>
				<div>
					{getRecurrenceRate()} {getDaysOfWeek()}
				</div>
			</div>
		)
	}

	function getEventStartDate(): string {
		if (!props.event.recurring) {
			return props.event.startDate
		} else {
			return 'UNKNOWN'
		}
	}

	function formatHour(hour: number) {
		const hour12 = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour
		return DateService.prependSingleDigitWithZero(hour12)
	}

	function getPeriod(hour: number) {
		return hour >= 12 ? 'PM' : 'AM'
	}

	function formatHourString(hour: string): number {
		const hourInt = parseInt(hour, 10)
		return hourInt > 12 ? hourInt - 12 : hourInt === 0 ? 12 : hourInt
	}

	function getPeriodString(hour: string): string {
		return parseInt(hour, 10) >= 12 ? 'PM' : 'AM'
	}

	function getEventDateString(): React.ReactNode {
		if (props.instanceStartDate && props.instanceEndDate) {
			const startHour = formatHour(props.instanceStartDate.getHours())
			const startMinute = DateService.prependSingleDigitWithZero(props.instanceStartDate.getMinutes())
			const startPeriod = getPeriod(props.instanceStartDate.getHours())

			const endHour = formatHour(props.instanceEndDate.getHours())
			const endMinute = DateService.prependSingleDigitWithZero(props.instanceEndDate.getMinutes())
			const endPeriod = getPeriod(props.instanceEndDate.getHours())

			return (
				<div className={`flex flex-alignItems-center`}>
					<div>{DateService.getFormattedDateFromDateObj(props.instanceStartDate)}</div>
					<div className="mx-10">
						<FontAwesomeIcon icon={['fas', 'circle']} style={{ width: '4px' }} />
					</div>
					<div className="mr-5">
						{startHour}:{startMinute} {startPeriod}
					</div>
					<div className="mr-5">&mdash;</div>
					<div>
						{endHour}:{endMinute} {endPeriod}
					</div>
				</div>
			)
		} else {
			const startHour = formatHourString(props.event.startTime.split(':')[0])
			const startMinute = props.event.startTime.split(':')[1]
			const startPeriod = getPeriodString(props.event.startTime.split(':')[0])

			const endHour = formatHourString(props.event.endTime.split(':')[0])
			const endMinute = props.event.endTime.split(':')[1]
			const endPeriod = getPeriodString(props.event.endTime.split(':')[0])

			return (
				<div className={`flex flex-alignItems-center`}>
					{!props.event.recurring && (
						<>
							<div>{DateService.getFormattedDateFromYYYYMMDD(getEventStartDate())}</div>
							<div className="mx-10">
								<FontAwesomeIcon icon={['fas', 'circle']} style={{ width: '4px' }} />
							</div>
						</>
					)}
					<div className="mr-5">
						{startHour}:{startMinute} {startPeriod}
					</div>
					<div className="mr-5">&mdash;</div>
					<div>
						{endHour}:{endMinute} {endPeriod}
					</div>
				</div>
			)
		}
	}

	function getAllDayString(): React.ReactNode {
		if (props.instanceStartDate && props.instanceEndDate) {
			return (
				<div className={`flex flex-alignItems-center`}>
					<div>{DateService.getFormattedDateFromDateObj(props.instanceStartDate)}</div>
					<div className="mx-10">All Day</div>
				</div>
			)
		} else {
			return (
				<div className={`flex flex-alignItems-center`}>
					{!props.event.recurring && (
						<>
							<div>{DateService.getFormattedDateFromYYYYMMDD(getEventStartDate())}</div>
							<div className="mx-10">
								<FontAwesomeIcon icon={['fas', 'circle']} style={{ width: '4px' }} />
							</div>
						</>
					)}
					<div className="mr-5">All Day</div>
				</div>
			)
		}
	}

	if (props.event.allDay) {
		return (
			<div style={props.style} className={`flex flex-column ${props.className ? props.className : ''}`}>
				{getAllDayString()}
			</div>
		)
	}

	if (props.instanceStartDate && props.instanceEndDate) {
		return (
			<div style={props.style} className={`flex flex-column ${props.className ? props.className : ''}`}>
				{getEventDateString()}
			</div>
		)
	}

	if (props.event.recurring) {
		return (
			<div style={props.style} className={`flex flex-column ${props.className ? props.className : ''}`}>
				{getRecurrenceString()}
			</div>
		)
	}

	return (
		<div style={props.style} className={`flex flex-column ${props.className ? props.className : ''}`}>
			{getEventDateString()}
		</div>
	)
}
