import { AxiosResponse } from 'axios'

import { httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { GetItemsParams } from '../utility.types'
import { MLSBoard } from './mls-board.types'

export interface GetMLSOptionsResponse {
	size: number
	totalElements: number
	totalPages: number
	number: number
	items: MLSBoard.Complete[]
}

export function MLSBoardAPI() {
	function getAllOptions(props: GetItemsParams<MLSBoard.Complete>): Promise<AxiosResponse<GetMLSOptionsResponse>> {
		const headers: { [key: string]: any } = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props })

		const requestPromise = httpRequest.get(`${RequestBaseURL}/api/v1/mlsBoards/`, {
			headers,
			params,
		})
		return requestPromise
	}

	function getAllBoardMembers(mlsOptionId: number): Promise<AxiosResponse<unknown>> {
		const headers: { [key: string]: any } = {}
		/** Set params */

		const requestPromise = httpRequest.get(`${RequestBaseURL}/api/v1/mlsBoards/${mlsOptionId}/endUsers`, {
			headers,
		})
		return requestPromise
	}

	function addOption(option: Omit<MLSBoard.Complete, 'mlsBoardId'>): Promise<AxiosResponse<MLSBoard.Complete>> {
		const headers: { [key: string]: any } = {}
		const requestPromise = httpRequest.post(`${RequestBaseURL}/api/v1/mlsBoards/`, option, {
			headers,
		})
		return requestPromise
	}

	function deleteOption(mlsOptionId: number): Promise<AxiosResponse<unknown>> {
		const headers: { [key: string]: any } = {}
		const requestPromise = httpRequest.delete(`${RequestBaseURL}/api/v1/mlsBoards/${mlsOptionId}`, {
			headers,
		})
		return requestPromise
	}

	function patchOption(option: MLSBoard.Complete): Promise<AxiosResponse<MLSBoard.Complete>> {
		const headers: { [key: string]: any } = {}
		const requestPromise = httpRequest.patch(`${RequestBaseURL}/api/v1/mlsBoards/${option.mlsBoardId}`, option, {
			headers,
		})
		return requestPromise
	}

	return { getAllOptions, addOption, getAllBoardMembers, deleteOption, patchOption }
}
