// import { DateTime } from 'luxon'

import { StripeTypes } from './stripe.types'

export namespace StripeService {
	export function getDefaultCouponProps(): StripeTypes.CouponData {
		// let currentDateObj = DateTime.now().toUTC()

		const newCoupon: StripeTypes.CouponData = {
			id: '',
			object: '',
			amountOff: 0,
			created: 0,
			currency: '',
			duration: 'once',
			durationInMonths: 0,
			livemode: '',
			maxRedemptions: '',
			metadata: '',
			name: '',
			percentOff: 0,
			redeemBy: '',
			timesRedeemed: 0,
			valid: true,
		}
		return newCoupon
	}

	export function getDefaultPromotionCodeProps(couponId?: string): StripeTypes.PromotionCodeCreation {
		// let currentDateObj = DateTime.now().toUTC()

		const newCoupon: StripeTypes.PromotionCodeCreation = {
			id: '',
			maxRedemptions: null,
			code: '',
			couponId: couponId ? couponId : '',
		}
		return newCoupon
	}

	/** Converts [YYYY, MM, DD] into 'YYYY-MM-DD' */
	export function convertDateArrayIntoString(dateArray: number[]): string {
		const year = String(dateArray[0])
		const month = dateArray[1] < 10 ? `0${dateArray[1]}` : `${dateArray[1]}`
		const day = dateArray[2] < 10 ? `0${dateArray[2]}` : `${dateArray[2]}`
		return `${year}-${month}-${day}`
	}

	/** Converts 'YYYY-MM-DD' into [YYYY, MM, DD] */
	export function convertDateStringIntoArray(dateString: string): number[] {
		const splitDate = dateString.split('-')
		return [parseFloat(splitDate[0]), parseFloat(splitDate[1]), parseFloat(splitDate[2])]
	}
}
