import { ContextMenu } from '@components/context-menu/context-menu'
import { ContextMenuTypes } from '@components/context-menu/context-menu.types'
import { IconButton } from '@components/icon-button/icon-button'
import { useState } from 'react'
import { useContextMenu } from 'src/services/hooks/use-context-menu'
import { StripeCouponAPI } from 'src/services/stripe/stripe.coupon.api'

interface PromotionCodeStatusProps {
	promotionId: string
	promotionCode: string
	archiveStatus: boolean
}

export function PromotionCodeStatus(props: PromotionCodeStatusProps) {
	const { contextMenu } = useContextMenu()
	const [promotionModifiedStatus, setPromotionModifiedStatus] = useState<boolean>(props.archiveStatus)

	function getPromotionCodeOptions(): ContextMenuTypes.Option[] {
		const menuOptions: ContextMenuTypes.Option[] = []

		menuOptions.push({
			label: promotionModifiedStatus ? 'Archive' : 'Unarchive',
			onClick: () => {
				StripeCouponAPI.updatePromotionCode(props.promotionId, promotionModifiedStatus).then((res) => {
					setPromotionModifiedStatus(res.active)
				})
			},
		})

		return menuOptions
	}
	return (
		<div>
			<div className="form__card">
				<div>
					<strong>{props.promotionCode}</strong>
					{!promotionModifiedStatus && <span className="ml-15">Inactive</span>}
				</div>
				<div>
					<IconButton
						className=""
						icon={'ellipsis-h'}
						onClick={(evt) => {
							contextMenu.set({ isVisible: true, x: evt.pageX, y: evt.pageY })
						}}
					/>
				</div>
				<ContextMenu
					position={`absolute`}
					x={contextMenu.x}
					y={contextMenu.y}
					visible={contextMenu.isVisible}
					style={{ width: '200px' }}
					options={getPromotionCodeOptions()}
					onDismiss={() => {
						contextMenu.setIsVisible(false)
					}}
				/>
			</div>{' '}
			{/* Ensure each item has a unique key */}
		</div>
	)
}
