import React, { useState } from 'react'

import { Calendar } from '../../services/calendar/calendar.types'
import { CollapsibleContent, CollapsibleContentStates } from '../collapsible-content/collapsible-content'
import { RadioGroup, RadioGroupOption } from '../radio-group/radio-group'

export interface ItemSearchCalendarFilterProps {
	allCalendars: Calendar.Calendar[]
	selectedCalendarId: number | null
	onChange: (selectedCalendar: number | null) => void
	style?: React.CSSProperties
	className?: string
}

export function ItemSearchCalendarFilter(props: ItemSearchCalendarFilterProps) {
	const [state, setState] = useState<CollapsibleContentStates>('OPEN')

	/** ======================================== */
	/** Methods */

	function getModeOptions(): RadioGroupOption<number | null>[] {
		const options: RadioGroupOption<number | null>[] = []

		options.push({
			label: 'All Calendars',
			value: null,
		})

		props.allCalendars.forEach((calendar) => {
			options.push({
				label: calendar.name,
				value: calendar.calendarId,
			})
		})

		return options
	}

	/** ======================================== */
	/** Render Component */

	return (
		<CollapsibleContent label="Calendars" state={state} setState={setState}>
			<>
				<div className={`base-search-filter ${props.className ? props.className : ''}`} style={props.style}>
					<RadioGroup<number | null>
						variant="SMALL"
						options={getModeOptions()}
						optionClassName="mb-10"
						value={props.selectedCalendarId}
						onChange={(value) => {
							props.onChange(value)
						}}
					/>
				</div>
			</>
		</CollapsibleContent>
	)
}
