import './divider.scss'

interface DividerProps {
	width: number | 'fill'
	className?: string
}

export function Divider(props: DividerProps) {
	/** ================================ */
	/** Methods */

	function getStyle(): React.CSSProperties {
		let style: React.CSSProperties = {}

		if (props.width === 'fill') {
			style.width = `100%`
		} else {
			style.width = `${props.width}px`
		}
		return style
	}

	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`divider`)

		if (props.className) {
			classes.push(props.className)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	/** ================================ */
	/** Render Component */

	return <div className={getClass()} style={getStyle()}></div>
}
