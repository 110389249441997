import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CalendarAPI } from '../../services/calendar/calendar.api'
import { Calendar } from '../../services/calendar/calendar.types'

const initialState: Calendar.Calendar[] = []

export const getCalendars = createAsyncThunk('calendar/get', async () => {
	const res = await CalendarAPI.getCalendars({ page: 0, size: 100 })
	return res.data.items
})

export const deleteCalendar = createAsyncThunk(
	'calendar/delete',
	async (props: { calendarId: number }, { rejectWithValue }) => {
		const res = await CalendarAPI.deleteCalendar(props.calendarId)
		if (res.status > 199 && res.status < 300) {
			return props.calendarId
		} else {
			return rejectWithValue(res)
		}
	},
)

export const calendarSlice = createSlice({
	name: 'calendar',
	initialState,
	reducers: {
		addEvent: (state, action: PayloadAction<Calendar.Calendar>) => {
			return [...state, action.payload]
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getCalendars.fulfilled, (state, action) => {
			state = action.payload
			return state
		})
		builder.addCase(deleteCalendar.fulfilled, (state, action) => {
			return state.filter((calendar) => calendar.calendarId !== action.payload)
		})
	},
})

export const { addEvent } = calendarSlice.actions

export default calendarSlice.reducer
