import { useLoaderData } from 'react-router'

import { DomainAdminInner } from './domain-inner'
import { DomainAdminProvider } from './state/domain-admin__state'
import { DomainAdminState } from './state/domain-admin__state.types'

export function DomainAdminProviderWrapper() {
	const initialLocalState = useLoaderData() as DomainAdminState.DomainAdminData | undefined

	if (!initialLocalState) {
		return <></>
	}

	return (
		<DomainAdminProvider initialLocalState={initialLocalState}>
			<DomainAdminInner />
		</DomainAdminProvider>
	)
}
