import { LoKationButton } from '@components/button/button'

export function BackToAdminButton() {
	return (
		<LoKationButton
			to="/administration"
			label="Administration"
			icon={'angle-left'}
			iconPosition="left"
			variant="text"
			size="sm"
		/>
	)
}
