import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { GetItemsParams } from '../utility.types'
import { LokationSpecialty } from './specialty.types'

export class SpecialtyAPI {
	static getAllSpecialties(props: GetItemsParams<LokationSpecialty>): Promise<AxiosResponse<LokationSpecialty[]>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/specialties/`, {
			headers,
			params,
		})
		return requestPromise
	}
}
