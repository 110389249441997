import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { Domain } from './domain.types'

export const DOMAIN_ID = 1

export class DomainAPI {
	public static get(domainId: number): Promise<AxiosResponse<Domain.Properties>> {
		const headers = {}
		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/domains/${domainId}`, {
			headers,
		})
		return requestPromise
	}

	public static update(domainId: number, domainProperties: Domain.Properties): Promise<AxiosResponse<void>> {
		const headers = {}
		const requestPromise = httpRequest.patch(
			`${RequestBaseURL}${apiVersionURL}/domains/${domainId}`,
			domainProperties,
			{
				headers,
			},
		)
		return requestPromise
	}
}
