import './mobile-app-tray.scss'

import { useAppDispatch } from '@redux/hooks'
import { uiSlice } from '@redux/reducers/ui-reducer'
import { RootState, store } from '@redux/store'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'

import { hubRoutePath } from '../../routes/routes.constant'
import { ColorService } from '../../services/color/color.service'
import { Hub } from '../../services/hubs/hubs.types'
import { EndUserProps } from '../../services/user/user.types'
import { MobileAppHeaderInner } from '../mobile-app-header/mobile-app-header__inner'
import { HubsService } from './../../services/hubs/hubs.service'
import { MobileAppTrayLink } from './mobile-app-tray__link'

function MobileAppTrayPrototype(props: { hubs: Hub[]; currentUser: EndUserProps | null }) {
	const dispatch = useAppDispatch()
	const sortedHubs = HubsService.sortHubsByDisplayOrder(props.hubs)

	const hasFloridaLicense =
		props.currentUser &&
		props.currentUser.licenseInformation.some((license) => license.licensedState.abbreviation === 'FL')

	const userSuperAdmin = props.currentUser?.roles.some((role) => role.roleId === 4)

	function getAppTrayBgColor(): string {
		const themeProps = store.getState().theme
		const bgColor = ColorService.mixColors(themeProps.appTrayBgColor, '#000000', 0.35, 'hex')
		return ColorService.hexToRGBA(bgColor, 0.95)
	}

	return (
		<>
			{ReactDOM.createPortal(
				<div className="mobile-app-tray" style={{ backgroundColor: getAppTrayBgColor() }}>
					<div className="mobile-app-header">
						<MobileAppHeaderInner headerStyle="REVERSED" />
					</div>
					<div className="overflow-y__scroll height__100pct">
						<MobileAppTrayLink
							label={`Home`}
							href={`${hubRoutePath}/`}
							updateIsAppTrayOpen={() => {
								dispatch(uiSlice.actions.setMobileTrayState(false))
							}}
						/>
						{sortedHubs.map((hub) => {
							if ((hub.hubId === 3 && !hasFloridaLicense) || (hub.hubId === 7 && !hasFloridaLicense)) {
								return <></>
							}
							if (!userSuperAdmin && !hub.published) {
								return <></>
							}
							return (
								<MobileAppTrayLink
									label={hub.title}
									href={`${hubRoutePath}/${hub.slug}`}
									updateIsAppTrayOpen={() => {
										dispatch(uiSlice.actions.setMobileTrayState(false))
									}}
								/>
							)
						})}

						<MobileAppTrayLink
							label={`Agent Referral Network`}
							href={`/user-search`}
							updateIsAppTrayOpen={() => {
								dispatch(uiSlice.actions.setMobileTrayState(false))
							}}
						/>
					</div>
				</div>,
				document.body,
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
		hubs: state.hubs,
	}
}

export const MobileAppTray = connect(mapStateToProps)(MobileAppTrayPrototype)
