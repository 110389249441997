import { store } from '@redux/store'
import React, { useState } from 'react'

import { LokationTag } from '../../services/tags/tag.types'
import { LoKationButton } from '../button/button'
import { CheckboxList } from '../checkbox-list/checkbox-list'
import { CollapsibleContent, CollapsibleContentStates } from '../collapsible-content/collapsible-content'

export interface ItemSearchTagFilterProps {
	selectedTags: LokationTag[]
	onChange: (selectedTags: LokationTag[]) => void
	style?: React.CSSProperties
	className?: string
}

export function ItemSearchTagFilter(props: ItemSearchTagFilterProps) {
	const [state, setState] = useState<CollapsibleContentStates>('OPEN')

	return (
		<CollapsibleContent label="Tags" state={state} setState={setState}>
			<>
				<div className={`base-search-filter ${props.className ? props.className : ''}`} style={props.style}>
					<CheckboxList
						onChange={props.onChange}
						selectedItems={props.selectedTags}
						allItems={store.getState().tags}
						itemIdKey={'tagId'}
						itemLabelKey={'label'}
						optionClassName="col-xs-12"
					/>
				</div>
				<div>
					<LoKationButton
						variant="text"
						size="sm"
						label="Select None"
						className="mr-20"
						onClick={() => {
							props.onChange([])
						}}
					/>
					<LoKationButton
						variant="text"
						size="sm"
						label="Select All"
						onClick={() => {
							props.onChange(store.getState().tags)
						}}
					/>
				</div>
			</>
		</CollapsibleContent>
	)
}
