import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { Paper } from '@components/paper/paper'
import { Tooltip } from '@components/tooltip/tooltip'
import { useEffect, useState } from 'react'

import { StripeAPI } from '../../../../../services/stripe/stripe.api'
import { StripeTypes } from '../../../../../services/stripe/stripe.types'
import { EndUserProps } from '../../../../../services/user/user.types'

interface StripeInvoicesProps {
	userToEdit: EndUserProps
	currentUser: EndUserProps | null
}

function StripeInvoices(props: StripeInvoicesProps) {
	const [invoices, setInvoices] = useState<StripeTypes.InvoiceData[]>([])

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		setLoading(true)
		let endUserId: number | undefined
		let isAdmin = false // Determine the admin status locally within the effect
		if (props.currentUser && props.userToEdit) {
			isAdmin = props.currentUser.endUserId !== props.userToEdit.endUserId
			if (isAdmin) {
				endUserId = props.userToEdit.endUserId
			}
		}
		StripeAPI.fetchCustomerInvoices(endUserId)
			.then((res) => {
				const invoicesArray = Object.values(res) as StripeTypes.InvoiceData[]
				setInvoices(invoicesArray)
				setLoading(false)
			})
			.catch((error) => {
				setLoading(false)
				console.log('Failed to fetch product details:', error)
			})
	}, [])

	return (
		<div className="mt-40">
			<h5 className="mb-10">Past Invoices</h5>
			<div>
				{loading ? (
					<>
						<div className="mb-10">
							<GenericContentLoader width={'fill'} height={90} />
						</div>
						<div className="mb-10">
							<GenericContentLoader width={'fill'} height={90} />
						</div>
						<div className="mb-10">
							<GenericContentLoader width={'fill'} height={90} />
						</div>
						<div className="mb-10">
							<GenericContentLoader width={'fill'} height={90} />
						</div>
					</>
				) : (
					invoices &&
					invoices.map((invoice) => (
						<div key={invoice.id}>
							<Paper bgColor="primary" padding={['all']} margins={['bottom']}>
								<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center flex-column-md-down">
									<div className="flex flex-column-md-down">
										<div>
											<div className="invoice-date mb-5">
												{new Date(invoice.created * 1000).toLocaleDateString()}
											</div>
											<div className="invoice-amount">
												${(invoice.amountPaid / 100).toFixed(2)}
											</div>
											<div>
												{invoice.discount && invoice.discount.coupon.duration === 'forever' && (
													<div className="flex mt-5">
														<Tooltip body="(Multi-License Discount)" icon="circle-info" />{' '}
													</div>
												)}
												{invoice.discount && invoice.discount.coupon.duration === 'once' && (
													<div className="flex mt-5">
														<Tooltip body="(One-Time Discount)" icon="circle-info" />
													</div>
												)}
											</div>
										</div>
										<div className="product-name ml-20">
											{invoice.lineItems.map((lineItem, index) => (
												<p key={index}>{lineItem.description.replace(/\d+ × /, '')}</p>
											))}
										</div>
									</div>
									<div className="subscribe-button">
										<a
											href={invoice.hostedInvoiceUrl}
											target="_blank"
											className="button outlined sm primary "
											rel="noreferrer"
										>
											View Invoice
										</a>
									</div>
								</div>
							</Paper>
						</div>
					))
				)}
			</div>
		</div>
	)
}

export default StripeInvoices
