import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { LicensedRegionsAPI } from '../../services/regions/regions.api'
import { LicensedRegion } from '../../services/regions/regions.types'

const initialState: LicensedRegion[] = []

export const getLicensedRegions = createAsyncThunk('licensedRegions/get', async () => {
	const res = await LicensedRegionsAPI.getLicensedRegions()
	return res.data
})

export const licensedRegionsSlice = createSlice({
	name: 'licensed-regions',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getLicensedRegions.fulfilled, (state, action) => {
			return action.payload
		})
	},
})

export default licensedRegionsSlice.reducer
