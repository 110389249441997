import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalHeader } from '@components/modal/modal-header'
import { RootState } from '@redux/store'
import { QRCode } from 'react-qrcode-logo'
import { connect } from 'react-redux'
import { DomainTheme } from 'src/services/theme/theme.types'
import { EndUserProps } from 'src/services/user/user.types'

interface QrCodeQuickAddPropsProps {
	dismissModal: () => void
	currentUser: EndUserProps | null
	theme: DomainTheme.CompleteTheme
}

function QrCodeQuickAddPrototype(props: QrCodeQuickAddPropsProps) {
	return (
		<>
			<Modal onClose={props.dismissModal} maxWidth={750} maxHeight={800} fixedHeight={false}>
				<ModalHeader title="Referral Quick Add">
					<></>
				</ModalHeader>
				<ModalBody>
					{props.currentUser && (
						<QRCode
							size={1000}
							style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
							value={`https://joinlokation.com/referral/?fname=${props.currentUser.firstName}&lname=${props.currentUser.lastName}&referral=${props.currentUser.endUserId}`}
							logoPaddingStyle="square"
							logoPadding={10}
							logoHeight={200}
							logoWidth={200}
							fgColor={props.theme.appHeaderBgColor}
							// bgColor="#000"
							removeQrCodeBehindLogo={true}
							logoImage={
								'https://lokationre.com/wp-content/uploads/2019/03/cropped-lokation-icon-dark-1.png'
							}
							// viewBox={`0 0 256 256`}
						/>
						// <QRCode />
					)}
				</ModalBody>
			</Modal>
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
		theme: state.theme,
	}
}

export const QrCodeQuickAdd = connect(mapStateToProps)(QrCodeQuickAddPrototype)
