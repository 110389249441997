import moment from 'moment'
import * as momentTimeZone from 'moment-timezone'

class DateServicePrototype {
	public getFormattedDateFromDateObj(dateObj: Date): string {
		const date = this.prependSingleDigitWithZero(dateObj.getDate())
		const year = dateObj.getFullYear()

		return `${this.getMonthLabel(dateObj.getMonth())} ${date}, ${year}`
	}

	public getFormattedDateFromISO8601(isoDate: string): string {
		const momentInstance = moment(isoDate)
		return `${this.getMonthLabel(momentInstance.month())} ${momentInstance.date()}, ${momentInstance.year()}`
	}

	public getFormattedDateFromYYYYMMDD(dateString: string): string {
		const year = dateString.split('-')[0]
		const month = parseFloat(dateString.split('-')[1]) - 1
		const date = parseFloat(dateString.split('-')[2])
		return `${this.getMonthLabel(month)} ${date}, ${year}`
	}

	public getUserTimeZone(abbreviate: boolean): string {
		if (abbreviate) {
			return momentTimeZone.tz(momentTimeZone.tz.guess()).zoneAbbr()
		} else {
			return momentTimeZone.tz.guess()
		}
	}

	private getMonthLabel(month: number): string {
		switch (month) {
			case 0:
				return `January`
			case 1:
				return `February`
			case 2:
				return `March`
			case 3:
				return `April`
			case 4:
				return `May`
			case 5:
				return `June`
			case 6:
				return `July`
			case 7:
				return `August`
			case 8:
				return `September`
			case 9:
				return `October`
			case 10:
				return `November`
			case 11:
				return `December`
			default:
				return `Unknown`
		}
	}

	getCurrentDateString(monthOffset: number): string {
		const currentDateObj = new Date()

		let year = currentDateObj.getFullYear()
		let month = currentDateObj.getMonth() + 1 + monthOffset

		if (month > 12) {
			month -= 12 // Reset month to January if it exceeds December
			year += 1 // Increment the year
		}

		// Prepend zero to month and date if they are single digit
		const monthStr = this.prependSingleDigitWithZero(month)
		const dateStr = this.prependSingleDigitWithZero(currentDateObj.getDate())

		const dateString = `${year}-${monthStr}-${dateStr}`
		return dateString
	}

	prependSingleDigitWithZero(number: number | string): string {
		const valueToPrepend = typeof number === 'number' ? number : parseFloat(number)

		if (valueToPrepend <= 9) return `0${valueToPrepend}`
		else return String(valueToPrepend)
	}

	checkPlanChangeDate(dateString: string): boolean {
		const date = new Date(dateString)

		const sixMonthsAgo = new Date()
		sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6)

		if (date > sixMonthsAgo) {
			return true
		} else {
			return false
		}
	}

	convertTimestampToDate(timestamp: number) {
		const date = new Date(timestamp * 1000)

		const formattedDate = date.toLocaleDateString('en-US', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		})

		return formattedDate
	}

	billPlanChangeCheck(dateString: string): boolean {
		const date = new Date(dateString)

		const oneMonth = new Date()
		oneMonth.setMonth(oneMonth.getMonth() - 1)

		if (date > oneMonth) {
			return true
		} else {
			return false
		}
	}
}

export const DateService = new DateServicePrototype()
