import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { IconButton } from '@components/icon-button/icon-button'
import { Modal } from '@components/modal/modal'
import { NewsFeedStory } from '@components/news-feed__story/news-feed__story'
import { NewsFeedStoryTimestamp } from '@components/news-feed__story/news-feed__story-timestamp'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { NewsFeedAPI } from '../../services/news-feed/news-feed.api'
import { NewsFeed } from '../../services/news-feed/news-feed.types'

export function NewsFeedStoryModal(props: { storyId: number }) {
	const [storyProps, setStoryProps] = useState<NewsFeed.Story | null>(null)

	const navigate = useNavigate()

	useEffect(() => {
		NewsFeedAPI.getStory(props.storyId).then((res) => {
			setStoryProps(res.data)
		})
	}, [])

	function closeModal(): void {
		navigate(-1)
	}

	return (
		<Modal
			maxHeight={800}
			maxWidth={800}
			fixedHeight={false}
			className="flex flex-column p-20"
			onClose={closeModal}
		>
			<>
				<IconButton
					icon={'xmark'}
					style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 100 }}
					onClick={closeModal}
				/>
				{storyProps && (
					<>
						<NewsFeedStoryTimestamp story={storyProps} className="mb-20" />
						<NewsFeedStory story={storyProps} className="overflow-y__scroll pb-30 pr-30" />
					</>
				)}
				{!storyProps && (
					<>
						<GenericContentLoader width={'fill'} height={10} className="mb-10" />
						<GenericContentLoader width={'fill'} height={10} className="mb-10" />
						<GenericContentLoader width={'fill'} height={10} className="mb-10" />
					</>
				)}
			</>
		</Modal>
	)
}
