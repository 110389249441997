import axios from 'axios'
import { PDFDocument } from 'pdf-lib'

import { ContractTemplateAddition } from '../../routes/registration/routes/contracts/contracts.service'

type DownloadBlobMimeTypes = `application/octet-stream`

export function PDFGenerationService() {
	function modifyPDF(url: string, additions: ContractTemplateAddition[]): Promise<Uint8Array> {
		return new Promise((resolve, reject) => {
			axios
				.get(url, {
					responseType: 'blob',
				})
				.then((res) => {
					const blob = res.data as Blob
					blob.arrayBuffer().then((arrayBuffer) => {
						PDFDocument.load(arrayBuffer).then((pdfDoc) => {
							const asyncAdditions: Promise<unknown>[] = []

							const pages = pdfDoc.getPages()

							additions.forEach((addition) => {
								const pageToModify = pages[addition.page]
								const pageHeight = pageToModify.getHeight()

								/** Add Text */
								if ('text' in addition) {
									pageToModify.drawText(addition.text, {
										...addition.options,
										y: addition.options.y ? pageHeight - addition.options.y : 10,
									})
								}

								/** Add Image */
								if ('image' in addition) {
									const additionPromise = new Promise<void>((resolve) => {
										if (addition.image === null) {
											resolve()
										} else {
											addition.image.arrayBuffer().then((arrayBuffer) => {
												console.info(arrayBuffer)
												pdfDoc
													.embedPng(arrayBuffer)
													.then((pngImage) => {
														pageToModify.drawImage(pngImage, {
															...addition.options,
															y: addition.options.y
																? pageHeight - addition.options.y
																: 10,
														})
														resolve()
													})
													.catch((err) => {
														console.info(`Cannot add image to PDF`)
														reject()
													})
											})
										}
									})

									asyncAdditions.push(additionPromise)
								}
							})

							Promise.all(asyncAdditions).then(() => {
								pdfDoc.save().then((pdfBytes) => {
									resolve(pdfBytes)
								})
							})
						})
					})
				})
		})
	}

	function downloadURL(data: string, fileName: string) {
		const a = document.createElement('a')
		a.href = data
		a.download = fileName
		document.body.appendChild(a)
		a.style.display = 'none'
		a.click()
		a.remove()
	}

	function downloadBlob(data: Uint8Array, fileName: string, mimeType: DownloadBlobMimeTypes) {
		const blob = new Blob([data], {
			type: mimeType,
		})

		const url = window.URL.createObjectURL(blob)
		downloadURL(url, fileName)
		setTimeout(() => window.URL.revokeObjectURL(url), 1000)
	}

	return { downloadBlob, modifyPDF }
}
