import { LoKationButton } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalHeader } from '@components/modal/modal-header'

interface GenericDeleteConfirmationModalProps {
	itemLabel: string
	onDelete: () => void
	onClose: () => void
	zIndex?: number
}

export function GenericDeleteConfirmationModal(props: GenericDeleteConfirmationModalProps) {
	function hideModal(): void {
		props.onClose()
	}

	return (
		<Modal maxWidth={500} maxHeight={400} fixedHeight={false} onClose={hideModal} zIndex={props.zIndex}>
			<ModalHeader title={`Are you sure you want to delete ${props.itemLabel}?`}>
				<></>
			</ModalHeader>
			<div className="p-20">
				<div className="flex flex-justifyContent-end">
					<LoKationButton
						variant="outlined"
						size="lg"
						primary={false}
						label="Cancel"
						className="mr-10"
						onClick={hideModal}
					/>
					<LoKationButton variant="contained" size="lg" label="Delete" onClick={props.onDelete} />
				</div>
			</div>
		</Modal>
	)
}
