import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, httpRequestNoErrors, RequestBaseURL } from '../axios/http-common.service'
import { EndUserReferral, LeaderboardList } from './referral.types'

class ReferralAPIPrototype {
	getReferralsFromUser(endUserId: number): Promise<AxiosResponse<EndUserReferral[]>> {
		const requestPromise = httpRequestNoErrors.get(`${RequestBaseURL}${apiVersionURL}/referrals/${endUserId}`)
		return requestPromise
	}

	async removeReferralFromUser(referringEndUserId: number, referredEndUserIds: number[]): Promise<AxiosResponse> {
		const headers: { [key: string]: any } = {}

		const requestPromise = httpRequest.delete(`${RequestBaseURL}${apiVersionURL}/referrals/delete`, {
			headers,
			data: { referringEndUserId, referredEndUserIds },
		})

		return requestPromise
	}

	async updateReferralsForUser(
		referringEndUserId: number,
		referredEndUserIds: number[],
	): Promise<AxiosResponse<EndUserReferral[]>> {
		const headers: { [key: string]: any } = {}

		const data = { referringEndUserId, referredEndUserIds }

		const requestPromise = httpRequest.patch(`${RequestBaseURL}${apiVersionURL}/referrals/patch`, data, { headers })

		return requestPromise
	}

	async referralLeaderboard(DateTimeString: string): Promise<AxiosResponse<LeaderboardList[]>> {
		const requestPromise = httpRequestNoErrors.get(
			`${RequestBaseURL}${apiVersionURL}/referrals/leaderboard?timestamp=${encodeURIComponent(DateTimeString)}`,
		)
		return requestPromise
	}
}

export const ReferralAPI = new ReferralAPIPrototype()
