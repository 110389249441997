import './modal.scss'

import { useState } from 'react'
import ReactDOM from 'react-dom'

import { IconButton } from '../icon-button/icon-button'
import { ModalTypes } from './modal.types'

export function Modal(props: ModalTypes.Component) {
	const [mouseClickOrigin, setMouseClickOrigin] = useState<'SCRIM' | 'MODAL'>('SCRIM')
	const isEsacapable = getIsModalEscapable()

	/** ======================================== */
	/** Effects */

	/** ======================================== */
	/** Methods */

	function getIsModalEscapable(): boolean {
		if (props.escapable === undefined) {
			return true
		}
		return props.escapable
	}

	function getModalStyle(): React.CSSProperties {
		const style: React.CSSProperties = {}
		style.width = `${props.maxWidth}px`
		if (props.fixedHeight) {
			style.maxHeight = `calc(100vh - 60px)`
			style.height = `${props.maxHeight}px`
		} else {
			style.maxHeight = `min(100vh - 40px, ${props.maxHeight}px)`
		}
		return style
	}

	function getScrimStyle(): React.CSSProperties {
		const style: React.CSSProperties = {}
		if (props.zIndex) {
			style.zIndex = props.zIndex
		}
		return style
	}

	function getModalClassName(): string {
		let className = 'modal__wrapper'
		if (props.fixedHeight) {
			className += ` height__fixed`
		} else {
			className += ` height__fit`
		}

		if (props.className) {
			className += ` ${props.className}`
		}
		return className
	}

	/** ======================================== */
	/** Render Component */

	return (
		<>
			{' '}
			{ReactDOM.createPortal(
				<div
					className="modal__scrim"
					style={getScrimStyle()}
					onMouseUp={() => {
						if (!isEsacapable) {
							return
						}

						if (mouseClickOrigin === 'SCRIM') {
							props.onClose()
						}
					}}
					onMouseDown={() => {
						setMouseClickOrigin('SCRIM')
					}}
				>
					<dialog
						open
						className={getModalClassName()}
						style={getModalStyle()}
						onClick={(updatedValue) => {
							updatedValue.stopPropagation()
						}}
						onMouseDown={(evt) => {
							evt.stopPropagation()
							setMouseClickOrigin('MODAL')
						}}
					>
						{isEsacapable && (
							<IconButton
								icon={'xmark'}
								onClick={props.onClose}
								style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 100 }}
							/>
						)}
						{props.children}
					</dialog>
				</div>,
				document.body,
			)}
		</>
	)
}
