import { LoKationButton } from '@components/button/button'
import { EmptyStatePlaceholder } from '@components/empty-state-placeholder/empty-state-placeholder'
import { useEffect, useState } from 'react'
import { AdvertisementAPI } from 'src/services/advertising/advertisement.api'
import { AdvertisingTypes } from 'src/services/advertising/advertising.types'

import { AdvertisingAccount__Ad__SideSheet__Wrapper } from '../../side-sheets/ad/ad__side-sheet__wrapper'
import { useAdvertisingAccount } from '../../state/ad-account__state'
import { AdvertisingAccount__AdCard } from './ad-card/ad__card'

export function AdvertisingAccount__Route__Advertisements() {
	const advertisingAccountState = useAdvertisingAccount()
	const [selectedAd, setSelectedAd] = useState<AdvertisingTypes.Advertisement | 'NEW' | null>(null)
	const [showAdSideSheet, setShowAdSideSheet] = useState(false)
	const [allAds, setAllAds] = useState<AdvertisingTypes.Advertisement[] | null>(null)

	useEffect(() => {
		getSortedAds()
	}, [])

	/** ============================= */
	/** Methods */

	function getSortedAds(): void {
		AdvertisementAPI.getAds(advertisingAccountState.modifiedState.accountId, { page: 0, size: 50 }).then((res) => {
			const sortedAds = res.data.sort((a, b) => (a.name > b.name ? 1 : -1))
			setAllAds(sortedAds)
		})
	}

	/** ============================= */
	/** Render Component */

	return (
		<>
			<div className="flex flex-justifyContent-spaceBetween flex-alignItems-start col-xs-12 mb-20">
				<div>
					<h2>Advertisements</h2>
				</div>
				<LoKationButton
					label="Create Ad"
					variant="outlined"
					size="sm"
					onClick={() => {
						setSelectedAd('NEW')
						setShowAdSideSheet(true)
					}}
				/>
			</div>
			{allAds && allAds.length === 0 && (
				<div className="flex flex-fillSpace flex-alignItems-center flex-justifyContent-center col-xs-12">
					<EmptyStatePlaceholder title={'No ads have been created'} icon={'rectangle-ad'} />
				</div>
			)}

			{allAds &&
				allAds.map((advertisement) => {
					return (
						// eslint-disable-next-line react/jsx-pascal-case
						<AdvertisingAccount__AdCard
							{...advertisement}
							onClick={() => {
								setSelectedAd(advertisement)
								setShowAdSideSheet(true)
							}}
						/>
					)
				})}
			{selectedAd && showAdSideSheet && (
				// eslint-disable-next-line react/jsx-pascal-case
				<AdvertisingAccount__Ad__SideSheet__Wrapper
					account={advertisingAccountState.modifiedState}
					advertisement={selectedAd}
					onClose={() => {
						setShowAdSideSheet(false)
					}}
					onDelete={() => {
						getSortedAds()
					}}
					onUpdate={(updatedAd) => {
						getSortedAds()
					}}
				/>
			)}
		</>
	)
}
