import { RootState } from '@redux/store'
import { connect } from 'react-redux'

import { EndUserProps } from '../../services/user/user.types'
import { AgentSearchRouteInner } from './agent-search'
import { AgentSearchProvider } from './state/agent-search__state'

interface ConnectedProps {
	user: EndUserProps | null
}

function AgentSearchRoutePrototype(props: ConnectedProps) {
	return (
		<AgentSearchProvider endUser={props.user}>
			<AgentSearchRouteInner />
		</AgentSearchProvider>
	)
}

function mapStateToProps(state: RootState) {
	return {
		user: state.user,
	}
}

export const AgentSearchRoute = connect(mapStateToProps)(AgentSearchRoutePrototype)
