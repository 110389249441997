import { DateService } from 'src/services/date.service'

import { EditCalendarEventService } from '../edit-calendar.event.service'
import { useEditCalendarEvent, useEditCalendarEventDispatch } from '../state/edit-calendar-event__state'

export function EditCalendarEventNonRecurringConfig() {
	const editCalendarState = useEditCalendarEvent()
	const editCalendarDispatch = useEditCalendarEventDispatch()

	const localStartTime = EditCalendarEventService().convertUTCDateToLocal({
		utcDate: editCalendarState.startDate,
		utcTime: editCalendarState.startTime,
	})
	const localEndTime = EditCalendarEventService().convertUTCDateToLocal({
		utcDate: editCalendarState.endDate,
		utcTime: editCalendarState.endTime,
	})

	/** ================================== */
	/** Render Component */

	if (!localStartTime || !localEndTime) {
		return <>An error has occured</>
	}

	return (
		<div className="flex flex-alignItems-center mb-10">
			<input
				type="date"
				className="text-input"
				value={localStartTime.localDate}
				onChange={(evt) => {
					const utcTime = EditCalendarEventService().convertLocalDateToUTC({
						localDate: evt.target.value,
						localTime: localStartTime.localTime,
					})
					if (!utcTime) {
						return
					}
					editCalendarDispatch([{ type: 'update-event-props', payload: { startDate: utcTime.utcDate } }])
				}}
			/>
			{!editCalendarState.allDay && (
				<input
					type="time"
					className="ml-5 text-input"
					value={localStartTime.localTime}
					onChange={(evt) => {
						const utcTime = EditCalendarEventService().convertLocalDateToUTC({
							localDate: localStartTime.localDate,
							localTime: evt.target.value,
						})
						if (!utcTime) {
							return
						}
						editCalendarDispatch([
							{
								type: 'update-event-props',
								payload: { startDate: utcTime.utcDate, startTime: utcTime.utcTime },
							},
						])
					}}
				/>
			)}
			<div className="mx-10">to</div>
			<input
				type="date"
				className="text-input"
				value={localEndTime.localDate}
				onChange={(evt) => {
					const utcTime = EditCalendarEventService().convertLocalDateToUTC({
						localDate: evt.target.value,
						localTime: localEndTime.localTime,
					})
					if (!utcTime) {
						return
					}
					editCalendarDispatch([{ type: 'update-event-props', payload: { endDate: utcTime.utcDate } }])
				}}
			/>
			{!editCalendarState.allDay && (
				<>
					<input
						type="time"
						className="text-input ml-5"
						value={localEndTime.localTime}
						onChange={(evt) => {
							const utcTime = EditCalendarEventService().convertLocalDateToUTC({
								localDate: localEndTime.localDate,
								localTime: evt.target.value,
							})
							if (!utcTime) {
								return
							}
							editCalendarDispatch([
								{
									type: 'update-event-props',
									payload: { endDate: utcTime.utcDate, endTime: utcTime.utcTime },
								},
							])
						}}
					/>
					<div className="ml-10">{DateService.getUserTimeZone(true)}</div>
				</>
			)}
		</div>
	)
}
