import './resource-admin__card.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RequestBaseURL } from 'src/services/axios/http-common.service'

import { LokationResource } from '../../../../services/resources/resources.types'
import { Utilities } from '../../../../services/utilities.service'
interface ResourceAdminCardProps {
	onClick: () => void
}

export function ResourceAdminCard(props: { resource: LokationResource } & ResourceAdminCardProps) {
	return (
		<div className={`resource-admin__card child`} onClick={props.onClick}>
			<div className="flex">
				<div
					className="thumbnail-img flex-noShrink flex flex-alignItems-center flex-justifyContent-center "
					style={{
						backgroundImage: props.resource.hasResourcePicture
							? `url(${RequestBaseURL}/api/v1/resources/${props.resource.resourceId}/resourcePicture)`
							: `url(${props.resource.bgImage})`,
						backgroundColor: `var(--colorAdjustAlpha10)`,
					}}
				>
					{!props.resource.bgImage && (
						<FontAwesomeIcon icon={['far', 'image']} size="3x" className="opacity-25" />
					)}
				</div>

				<div className="flex-fillSpace">
					<strong>{Utilities.truncateString(props.resource.title, 100)}</strong>
					<div>{Utilities.truncateString(props.resource.description, 200)}</div>
				</div>
			</div>
		</div>
	)
}
