import { httpRequest, RequestBaseURL } from '../axios/http-common.service'

class RealEstateLeadsPrototype {
	async syncUsers() {
		try {
			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/sync-rsp-users`)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to sync users')
		}
	}
}

export const RealEstateLeadsAPI = new RealEstateLeadsPrototype()
