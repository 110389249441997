import { LoKationButton } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { IconButton } from '@components/icon-button/icon-button'
import { Paper } from '@components/paper/paper'
import { Tooltip } from '@components/tooltip/tooltip'
import { useEffect, useState } from 'react'

import { GenericDeleteConfirmationModal } from '../../../../../modals/generic-delete-confirmation/generic-delete-confirmation'
import { StripeAPI } from '../../../../../services/stripe/stripe.api'
import { StripeTypes } from '../../../../../services/stripe/stripe.types'
import { EndUserProps } from '../../../../../services/user/user.types'
import LinkLogo from './cc-logos/link.png'

interface StripePaymentMethodsProps {
	setPaymentMethodAdded: (value: boolean) => void
	paymentMethodAdded: boolean
	userToEdit: EndUserProps
	currentUser: EndUserProps | null
}

function StripePaymentMethods(props: StripePaymentMethodsProps) {
	const [loading, setLoading] = useState(true)
	const [paymentMethods, setPaymentMethods] = useState<StripeTypes.PaymentMethod[]>([])
	const [paymentMethodToRemove, setPaymentMethodToRemove] = useState<string>('')
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [defaultMethodUpdated, setDefaultMethodUpdated] = useState(false)

	/** ===================================== */
	/** Effects */

	useEffect(() => {
		setLoading(true)
		fetchPaymentMethod()
		if (props.paymentMethodAdded) {
			// Reset the paymentMethodAdded state
			props.setPaymentMethodAdded(false)
		}
	}, [props.paymentMethodAdded, props.setPaymentMethodAdded, props.currentUser, props.userToEdit])

	useEffect(() => {
		if (!defaultMethodUpdated) return
		fetchPaymentMethod()
		setDefaultMethodUpdated(false)
	}, [defaultMethodUpdated])
	/** ===================================== */
	/** Methods */

	const getCardLogo = (cardBrand: string) => {
		// Define the type for cardLogos
		const cardLogos: Record<string, string> = {
			visa: require('./cc-logos/visa.svg').default,
			mastercard: require('./cc-logos/mc.svg').default,
			discover: require('./cc-logos/discover.svg').default,
			amex: require('./cc-logos/amex.svg').default,
			diners: require('./cc-logos/diners.svg').default,
			jcb: require('./cc-logos/jcb.svg').default,
			// Add more card brands and their logo paths
		}

		// Return the logo URL based on the card brand
		return cardLogos[cardBrand.toLowerCase()] || ''
	}

	const removePaymentMethod = (paymentMethodId: string) => {
		setShowDeleteModal(true)
		setPaymentMethodToRemove(paymentMethodId)
	}

	const setDefaultPaymentMethod = async (paymentMethodId: string) => {
		let isAdmin = false
		let endUserId = undefined
		if (props.currentUser && props.userToEdit) {
			isAdmin = props.currentUser.endUserId !== props.userToEdit.endUserId
			if (isAdmin) {
				endUserId = props.userToEdit.endUserId
			}
		}
		try {
			await StripeAPI.setDefaultPaymentMethod(paymentMethodId, endUserId)
			setDefaultMethodUpdated(true) // Indicate operation completion
		} catch (error) {
			console.error('Error setting default payment method:', error)
		}
	}

	const handleDeleteConfirmation = () => {
		// Perform the deletion operation
		StripeAPI.detachPaymentMethod(paymentMethodToRemove)
			.then(() => {
				// Remove the payment method from the state or perform any other necessary actions
				setPaymentMethods((prevMethods) =>
					prevMethods.filter((method) => method.paymentMethodId !== paymentMethodToRemove),
				)
				console.log('Payment method was removed')
				// Hide the delete confirmation modal by dispatching an action to update the visibility
				setShowDeleteModal(false)
			})
			.catch((error) => {
				console.log('Error removing payment method:', error)
				throw new Error(`Failed to remove payment method: ${error.message}`)
			})

		// Hide the delete confirmation modal
		setShowDeleteModal(false)
	}

	// Helper function to check if the payment method is expiring soon
	const isPaymentMethodExpiring = (expMonth: number, expYear: number): boolean => {
		const currentYear = new Date().getFullYear()
		const currentMonth = new Date().getMonth() + 1 // Months are zero-indexed, so add 1

		// Calculate remaining time until expiration
		const remainingMonths = (expYear - currentYear) * 12 + expMonth - currentMonth

		// Set the threshold for notifying the user (e.g., 1 month)
		const expirationThreshold = 1

		return remainingMonths <= expirationThreshold
	}

	function fetchPaymentMethod() {
		let isAdmin = false
		let endUserId = undefined
		if (props.currentUser && props.userToEdit) {
			isAdmin = props.currentUser.endUserId !== props.userToEdit.endUserId
			if (isAdmin) {
				endUserId = props.userToEdit.endUserId
			}
		}

		StripeAPI.fetchCustomerPaymentMethods(endUserId)
			.then((res) => {
				const paymentMethodsArray = Object.values(res) as StripeTypes.PaymentMethod[]
				setPaymentMethods(
					paymentMethodsArray.sort((a, b) => {
						if (a.default) return -1
						if (b.default) return 1
						return 0
					}),
				)
				setLoading(false)
			})
			.catch((error) => {
				console.log('Error fetching payment methods:', error)
			})
	}
	/** ===================================== */
	/** Render Component */

	return (
		<div>
			<h5>Payment Methods</h5>
			<div className="mt-10 flex flex-wrap flex-column-sm-down">
				{loading ? (
					<GenericContentLoader width={'fill'} height={220} />
				) : (
					paymentMethods &&
					paymentMethods.map((method) => (
						<div key={method.paymentMethodId} className="payment-method-element">
							<Paper
								bgColor="primary"
								padding={['all']}
								margins={['right', 'bottom']}
								className="flex flex-column flex-alignItems-center mr-0-md-down"
							>
								{method.card ? (
									<>
										{method.default === true ? (
											<div className="default-wrapper">
												<div className="default-indicator">
													<Tooltip
														icon="star"
														disablePopoverBackground={true}
														body="This is your default payment method"
														className="p-10"
													/>
												</div>
											</div>
										) : (
											<div className="default-wrapper flex flex-justifyContent-end">
												<div className="default-indicator">
													<IconButton
														icon="trash"
														onClick={() => removePaymentMethod(method.paymentMethodId)}
													/>
												</div>
											</div>
										)}
										<div className="card-logo mb-10">
											<img src={getCardLogo(method.card.brand)} alt={method.card.brand} />
										</div>
										<div className="last-4">x{method.card.last4}</div>
										<div className="exp-date flex flex-alignItems-center">
											<div>
												Exp: {method.card.expMonth}/{method.card.expYear}
											</div>
											{isPaymentMethodExpiring(method.card.expMonth, method.card.expYear) && (
												<div className="expiring-warning ml-5">
													<Tooltip
														icon="warning"
														body="Your payment method is expiring soon"
													/>{' '}
												</div>
											)}
										</div>
										<LoKationButton
											className="mt-10"
											variant="outlined"
											label={method.default ? 'Default Payment Method' : 'Set as Default'}
											size={'sm'}
											onClick={() => setDefaultPaymentMethod(method.paymentMethodId)}
											disabled={method.default}
										/>
										{showDeleteModal && (
											<GenericDeleteConfirmationModal
												itemLabel="this payment method"
												onDelete={handleDeleteConfirmation}
												onClose={() => {
													setShowDeleteModal(false)
												}}
											/>
										)}
									</>
								) : (
									<>
										<div className="default-wrapper">
											{method.default && (
												<div className="default-indicator">
													<Tooltip icon="star" body="This is your default payment method" />
												</div>
											)}
										</div>
										<div className="bank-name mb-10">
											{!method.usBankAccount?.bankName && (
												<img src={LinkLogo} alt="Link Payment Logo" width={70} />
											)}
										</div>
										{method.usBankAccount?.last4 ? (
											<div className="last-4">x{method.usBankAccount.last4}</div>
										) : (
											<div className="last-4"></div>
										)}
										<div className="exp-date">
											Type: {method.usBankAccount ? method.usBankAccount.type : method.type}
										</div>
										<LoKationButton
											className="mt-10"
											variant="outlined"
											label="Remove"
											size={'sm'}
											onClick={() => removePaymentMethod(method.paymentMethodId)}
											disabled={paymentMethods.length <= 1}
										/>
										{showDeleteModal && (
											<GenericDeleteConfirmationModal
												itemLabel="this payment method"
												onDelete={handleDeleteConfirmation}
												onClose={() => {
													setShowDeleteModal(false)
												}}
											/>
										)}
									</>
								)}
							</Paper>
						</div>
					))
				)}
			</div>
		</div>
	)
}

export default StripePaymentMethods
