import _ from 'lodash'

import { LokationTag } from '../tags/tag.types'
import { ValidationResult } from '../validation/validation.types'
import { Hub } from './hubs.types'

export class HubsService {
	public static sortHubsByDisplayOrder(hubs: Hub[]): Hub[] {
		const updatedHubs = _.cloneDeep(hubs)
		return updatedHubs.sort((a, b) => {
			const aDisplay = a.displayOrder
			const bDisplay = b.displayOrder
			return aDisplay > bDisplay ? 1 : -1
		})
	}

	public static validate(hub: Partial<Hub>, allHubs: Hub[]): ValidationResult {
		let result: ValidationResult = {
			isValid: true,
			messages: [],
		}

		if (!hub.title || (typeof hub.title === 'string' && hub.title.length === 0)) {
			result.isValid = false
			result.messages.push(`Title cannot be empty`)
		}
		if (!hub.description || (typeof hub.description === 'string' && hub.description.length === 0)) {
			result.isValid = false
			result.messages.push(`Description cannot be empty`)
		}
		if (!hub.icon || (typeof hub.icon === 'string' && hub.icon.length === 0)) {
			result.isValid = false
			result.messages.push(`An icon must be selected`)
		}
		if (!hub.tags || (hub.tags && hub.tags.length === 0)) {
			result.isValid = false
			result.messages.push(`Must contain at least one tag`)
		}
		if (!hub.slug || (typeof hub.slug === 'string' && hub.slug.length === 0)) {
			result.isValid = false
			result.messages.push(`Slug cannot be empty`)
		}
		if (hub.slug && HubsService.isSlugUnique(hub.slug, allHubs, hub.hubId) === false) {
			result.isValid = false
			result.messages.push(`Slug must be unique`)
		}

		return result
	}

	public static isSlugUnique(slug: string, allHubs: Hub[], editingHubId: number | undefined): boolean {
		return !allHubs.some((hub) => hub.slug === slug && hub.hubId !== editingHubId)
	}

	public static doesHubIncludeTag(item: Hub, selectedTags: LokationTag[]): boolean {
		const selectedTagIds = selectedTags.map((tag) => tag.tagId)
		let isTagInItem = false
		item.tags.forEach((tag) => {
			if (selectedTagIds.includes(tag.tagId)) {
				isTagInItem = true
			}
		})
		if (isTagInItem) {
			return true
		}

		return false
	}

	public static getHubFromId(allHubs: Hub[], hubId: number): Hub | undefined {
		return allHubs.find((hub) => hub.hubId === hubId)
	}

	public static getHubsFromIds(allHubs: Hub[], hubIds: number[]): Hub[] {
		const foundHubs: Hub[] = []
		hubIds.forEach((hubId) => {
			const foundHub = allHubs.find((thisHub) => thisHub.hubId === hubId)
			if (foundHub) {
				foundHubs.push(foundHub)
			}
		})
		return foundHubs
	}
}
