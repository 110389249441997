import { store } from '@redux/store'
import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { GetItemsParams, GetItemsResponse } from '../utility.types'
import { CustomForms } from './custom-forms.types'
import { customFormsState } from './static-values'

export class CustomFormsAPI {
	static getForms(props: GetItemsParams<CustomForms.Form>): Promise<AxiosResponse<{ items: CustomForms.Form[] }>> {
		const response: AxiosResponse<{ items: CustomForms.Form[] }> = {
			status: 200,
			statusText: '',
			data: {
				items: customFormsState,
			},
			headers: {},
			config: {},
		}

		return new Promise((resolve) => {
			resolve(response)
		})

		// const headers = {}
		// /** Set params */
		// const params = Utilities.createSearchParamsFromObj({ ...props })
		// const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/forms/`, {
		// 	headers,
		// 	params,
		// })
		// return requestPromise
	}

	public static findForms(
		props: GetItemsParams<CustomForms.Form>,
		itemTypeProps: CustomForms.SearchParams,
	): Promise<AxiosResponse<GetItemsResponse<CustomForms.Form>>> {
		const response: AxiosResponse<GetItemsResponse<CustomForms.Form>> = {
			status: 200,
			statusText: '',
			data: {
				items: customFormsState,
				totalElements: 1,
				size: 1,
				totalPages: 1,
				number: 1,
			},
			headers: {},
			config: {},
		}

		return new Promise((resolve) => {
			resolve(response)
		})

		// const headers = {}
		// /** Set params */
		// const params = Utilities.createSearchParamsFromObj({ ...props, ...itemTypeProps })

		// const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/forms/search`, {
		// 	headers,
		// 	params,
		// })
		// return requestPromise
	}

	static getFormById(id: number): Promise<AxiosResponse<CustomForms.Form | undefined>> {
		const matchingForm = customFormsState.find((form) => form.formId === id)

		const response: AxiosResponse<CustomForms.Form | undefined> = {
			status: 200,
			statusText: '',
			data: matchingForm,
			headers: {},
			config: {},
		}

		return new Promise((resolve) => {
			resolve(response)
		})

		// const headers = {}
		// const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/forms/${id}`, {
		// 	headers,
		// })
		// return requestPromise
	}

	static createForm(form: Omit<CustomForms.Form, 'formId'>): Promise<AxiosResponse<CustomForms.Form>> {
		const userProps = store.getState().user
		if (!userProps) {
			throw new Error(`User not found. CustomForms.Form cannot be created`)
		}

		const headers = {}
		const requestPromise = httpRequest.post(`${RequestBaseURL}${apiVersionURL}/forms/`, form, {
			headers,
		})
		return requestPromise
	}

	static updateForm(
		formId: number,
		updateFormDTO: Partial<CustomForms.Form>,
	): Promise<AxiosResponse<CustomForms.Form>> {
		const userProps = store.getState().user
		if (!userProps) {
			throw new Error(`User not found. CustomForms.Form cannot be updated`)
		}

		const headers = {}
		const requestPromise = httpRequest.patch(`${RequestBaseURL}${apiVersionURL}/forms/${formId}`, updateFormDTO, {
			headers,
		})
		return requestPromise
	}

	static deleteForm(formId: number): Promise<AxiosResponse<void>> {
		const headers = {}
		const requestPromise = httpRequest.delete(`${RequestBaseURL}${apiVersionURL}/forms/${formId}`, {
			headers,
		})
		return requestPromise
	}
}
