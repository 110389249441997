import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { GetItemsParams } from '../utility.types'
import { MessageDraftTypes } from './message-drafts.types'
import { MessagingService } from './messaging-service.types'

export function MessagesAPI() {
	function getAllChatGroups(
		props: GetItemsParams<MessagingService.ChatGroup>,
	): Promise<AxiosResponse<{ items: MessagingService.ChatGroup[] }>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/chats/`, {
			headers,
			params,
		})
		return requestPromise
	}

	function getChatGroupById(chatGroupId: number): Promise<AxiosResponse<MessagingService.ChatGroupDetails | null>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ id: chatGroupId })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/chats/${chatGroupId}`, {
			headers,
			params,
		})
		return requestPromise
	}

	function markChatAsReadById(chatGroupId: number): Promise<AxiosResponse<MessagingService.ChatGroupDetails | null>> {
		const headers = {}

		const requestPromise = httpRequest.post(`${RequestBaseURL}${apiVersionURL}/chats/${chatGroupId}/mark_read`, {
			headers,
		})
		return requestPromise
	}

	function getAllMessagesInChatGroup(
		chatGroupId: number,
		props: GetItemsParams<MessagingService.ChatGroup>,
	): Promise<AxiosResponse<{ items: MessagingService.ChatMessage[] }>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/chats/${chatGroupId}/messages`, {
			headers,
			params,
		})
		return requestPromise
	}

	function sendMessage(
		props: MessageDraftTypes.MessageDraft,
	): Promise<AxiosResponse<MessageDraftTypes.SentMessageDraftResponeDTO>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props })

		const requestPromise = httpRequest.post(`${RequestBaseURL}${apiVersionURL}/receivedMessages/`, {
			headers,
			params,
		})
		return requestPromise
	}

	return { sendMessage, getAllChatGroups, getChatGroupById, markChatAsReadById, getAllMessagesInChatGroup }
}
