import { RootState } from '@redux/store'
import _ from 'lodash'
import React, { useState } from 'react'
import { connect } from 'react-redux'

import { LicensedRegion } from '../../services/regions/regions.types'
import { LoKationButton } from '../button/button'
import { Checkbox } from '../checkbox/checkbox'
import { CollapsibleContent, CollapsibleContentStates } from '../collapsible-content/collapsible-content'

export interface RegionFilterProps {
	selectedRegions: Array<LicensedRegion>
	selectedStateId?: number
	onSelect: (selectedRegions: LicensedRegion[]) => void
	style?: React.CSSProperties
	className?: string
	isMulti: boolean
	expanded?: true
}

function RegionFilterPrototype(props: RegionFilterProps & { licensedRegions: LicensedRegion[] }) {
	const [state, setState] = useState<CollapsibleContentStates>(props.expanded ? 'OPEN' : 'CLOSED')

	function handleUpdateSelectedTags(newRegion: LicensedRegion, isRegionSelected: boolean): void {
		let updatedState = _.cloneDeep(props.selectedRegions)
		if (isRegionSelected === true) {
			if (props.isMulti) {
				updatedState.push(newRegion)
				props.onSelect(updatedState)
			} else {
				props.onSelect([newRegion])
			}
		}
		if (isRegionSelected === false) {
			if (props.isMulti) {
				props.onSelect(
					updatedState.filter((existingTag) => {
						return existingTag !== newRegion
					}),
				)
			} else {
				props.onSelect([])
			}
		}
	}

	function createFilterRegion(region: LicensedRegion): JSX.Element {
		const isChecked = props.selectedRegions.includes(region)
		return (
			<div className="search-filter__option" key={region.regionId}>
				<Checkbox
					checked={isChecked}
					onChange={(checkedState) => {
						handleUpdateSelectedTags(region, checkedState)
					}}
				>
					{region.regionName}
				</Checkbox>
			</div>
		)
	}

	const filteredRegions = props.selectedStateId
		? props.licensedRegions.filter((region) => region.licensedStateId === props.selectedStateId)
		: props.licensedRegions

	return (
		<div>
			<CollapsibleContent label="Regions" state={state} setState={setState}>
				<>
					<div className={`base-search-filter ${props.className ? props.className : ''}`} style={props.style}>
						{filteredRegions.map((region) => {
							return createFilterRegion(region)
						})}
						{props.isMulti && (
							<div>
								<LoKationButton
									variant="text"
									size="sm"
									label="Select None"
									className="mr-20"
									onClick={() => {
										props.onSelect([])
									}}
								/>
								<LoKationButton
									variant="text"
									size="sm"
									label="Select All"
									onClick={() => {
										props.onSelect(filteredRegions)
									}}
								/>
							</div>
						)}
					</div>
				</>
			</CollapsibleContent>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		licensedRegions: state.licensedRegions,
	}
}

export const RegionFilter = connect(mapStateToProps)(RegionFilterPrototype)
