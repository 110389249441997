import { LoKationButton } from '@components/button/button'
import { Paper } from '@components/paper/paper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { WmPrintingAPI } from 'src/services/wm-printing/wm-printing.api'

import { EndUserProps } from '../../services/user/user.types'

interface WmPrintingProps {
	currentUser: EndUserProps | null
}

function WmPrintingPrototype({ currentUser }: WmPrintingProps) {
	const [externalLinkForSso, setExternalLinkForSso] = useState('')
	async function getSSO() {
		try {
			const ssoUrl = await WmPrintingAPI.getSsoUrl()
			if (ssoUrl.length > 0) {
				if (ssoUrl) {
					setExternalLinkForSso(ssoUrl)
					console.log(externalLinkForSso)
					window.open(ssoUrl, '_blank')
				} else {
					console.log('No SSO URL generated')
				}
			} else {
				console.log('No SSO URL generated')
			}
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		getSSO()
	}, [])

	useEffect(() => {
		if (externalLinkForSso) {
			setTimeout(() => {
				setExternalLinkForSso('')
			}, 3000)
		}
	}, [externalLinkForSso])

	return (
		<div className="flex-lg overflow-y__scroll" style={{ width: '100%' }}>
			<div className="col-xs-12 col-lg-12 pr-20-sm flex flex-wrap flex-alignItems-start flex-justifyContent-center overflow-y__scroll">
				<Paper bgColor="primary" padding={['all']} margins={['right', 'bottom']}>
					<div style={{ maxWidth: '300px' }}>
						<h5 className="mb-20">Login to WM Printing</h5>

						<LoKationButton
							variant="contained"
							size="lg"
							primary={true}
							label={`Login`}
							onClick={() => getSSO()}
						/>
						{externalLinkForSso && (
							<>
								{ReactDOM.createPortal(
									<div className="modal__scrim">
										<div className="external-site-msg__wrapper">
											<FontAwesomeIcon
												icon={['far', 'globe']}
												size={`5x`}
												className={`mb-20 opacity-50`}
											/>
											<h5>Loading External Website</h5>
											<div className="mt-10">
												If you are not redirected within 5 seconds,{' '}
												<a href={externalLinkForSso} target="_blank" rel="noreferrer">
													click here
												</a>
												.
											</div>
										</div>
									</div>,
									document.body,
								)}
							</>
						)}
					</div>
				</Paper>
			</div>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const WmPrintingRoute = connect(mapStateToProps)(WmPrintingPrototype)
