import { LoKationButton } from '@components/button/button'
import { Dropdown } from '@components/dropdown/dropdown'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { PhoneInput } from '@components/text-input/phone-input'
import { TextInput } from '@components/text-input/text-input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UIState } from '@redux/reducers/ui-reducer'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MLSBoard } from 'src/services/mls-board/mls-board.types'
import { LicensedRegion } from 'src/services/regions/regions.types'

import useAdminRedirect from '../../../services/hooks/user-admin-redirect'
import { LicensedStatesService } from '../../../services/licensed-states/licensed-states.service'
import { LicensedState } from '../../../services/licensed-states/licenses-states.types'
import { UserAPI } from '../../../services/user/user.api'
import {
	EndUserLanguageSpecialty,
	EndUserProps,
	EndUserSpecialty,
	FindUsersInDomainQueryFilters,
} from '../../../services/user/user.types'
import { UserExport } from '../../../services/user-export/user-export.api'
import { useItemAdminHook } from '../use-item-admin-hook'
import { EditUserSideSheetProvider } from './edit-user__side-sheet-provider'
import { UserAdminCard } from './user-admin__card/user-admin__card'
import { UserAdminService } from './user-administration.service'

type ConnectedProps = {
	ui: UIState
	userProfile: EndUserProps | null
	licensedStates: LicensedState[]
	licensedRegions: LicensedRegion[]
	languageSpecialties: EndUserLanguageSpecialty[]
	specialties: EndUserSpecialty[]
}

function UserAdministrationRoute__Prototype(props: ConnectedProps) {
	const navigate = useNavigate()
	const [searchFirstName, setSearchFirstName] = useState('')
	const [searchLastName, setSearchLastName] = useState('')
	const [searchEnabled, setSearchEnabled] = useState<string | undefined>(undefined)
	const [searchHasPaymentMethod, setSearchHasPaymentMethod] = useState<string | undefined>(undefined)
	const [searchPhone, setSearchPhone] = useState<number | null>(null)
	const [searchEmail, setSearchEmail] = useState('')
	// const [searchLicensedStateId, setSearchLicensedStateId] = useState<number | undefined>(undefined)
	const [searchLicensedStateIds, setSearchLicensedStateIds] = useState<number[] | undefined>(undefined)
	const [searchRegionIds, setSearchRegionIds] = useState<number[] | undefined>(undefined)
	const [searchLanguageSpecialtyIds, setSearchLanguageSpecialtyIds] = useState<number[] | undefined>(undefined)
	const [searchSpecialtyIds, setSearchSpecialtyIds] = useState<number[] | undefined>(undefined)
	const selectedLicensedStates: LicensedState[] = searchLicensedStateIds
		? searchLicensedStateIds
				.map((id) => LicensedStatesService.getStateFromId(props.licensedStates, id))
				.filter((state): state is LicensedState => state !== undefined)
		: []

	const [searchMlsBoardIds, setSearchMlsBoardIds] = useState<number[] | undefined>(undefined)
	const [mlsBoardOptions, setMlsBoardOptions] = useState<MLSBoard.Complete[]>([])

	const [endUserId, setEndUserId] = useState<number | null>()

	useAdminRedirect(props.userProfile)
	const [buttonLabel, setButtonLabel] = useState('Export Current View')

	const [isPending, setIsPending] = useState(true)
	const userAdminHook = useItemAdminHook<EndUserProps, FindUsersInDomainQueryFilters>({
		getItemsFunction: (page, size, sort, searchParams) => {
			console.log(searchParams)
			return UserAPI.findUsersInDomainAdmin({
				query: {
					first: searchParams.first ? searchParams.first : undefined,
					last: searchParams.last ? searchParams.last : undefined,
					enabled: searchParams.enabled ? searchParams.enabled : undefined,
					licensedStateId: searchParams.licensedStateId ? searchParams.licensedStateId : undefined,
					regionId: searchParams.regionId ? searchParams.regionId : undefined,
					specialtyIds: searchParams.specialtyIds ? searchParams.specialtyIds : undefined,
					phone: searchParams.phone ? searchParams.phone : undefined,
					email: searchParams.email ? searchParams.email : undefined,
					mlsBoardIds: searchParams.mlsBoardIds ? searchParams.mlsBoardIds : undefined,
					languageSpecialtyIds: searchParams.languageSpecialtyIds
						? searchParams.languageSpecialtyIds
						: undefined,
					hasPaymentMethod: searchParams.hasPaymentMethod ? searchParams.hasPaymentMethod : undefined,
				},
				page,
				size,
				sort: [{ property: 'firstName', direction: 'asc' }],
			})
		},
		searchParams: {
			first: searchFirstName,
			last: searchLastName,
			enabled: searchEnabled,
			licensedStateId: searchLicensedStateIds,
			regionId: searchRegionIds,
			phone: searchPhone ? String(searchPhone) : undefined,
			email: searchEmail,
			mlsBoardIds: searchMlsBoardIds,
			languageSpecialtyIds: searchLanguageSpecialtyIds,
			specialtyIds: searchSpecialtyIds,
			hasPaymentMethod: searchHasPaymentMethod,
		},
		itemIdKey: 'endUserId',
		itemTitleKey: 'firstName',
		setIsPending: (state) => {
			setIsPending(state)
		},
	})

	const searchParams = () => ({
		first: searchFirstName,
		last: searchLastName,
		enabled: searchEnabled,
		licensedStateIds: searchLicensedStateIds,
		regionId: searchRegionIds,
		phone: searchPhone ? String(searchPhone) : undefined,
		email: searchEmail,
		mlsBoardIds: searchMlsBoardIds,
		languageSpecialtyIds: searchLanguageSpecialtyIds,
		specialtyIds: searchSpecialtyIds,
	})

	const currentSearchParams = searchParams()

	const getEnabledOptions = () => [
		{ label: 'All', value: undefined },
		{ label: 'Enabled', value: 'true' },
		{ label: 'Disabled', value: 'false' },
	]

	const getHasPaymentMethodOptions = () => [
		{ label: 'All', value: undefined },
		{ label: 'True', value: 'true' },
		{ label: 'False', value: 'false' },
	]

	const selectedMLSOption = UserAdminService.getSelectedMLSOptions(mlsBoardOptions, searchMlsBoardIds)

	const selectedLicenseStateOption = UserAdminService.getSelectedStateOptions(
		props.licensedStates,
		searchLicensedStateIds,
	)

	const selectedRegionOptions = UserAdminService.getSelectedRegionOptions(props.licensedRegions, searchRegionIds)

	const selectedSpecialtyOptions = UserAdminService.getSelectedSpecialties(props.specialties, searchSpecialtyIds)

	const selectedLanguageSpecialtyOptions = UserAdminService.getSelectedLanguageSpecialties(
		props.languageSpecialties,
		searchLanguageSpecialtyIds,
	)

	async function getUserExport() {
		try {
			setButtonLabel('Generating...')
			await UserExport.getAdminUserExport(currentSearchParams)
		} catch (error) {
			console.error(error)
		} finally {
			setButtonLabel('Export Current View')
		}
	}

	useEffect(() => {
		if (searchLicensedStateIds && searchLicensedStateIds.includes(1) && searchLicensedStateIds.length > 1) {
			setSearchRegionIds(undefined)
		}
	}, [searchLicensedStateIds])

	useEffect(() => {
		async function loadMlsBoardOptions() {
			try {
				const items = await UserAdminService.fetchMlsBoardOptions()
				setMlsBoardOptions(items)
			} catch (error) {
				console.error('Failed to load options:', error)
			}
		}
		loadMlsBoardOptions()
	}, [])

	return (
		<RouteWrapperV2>
			<RouteSubnavigation
				title={`User Administration`}
				backButton={{
					label: 'Administration',
					onClick: () => {
						navigate('/administration')
					},
				}}
				isCustom={true}
			>
				<TextInput
					dataType="text"
					label="First name"
					value={searchFirstName}
					onChange={(updatedValue) => {
						setSearchFirstName(updatedValue)
					}}
					width={'100%'}
					className="mb-20"
				/>
				<TextInput
					dataType="text"
					label="Last name"
					value={searchLastName}
					onChange={(updatedValue) => {
						setSearchLastName(updatedValue)
					}}
					width={'100%'}
					className="mb-20"
				/>
				<div className="mb-20">
					<Dropdown<string | undefined>
						value={searchEnabled !== undefined ? [searchEnabled] : [undefined]}
						label="Account Status"
						searchable={false}
						options={getEnabledOptions()}
						onSelect={(selectedValues) => {
							if (selectedValues[0] === undefined || typeof selectedValues[0] === 'string') {
								setSearchEnabled(selectedValues[0])
							}
						}}
					/>
				</div>
				<div className="mb-20">
					<Dropdown<string | undefined>
						value={searchHasPaymentMethod !== undefined ? [searchHasPaymentMethod] : [undefined]}
						label="Has Payment Method"
						searchable={false}
						options={getHasPaymentMethodOptions()}
						onSelect={(selectedValues) => {
							if (selectedValues[0] === undefined || typeof selectedValues[0] === 'string') {
								setSearchHasPaymentMethod(selectedValues[0])
							}
						}}
					/>
				</div>
				<TextInput
					dataType="text"
					label="Email"
					value={searchEmail}
					onChange={(updatedValue) => {
						setSearchEmail(updatedValue)
					}}
					width={'100%'}
					className="mb-20 mt-20"
				/>
				<PhoneInput
					label="Phone"
					dataType="number"
					value={searchPhone}
					onChange={(updatedValue) => {
						setSearchPhone(updatedValue)
					}}
					width={'100%'}
					className="mb-20"
				/>

				<div className="mb-20">
					<Dropdown<LicensedState>
						label="Licensed State"
						value={selectedLicenseStateOption}
						options={UserAdminService.getDropdownOptions(props.licensedStates, 'stateName')}
						onSelect={(selectedStates) => {
							const selectedStateIds = selectedStates.map((state) => state.licensedStateId)
							setSearchLicensedStateIds(() => {
								if (selectedStateIds && selectedStateIds.length > 0) {
									return selectedStateIds
								} else {
									return undefined
								}
							})

							// Check if any selected state ID is not 1 and set regions to undefined if so
							const hasNonOneStateId = selectedStateIds.some((stateId) => stateId !== 1)
							if (hasNonOneStateId) {
								setSearchRegionIds(undefined)
							}
						}}
						multiselect={true}
					/>
				</div>
				{selectedLicensedStates.length === 1 &&
					selectedLicensedStates.some((state) => state.licensedStateId === 1) && (
						<div className="mb-20">
							<Dropdown<LicensedRegion>
								label="Regions"
								value={selectedRegionOptions}
								options={UserAdminService.getDropdownOptions(props.licensedRegions, 'regionName')}
								onSelect={(selectedRegions) => {
									const selectedRegionIds = selectedRegions.map((region) => region.regionId)
									setSearchRegionIds(() => {
										if (selectedRegionIds && selectedRegionIds.length > 0) {
											return selectedRegionIds
										} else {
											return undefined
										}
									})
								}}
								multiselect={true}
							/>
						</div>
					)}

				<div className="mt-20">
					<Dropdown<MLSBoard.Complete>
						className="col-xs-12"
						label="Filter by MLS Board"
						multiselect={true}
						options={UserAdminService.getMLSBoardDropdownOptions(mlsBoardOptions)}
						value={selectedMLSOption}
						onSelect={(selectedBoards) => {
							const selectedBoardIds = selectedBoards.map((board) => board.mlsBoardId)
							setSearchMlsBoardIds(() => {
								if (selectedBoardIds && selectedBoardIds.length > 0) {
									return selectedBoardIds
								} else {
									return undefined
								}
							})
						}}
					/>
				</div>

				<div className="mt-20">
					<Dropdown<EndUserLanguageSpecialty>
						className="col-xs-12"
						label="Filter by Language Specialty"
						multiselect={true}
						options={UserAdminService.getDropdownOptions(props.languageSpecialties, 'languageName')}
						value={selectedLanguageSpecialtyOptions}
						onSelect={(selectedLanguages) => {
							const selectedLanguageSpecialtyIds = selectedLanguages.map(
								(language) => language.languageSpecialtyId,
							)
							setSearchLanguageSpecialtyIds(() => {
								if (selectedLanguageSpecialtyIds && selectedLanguageSpecialtyIds.length > 0) {
									return selectedLanguageSpecialtyIds
								} else {
									return undefined
								}
							})
						}}
					/>
				</div>

				<div className="mt-20">
					<Dropdown<EndUserSpecialty>
						className="col-xs-12"
						label="Filter by Specialty"
						multiselect={true}
						options={UserAdminService.getDropdownOptions(props.specialties, 'specialtyName')}
						value={selectedSpecialtyOptions}
						onSelect={(specialties) => {
							const selectedSpecialtyIds = specialties.map((specialty) => specialty.specialtyId)
							setSearchSpecialtyIds(() => {
								if (selectedSpecialtyIds && selectedSpecialtyIds.length > 0) {
									return selectedSpecialtyIds
								} else {
									return undefined
								}
							})
						}}
					/>
				</div>
			</RouteSubnavigation>

			<RouteBodyV2 id={`user-admin__route-body`}>
				<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center flex-column-md-down flex-alignItems-start-md-down mb-10">
					<h1>User Administration</h1>
					<LoKationButton
						variant="contained"
						label={buttonLabel}
						size={'sm'}
						onClick={() => {
							getUserExport()
						}}
						className="mb-20-md-down"
					/>
				</div>
				<div className="mb-20 opacity-50">{userAdminHook.totalElements} Results</div>
				<div className="flex flex-column-md-down">
					<div className="flex flex-column flex-fillSpace mt-20-md-down">
						{!isPending && !userAdminHook.hasMoreItems && userAdminHook.items.length === 0 && (
							<div className="flex flex-column flex-fillSpace flex-justifyContent-center flex-alignItems-center">
								<FontAwesomeIcon icon={['far', 'users']} size="4x" className="mb-20" />
								<h3>We cannot find any users matching your criteria</h3>
							</div>
						)}

						<InfiniteScroll
							dataLength={userAdminHook.items.length}
							next={userAdminHook.loadNextPageOfItems}
							hasMore={userAdminHook.hasMoreItems}
							scrollableTarget={props.ui.viewMode === 'FULL' ? `user-admin__route-body` : undefined}
							loader={
								<>
									<GenericContentLoader width={'fill'} height={70} className="mb-10" />
									<GenericContentLoader width={'fill'} height={70} className="mb-10" />
									<GenericContentLoader width={'fill'} height={70} className="mb-10" />
								</>
							}
						>
							{userAdminHook.items &&
								userAdminHook.items.map((user) => {
									return (
										<UserAdminCard
											key={user.endUserId}
											onClick={() => {
												setEndUserId(user.endUserId)
												// navigate(`/administration/users/${user.endUserId}`)
											}}
											user={user}
										/>
									)
								})}
						</InfiniteScroll>
					</div>
				</div>
			</RouteBodyV2>
			{endUserId && props.userProfile && (
				<EditUserSideSheetProvider
					endUserId={endUserId}
					currentUser={props.userProfile}
					onClose={() => {
						setEndUserId(null)
					}}
				/>
			)}
		</RouteWrapperV2>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
		licensedStates: state.licensedStates,
		licensedRegions: state.licensedRegions,
		specialties: state.specialties,
		languageSpecialties: state.languageSpecialties,
		ui: state.ui,
	}
}

export const UserAdministrationRoute = connect(mapStateToProps)(UserAdministrationRoute__Prototype)
