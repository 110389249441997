import { LoKationButton } from '@components/button/button'
import { RootState } from '@redux/store'
import { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { EndUserProps } from 'src/services/user/user.types'

interface ToolkitCMAProps {
	currentUser: EndUserProps | null
}

function ToolkitCMASignOnPrototype({ currentUser }: ToolkitCMAProps) {
	const formSubmitBtn = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (formSubmitBtn.current) {
			formSubmitBtn.current.click()
		}
	}, [])

	return (
		<div className="flex flex-column overflow-y__scroll px-40 px-10-md-down">
			<div className="text-center col-xs-12">
				<p className="text-center"></p>
				<LoKationButton
					variant="contained"
					size="lg"
					label="Sign In"
					onClick={() => {
						if (formSubmitBtn.current) {
							formSubmitBtn.current.click()
						}
					}}
				/>
			</div>

			<form
				action="https://www.toolkitcma.com/signuplink/"
				method="post"
				target="_blank"
				style={{ display: 'none' }}
			>
				<input type="hidden" name="login" value={currentUser?.email} />
				<input type="hidden" name="cid" value="23628" />
				<input type="hidden" name="userid" value={currentUser?.endUserId} />
				<input type="hidden" name="firstname" value={currentUser?.firstName} />
				<input type="hidden" name="lastname" value={currentUser?.lastName} />
				<input type="hidden" name="emailaddress" value={currentUser?.email} />
				<input type="submit" value="Submit" ref={formSubmitBtn}></input>
			</form>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const ToolkitCMASignOnRoute = connect(mapStateToProps)(ToolkitCMASignOnPrototype)
