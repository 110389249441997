import { LoKationButton } from '@components/button/button'
import { ListItem } from '@components/list-item/list-item'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalBodyStickyHeader } from '@components/modal/modal-body__sticky-header'
import { ModalFooter } from '@components/modal/modal-footer'
import { ModalHeader } from '@components/modal/modal-header'
import { OptionList } from '@components/option-list/option-list'
import { OptionListProps } from '@components/option-list/option-list.types'
import { SubrouteNavigation } from '@components/subroute-navigation/subroute-navigation'
import { TextInput } from '@components/text-input/text-input'
import { ModalReducerState } from '@redux/reducers/modal-reducer'
import { RootState } from '@redux/store'
import _ from 'lodash'
import { useState } from 'react'
import { connect } from 'react-redux'
import { LicensedRegion } from 'src/services/regions/regions.types'

import { LicensedState } from '../../services/licensed-states/licenses-states.types'
import { LokationSpecialty } from '../../services/specialties/specialty.types'
import { UserExplorer } from './components/user-explorer'

export interface UserSelectorModalRecipients {
	endUsers: number[]
	licensedStates: number[]
	specialties: number[]
	licensedRegions: number[]
	endUsersByPaymentMethod: number[]
}

interface UserSelectorModalProps {
	selectedRecipients: UserSelectorModalRecipients
	onSelectUsers: (updatedRecipients: UserSelectorModalRecipients) => void
	onHide: () => void
	zIndex?: number
	displayOptions?: ('state' | 'region' | 'specialty' | 'user' | 'paymentMethod')[]
}

interface ConnectedProps {
	licensedStates: LicensedState[]
	specialties: LokationSpecialty[]
	licensedRegions: LicensedRegion[]
	hasPaymentMethod?: boolean
	modals: ModalReducerState
}

type SelectUserCategory = 'state' | 'region' | 'specialty' | 'user' | 'paymentMethod'

export interface OnClickOptionProps {
	category: SelectUserCategory
	optionId: number
	state: boolean
}

function UserSelectorModalPrototype(props: UserSelectorModalProps & ConnectedProps) {
	/** =================================== */
	/** Props and State */
	const [modifiedSelectedUsers, setModifiedSelectedUsers] = useState(props.selectedRecipients)
	const [searchValue, setSearchValue] = useState('')
	function getInitialSection(): SelectUserCategory {
		if (props.displayOptions && props.displayOptions.length > 0) {
			return props.displayOptions[0]
		}
		return 'state' // default value if displayOptions is not provided or empty
	}

	const [selectedSection, setSelectedSection] = useState<SelectUserCategory>(getInitialSection)

	/** ============================= */

	/** =================================== */
	/** Methods */

	function getIsSelected(category: SelectUserCategory, optionId: number): boolean {
		switch (category) {
			case 'specialty':
				return modifiedSelectedUsers.specialties.includes(optionId)
			case 'state':
				return modifiedSelectedUsers.licensedStates.includes(optionId)
			case 'region':
				return modifiedSelectedUsers.licensedRegions.includes(optionId)
			case 'user':
				return modifiedSelectedUsers.endUsers.includes(optionId)
			case 'paymentMethod':
				return modifiedSelectedUsers.endUsersByPaymentMethod.includes(optionId)
		}
	}

	function onClickOption(fcProps: OnClickOptionProps): void {
		const updatedSelectedUsers = _.cloneDeep(modifiedSelectedUsers)

		if (fcProps.state === true) {
			switch (fcProps.category) {
				case 'specialty':
					updatedSelectedUsers.specialties.push(fcProps.optionId)
					break
				case 'state':
					updatedSelectedUsers.licensedStates.push(fcProps.optionId)
					break
				case 'region':
					updatedSelectedUsers.licensedRegions.push(fcProps.optionId)
					break
				case 'user':
					updatedSelectedUsers.endUsers.push(fcProps.optionId)
					break
				case 'paymentMethod':
					updatedSelectedUsers.endUsersByPaymentMethod.push(fcProps.optionId)
			}
		}

		if (fcProps.state === false) {
			switch (fcProps.category) {
				case 'specialty':
					updatedSelectedUsers.specialties = updatedSelectedUsers.specialties.filter(
						(specialtyId) => specialtyId !== fcProps.optionId,
					)
					break
				case 'state':
					updatedSelectedUsers.licensedStates = updatedSelectedUsers.licensedStates.filter(
						(licensedStateId) => licensedStateId !== fcProps.optionId,
					)
					break
				case 'region':
					updatedSelectedUsers.licensedRegions = updatedSelectedUsers.licensedRegions.filter(
						(regionId) => regionId !== fcProps.optionId,
					)
					break
				case 'user':
					updatedSelectedUsers.endUsers = updatedSelectedUsers.endUsers.filter(
						(endUserId) => endUserId !== fcProps.optionId,
					)
					break
				case 'paymentMethod':
					updatedSelectedUsers.endUsersByPaymentMethod = updatedSelectedUsers.endUsersByPaymentMethod.filter(
						(optionId) => optionId !== fcProps.optionId,
					)
			}
		}

		setModifiedSelectedUsers(updatedSelectedUsers)
	}

	function hideModal(): void {
		props.onHide()
	}

	function getSectionOptions(
		displayOptions?: ('state' | 'region' | 'specialty' | 'user' | 'paymentMethod')[],
	): OptionListProps.Option<SelectUserCategory>[] {
		const allOptions: OptionListProps.Option<SelectUserCategory>[] = [
			{
				label: 'State',
				value: 'state',
			},
			{
				label: 'Region',
				value: 'region',
			},
			{
				label: 'Specialty',
				value: 'specialty',
			},
			{
				label: 'All Users',
				value: 'user',
			},
			{
				label: 'Payment Method',
				value: 'paymentMethod',
			},
		]

		if (!displayOptions) {
			return allOptions
		}

		return allOptions.filter((option) => displayOptions.includes(option.value))
	}

	function getSelectorOptions(): React.ReactNode {
		switch (selectedSection) {
			case 'specialty':
				const specialtiesToShow = props.specialties.filter((specialty) =>
					specialty.specialtyName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
				)

				return specialtiesToShow.map((specialty) => {
					return (
						<ListItem
							key={`specialty__${specialty.specialtyId}`}
							body={specialty.specialtyName}
							includeCheckbox={true}
							selected={getIsSelected('specialty', specialty.specialtyId)}
							onClick={(newState) => {
								onClickOption({
									category: 'specialty',
									optionId: specialty.specialtyId,
									state: newState,
								})
							}}
						/>
					)
				})
			case 'state':
				const statesToShow = props.licensedStates.filter((state) =>
					state.stateName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
				)

				return statesToShow.map((state) => {
					return (
						<ListItem
							key={`state__${state.licensedStateId}`}
							body={state.stateName}
							includeCheckbox={true}
							selected={getIsSelected('state', state.licensedStateId)}
							onClick={(newState) => {
								onClickOption({ category: 'state', optionId: state.licensedStateId, state: newState })
							}}
						/>
					)
				})
			case 'region':
				const regionsToShow = props.licensedRegions.filter((region) =>
					region.regionName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
				)

				return regionsToShow.map((region) => {
					return (
						<ListItem
							key={`region__${region.regionId}`}
							body={region.regionName}
							includeCheckbox={true}
							selected={getIsSelected('region', region.regionId)}
							onClick={(newState) => {
								console.log('Region', newState)
								onClickOption({ category: 'region', optionId: region.regionId, state: newState })
							}}
						/>
					)
				})
			case 'user':
				return []

			case 'paymentMethod':
				const paymentMethodsToShow = [
					{ label: 'Has Payment Method', optionId: 1 },
					{ label: 'No Payment Method', optionId: 0 },
				]

				return paymentMethodsToShow.map((paymentMethodOption) => {
					const isSelected = getIsSelected('paymentMethod', paymentMethodOption.optionId)
					const otherOptionSelected = getIsSelected(
						'paymentMethod',
						paymentMethodOption.optionId === 1 ? 0 : 1,
					)
					return (
						<ListItem
							key={`paymentMethod__${paymentMethodOption.optionId}`}
							body={paymentMethodOption.label}
							includeCheckbox={true}
							selected={isSelected}
							onClick={(newState) => {
								onClickOption({
									category: 'paymentMethod',
									optionId: paymentMethodOption.optionId,
									state: newState,
								})
							}}
							disabled={otherOptionSelected}
						/>
					)
				})
		}
	}

	/** =================================== */
	/** Render Component */

	return (
		<Modal
			maxWidth={1200}
			maxHeight={750}
			fixedHeight={true}
			onClose={hideModal}
			zIndex={props.zIndex}
			className="flex flex-column"
		>
			<ModalHeader title={`Select Users`}>
				<></>
			</ModalHeader>
			<ModalBody className="flex" omitPadding={['all']}>
				<SubrouteNavigation className="modal-body__p">
					<OptionList<SelectUserCategory>
						persistent={true}
						value={selectedSection}
						options={getSectionOptions(props.displayOptions)}
						onClick={(option) => {
							setSearchValue('')
							setSelectedSection(option)
							console.log('Selected: ', option)
						}}
					/>
				</SubrouteNavigation>
				<div className="flex-fillSpace flex flex-column overflow-y__scroll">
					{selectedSection === 'user' && (
						<UserExplorer onClickOption={onClickOption} selectedUsers={modifiedSelectedUsers} />
					)}
					{selectedSection !== 'user' && (
						<>
							<ModalBodyStickyHeader className="flex flex-alignItems-center flex-justifyContent-spaceBetween modal-body__pl modal-body__pr pt-10 pb-10">
								<div></div>
								<TextInput
									dataType={'text'}
									placeholder="Search"
									onChange={(updatedValue) => {
										setSearchValue(updatedValue)
									}}
									value={searchValue}
									width={300}
									icon={{
										name: 'search',
									}}
								/>
							</ModalBodyStickyHeader>
							<div className="modal-body__p">{getSelectorOptions()}</div>
						</>
					)}
				</div>
			</ModalBody>
			<ModalFooter>
				<div className="flex flex-justifyContent-end">
					<LoKationButton
						variant="outlined"
						size="sm"
						primary={false}
						label="Cancel"
						className="mr-10"
						onClick={hideModal}
					/>
					<LoKationButton
						variant="contained"
						size="sm"
						label="Select"
						onClick={() => {
							props.onSelectUsers(modifiedSelectedUsers)
						}}
					/>
				</div>
			</ModalFooter>
		</Modal>
	)
}

function mapStateToProps(state: RootState) {
	return {
		modals: state.modals,
		users: state.user,
		licensedStates: state.licensedStates,
		specialties: state.specialties,
		licensedRegions: state.licensedRegions,
	}
}

export const UserSelectorModal = connect(mapStateToProps)(UserSelectorModalPrototype)
