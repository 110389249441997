import { RichTextEditor } from '@components/rich-text-editor/rich-text-editor'

import { EditStorySectionProps } from './edit-news-feed-story'

export function EditStoryBodySection(props: EditStorySectionProps) {
	return (
		<>
			<RichTextEditor
				className="mb-20"
				value={props.mergedStoryProps.description}
				rows={14}
				onChange={(updatedValue) => {
					props.updateStoryProp('description', updatedValue)
				}}
			/>
		</>
	)
}
