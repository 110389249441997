import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UserAPI } from '../../services/user/user.api'
import { EndUserProps } from '../../services/user/user.types'

export type UserState = EndUserProps | null

const initialState = null as UserState

export const getUserProps = createAsyncThunk('user/get', async (props: { userId: number }) => {
	const res = await UserAPI.getUserDetails(props.userId)
	return res.data
})

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserProps: (state, action: PayloadAction<EndUserProps>) => {
			return action.payload
		},
		logout: (state) => {
			return null
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getUserProps.fulfilled, (state, action) => {
			return action.payload
		})
	},
})

export const { setUserProps, logout } = userSlice.actions

export default userSlice.reducer
