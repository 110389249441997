import { RootState } from '@redux/store'
import _ from 'lodash'
import React, { useState } from 'react'
import { connect } from 'react-redux'

import { EndUserLanguageSpecialty } from '../../services/user/user.types'
import { LoKationButton } from '../button/button'
import { Checkbox } from '../checkbox/checkbox'
import { CollapsibleContent, CollapsibleContentStates } from '../collapsible-content/collapsible-content'

export interface LanguageSpecialtyFilterProps {
	selectedLanguageSpecialties: Array<EndUserLanguageSpecialty>
	onSelect: (selectedSpecialties: EndUserLanguageSpecialty[]) => void
	style?: React.CSSProperties
	className?: string
	isMulti: boolean
	expanded?: true
}

function LanguageSpecialtyFilterPrototype(
	props: LanguageSpecialtyFilterProps & { languageSpecialties: EndUserLanguageSpecialty[] },
) {
	const [state, setState] = useState<CollapsibleContentStates>(props.expanded ? 'OPEN' : 'CLOSED')

	function handleUpdateSelectedTags(newState: EndUserLanguageSpecialty, isLanguageSpecialtySelected: boolean): void {
		let updatedState = _.cloneDeep(props.selectedLanguageSpecialties)
		if (isLanguageSpecialtySelected === true) {
			if (props.isMulti) {
				updatedState.push(newState)
				props.onSelect(updatedState)
			} else {
				props.onSelect([newState])
			}
		}
		if (isLanguageSpecialtySelected === false) {
			if (props.isMulti) {
				props.onSelect(
					updatedState.filter((existingTag) => {
						return existingTag.languageSpecialtyId !== newState.languageSpecialtyId
					}),
				)
			} else {
				props.onSelect([])
			}
		}
	}

	function getIsLanguageSpecialtySelected(specialty: EndUserLanguageSpecialty): boolean {
		const isChecked =
			props.selectedLanguageSpecialties.find(
				(selectedSpecialty) => selectedSpecialty.languageSpecialtyId === specialty.languageSpecialtyId,
			) !== undefined
		return isChecked
	}

	function createFilterOption(languageSpecialtyToCreate: EndUserLanguageSpecialty): JSX.Element {
		const isChecked = getIsLanguageSpecialtySelected(languageSpecialtyToCreate)
		return (
			<div className="search-filter__option" key={languageSpecialtyToCreate.languageSpecialtyId}>
				<Checkbox
					checked={isChecked}
					onChange={(checkedState) => {
						handleUpdateSelectedTags(languageSpecialtyToCreate, checkedState)
					}}
				>
					{languageSpecialtyToCreate.languageName}
				</Checkbox>
			</div>
		)
	}

	const allLanguageSpecialties = props.languageSpecialties

	return (
		<div>
			<CollapsibleContent label="Languages" state={state} setState={setState}>
				<>
					<div className={`base-search-filter ${props.className ? props.className : ''}`} style={props.style}>
						{allLanguageSpecialties.map((languageSpecialty) => {
							return createFilterOption(languageSpecialty)
						})}
						{props.isMulti && (
							<div>
								<LoKationButton
									variant="text"
									size="sm"
									label="Select None"
									className="mr-20"
									onClick={() => {
										props.onSelect([])
									}}
								/>
								<LoKationButton
									variant="text"
									size="sm"
									label="Select All"
									onClick={() => {
										props.onSelect(allLanguageSpecialties)
									}}
								/>
							</div>
						)}
					</div>
				</>
			</CollapsibleContent>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		languageSpecialties: state.languageSpecialties,
	}
}

export const LanguageSpecialtyFilter = connect(mapStateToProps)(LanguageSpecialtyFilterPrototype)
