import { IconSelector } from './icon-selector.types'

export const iconSelectorOptions: IconSelector.Category[] = [
	{
		label: 'Alert',
		icons: [
			'bell',
			'alarm-clock',
			'question',
			'exclamation',
			'file-exclamation',
			'engine-warning',
			'wifi-exclamation',
			'star-exclamation',
			'square-exclamation',
			'skull-crossbones',
			'sensor-on',
			'sensor-fire',
			'sensor',
			'seal-question',
			'radiation',
			'party-horn',
			'party-bell',
			'message-exclamation',
			'location-exclamation',
			'lightbulb-exclamation',
			'light-emergency',
			'bells',
			'bell-exclamation',
		],
	},
	{
		label: 'Animals',
		icons: [
			'hippo',
			'fish',
			'dolphin',
			'bird',
			'bat',
			'dragon',
			'otter',
			'worm',
			'whale',
			'unicorn',
			'turtle',
			'squirrel',
			'spider',
			'snake',
			'skull-cow',
			'sheep',
			'rabbit',
			'rabbit-running',
			'pig',
			'paw-simple',
			'paw-claws',
			'paw',
			'narwhal',
			'monkey',
			'horse',
			'frog',
			'elephant',
			'duck',
			'dove',
			'dog',
			'deer',
			'crow',
			'cow',
			'cat',
			'bugs',
			'bee',
		],
	},
	{
		label: 'Astronomy',
		icons: [
			'globe',
			'planet-ringed',
			'ufo',
			'moon-stars',
			'moon',
			'planet-moon',
			'radar',
			'stars',
			'user-astronaut',
			'user-alien',
			'ufo-beam',
			'telescope',
			'star-shooting',
			'shuttle-space',
			'satellite-dish',
			'satellite',
			'moon-over-sun',
			'meteor',
			'eclipse',
			'comet',
			'cat-space',
			'alien',
		],
	},
	{
		label: 'Automotive',
		icons: [
			'car',
			'truck',
			'gauge',
			'moped',
			'motorcycle',
			'truck-monster',
			'wagon-covered',
			'van-shuttle',
			'truck-pickup',
			'truck-medical',
			'taxi',
			'tire',
			'steering-wheel',
			'rv',
			'pump',
			'oil-temperature',
			'oil-can',
			'gas-pump',
			'charging-station',
			'bus',
			'car-battery',
		],
	},
	{
		label: 'Buildings',
		icons: [
			'house',
			'city',
			'shop',
			'landmark',
			'building',
			'store',
			'hotel',
			'hospital',
			'industry',
			'warehouse',
			'tent',
			'campground',
			'cabin',
			'archway',
			'apartment',
		],
	},
	{
		label: 'Business',
		icons: [
			'phone',
			'envelope',
			'calendar',
			'paperclip',
			'file',
			'clipboard',
			'tag',
			'book',
			'print',
			'folder',
			'folder-open',
			'thumbtack',
			'globe',
			'city',
			'briefcase',
			'compass',
			'address-book',
			'business-time',
			'mug-saucer',
			'landmark',
			'building',
			'wallet',
			'calculator',
			'industry',
			'person-chalkboard',
			'chart-pie',
			'chart-bar',
			'chart-area',
			'signature-lock',
			'signature',
			'note-sticky',
			'file-user',
			'timeline',
			'home',
		],
	},
	{
		label: 'Charts and Diagrams',
		icons: [
			'chart-simple',
			'diagram-venn',
			'chart-scatter',
			'square-poll-vertical',
			'diagram-project',
			'chart-waterfall',
			'chart-user',
			'chart-pyramid',
			'chart-pie',
			'chart-mixed',
			'chart-line',
			'chart-area',
		],
	},
	{
		label: 'Design',
		icons: [
			'pen',
			'eye',
			'paint-roller',
			'brush',
			'layer-group',
			'pencil',
			'crosshairs',
			'eraser',
			'stamp',
			'sparkle',
			'scissors',
			'scribble',
			'paintbrush',
			'image',
		],
	},
	{
		label: 'Devices and Hardware',
		icons: [
			'headphones',
			'print',
			'camera',
			'gamepad',
			'database',
			'mobile',
			'laptop',
			'phone-office',
			'desktop',
			'microchip',
			'speaker',
			'plug',
			'keyboard',
			'tv',
			'shredder',
			'memory',
			'floppy-disk',
			'flashlight',
		],
	},
	{
		label: 'Gaming',
		icons: [
			'ghost',
			'sparkles',
			'gamepad',
			'joystick',
			'alien-8bit',
			'dragon',
			'scroll',
			'puzzle-piece',
			'dice',
			'headset',
			'wand',
			'treasure-chest',
			'teddy-bear',
			'sword',
			'spade',
			'slot-machine',
			'scythe',
			'paw-claws',
			'paw',
			'hand-fist',
			'gamepad-modern',
			'dice-d10',
			'dice-d12',
			'chess-pawn',
			'chess-knight',
			'chess-king',
		],
	},
	{
		label: 'Household',
		icons: [
			'bath',
			'bed-front',
			'loveseat',
			'shower',
			'mailbox',
			'books',
			'speaker',
			'chair',
			'house-night',
			'lamp',
			'microwave',
			'window-frame',
			'utensils',
			'tv',
			'spoon',
			'sink',
			'pan-frying',
			'lamp-desk',
			'house-lock',
			'house-heart',
			'house-day',
			'garage-car',
			'bed',
		],
	},
	{
		label: 'Logistics',
		icons: [
			'car',
			'gears',
			'truck',
			'anchor',
			'truck-fast',
			'truck-front',
			'person-dolly',
			'warehouse',
			'road',
			'truck-plane',
			'truck-clock',
			'train-subway-tunnel',
			'train-subway',
			'shelves',
			'scanner-gun',
			'sailboat',
			'road-barrier',
			'person-carry-box',
			'pallet-boxes',
			'helicopter',
			'box',
			'box-check',
			'bus',
		],
	},
	{
		label: 'Marketing',
		icons: [
			'megaphone',
			'badge',
			'bullseye',
			'lightbulb',
			'timeline',
			'timeline-arrow',
			'square-poll-vertical',
			'signal-stream',
			'ranking-star',
			'message-dollar',
			'lightbulb-dollar',
			'hundred-points',
			'gift-card',
			'filter',
			'filter-circle-dollar',
			'comment-dollar',
			'bullhorn',
			'arrows-spin',
		],
	},
	{
		label: 'Medical and Health',
		icons: [
			'heart',
			'plus',
			'eye',
			'flask',
			'stethoscope',
			'notes-medical',
			'user-nurse',
			'vials',
			'brain',
			'hospital',
			'virus',
			'cauldron',
			'receipt',
			'tablets',
			'x-ray',
			'user-doctor',
			'truck-medical',
			'tooth',
			'syringe',
			'skull',
			'eye-dropper',
			'ear',
			'dna',
			'crutch',
			'clipboard',
			'clipboard-user',
		],
	},
	{
		label: 'Money',
		icons: [
			'money-bill',
			'credit-card',
			'wallet',
			'coins',
			'receipt',
			'stamp',
			'sack',
			'scale-balanced',
			'scale-unbalanced',
			'message-dollar',
			'badge-dollar',
		],
	},
	{
		label: 'Moving',
		icons: [
			'person-dolly',
			'truck-ramp',
			'truck-moving',
			'trailer',
			'suitcase',
			'sign-hanging',
			'person-carry-box',
			'box-taped',
			'box-open',
		],
	},
	{
		label: 'Security',
		icons: [
			'lock',
			'eye',
			'eye-slash',
			'key',
			'bug',
			'user-secret',
			'passport',
			'shield',
			'shield-check',
			'mask',
			'fingerprint',
			'unlock',
			'vault',
			'user-shield',
			'user-police',
			'user-lock',
			'siren',
			'siren-on',
			'person-to-door',
			'id-card',
			'id-badge',
			'house-lock',
			'house-fire',
			'hands-bound',
			'handcuffs',
			'camera-cctv',
			'building-shield',
			'building-lock',
		],
	},
	{
		label: 'Shopping',
		icons: [
			'cart-shopping',
			'gift',
			'tag',
			'bookmark',
			'camera',
			'thumbs-up',
			'thumbs-down',
			'shop',
			'shirt',
			'bag-shopping',
			'store',
			'credit-card',
			'tags',
			'basket-shopping',
		],
	},
	{
		label: 'Sports and Fitness',
		icons: [
			'heart',
			'bicycle',
			'medal',
			'dumbbell',
			'whistle',
			'wave-pulse',
			'watch-fitness',
			'volleyball',
			'tennis-ball',
			'spa',
			'ski-boot',
			'shirt-running',
			'ranking-star',
			'racquet',
			'person-walking',
			'person-swimming',
			'person-skating',
			'person-skiing',
			'person-running',
			'person-hiking',
			'person-biking',
			'mask-snorkel',
			'lacrosse-stick',
			'ice-skate',
			'hockey-sticks',
			'heart-pulse',
			'golf-flag-hole',
			'golf-club',
			'football',
			'football-helmet',
			'fishing-rod',
			'boxing-glove',
			'bowling-pins',
			'bowling-ball-pin',
			'baseball',
		],
	},
	{
		label: 'Social',
		icons: ['user', 'image', 'location', 'comment', 'video', 'thumbs-up', 'thumbs-down', 'share', 'users'],
	},
	{
		label: 'Users and People',
		icons: [
			'user',
			'face-smile',
			'person',
			'person-dress',
			'users',
			'user-secret',
			'street-view',
			'child',
			'baby',
			'user-cowboy',
			'user-alien',
			'user-astronaut',
			'skull',
			'id-card',
			'id-badge',
			'head-side-virus',
			'face-frown',
			'face-meh',
			'family',
			'bed',
			'angel',
		],
	},
	{
		label: 'Weather',
		icons: [
			'cloud',
			'bolt',
			'umbrella',
			'sparkles',
			'snowflake',
			'sun',
			'stars',
			'star',
			'water',
			'moon',
			'wind',
			'raindrops',
			'volcano',
			'icicles',
			'heat',
			'bolt-lightning',
			'temperature-half',
		],
	},
]
