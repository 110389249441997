import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface IconSelectorOptionProps {
	isSelected: boolean
	iconName: IconName
	onClick: () => void
}

export function IconSelectorOption(props: IconSelectorOptionProps) {
	return (
		<div
			className={`icon-selector__option ${props.isSelected ? 'selected' : ''}`}
			onClick={() => {
				props.onClick()
			}}
		>
			<FontAwesomeIcon icon={['fat', props.iconName]} size="2x" />
		</div>
	)
}
