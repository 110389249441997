import './faq__card.scss'

import { FAQ } from '../../../../services/faq/faq.types'
import { Utilities } from '../../../../services/utilities.service'

interface FAQCardProps {
	onClick: () => void
}

export function FAQCard(props: FAQ & FAQCardProps) {
	return (
		<div className="faq__card" onClick={props.onClick}>
			<strong>{Utilities.truncateString(props.question, 100)}</strong>
		</div>
	)
}
