import _ from 'lodash'

import { Checkbox } from '../checkbox/checkbox'

interface CheckboxListProps<T> {
	allItems: T[]
	onChange: (selectedTags: T[]) => void
	selectedItems: T[]
	itemLabelKey: keyof T & string
	itemIdKey: keyof T & (number | string)
	className?: string
	optionClassName?: string
}

export function CheckboxList<T>(props: CheckboxListProps<T>) {
	function handleUpdateOptions(thisOption: T, addOrRemove: 'ADD' | 'REMOVE'): void {
		let updatedState = _.cloneDeep(props.selectedItems)
		if (addOrRemove === 'ADD') {
			updatedState.push(thisOption)
		} else {
			updatedState = props.selectedItems.filter(
				(selectedState) => selectedState[props.itemIdKey] !== thisOption[props.itemIdKey],
			)
		}
		props.onChange(updatedState)
	}

	function optionClassName(): string {
		let className = `mr-20 mb-10 ${props.optionClassName ? props.optionClassName : ''}`
		return className
	}

	return (
		<div className={`${props.className ? props.className : ''}`}>
			{props.allItems.map((thisOption) => {
				const isSelected =
					props.selectedItems.findIndex(
						(option) => option[props.itemIdKey] === thisOption[props.itemIdKey],
					) !== -1
				const optionLabel = thisOption[props.itemLabelKey]
				return (
					<Checkbox
						key={String(optionLabel)}
						checked={isSelected}
						className={optionClassName()}
						onChange={(isIncluded) => {
							handleUpdateOptions(thisOption, isIncluded ? `ADD` : `REMOVE`)
						}}
					>
						{String(optionLabel)}
					</Checkbox>
				)
			})}
		</div>
	)
}
