interface ColorBlockProps {
	className?: string
	style?: React.CSSProperties
	color: string
	size?: number
	radius?: number
	onClick?: () => void
}

export function ColorBlock(props: ColorBlockProps) {
	function getStyle(): React.CSSProperties {
		const style: React.CSSProperties = { ...props.style }

		style.width = `${props.size ? props.size : 16}px`
		style.height = `${props.size ? props.size : 16}px`
		style.borderRadius = props.radius ? props.radius : 'var(--elementBorderRadius)'

		style.backgroundColor = props.color
		return style
	}

	function getClassName(): string {
		const className = `color-block ${props.className ? props.className : ''}`
		return className
	}

	return (
		<div
			className={getClassName()}
			style={getStyle()}
			onClick={() => {
				if (props.onClick) {
					props.onClick()
				}
			}}
		></div>
	)
}
