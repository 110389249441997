import backAtYouLogo from './back-at-you__logo.png'
export function RegistrationBackAtYouDesc() {
	return (
		<div className="col-xs-12">
			<img src={backAtYouLogo} style={{ width: '200px' }} className="mb-20" />
			<h6>Back At You is ready to set you up for long-term success with our industry-changing tools</h6>
			<div className="col-xs-12 flex mt-20">
				<div className="col-xs-12 col-lg-4 pr-20-lg">
					<strong>SocialBAY</strong>
					<p>
						Meet the ultimate marketing center for your real estate business! From targeted social media
						advertising to professional listing videos, exclusive seller lead capture to branded, local
						content and modern postcards, our SocialBAY has you covered. With SocialBAY, your brokerage will
						see more visibility and leads than ever before.
					</p>
				</div>
				<div className="col-xs-12 col-lg-4 pl-20-lg pr-20-lg">
					<strong>ClientBAY</strong>
					<p>
						Attract buyers and sellers with modern, mobile-first websites, manage your relationships with a
						savvy CRM, send newsletters to keep people in the know, create action plans and more. With
						clients at the center of everything you do, our ClientBAY makes sure your agents stay connected.
					</p>
				</div>
				<div className="col-xs-12 col-lg-4 pl-20-lg pr-20-lg">
					<strong>SocialBAY</strong>
					<p>
						Experience our easy-to-use, robust solution that streamlines your entire back-office. From
						transaction management to sophisticated commission tracking and disbursements, hundreds of
						financial reports, workflows, dashboards, third-party integrations and more. Instantly reduce
						your workload and make your business more profitable.
					</p>
				</div>
			</div>
		</div>
	)
}
