/* eslint-disable react/jsx-pascal-case */
import { LoKationButton } from '@components/button/button'
import { Checkbox } from '@components/checkbox/checkbox'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownOptionProps } from '@components/dropdown/dropdown.types'
import { IconButton } from '@components/icon-button/icon-button'
import { SideSheet } from '@components/side-sheet/side-sheet'
import { SideSheetTypes } from '@components/side-sheet/side-sheet.types'
import { TextInput } from '@components/text-input/text-input'
import { useEffect, useState } from 'react'
import { GenericDeleteConfirmationModal } from 'src/modals/generic-delete-confirmation/generic-delete-confirmation'
import { DateService } from 'src/services/date.service'
import { StripeCouponAPI } from 'src/services/stripe/stripe.coupon.api'
import { StripeService } from 'src/services/stripe/stripe.service'
import { StripeTypes } from 'src/services/stripe/stripe.types'

import { PromotionCodeStatus } from './promotion-code-status'

interface EditCouponSideSheetProps {
	couponToEdit: StripeTypes.CouponData | 'NEW'
	onClose: () => void
	onSave: () => void
}

export function Stripe__Coupon__SideSheet(props: EditCouponSideSheetProps) {
	const [modifiedCoupon, setModifiedCoupon] = useState<StripeTypes.CouponData | null>(null)
	const [modifiedPromotionCode, setModifiedPromotionCode] = useState<StripeTypes.PromotionCodeCreation | null>(null)
	// const [modifiedPromoCode, setModifiedPromoCode] = useState<string | null>(null)
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
	const [promotionCodes, setPromotionCodes] = useState<StripeTypes.Promotion[] | null>(null)
	const [showPromotionCodeModal, setShowPromotionCodeModal] = useState(false)
	const [discountType, setDiscountType] = useState<StripeTypes.CouponDiscountType>('PERCENT')
	const [displayMaxRedemptions, setDisplayMaxRedemptions] = useState<boolean>(false)

	useEffect(() => {
		if (props.couponToEdit === 'NEW') {
			const newCoupon = StripeService.getDefaultCouponProps()
			const newPromoCode = StripeService.getDefaultPromotionCodeProps()
			setModifiedPromotionCode(newPromoCode)
			setModifiedCoupon(newCoupon)
			setDiscountType('FIXED') // Default discount type for new coupons
		} else {
			const existingCoupon = props.couponToEdit
			setModifiedCoupon(existingCoupon)
			const newPromoCode = StripeService.getDefaultPromotionCodeProps(props.couponToEdit.id)
			setModifiedPromotionCode(newPromoCode)
			// Infer the discount type based on amountOff or percentOff
			if (existingCoupon.amountOff && existingCoupon.amountOff > 0) {
				setDiscountType('FIXED')
			} else if (existingCoupon.percentOff && existingCoupon.percentOff > 0) {
				setDiscountType('PERCENT')
			}
			fetchPromoCodes()
		}
	}, [props.couponToEdit])

	async function handleCouponCreation() {
		if (props.couponToEdit === 'NEW' && modifiedCoupon) {
			StripeCouponAPI.createCoupon(modifiedCoupon).then((res) => {
				const couponId = res.id
				if (couponId && modifiedPromotionCode) {
					const updatedPromotionCode = { ...modifiedPromotionCode, couponId: res.id }
					StripeCouponAPI.createPromotionCode(updatedPromotionCode)
				}
			})
		}
		if (props.couponToEdit !== 'NEW' && modifiedPromotionCode && modifiedPromotionCode.code) {
			console.log(modifiedPromotionCode)
			StripeCouponAPI.createPromotionCode(modifiedPromotionCode)
			console.log('TEST')
		}
	}

	function renderFooter(): React.ReactNode {
		return (
			<>
				<LoKationButton
					variant={'outlined'}
					label={'Cancel'}
					size={'sm'}
					className="mr-10"
					onClick={() => {
						props.onClose()
					}}
				/>
				<LoKationButton
					variant={'contained'}
					label={'Save and Close'}
					size={'sm'}
					// disabled={isAdValid.length > 0}
					onClick={() => {
						handleCouponCreation().then(() => {
							props.onSave()
							// props.onUpdate(adEditorState.modifiedAd)
						})
					}}
				/>
			</>
		)
	}

	function getActions(): SideSheetTypes.Action[] {
		const actions: SideSheetTypes.Action[] = []

		actions.push({
			label: 'Delete',
			icon: 'trash',
			onClick: () => {
				setShowDeleteConfirmationModal(true)
			},
		})

		return actions
	}

	function fetchPromoCodes() {
		if (props.couponToEdit !== 'NEW') {
			StripeCouponAPI.getAssociatedPromotions(props.couponToEdit.id).then((res) => {
				setPromotionCodes(res)
			})
		}
	}

	function getDiscountType(): DropdownOptionProps<StripeTypes.CouponDiscountType>[] {
		return [
			{ value: 'PERCENT', label: 'Percentage Discount' },
			{ value: 'FIXED', label: 'Fixed Amount Discount' },
		]
	}

	function getDiscountDurationType(): DropdownOptionProps<StripeTypes.CouponDurationType>[] {
		return [
			{ value: 'once', label: 'Once' },
			{ value: 'forever', label: 'Forever' },
		]
	}

	if (!modifiedCoupon) {
		return <></>
	}

	return (
		<>
			<SideSheet
				title={props.couponToEdit === 'NEW' ? 'Create Coupon' : `${modifiedCoupon.name}`}
				actions={getActions()}
				onClose={props.onClose}
				persistent={false}
				width="wide"
				footer={renderFooter()}
			>
				<div className="flex flex-wrap mb-20 mt-10">
					<div className="col-12">
						<h3 className="mb-20">Coupon Information</h3>
					</div>

					<TextInput
						width={300}
						dataType="text"
						label="Name"
						disabled={props.couponToEdit === 'NEW' ? false : true}
						value={modifiedCoupon.name}
						className={`mb-20 mr-20`}
						onChange={(updatedValue) => {
							setModifiedCoupon({ ...modifiedCoupon, name: updatedValue })
						}}
					/>

					<div className="mr-10 mb-20">
						<Dropdown<StripeTypes.CouponDiscountType>
							width={300}
							label="Discount Type"
							value={[discountType]} // Always show the current discount type
							options={getDiscountType()}
							onSelect={(selectedValues) => {
								setDiscountType(selectedValues[0])
								console.log(selectedValues[0])

								// Reset corresponding fields when discount type changes
								if (selectedValues[0] === 'FIXED') {
									setModifiedCoupon({ ...modifiedCoupon, amountOff: 0, percentOff: null })
								} else if (selectedValues[0] === 'PERCENT') {
									setModifiedCoupon({ ...modifiedCoupon, amountOff: null, percentOff: 0 })
								}
							}}
							disabled={props.couponToEdit !== 'NEW'}
						/>
					</div>

					{discountType === 'FIXED' && modifiedCoupon.amountOff !== null && (
						<TextInput
							width={300}
							dataType="text"
							label="Discount amount (in dollars)"
							disabled={props.couponToEdit === 'NEW' ? false : true}
							value={(modifiedCoupon.amountOff / 100).toFixed(2)}
							className={`mb-20 mr-20`}
							onChange={(updatedValue) => {
								const input = updatedValue.replace(/\D/g, '') // Only allow digits
								const amountInCents = parseInt(input, 10) * 100 || 0 // Convert dollars to cents
								setModifiedCoupon({
									...modifiedCoupon,
									amountOff: amountInCents, // Store value in cents for API
									percentOff: null,
								})
							}}
						/>
					)}

					{discountType === 'PERCENT' && (
						<TextInput
							width={300}
							dataType="number"
							label="Percentage off"
							disabled={props.couponToEdit === 'NEW' ? false : true}
							value={modifiedCoupon.percentOff}
							className={`mb-20 mr-20`}
							onChange={(updatedValue) => {
								setModifiedCoupon({ ...modifiedCoupon, amountOff: null, percentOff: updatedValue })
							}}
						/>
					)}

					<div className="mr-10 mb-20">
						<Dropdown<StripeTypes.CouponDurationType>
							width={300}
							label="Discount Type"
							value={[modifiedCoupon.duration]} // Always show the current discount type
							options={getDiscountDurationType()}
							onSelect={(selectedValues) => {
								setModifiedCoupon({ ...modifiedCoupon, duration: selectedValues[0] })
							}}
							disabled={props.couponToEdit !== 'NEW'}
						/>
					</div>

					{props.couponToEdit !== 'NEW' && (
						<TextInput
							width={300}
							dataType="text"
							label="Created"
							disabled={true}
							value={DateService.convertTimestampToDate(modifiedCoupon.created)}
							className={`mb-20 mr-20`}
						/>
					)}

					{props.couponToEdit !== 'NEW' && (
						<TextInput
							width={300}
							dataType="text"
							label="Valid"
							value={modifiedCoupon.valid ? 'Yes' : 'No'}
							disabled={true}
							className={`mb-20 mr-20`}
						/>
					)}

					<div className="col-12 flex flex-justifyContent-spaceBetween">
						<h3 className="mb-20">Promotion Codes</h3>

						{props.couponToEdit !== 'NEW' && (
							<IconButton
								tooltip="Add Promotion Code"
								tooltipPlacement="bottom"
								icon={'plus'}
								className="ml-10"
								onClick={() => {
									setShowPromotionCodeModal(true)
								}}
							/>
						)}
					</div>

					{modifiedPromotionCode && (props.couponToEdit === 'NEW' || showPromotionCodeModal) ? (
						<div className="flex flex-wrap mb-20 mt-10">
							<TextInput
								width={300}
								dataType="text"
								label="Promotion Code"
								value={modifiedPromotionCode.id}
								className={`mb-20 mr-20`}
								onChange={(updatedValue) => {
									setModifiedPromotionCode({ ...modifiedPromotionCode, code: updatedValue })
								}}
							/>

							<div className="flex flex-alignItems-center">
								<div className="mb-20-xs-down" style={{ width: '300px' }}>
									<Checkbox
										checked={displayMaxRedemptions}
										className="mt-5"
										onChange={(newState) => {
											setDisplayMaxRedemptions(newState)
										}}
									>
										Limit the number of times this code can be redeemed
									</Checkbox>
								</div>
							</div>

							{displayMaxRedemptions && (
								<TextInput
									width={300}
									dataType="number"
									label="Max redemptions"
									value={modifiedPromotionCode.maxRedemptions}
									className={`mb-20 mr-20`}
									onChange={(updatedValue) => {
										setModifiedPromotionCode({
											...modifiedPromotionCode,
											maxRedemptions: updatedValue,
										})
									}}
								/>
							)}
						</div>
					) : null}

					{props.couponToEdit !== 'NEW' && (
						<div className="col-12">
							{promotionCodes ? (
								<div>
									{promotionCodes.map((promotionCode) => {
										return (
											<div key={promotionCode.id}>
												<PromotionCodeStatus
													promotionCode={promotionCode.code}
													promotionId={promotionCode.id}
													archiveStatus={promotionCode.active}
												/>
											</div>
										)
									})}
								</div>
							) : (
								<div>There are currently no promotion codes</div>
							)}
						</div>
					)}
				</div>
			</SideSheet>

			{showDeleteConfirmationModal && (
				<GenericDeleteConfirmationModal
					itemLabel={`${modifiedCoupon.name} coupon`}
					onDelete={() => {
						if (props.couponToEdit !== 'NEW')
							StripeCouponAPI.deleteCoupon(props.couponToEdit.id).then(() => {
								setShowDeleteConfirmationModal(false)
								props.onClose()
							})
					}}
					onClose={() => {
						setShowDeleteConfirmationModal(false)
					}}
				/>
			)}
		</>
	)
}
