import { useEffect } from 'react'

declare global {
	interface Window {
		HFCHAT_CONFIG: {
			EMBED_TOKEN: string
			ASSETS_URL: string
		}
	}
}

const HappyFoxChatWidget = () => {
	useEffect(() => {
		window.HFCHAT_CONFIG = {
			EMBED_TOKEN: '73b33680-4496-11eb-8e11-f1f72b87f005',
			ASSETS_URL: 'https://widget.happyfoxchat.com/v2/visitor',
		}

		const scriptTag = document.createElementNS('http://www.w3.org/1999/xhtml', 'script') as HTMLScriptElement
		scriptTag.type = 'text/javascript'
		scriptTag.async = true
		scriptTag.src = window.HFCHAT_CONFIG.ASSETS_URL + '/js/widget-loader.js'

		const s = document.getElementsByTagName('script')[0]
		if (s.parentNode) {
			s.parentNode.insertBefore(scriptTag, s)
		}
	}, [])

	return null
}

export default HappyFoxChatWidget
