import { LoKationButton } from '@components/button/button'
import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'

import AutoPlay from './zip-AutoPlay.gif'
import CoverImg from './zip-flyer.png'

export function LoKationResourceZipYourFlyer() {
	return (
		<div className="flex-lg overflow-y__scroll">
			<div className="col-xs-12 col-lg-8 pr-20-sm flex flex-column flex-alignItems-start overflow-y__scroll">
				<div className="text-center col-xs-12">
					<img src={CoverImg} style={{ maxWidth: '400px' }} className="col-xs-12" />
				</div>
				<HorizontalRule className="mt-20" />
				<p>
					Zip Your Flyer is the Nationwide leader in Agent to Agent E-Flyers sending millions of email flyers
					every day. Effortlessly send your professionally designed listings flyers to agents in your area who
					have buyers ready to purchase. Properties marketed by Zip Your Flyer have shown to have 22% fewer
					days on market!
				</p>
				<p>
					The process is super easy - simply provide them with the property information and they will do the
					rest. You will receive a proof within two hours of placing your order. If you need help, they have
					live phone support with real people that answer the phones. Zip Your Flyer has their own agent email
					database so no need to upload your own list, however, you can if you'd like.
				</p>
				<p>
					<strong>
						Sign up through LoKation and receive autoplay E-Flyer Designs at no cost (savings of $19.95 per
						order) and free Fast Lane service (savings of $9.95 per order).
					</strong>
				</p>

				<div className="flex flex-wrap">
					<LoKationButton
						size="sm"
						variant="outlined"
						label="Log In"
						className="mr-10 mb-10"
						onClick={() => {
							window.open('https://www.zipyourflyer.com/', '_blank')
						}}
					/>
					{/* <LoKationButton
						size="sm"
						variant="outlined"
						label="Listing Presentation Sample"
						className="mr-10 mb-10"
						onClick={() => {
							window.open(
								'https://agent.lokationre.com/images/marketing/zyf/LoKation_Real_Estate_Pompano_Beach_FL_Listing_Presentation_Widescreen.pdf?type=file',
								'_blank',
							)
						}}
					/> */}
					<LoKationButton
						size="sm"
						variant="outlined"
						label="Pricing"
						className="mr-10 mb-10"
						onClick={() => {
							window.open('https://www.zipyourflyer.com/pricing-email/', '_blank')
						}}
					/>
					<LoKationButton
						size="sm"
						variant="outlined"
						label="Overview Training"
						className="mr-10 mb-10"
						onClick={() => {
							window.open(
								'https://lokation.thinkific.com/courses/zip-your-flyer-agent-to-agent-email-flyers',
								'_blank',
							)
						}}
					/>
				</div>

				<div>
					<strong>Support:</strong>
					<br />
					Local: 425.712.8509
					<br />
					Toll Free: 888.947.8509
					<br />
					Email: service@zipyourflyer.com
					<br />
				</div>
			</div>
			<div className="col-xs-12 col-lg-4 bg-color__adjust-10 p-20 flex flex-alignItems-center">
				<img src={AutoPlay} className="col-xs-12" />
			</div>
		</div>
	)
}
