import { HomeSearchGrid } from '../../../../../lokation-routes/home-search-app-fl/home-search__grid'
export function RegistrationHomeSearchDesc() {
	return (
		<div className="col-xs-12">
			<h1>
				<strong>HomeSearch</strong>
			</h1>
			<h6>Finally, a tool with longevity!</h6>
			<p>
				Give your clients a tool they can use for years to come. Your app is buyer, seller, renter, and
				investory friendly. They can search, save and set notifications for themselves for any and all searches
				their path may lead them down. All while being one touch away from you!
			</p>
			<HomeSearchGrid />
		</div>
	)
}
