import './hub__card.scss'

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Hub } from '../../../services/hubs/hubs.types'
import { Utilities } from '../../../services/utilities.service'

interface HubCardProps {
	onClick: () => void
}

export function HubCard(props: { hub: Hub } & HubCardProps) {
	return (
		<div className={`hub__card`} onClick={props.onClick}>
			<div className="hub__icon-column">
				<FontAwesomeIcon icon={['far', props.hub.icon as IconName]} size="2x" className="opacity-30" />
			</div>
			<div className="flex flex-column">
				<strong>{props.hub.title}</strong>
				<div>{Utilities.truncateString(props.hub.description, 200)}</div>
			</div>
		</div>
	)
}
