import './tabs.scss'

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface TabsProps {
	options: Array<TabsOption>
	selectedOptionId: string
	className?: string
	handleUpdateSelectedOption: (optionId: string) => void
}

export interface TabsOption {
	name: string
	icon?: IconName
	id: string
	onClick?: () => void
	width: number | 'evenly-distributed'
}

export function Tabs(props: TabsProps) {
	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`fs-unmask flex`)

		if (props.className) {
			classString = `${props.className} `
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	return (
		<div className={getClass()}>
			{props.options.map((option) => {
				const classesToApply = `tabs-option ${option.id === props.selectedOptionId ? 'selected' : ''}`

				return (
					<div
						onClick={() => {
							props.handleUpdateSelectedOption(option.id)
							if (option.onClick) {
								option.onClick()
							}
						}}
						className={classesToApply}
						style={{
							width:
								typeof option.width === 'number'
									? `${option.width}px`
									: `${100 / props.options.length}%`,
						}}
						key={`tabs__${option.id}`}
					>
						{option.icon && <FontAwesomeIcon icon={['far', option.icon]} className="mr-10" />}
						<strong>{option.name}</strong>
					</div>
				)
			})}
		</div>
	)
}
