import { LoKationButton } from '@components/button/button'
import { SideSheet } from '@components/side-sheet/side-sheet'
import { SideSheetTypes } from '@components/side-sheet/side-sheet.types'
import { useAppDispatch } from '@redux/hooks'
import { useState } from 'react'
import { EndUserProps } from 'src/services/user/user.types'
import { UserUpdateService } from 'src/services/user/user-update-service'

import AddOnServices from '../user-profile/components/add-on-services'
import AdminTools from '../user-profile/components/admin-tools/admin-tools'
import AgentLicenses from '../user-profile/components/agent-licenses'
import BillingComponent from '../user-profile/components/billing'
import PersonalInformation from '../user-profile/components/personal-information'
import Subscriptions from '../user-profile/components/subscriptions'
import { useUserProfile } from '../user-profile/state/user-profile__state'
import { UserProfileService } from '../user-profile/user-profile.service'

interface EditUserSideSheetProps {
	userToEdit: EndUserProps
	currentUser: EndUserProps
	onClose: () => void
}

export function EditUserSideSheet(props: EditUserSideSheetProps) {
	/** ======================================= */
	/** Props and State */

	const [activeComponent, setActiveComponent] = useState('personalInfo')
	const userProfileState = useUserProfile()
	const mergedUserProps = UserProfileService().applyModifiedStateToOriginalProps(
		userProfileState.modifiedProps,
		props.userToEdit,
	)

	const dispatch = useAppDispatch()

	/** ======================================= */
	/** Effects */

	/** ======================================= */
	/** Methods */

	function renderFooter(): React.ReactNode {
		return (
			<div className="flex flex-justifyContent-end">
				<LoKationButton
					variant="outlined"
					label="Close"
					size="sm"
					className="mr-10"
					onClick={() => {
						props.onClose()
					}}
				/>
				<LoKationButton
					size={'sm'}
					label="Save Changes"
					onClick={() => {
						const currentUserId = props.currentUser.endUserId
						UserUpdateService(dispatch, mergedUserProps, userProfileState, currentUserId)
					}}
					variant="contained"
				/>
			</div>
		)
	}

	function getActions(): SideSheetTypes.Action[] {
		const actions: SideSheetTypes.Action[] = []

		actions.push({
			label: 'Go To Profile',
			icon: 'id-badge',
			onClick: () => {
				window.open(`/administration/users/${props.userToEdit.endUserId}`, '_blank')
			},
		})

		return actions
	}

	/** ======================================= */
	/** Render Component */

	if (!mergedUserProps) {
		return <></>
	}

	return (
		<>
			<SideSheet
				title={`User: ${mergedUserProps.firstName} ${mergedUserProps.lastName} - ${mergedUserProps.endUserId}`}
				actions={getActions()}
				onClose={props.onClose}
				persistent={false}
				width={'wide'}
				footer={renderFooter()}
				tabs={{
					options: [
						{
							name: 'Personal',
							id: 'personalInfo',
							onClick: () => {
								setActiveComponent('personalInfo')
							},
							width: 'evenly-distributed',
						},
						{
							name: 'Agent Licenses',
							id: 'agentLicenses',
							onClick: () => {
								setActiveComponent('agentLicenses')
							},
							width: 'evenly-distributed',
						},
						{
							name: 'Billing',
							id: 'billing',
							onClick: () => {
								setActiveComponent('billing')
							},
							width: 'evenly-distributed',
						},
						{
							name: 'Add Ons',
							id: 'addOnServices',
							onClick: () => {
								setActiveComponent('addOnServices')
							},
							width: 'evenly-distributed',
						},
						{
							name: 'Admin Tools',
							id: '5',
							onClick: () => {
								setActiveComponent('adminTools')
							},
							width: 'evenly-distributed',
						},
					],
					selectedOptionId: activeComponent,
					handleUpdateSelectedOption: () => {},
				}}
			>
				{activeComponent === 'personalInfo' && (
					<section id="personalInfo">
						<PersonalInformation currentUser={props.currentUser} userToEdit={mergedUserProps} />
					</section>
				)}

				{activeComponent === 'agentLicenses' && (
					<section id="agentLicenses">
						<AgentLicenses userToEdit={mergedUserProps} currentUser={props.currentUser} />
					</section>
				)}

				{activeComponent === 'billing' && (
					<section id="billing">
						<BillingComponent userToEdit={mergedUserProps} currentUser={props.currentUser} />
						<Subscriptions userToEdit={mergedUserProps} currentUser={props.currentUser} />
					</section>
				)}

				{activeComponent === 'addOnServices' && (
					<section id="addOnServices">
						<AddOnServices userToEdit={mergedUserProps} currentUser={props.currentUser} />
					</section>
				)}

				{activeComponent === 'adminTools' && (
					<section id="adminTools">
						<AdminTools userToEdit={mergedUserProps} currentUser={props.currentUser} />
					</section>
				)}
			</SideSheet>
		</>
	)
}
