import './chip.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ColorService } from '../../services/color/color.service'

interface ChipProps {
	label: string
	color: string
	onClick?: () => void
	className?: string
	onClickRemove?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export function Chip(props: ChipProps) {
	function getStyle(): React.CSSProperties {
		let style: React.CSSProperties = {}
		style.borderColor = props.color

		if (ColorService.getColorTone(props.color) === 'light') {
			style.color = ColorService.adjustHexBrightness(props.color, -100)
		} else {
			style.color = props.color
		}

		return style
	}

	function getClassName(): string {
		let className = 'chip'

		if (props.className) {
			className += ` ${props.className}`
		}
		return className
	}

	return (
		<div className={getClassName()} style={getStyle()}>
			{props.label}
			{props.onClickRemove && (
				<FontAwesomeIcon
					icon={['fas', 'times']}
					className="ml-10 mr-5 chip__remove-btn"
					onClick={(event) => {
						if (props.onClickRemove) {
							props.onClickRemove()
							event.stopPropagation()
						}
					}}
				/>
			)}
		</div>
	)
}
