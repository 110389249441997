import { NavigateFunction, useNavigate } from 'react-router-dom'

export let globalNavigate: NavigateFunction

/** The following is a solution to allow us to use react-router outside of a react component. This enables us to use redirects on requests */
export const GlobalHistory = () => {
	globalNavigate = useNavigate()

	return null
}
