import { AxiosResponse } from 'axios'

import { Registration } from '../../routes/registration/registration.types'
import { httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { GetItemsParams } from '../utility.types'

export interface GetReferralTypeOptionsResponse {
	size: number
	totalElements: number
	totalPages: number
	number: number
	items: Registration.ReferralTypeOption[]
}

export function ReferralTypeAPI() {
	function getAllOptions(
		props: GetItemsParams<Registration.ReferralTypeOption>,
	): Promise<AxiosResponse<GetReferralTypeOptionsResponse>> {
		const headers: { [key: string]: any } = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props })

		const requestPromise = httpRequest.get(`${RequestBaseURL}/api/v1/referralTypes/`, {
			headers,
			params,
		})
		return requestPromise
	}

	function addOption(
		option: Registration.ReferralTypeOption,
	): Promise<AxiosResponse<Registration.ReferralTypeOption>> {
		const headers: { [key: string]: any } = {}
		const requestPromise = httpRequest.post(`${RequestBaseURL}/api/v1/referralTypes/`, option, {
			headers,
		})
		return requestPromise
	}

	function deleteOption(referralOptionId: number): Promise<AxiosResponse<unknown>> {
		const headers: { [key: string]: any } = {}
		const requestPromise = httpRequest.delete(`${RequestBaseURL}/api/v1/referralTypes/${referralOptionId}`, {
			headers,
		})
		return requestPromise
	}

	function patchOption(
		option: Registration.ReferralTypeOption,
	): Promise<AxiosResponse<Registration.ReferralTypeOption>> {
		const headers: { [key: string]: any } = {}
		const requestPromise = httpRequest.patch(
			`${RequestBaseURL}/api/v1/referralTypes/${option.referralTypeId}`,
			option,
			{
				headers,
			},
		)
		return requestPromise
	}

	return { getAllOptions, addOption, deleteOption, patchOption }
}
