import { AgentLicenseCardService } from '@components/agent-license-card/agent-license-card.service'
import { StepperStep } from '@components/stepper/stepper'
import { ToastService } from 'src/services/toast/toast.service'

import { DateService } from '../../services/date.service'
import { LicensedStatesAPI } from '../../services/licensed-states/licensed-states.api'
import { LicensedState, LicensedStateEnums } from '../../services/licensed-states/licenses-states.types'
import { PDFGenerationService } from '../../services/pdf-generation/pdf-generation.service'
import { UserAPI } from '../../services/user/user.api'
import { AgentLicense, EndUserProps } from '../../services/user/user.types'
import { Registration } from './registration.types'
import { RegistrationContractsService } from './routes/contracts/contracts.service'
import { RegistrationAccessadoctorDesc } from './routes/optional-services/components/accessadoctor__desc'
import { RegistrationBackAtYouDesc } from './routes/optional-services/components/back-at-you__desc'
import { RegistrationDotloopDesc } from './routes/optional-services/components/dotloop__desc'
import { RegistrationHomeSearchDesc } from './routes/optional-services/components/home-search__desc'
import { RegistrationKVCoreDesc } from './routes/optional-services/components/kv-core__desc'
import { RegistrationLuxuryDesc } from './routes/optional-services/components/luxury__desc'
import { RegistrationToolkitCmaDesc } from './routes/optional-services/components/toolkit-cma_desc'
import Accessadoctor from './routes/optional-services/images/accessadoctor__card-bkg.jpg'
import BackAtYouCardBg from './routes/optional-services/images/back-at-you__card-bg.jpg'
import DotLoopCardBg from './routes/optional-services/images/dotloop__card-bg.jpg'
import HomeSearchCardBg from './routes/optional-services/images/home-search__card-bg.jpg'
import KvCoreCardBg from './routes/optional-services/images/kvcore__card-bg.jpg'
import LuxuryCardBg from './routes/optional-services/images/luxury__card-bg.jpg'
import { RegistrationState } from './state/registration__state.types'

interface GetCostsResponse {
	initialMonthly: number
	initialQuarterly: number
	complianceFeeQuarterlyTotal: number
	initialYearly: number
	initialTotal: number
	totalDue: number
}

interface GetPriceIdsResponse {
	priceIds: string[]
	futurePriceIds: string[]
}

interface GetInitialPriceIdsResponse {
	initialPriceIds: string[]
}

interface OptionalServiceProps {
	title: string
	description: string
	price: string
	initialCost: number
	initialId: string[]
	recurringCost: number
	recurringPriceId: string[]
	optionalRecurringPriceId: string[]
	subscriptionType: 'one-time' | 'monthly' | 'quarterly' | 'yearly'
	imageUrl: string
	modalContents: React.ReactNode
	id: Registration.OptionalServices | `LUXURY`
	showCard: boolean
	productId: string
}

export function RegistrationService() {
	function getRegistrationSteps(): StepperStep<string>[] {
		const registrationSteps: StepperStep<string>[] = [
			{
				label: 'Personal Info',
				id: 'PERSONAL_INFO',
				index: 0,
			},
			{
				label: 'Agent Info',
				id: 'AGENT_INFO',
				index: 1,
			},
			{
				label: 'Agreements',
				id: 'AGREEMENTS',
				index: 2,
			},
			// {
			//     label: "Optional Services",
			//     index: 3,
			// },
			{
				label: 'Payment',
				id: 'PAYMENT',
				index: 3,
			},
		]
		return registrationSteps
	}

	function getLicensePlans(): RegistrationState.DisplayedLicensedState[] {
		const plans: RegistrationState.DisplayedLicensedState[] = []

		plans.push({
			licenseEnum: 'FLBLUE',
			label: 'Florida Blue Plan',
			price: '$99/month',
			recurringCost: 99,
			intialCost: 0,
			state: 'FL',
			plan: 'BLUE',
			recurringPriceId: ['price.florida.blue.plan'],
			subscriptionType: 'monthly',
			complianceFeeTitle: 'Quarterly Compliance Fee',
			complianceFeeDescription: 'Fee regarding Silver/Blue plans',
			complianceFeePrice: '$50/quarter',
			complianceFeeRecurringCost: 50,
			complianceFeeRecurringPriceId: ['price.florida.blue.plan.compliance.fee'],
			// complianceFeeRecurringPriceId: [''],
			complianceFeeSubscriptionType: 'quarterly',
		})

		plans.push({
			licenseEnum: 'FLSILVER',
			label: 'Florida Silver Plan',
			price: '$99/year',
			recurringCost: 99,
			intialCost: 0,
			state: 'FL',
			plan: 'SILVER',
			recurringPriceId: ['price.florida.silver.plan'],
			subscriptionType: 'yearly',
			complianceFeeTitle: 'Quarterly Compliance Fee',
			complianceFeeDescription: 'Fee regarding Silver/Blue plans',
			complianceFeePrice: '$50/quarter',
			complianceFeeRecurringCost: 50,
			complianceFeeRecurringPriceId: ['price.florida.silver.plan.compliance.fee'],
			// complianceFeeRecurringPriceId: [''],
			complianceFeeSubscriptionType: 'quarterly',
		})

		plans.push({
			licenseEnum: 'GABLUE',
			label: 'Georgia Blue Plan',
			price: '$99/month',
			recurringCost: 99,
			intialCost: 0,
			state: 'GA',
			plan: 'BLUE',
			recurringPriceId: ['price.georgia.blue.plan'],
			subscriptionType: 'monthly',
			complianceFeeTitle: 'Quarterly Compliance Fee',
			complianceFeeDescription: 'Fee regarding Silver/Blue plans',
			complianceFeePrice: '$35/quarter',
			complianceFeeRecurringCost: 35,
			complianceFeeRecurringPriceId: ['price.georgia.blue.plan.compliance.fee'],
			// complianceFeeRecurringPriceId: [''],
			complianceFeeSubscriptionType: 'quarterly',
		})

		plans.push({
			licenseEnum: 'GASILVER',
			label: 'Georgia Silver Plan',
			price: '$99/year',
			recurringCost: 99,
			intialCost: 0,
			state: 'GA',
			plan: 'SILVER',
			recurringPriceId: ['price.georgia.silver.plan'],
			subscriptionType: 'yearly',
			complianceFeeTitle: 'Quarterly Compliance Fee',
			complianceFeeDescription: 'Fee regarding Silver/Blue plans',
			complianceFeePrice: '$35/quarter',
			complianceFeeRecurringCost: 35,
			complianceFeeRecurringPriceId: ['price.georgia.silver.plan.compliance.fee'],
			// complianceFeeRecurringPriceId: [''],
			complianceFeeSubscriptionType: 'quarterly',
		})

		plans.push({
			licenseEnum: 'COBLUE',
			label: 'Colorado Blue Plan',
			price: '$99/month',
			recurringCost: 99,
			intialCost: 0,
			state: 'CO',
			plan: 'BLUE',
			recurringPriceId: ['price.colorado.blue.plan'],
			subscriptionType: 'monthly',
			complianceFeeTitle: 'Quarterly Compliance Fee',
			complianceFeeDescription: 'Fee regarding Silver/Blue plans',
			complianceFeePrice: '$0/quarter',
			complianceFeeRecurringCost: 0,
			complianceFeeRecurringPriceId: ['price.colorado.blue.plan.compliance.fee'],
			// complianceFeeRecurringPriceId: [''],
			complianceFeeSubscriptionType: 'quarterly',
		})

		plans.push({
			licenseEnum: 'COSILVER',
			label: 'Colorado Silver Plan',
			price: '$99/year',
			recurringCost: 99,
			intialCost: 0,
			state: 'CO',
			plan: 'SILVER',
			recurringPriceId: ['price.colorado.silver.plan'],
			subscriptionType: 'yearly',
			complianceFeeTitle: 'Quarterly Compliance Fee',
			complianceFeeDescription: 'Fee regarding Silver/Blue plans',
			complianceFeePrice: '$0/quarter',
			complianceFeeRecurringCost: 0,
			complianceFeeRecurringPriceId: ['price.colorado.silver.plan.compliance.fee'],
			// complianceFeeRecurringPriceId: [''],
			complianceFeeSubscriptionType: 'quarterly',
		})

		plans.push({
			licenseEnum: 'ALBLUE',
			label: 'Alabama Blue Plan',
			price: '$99/month',
			recurringCost: 99,
			intialCost: 0,
			state: 'AL',
			plan: 'BLUE',
			recurringPriceId: ['price.alabama.blue.plan'],
			subscriptionType: 'monthly',
			complianceFeeTitle: 'Quarterly Compliance Fee',
			complianceFeeDescription: 'Fee regarding Silver/Blue plans',
			complianceFeePrice: '$35/quarter',
			complianceFeeRecurringCost: 35,
			complianceFeeRecurringPriceId: ['price.alabama.blue.plan.compliance.fee'],
			// complianceFeeRecurringPriceId: [''],
			complianceFeeSubscriptionType: 'quarterly',
		})

		plans.push({
			licenseEnum: 'ALSILVER',
			label: 'Alabama Silver Plan',
			price: '$99/year',
			recurringCost: 99,
			intialCost: 0,
			state: 'AL',
			plan: 'SILVER',
			recurringPriceId: ['price.alabama.silver.plan'],
			subscriptionType: 'yearly',
			complianceFeeTitle: 'Quarterly Compliance Fee',
			complianceFeeDescription: 'Fee regarding Silver/Blue plans',
			complianceFeePrice: '$35/quarter',
			complianceFeeRecurringCost: 35,
			complianceFeeRecurringPriceId: ['price.alabama.silver.plan.compliance.fee'],
			// complianceFeeRecurringPriceId: [''],
			complianceFeeSubscriptionType: 'quarterly',
		})

		plans.push({
			licenseEnum: 'TXBLUE',
			label: 'Texas Blue Plan',
			price: '$99/month',
			recurringCost: 99,
			intialCost: 0,
			state: 'TX',
			plan: 'BLUE',
			recurringPriceId: ['price.texas.blue.plan'],
			subscriptionType: 'monthly',
			complianceFeeTitle: 'Quarterly Compliance Fee',
			complianceFeeDescription: 'Fee regarding Silver/Blue plans',
			complianceFeePrice: '$50/quarter',
			complianceFeeRecurringCost: 50,
			complianceFeeRecurringPriceId: ['price.texas.blue.plan.compliance.fee'],
			// complianceFeeRecurringPriceId: [''],
			complianceFeeSubscriptionType: 'quarterly',
		})

		plans.push({
			licenseEnum: 'TXSILVER',
			label: 'Texas Silver Plan',
			price: '$99/year',
			recurringCost: 99,
			intialCost: 0,
			state: 'TX',
			plan: 'SILVER',
			recurringPriceId: ['price.texas.silver.plan'],
			subscriptionType: 'yearly',
			complianceFeeTitle: 'Quarterly Compliance Fee',
			complianceFeeDescription: 'Fee regarding Silver/Blue plans',
			complianceFeePrice: '$50/quarter',
			complianceFeeRecurringCost: 50,
			complianceFeeRecurringPriceId: ['price.texas.silver.plan.compliance.fee'],
			// complianceFeeRecurringPriceId: [''],
			complianceFeeSubscriptionType: 'quarterly',
		})

		plans.push({
			licenseEnum: 'SCBLUE',
			label: 'South Carolina Blue Plan',
			price: '$99/month',
			recurringCost: 99,
			intialCost: 0,
			state: 'SC',
			plan: 'BLUE',
			recurringPriceId: ['price.south.carolina.blue.plan'],
			subscriptionType: 'monthly',
			complianceFeeTitle: 'Quarterly Compliance Fee',
			complianceFeeDescription: 'Fee regarding Silver/Blue plans',
			complianceFeePrice: '$35/quarter',
			complianceFeeRecurringCost: 35,
			complianceFeeRecurringPriceId: ['price.south.carolina.blue.plan.compliance.fee'],
			// complianceFeeRecurringPriceId: [''],
			complianceFeeSubscriptionType: 'quarterly',
		})

		plans.push({
			licenseEnum: 'SCSILVER',
			label: 'South Carolina Silver Plan',
			price: '$99/year',
			recurringCost: 99,
			intialCost: 0,
			state: 'SC',
			plan: 'SILVER',
			recurringPriceId: ['price.south.carolina.silver.plan'],
			subscriptionType: 'yearly',
			complianceFeeTitle: 'Quarterly Compliance Fee',
			complianceFeeDescription: 'Fee regarding Silver/Blue plans',
			complianceFeePrice: '$35/quarter',
			complianceFeeRecurringCost: 35,
			complianceFeeRecurringPriceId: ['price.south.carolina.silver.plan.compliance.fee'],
			// complianceFeeRecurringPriceId: [''],
			complianceFeeSubscriptionType: 'quarterly',
		})

		return plans
	}

	function findLicensePlans(
		state: LicensedStateEnums,
		plan: AgentLicense.PlanType,
	): RegistrationState.DisplayedLicensedState | undefined {
		return getLicensePlans().find((licensePlan) => {
			return licensePlan.state === state && licensePlan.plan === plan
		})
	}

	function getServices(): OptionalServiceProps[] {
		const services: OptionalServiceProps[] = []

		services.push({
			title: 'LoKation Luxury®',
			description: 'Specialized resources tailored to meet the demands of high-end clientele',
			id: 'LUXURY',
			price: 'Pricing options available',
			initialCost: 0,
			initialId: [],
			recurringCost: 50,
			recurringPriceId: [],
			optionalRecurringPriceId: [],
			subscriptionType: 'monthly',
			modalContents: <RegistrationLuxuryDesc />,
			imageUrl: LuxuryCardBg,
			showCard: true,
			productId: 'lokationLuxuryParentProductId',
		})

		services.push({
			title: 'LoKation Luxury® - Annual',
			description: '',
			id: 'LUXURY_NONMEMBER_ANNUAL',
			price: '$1350/year',
			initialCost: 0,
			initialId: [],
			recurringCost: 1350,
			recurringPriceId: ['price.lokation.luxury.annual'],
			optionalRecurringPriceId: [],
			subscriptionType: 'yearly',
			modalContents: <RegistrationLuxuryDesc />,
			imageUrl: LuxuryCardBg,
			showCard: false,
			productId: 'product.lokation.luxury.annual',
		})

		services.push({
			title: 'LoKation Luxury® - Monthly',
			description: '',
			id: 'LUXURY_NONMEMBER_MONTHLY',
			price: '$125/month',
			initialCost: 0,
			initialId: [],
			recurringCost: 125,
			recurringPriceId: ['price.lokation.luxury.monthly'],
			optionalRecurringPriceId: [],
			subscriptionType: 'monthly',
			modalContents: <RegistrationLuxuryDesc />,
			imageUrl: LuxuryCardBg,
			showCard: false,
			productId: 'product.lokation.luxury.monthly',
		})

		services.push({
			title: 'LoKation Luxury® — Additional Team Member, Annual',
			description: '',
			id: 'LUXURY_MEMBER_ANNUAL',
			price: '$550/year',
			initialCost: 0,
			initialId: [],
			recurringCost: 500,
			recurringPriceId: ['price.lokation.luxury.additional.member.annual'],
			optionalRecurringPriceId: [],
			subscriptionType: 'yearly',
			modalContents: <RegistrationLuxuryDesc />,
			imageUrl: LuxuryCardBg,
			showCard: false,
			productId: 'product.lokation.luxury.additional.member.annual',
		})

		services.push({
			title: 'LoKation Luxury® — Additional Team Member, Monthly',
			description: '',
			id: 'LUXURY_MEMBER_MONTHLY',
			price: '$50/month',
			initialCost: 0,
			initialId: [],
			recurringCost: 50,
			modalContents: <RegistrationLuxuryDesc />,
			imageUrl: LuxuryCardBg,
			showCard: false,
			recurringPriceId: ['price.lokation.luxury.additional.member.monthly'],
			optionalRecurringPriceId: [],
			subscriptionType: 'monthly',
			productId: 'product.lokation.luxury.additional.member.monthly',
		})

		services.push({
			title: 'kvCore',
			description: 'Power your entire brokerage with next generation technology your agents will use and love...',
			id: 'KV_CORE',
			price: '30 day free trial ($99 setup & $50/month)',
			initialCost: 0,
			initialId: [''],
			recurringCost: 0,
			recurringPriceId: ['price.kvcore.monthly.trial'],
			optionalRecurringPriceId: [],
			subscriptionType: 'monthly',
			modalContents: <RegistrationKVCoreDesc />,
			imageUrl: KvCoreCardBg,
			showCard: true,
			productId: 'product.kvcore.trial',
		})

		services.push({
			title: 'Dotloop',
			description: 'Complete Real Estate Transaction Management Software for Brokers',
			id: 'DOTLOOP',
			price: '$20/month',
			initialCost: 0,
			initialId: [],
			recurringCost: 20,
			recurringPriceId: ['price.dotloop.monthly'],
			optionalRecurringPriceId: [],
			subscriptionType: 'monthly',
			modalContents: <RegistrationDotloopDesc />,
			imageUrl: DotLoopCardBg,
			showCard: true,
			productId: 'product.dotloop',
		})

		services.push({
			title: 'HomeSearch',
			description: 'Search, save and set notifications for real estate listings',
			id: 'HOME_SEARCH',
			price: '$15/month',
			initialCost: 0,
			initialId: [],
			recurringCost: 15,
			recurringPriceId: ['price.homesearch.monthly'],
			optionalRecurringPriceId: [],
			subscriptionType: 'monthly',
			modalContents: <RegistrationHomeSearchDesc />,
			imageUrl: HomeSearchCardBg,
			showCard: true,
			productId: 'product.homesearch',
		})

		services.push({
			title: 'Back at You!',
			description: 'Automated marketing that saves time and keeps you top-of-mind',
			id: 'BACK_AT_YOU',
			price: '$35/month',
			initialCost: 0,
			initialId: [],
			recurringCost: 35,
			recurringPriceId: ['price.backatyou.monthly'],
			optionalRecurringPriceId: [],
			subscriptionType: 'monthly',
			modalContents: <RegistrationBackAtYouDesc />,
			imageUrl: BackAtYouCardBg,
			showCard: true,
			productId: 'product.backatyou',
		})

		services.push({
			title: 'Access a Doctor',
			description: 'Access a Doctor by MyTelemedicine is a convenient and affordable solution.',
			id: 'ACCESSADOCTOR',
			price: '$12.95/month',
			initialCost: 0,
			initialId: [],
			recurringCost: 12.95,
			recurringPriceId: ['price.accessadoctor.monthly'],
			optionalRecurringPriceId: [],
			subscriptionType: 'monthly',
			modalContents: <RegistrationAccessadoctorDesc />,
			imageUrl: Accessadoctor,
			showCard: true,
			productId: 'product.accessadoctor',
		})

		services.push({
			title: 'Toolkit CMA',
			description:
				'Distinctive, branded, CMA Presentations from any deviceToolkitCMA is cloud-based Real Estate CMA Software that provides residential real estate professionals.',
			id: 'TOOLKIT_CMA',
			price: '$20/month',
			initialCost: 0,
			initialId: [],
			recurringCost: 20,
			recurringPriceId: ['price.toolkitcma.monthly'],
			optionalRecurringPriceId: [],
			subscriptionType: 'monthly',
			modalContents: <RegistrationToolkitCmaDesc />,
			imageUrl: Accessadoctor,
			showCard: true,
			productId: 'product.toolkitcma',
		})

		return services
	}

	interface CostsSummary {
		optionalServicesCosts: GetCostsResponse
		licensePlansCosts: GetCostsResponse
		grandTotalCosts: GetCostsResponse
	}

	function getCosts(formState: Registration.Form): CostsSummary {
		const optionalServicesCosts: GetCostsResponse = {
			initialMonthly: 0,
			initialQuarterly: 0,
			complianceFeeQuarterlyTotal: 0,
			initialYearly: 0,
			initialTotal: 0,
			totalDue: 0,
		}

		// Aggregate the costs of all selected services
		formState.optionalServices.forEach((service) => {
			const serviceDetails = RegistrationService()
				.getServices()
				.find((s) => s.id === service)
			if (serviceDetails) {
				const cost = serviceDetails.initialCost
				const recurringCost = serviceDetails.recurringCost
				const subscriptionType = serviceDetails.subscriptionType

				switch (subscriptionType) {
					case 'monthly':
						optionalServicesCosts.initialMonthly += recurringCost
						break
					case 'quarterly':
						optionalServicesCosts.initialQuarterly += recurringCost
						break
					case 'yearly':
						optionalServicesCosts.initialYearly += recurringCost
						break
					// case 'one-time':
					//   optionalServicesCosts.initialOneTime += cost;
					//   break;
				}

				optionalServicesCosts.initialTotal += cost
			}
		})

		optionalServicesCosts.totalDue =
			optionalServicesCosts.initialTotal +
			optionalServicesCosts.initialMonthly +
			optionalServicesCosts.initialQuarterly +
			optionalServicesCosts.initialYearly

		const licensePlansCosts: GetCostsResponse = {
			initialMonthly: 0,
			initialQuarterly: 0,
			complianceFeeQuarterlyTotal: 0,
			initialYearly: 0,
			initialTotal: 0,
			totalDue: 0,
		}

		// Aggregate the costs of all selected plans
		formState.licenseInfo.forEach((plan) => {
			const licensePlan = RegistrationService()
				.getLicensePlans()
				.find((s) => s.plan === plan.plan)

			if (licensePlan) {
				const cost = licensePlan.intialCost
				const recurringCost = licensePlan.recurringCost
				// const feeCost = licensePlan.complianceFeeRecurringCost
				const subscriptionType = licensePlan.subscriptionType
				// const feeSubscriptionType = licensePlan.complianceFeeSubscriptionType;

				switch (subscriptionType) {
					case 'monthly':
						licensePlansCosts.initialMonthly += recurringCost
						break
					case 'quarterly':
						licensePlansCosts.initialQuarterly += recurringCost
						break
					case 'yearly':
						licensePlansCosts.initialYearly += recurringCost
						break
					// case 'one-time':
					//   licensePlansCosts.initialOneTime += cost;
					//   break;
				}
				// Add the quarterly compliance fee to the Quarterly cost
				// switch (feeSubscriptionType) {
				//     // case 'monthly':
				//     //     licensePlansCosts.initialMonthly += feeCost;
				//     //     break;
				//     case 'quarterly':
				//         licensePlansCosts.complianceFeeQuarterlyTotal += feeCost;
				//         console.log('Quarterly fee ', licensePlansCosts.complianceFeeQuarterlyTotal)
				//         break;
				//     // case 'yearly':
				//     //     licensePlansCosts.initialYearly += feeCost;
				//     //     break;
				//     // case 'one-time':
				//     //   licensePlansCosts.initialOneTime += cost;
				//     //   break;
				// }

				licensePlansCosts.initialTotal += cost
			}
		})

		licensePlansCosts.totalDue =
			licensePlansCosts.initialTotal +
			licensePlansCosts.initialMonthly +
			licensePlansCosts.initialQuarterly +
			licensePlansCosts.initialYearly

		formState.licenseInfo.forEach((addedLicense) => {
			if (!addedLicense.licensedState) {
				return
			}

			const complianceFeeRecurringCost = RegistrationService()
				.getLicensePlans()
				.find(
					(licensePlan) =>
						licensePlan.plan === addedLicense.plan &&
						licensePlan.state === addedLicense.licensedState?.abbreviation,
				)?.complianceFeeRecurringCost

			if (complianceFeeRecurringCost) {
				licensePlansCosts.complianceFeeQuarterlyTotal += complianceFeeRecurringCost
			}
		})

		// Grand total costs
		const grandTotalCosts: GetCostsResponse = {
			initialMonthly: optionalServicesCosts.initialMonthly + licensePlansCosts.initialMonthly,
			initialQuarterly: optionalServicesCosts.initialQuarterly + licensePlansCosts.initialQuarterly,
			complianceFeeQuarterlyTotal: licensePlansCosts.complianceFeeQuarterlyTotal,
			initialYearly: optionalServicesCosts.initialYearly + licensePlansCosts.initialYearly,
			initialTotal: optionalServicesCosts.initialTotal + licensePlansCosts.initialTotal,
			totalDue: optionalServicesCosts.totalDue + licensePlansCosts.totalDue,
		}

		return {
			optionalServicesCosts,
			licensePlansCosts,
			grandTotalCosts,
		}
	}

	function getPriceIds(formState: Registration.Form): GetPriceIdsResponse {
		const allPriceIds: GetPriceIdsResponse = {
			priceIds: [],
			futurePriceIds: [],
		}

		formState.optionalServices.forEach((selectedService) => {
			const priceId = RegistrationService()
				.getServices()
				.find((service) => service.id === selectedService)?.recurringPriceId
			if (priceId) {
				allPriceIds.priceIds.push(...priceId)
			}
		})

		formState.licenseInfo.forEach((addedLicense) => {
			if (!addedLicense.licensedState) {
				return
			}

			const planPriceId = RegistrationService()
				.getLicensePlans()
				.find(
					(licensePlan) =>
						licensePlan.plan === addedLicense.plan &&
						licensePlan.state === addedLicense.licensedState?.abbreviation,
				)?.recurringPriceId

			const complianceFeePriceId = RegistrationService()
				.getLicensePlans()
				.find(
					(licensePlan) =>
						licensePlan.plan === addedLicense.plan &&
						licensePlan.state === addedLicense.licensedState?.abbreviation,
				)?.complianceFeeRecurringPriceId

			if (planPriceId) {
				allPriceIds.priceIds.push(...planPriceId)
			}
			if (complianceFeePriceId) {
				allPriceIds.futurePriceIds.push(...complianceFeePriceId)
			}
		})

		// console.log('All Price IDs:', allPriceIds);
		return allPriceIds
	}

	function getInitialPriceIds(formState: Registration.Form): GetInitialPriceIdsResponse {
		const allInitialPriceIds: GetInitialPriceIdsResponse = {
			initialPriceIds: [],
		}
		formState.optionalServices.forEach((selectedService) => {
			const initialPriceId = RegistrationService()
				.getServices()
				.find((service) => service.id === selectedService)?.initialId
			if (initialPriceId) {
				allInitialPriceIds.initialPriceIds.push(...initialPriceId)
			}
		})
		// console.log('All Initial Price IDs:', allInitialPriceIds);
		return allInitialPriceIds
	}

	function createNewLicenseProps(
		licensedStates: LicensedState[],
		stateAbbreviation?: string,
		plan?: AgentLicense.PlanType,
	): Promise<AgentLicense.Entry> {
		return new Promise((createLicenseResolve) => {
			/** If no state abbreviation is passed in, just add an empty license */
			if (!stateAbbreviation) {
				const newLicense = AgentLicenseCardService().createNewLicenseProps({ plan: plan })
				createLicenseResolve(newLicense)
			}

			/** Add an agent license with a state attached to it */
			const getLicensedStates = new Promise<LicensedState[]>((resolve) => {
				if (licensedStates.length > 0) {
					resolve(licensedStates)
				} else {
					LicensedStatesAPI.getLicensedStates().then((res) => {
						resolve(res.data)
					})
				}
			})

			getLicensedStates.then((states) => {
				const matchingState = states.find(
					(thisState) =>
						thisState.abbreviation.toLocaleLowerCase() === stateAbbreviation?.toLocaleLowerCase(),
				)

				if (matchingState) {
					const newLicense = AgentLicenseCardService().createNewLicenseProps({
						licensedState: matchingState,
						plan: plan,
					})
					createLicenseResolve(newLicense)
				} else {
					console.warn(`Could not add license. State '${stateAbbreviation}' could not be found`)
				}
			})
		})
	}

	function submitRegistration(fields: Registration.Form, profilePhoto: string | null): Promise<EndUserProps> {
		if (!fields.address2) {
			fields.address2 = null
		}
		return new Promise((resolve, reject) => {
			const mappedForm = mapFormForRegistrationRequest(fields)

			UserAPI.registerUser(mappedForm, profilePhoto)
				.then((res) => {
					resolve(res.data)
					console.info(res)
				})
				.catch((err) => {
					reject()
					console.info(err)
				})
		})
	}

	function base64_arraybuffer(data: Uint8Array): Promise<string> {
		return new Promise((resolve) => {
			const reader = new FileReader()
			reader.onload = () => {
				if (typeof reader.result === 'string') {
					//const base64 = reader.result.substring(reader.result.indexOf(',') + 1)
					resolve(reader.result)
				}
			}
			reader.readAsDataURL(new Blob([data]))
		})
	}

	function attachContractToLicense(fields: Registration.Form, signature: string): Promise<void> {
		function generateContract(abbreviation: LicensedStateEnums, plan: AgentLicense.PlanType): Promise<string> {
			return new Promise((resolve) => {
				fetch(signature).then((res) => {
					res.blob().then((signatureBlob) => {
						if (!signatureBlob) {
							ToastService().create({
								type: 'ERROR',
								body: `Contract could not be generated`,
							})
							return
						}

						const userName = `${fields.firstName} ${fields.lastName}`
						const todaysDate = DateService.getCurrentDateString(0)

						const template = RegistrationContractsService().getContractTemplate(
							abbreviation,
							plan,
							userName,
							todaysDate,
							signatureBlob,
						)

						if (!template) {
							ToastService().create({ type: 'ERROR', body: `Could not generate PDF for contract` })
							return
						}

						PDFGenerationService()
							.modifyPDF(template.basePDF, template.additions)
							.then((uintArray) => {
								base64_arraybuffer(uintArray).then((res) => {
									resolve(res)
								})
							})
							.catch((err) => {
								ToastService().create({
									type: 'ERROR',
									body: `Contract could not be generated`,
								})
							})
					})
				})
			})
		}

		return new Promise((resolve, reject) => {
			const contractGenPromises: Promise<unknown>[] = []

			fields.licenseInfo.forEach((license) => {
				if (!license.licensedState) {
					reject()
					return
				}

				if (!license.plan) {
					reject()
					return
				}

				const newPromise = generateContract(license.licensedState.abbreviation, license.plan).then(
					(contractBase64) => {
						license.licenseAgreement = contractBase64
					},
				)
				contractGenPromises.push(newPromise)
			})

			Promise.all(contractGenPromises).then(() => {
				console.info(fields)
				resolve()
			})
		})
	}

	function mapFormForRegistrationRequest(form: Registration.Form): Registration.FormForRegistrationRequest {
		const mappedForm: Registration.FormForRegistrationRequest = {
			licenseInfo: [],
			address1: form.address1,
			address2: form.address2,
			bio: form.bio,
			birthDate: form.birthDate,
			businessName: form.businessName,
			city: form.city,
			email: form.email,
			endUserId: form.endUserId,
			expDate: form.expDate,
			facebookUsername: form.facebookUsername,
			firstName: form.firstName,
			instagramUsername: form.instagramUsername,
			lastName: form.lastName,
			linkedInUsername: form.linkedInUsername,
			mlsBoard: form.mlsBoard,
			notificationsEmail: form.notificationsEmail,
			notificationsText: form.notificationsText,
			numYearsLicensed: form.numYearsLicensed,
			optionalServices: form.optionalServices,
			password: form.password,
			phone: form.phone,
			profilePicture: form.profilePicture,
			referralAdditionalInformation: form.referralAdditionalInformation,
			referralTypeId: form.referralTypeId,
			repeatPassword: form.repeatPassword,
			state: form.state,
			twitterUsername: form.twitterUsername,
			zip: form.zip,
			middleName: form.middleName,
			referrerId: form.referrerId,
		}

		form.licenseInfo.forEach((license) => {
			if (license.licenseAgreement && license.licensedState && license.plan) {
				mappedForm.licenseInfo.push({
					licenseAgreement: license.licenseAgreement,
					licenseNumber: license.licenseNumber,
					licensedStateId: license.licensedState.licensedStateId,
					plan: license.plan,
					regionId: license.region?.regionId,
				})
			}
		})

		return mappedForm
	}

	return {
		getCosts,
		getServices,
		getPriceIds,
		getInitialPriceIds,
		createNewLicenseProps,
		getRegistrationSteps,
		submitRegistration,
		getLicensePlans,
		findLicensePlans,
		attachContractToLicense,
	}
}
