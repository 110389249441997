import './footer.scss'

import { LoKationButton } from '@components/button/button'
import { Stepper, StepperStep } from '@components/stepper/stepper'

import { UserOnboardingModalTypes } from '../../new-user-onboarding.types'
import { useNewUserOnboarding, useNewUserOnboardingDispatch } from '../../state/new-user-onboarding__state'
import { NewUserOnboardingState } from '../../state/new-user-onboarding__state.types'

interface NUOFooterProps {
	routes: UserOnboardingModalTypes.Route[]
	onClose: () => void
}

export function NUOFooter(props: NUOFooterProps) {
	const NUOState = useNewUserOnboarding()
	const NUODispatch = useNewUserOnboardingDispatch()
	const stepperSteps = getStepperSteps()

	const currentRouteIndex = getCurrentRouteIndex()
	const lastRoute = stepperSteps[stepperSteps.length - 1]

	/** ======================================== */
	/** Methods */

	function getStepperSteps(): StepperStep<NewUserOnboardingState.Routes>[] {
		const steps: StepperStep<NewUserOnboardingState.Routes>[] = []

		props.routes.forEach((route) => {
			switch (route.id) {
				case 'TASKS':
				case 'WELCOME':
				case 'QUESTION_0':
				case 'QUESTION_1':
				case 'QUESTION_2':
				case 'QUESTION_3':
				case 'QUESTION_4':
				case 'QUESTION_5':
					steps.push({
						index: route.index,
						label: route.title,
						id: route.id,
						onClick: () => {
							NUODispatch([{ type: 'set-route', payload: route.id }])
						},
					})
					break
			}
		})

		return steps
	}

	function getCurrentRouteIndex(): number {
		const currentRoute = stepperSteps.find((step) => step.id === NUOState.route)
		if (currentRoute) {
			return currentRoute.index
		}
		return 0
	}

	function getPreviousRoute(): NewUserOnboardingState.Routes | undefined {
		const previousRoute = stepperSteps.find((step) => step.index === currentRouteIndex - 1)
		if (previousRoute) {
			return previousRoute.id
		}
		return undefined
	}

	function getNextRoute(): NewUserOnboardingState.Routes | undefined {
		const nextRoute = stepperSteps.find((step) => step.index === currentRouteIndex + 1)
		if (nextRoute) {
			return nextRoute.id
		}
		return undefined
	}

	/** ======================================== */
	/** Render Component */

	/** Footer for the last route */
	if (NUOState.route === 'TASKS') {
		return (
			<div className="new-user-onboarding__footer">
				<LoKationButton
					variant="contained"
					label="Remind Me Later"
					size="sm"
					onClick={() => {
						return new Promise((resolve) => {
							resolve()
							props.onClose()
						})
					}}
				/>
			</div>
		)
	}

	/** Footer for all other routes */
	return (
		<div className="new-user-onboarding__footer">
			<div style={{ width: '200px' }}>
				{currentRouteIndex > 0 && (
					<LoKationButton
						variant="text"
						label="Back"
						size="sm"
						onClick={() => {
							return new Promise((resolve) => {
								resolve()
								const previousRoute = getPreviousRoute()
								if (previousRoute) {
									NUODispatch([{ type: 'set-route', payload: previousRoute }])
								}
							})
						}}
					/>
				)}
			</div>
			<Stepper<NewUserOnboardingState.Routes>
				variant={'dots'}
				steps={stepperSteps}
				activeStep={currentRouteIndex}
			/>
			<div style={{ width: '200px' }} className="flex flex-justifyContent-end">
				{currentRouteIndex < lastRoute.index && (
					<LoKationButton
						variant="contained"
						label="Next"
						size="sm"
						onClick={() => {
							return new Promise((resolve) => {
								resolve()
								const nextRoute = getNextRoute()
								if (nextRoute) {
									NUODispatch([{ type: 'set-route', payload: nextRoute }])
								}
							})
						}}
					/>
				)}
			</div>
		</div>
	)
}
