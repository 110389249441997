import './stepper.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export interface StepperProps<T> {
	steps: StepperStep<T>[]
	activeStep: number
	className?: string
	variant: 'dots' | 'buttons'
}

export interface StepperStep<T> {
	index: number
	label: string
	id: T
	onClick?: () => void
}

export function Stepper<T>(props: StepperProps<T>) {
	function createStep(step: StepperStep<T>): JSX.Element {
		if (props.variant === 'buttons') {
			return (
				<div className={`stepper__step variant__button ${props.activeStep === step.index ? 'active' : ''}`}>
					{step.label}
				</div>
			)
		}
		if (props.variant === 'dots') {
			return (
				<div
					title={step.label}
					className={`stepper__step variant__dots ${props.activeStep === step.index ? 'active' : ''}`}
				></div>
			)
		}
		return <>ERROR</>
	}

	return (
		<div className={`flex flex-alignItems-center ${props.className ? props.className : ''}`}>
			{props.steps.map((step, stepIndex) => {
				return (
					<React.Fragment key={step.index}>
						{createStep(step)}
						{props.variant === 'buttons' && stepIndex < props.steps.length - 1 && (
							<FontAwesomeIcon icon={['far', 'caret-right']} className="mx-10" />
						)}
					</React.Fragment>
				)
			})}
		</div>
	)
}
