import './caret.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface CaretProps {
	state: 'opened' | 'closed'
}

export function Caret(props: CaretProps) {
	return <FontAwesomeIcon icon={['far', 'angle-right']} className={`caret ${props.state}`} />
}
