import { AxiosResponse } from 'axios'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { useDebounce } from '../../services/hooks/use-debounce'
import { SortParam } from '../../services/utility.types'

interface useItemAdminHookProps<T, K> {
	getItemsFunction: (
		page: number,
		size: number,
		sort: SortParam<T>[],
		filterProps: K,
	) => Promise<AxiosResponse<{ items: T[]; totalElements: number }>>
	itemIdKey: keyof T
	itemTitleKey: keyof T
	setIsPending: (isPending: boolean) => void
	searchParams: K
	pageSize?: number
	isItemShownInList?: (item: T) => boolean
	sortParams?: SortParam<T>[]
}

/** Hook maintains the state and functions needed to lazy load items of any type, and the ability to CRUD them. T = Item properties, K = Item search parameters */
export function useItemAdminHook<T, K>(props: useItemAdminHookProps<T, K>) {
	const PAGE_SIZE = props.pageSize ? props.pageSize : 20

	/** FAQs State */
	const [items, setItems] = useState<T[]>([])
	const [hasMoreItems, setHasMoreItems] = useState(true)
	const [itemsPage, setItemsPage] = useState<number | null>(null)
	const [searchParams, setSearchParams] = useState<K>(props.searchParams)
	const [totalElements, setTotalElements] = useState<number>(0)

	/** Reset search when search params change */
	useEffect(() => {
		if (_.isEqual(props.searchParams, searchParams) === false) {
			setSearchParams(props.searchParams)
			setItems([])
			setHasMoreItems(true)
			if (itemsPage === null) {
				setItemsPage(0)
			} else {
				setItemsPage(null)
			}
		}
	}, [props.searchParams])

	/** Load items on itemPage change */
	useEffect(() => {
		debounceExecuteItemsSearch(items, itemsPage, searchParams)
	}, [itemsPage])

	const debounceExecuteItemsSearch = useDebounce(executeItemSearch, 250)

	function getSortParams(): SortParam<T>[] {
		if (props.sortParams) {
			return props.sortParams
		}

		return [{ property: props.itemTitleKey, direction: 'asc' }]
	}

	/** Note: The reason I am passing the page and tags as parameters of this function instead of grabbing the values from state is because this function is debounced.
	 * Because it is debounced, the "version" of state it would be reading from would be out of date
	 */
	function executeItemSearch(currentResults: T[], paramPage: number | null, filterProps: K): void {
		props.setIsPending(true)
		const searchPage = typeof paramPage === 'number' ? paramPage : 0

		props.getItemsFunction(searchPage, PAGE_SIZE, getSortParams(), filterProps).then((res) => {
			/** Filter out duplicate ID's before adding to the table. This can happen when a user modifies or creates a item */
			const idsOfItemsInTable = items.map((item) => item[props.itemIdKey])
			const itemsToAdd = res.data.items.filter(
				(itemToAdd) => !idsOfItemsInTable.includes(itemToAdd[props.itemIdKey]),
			)

			const updatedItems = [...currentResults, ...itemsToAdd]
			setItems(updatedItems)
			if (res.data.totalElements) {
				setTotalElements(res.data.totalElements)
			}

			if (res.data.items.length < PAGE_SIZE) {
				setHasMoreItems(false)
			}
			props.setIsPending(false)
		})
	}

	function incrementItemPage(): void {
		if (typeof itemsPage === 'number') {
			setItemsPage(itemsPage + 1)
		} else {
			setItemsPage(0)
		}
	}

	function handleCreateItem(item: T): void {
		let modifiedState = _.cloneDeep(items)
		let showItem = true
		if (props.isItemShownInList && !props.isItemShownInList(item)) {
			showItem = false
		}

		if (showItem) {
			modifiedState.push(item)
		} else {
			modifiedState = modifiedState.filter(
				(originalItem) => originalItem[props.itemIdKey] !== item[props.itemIdKey],
			)
		}

		setItems(modifiedState)
	}

	function handleUpdateItem(item: T): void {
		let modifiedState = _.cloneDeep(items)
		let showItem = true
		if (props.isItemShownInList && !props.isItemShownInList(item)) {
			showItem = false
		}
		if (showItem) {
			const indexOfModifiedItem = modifiedState.findIndex(
				(originalItem) => originalItem[props.itemIdKey] === item[props.itemIdKey],
			)
			if (indexOfModifiedItem > -1) {
				modifiedState[indexOfModifiedItem] = item
			}
		} else {
			modifiedState = modifiedState.filter(
				(originalItem) => originalItem[props.itemIdKey] !== item[props.itemIdKey],
			)
		}

		setItems(modifiedState)
	}

	function handleDeleteItem(item: T): void {
		let modifiedState = _.cloneDeep(items)
		modifiedState = modifiedState.filter((originalItem) => originalItem[props.itemIdKey] !== item[props.itemIdKey])
		setItems(modifiedState)
	}

	function reset(): void {
		setItems([])
		setHasMoreItems(true)
		if (itemsPage === null) {
			setItemsPage(0)
		} else {
			setItemsPage(null)
		}
	}

	return {
		loadNextPageOfItems: incrementItemPage,
		handleCreateItem,
		handleUpdateItem,
		handleDeleteItem,
		reset,
		items,
		hasMoreItems,
		itemsPage,
		totalElements,
	}
}
