import { store } from '@redux/store'
import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { GetItemsParams, GetItemsResponse } from '../utility.types'
import { Calendar } from './calendar.types'

export class CalendarEventAPI {
	static getEvents(props: GetItemsParams<Calendar.Event>): Promise<AxiosResponse<{ items: Calendar.Event[] }>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/calendarevents/`, {
			headers,
			params,
		})
		return requestPromise
	}

	public static findCalendarEvents(
		props: GetItemsParams<Calendar.Event>,
		itemTypeProps: Calendar.EventSearchParams,
	): Promise<AxiosResponse<GetItemsResponse<Calendar.Event>>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props, ...itemTypeProps })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/calendarevents/search`, {
			headers,
			params,
		})
		return requestPromise
	}

	static getEventById(id: number): Promise<AxiosResponse<Calendar.Event | undefined>> {
		const headers = {}
		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/calendarevents/${id}`, {
			headers,
		})
		return requestPromise
	}

	static createEvent(event: Calendar.Event): Promise<AxiosResponse<Calendar.Event>> {
		const userProps = store.getState().user
		if (!userProps) {
			throw new Error(`User not found. Calendar cannot be created`)
		}

		if (event.calendarEventId === -1) {
			//@ts-ignore
			event.calendarEventId = undefined
		}

		const headers = {}
		const requestPromise = httpRequest.post(`${RequestBaseURL}${apiVersionURL}/calendarevents/`, event, {
			headers,
		})
		return requestPromise
	}

	static updateEvent(
		eventId: number,
		updateEventDTO: Partial<Calendar.Event>,
	): Promise<AxiosResponse<Calendar.Event>> {
		// /** Strip out ms from time stamps */
		// if (updateEventDTO.startTime) {
		// 	updateEventDTO.startTime = `${updateEventDTO.startTime.split(':')[0]}:${updateEventDTO.startTime.split(':')[1]}`
		// }
		// if (updateEventDTO.endTime) {
		// 	updateEventDTO.endTime = `${updateEventDTO.endTime.split(':')[0]}:${updateEventDTO.endTime.split(':')[1]}`
		// }

		const userProps = store.getState().user
		if (!userProps) {
			throw new Error(`User not found. Calendar cannot be updated`)
		}

		const headers = {}
		const requestPromise = httpRequest.patch(
			`${RequestBaseURL}${apiVersionURL}/calendarevents/${eventId}`,
			updateEventDTO,
			{
				headers,
			},
		)
		return requestPromise
	}

	static deleteEvent(eventId: number): Promise<AxiosResponse<void>> {
		const headers = {}
		const requestPromise = httpRequest.delete(`${RequestBaseURL}${apiVersionURL}/calendarevents/${eventId}`, {
			headers,
		})
		return requestPromise
	}
}
