import './subroute-navigation.scss'

interface SubrouteNavigationProps {
	children: React.ReactNode
	className?: string
	style?: React.CSSProperties
}

/** A surface to display links to sections or routes within a parent route */
export function SubrouteNavigation(props: SubrouteNavigationProps) {
	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`subroute-navigation`)

		if (props.className) {
			classes.push(props.className)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	return (
		<div className={getClass()} style={props.style}>
			{props.children}
		</div>
	)
}
