import { UIState } from '@redux/reducers/ui-reducer'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AdvertisementAPI } from 'src/services/advertising/advertisement.api'
import { AdvertisementImageAPI } from 'src/services/advertising/advertisement-image.api'
import { AdvertisingTypes } from 'src/services/advertising/advertising.types'
import { AnalyticsService } from 'src/services/analytics/analytics.service'
import { Hub } from 'src/services/hubs/hubs.types'

type ConnectedProps = {
	ui: UIState
}

type ComponentProps = {
	hub: Hub
}

function HubHeaderAdPrototype(props: ComponentProps & ConnectedProps) {
	const [imgUrl, setImgUrl] = useState<string | null>(null)
	const [headerAdToShow, setHeaderAdToShow] = useState<AdvertisingTypes.FindAdsResponse | null>(null)
	const platform: AdvertisingTypes.AdImagePlatform = props.ui.viewMode === 'FULL' ? 'DESKTOP' : 'MOBILE'

	/** Load an ad */
	useEffect(() => {
		const currentHubId = [props.hub.hubId]
		AdvertisementAPI.findAds('HUB_HEADER', currentHubId, platform).then((res) => {
			if (res.data) {
				setHeaderAdToShow(res.data)
			}
		})
	}, [props.hub.hubId, props.ui.viewMode])

	useEffect(() => {
		if (!headerAdToShow || !headerAdToShow.advertisementImage) {
			return
		}

		AdvertisementImageAPI.getAdvertisementImage(
			headerAdToShow.advertisement.advertisementId,
			headerAdToShow.advertisementImage.advertisementImageId,
		).then((res) => {
			setImgUrl(res.data)

			if (headerAdToShow.advertisementImage) {
				AnalyticsService().pushEvent({
					event_category: 'navigation',
					event_label: 'ad_view',
					value: {
						account_id: headerAdToShow.advertisement.accountId,
						ad_id: headerAdToShow.advertisement.advertisementId,
						ad_name: headerAdToShow.advertisement.name,
						ad_image_id: headerAdToShow.advertisementImage.advertisementImageId,
						ad_target_url: headerAdToShow.advertisementImage.link,
						ad_type: 'hub_header',
					},
				})
			}
		})
	}, [headerAdToShow])

	function getImageAspectRatio(): string {
		switch (platform) {
			case 'DESKTOP':
				return `4 / 1`
			case 'MOBILE':
				return `4 / 2`
		}
	}

	if (headerAdToShow === null || headerAdToShow.advertisementImage === null) {
		return <></>
	}

	let url = headerAdToShow.advertisementImage.link

	if (!headerAdToShow.advertisementImage.link.includes(`http`)) {
		url = `http://${headerAdToShow?.advertisementImage.link}`
	}

	return (
		<div
			className="col-xs-12 mb-20"
			style={{
				width: '100%',
				aspectRatio: getImageAspectRatio(),
				backgroundColor: '#dedede',
				overflow: 'hidden',
			}}
		>
			<img
				src={imgUrl ? imgUrl : ''}
				style={{ width: '100%', height: '100%', cursor: 'pointer' }}
				onClick={() => {
					if (headerAdToShow.advertisementImage) {
						AnalyticsService().pushEvent({
							event_category: 'navigation',
							event_label: 'ad_click',
							value: {
								account_id: headerAdToShow.advertisement.accountId,
								ad_id: headerAdToShow.advertisement.advertisementId,
								ad_name: headerAdToShow.advertisement.name,
								ad_image_id: headerAdToShow.advertisementImage.advertisementImageId,
								ad_target_url: headerAdToShow.advertisementImage.link,
								ad_type: 'hub_header',
							},
						})
					}

					window.open(url, '_blank')
				}}
			/>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		ui: state.ui,
	}
}

export const HubHeaderAd = connect(mapStateToProps)(HubHeaderAdPrototype)
