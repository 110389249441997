import { UserOnboardingTypes } from '../../services/user-onboarding/user-onboarding.types'
import { UserOnboardingModalTypes } from './new-user-onboarding.types'
import { NUOPromptRoute } from './routes/prompt/prompt'
import { NUOTasksRoute } from './routes/tasks/tasks'
import { NUOWelcomeRoute } from './routes/welcome/welcome'
import { NewUserOnboardingState } from './state/new-user-onboarding__state.types'

export function NewUserOnboardingService() {
	function getRoutes(
		questions: UserOnboardingTypes.Question[],
		onClose: () => void,
	): UserOnboardingModalTypes.Route[] {
		const routes: UserOnboardingModalTypes.Route[] = []

		routes.push({
			index: 0,
			title: 'Welcome',
			id: 'WELCOME',
			element: <NUOWelcomeRoute />,
		})

		questions.forEach((question) => {
			routes.push({
				index: routes.length,
				title: question.question,
				id: `QUESTION_${question.questionIndex}` as NewUserOnboardingState.Routes,
				element: <NUOPromptRoute field={question} />,
			})
		})

		routes.push({
			index: routes.length,
			title: 'Tasks',
			id: 'TASKS',
			element: <NUOTasksRoute onClose={onClose} />,
		})

		return routes
	}

	return { getRoutes }
}
