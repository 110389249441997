import { store } from '@redux/store'
import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { GetItemsParams, GetItemsResponse } from '../utility.types'
import { Hub, HubSearchParams } from './hubs.types'

class HubsAPIPrototype {
	getAllHubs(props: GetItemsParams<Hub>): Promise<AxiosResponse<GetItemsResponse<Hub>>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/hubs/`, {
			headers,
			params,
		})
		return requestPromise
	}

	findHubs(
		props: GetItemsParams<Hub>,
		itemTypeProps: HubSearchParams,
	): Promise<AxiosResponse<GetItemsResponse<Hub>>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props, ...itemTypeProps })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/hubs/search`, {
			headers,
			params,
		})
		return requestPromise
	}

	createHub(hub: Omit<Hub, 'hubId'>): Promise<AxiosResponse<Hub>> {
		const userProps = store.getState().user
		if (!userProps) {
			throw new Error(`User not found. FAQ cannot be created`)
		}

		const headers = {}
		const requestPromise = httpRequest.post(`${RequestBaseURL}${apiVersionURL}/hubs/`, hub, {
			headers,
		})
		return requestPromise
	}

	updateHub(hubId: number, updateHubDTO: Partial<Hub>): Promise<AxiosResponse<Hub>> {
		const userProps = store.getState().user
		if (!userProps) {
			throw new Error(`User not found. Tag cannot be updated`)
		}

		const headers = {}
		const requestPromise = httpRequest.patch(`${RequestBaseURL}${apiVersionURL}/hubs/${hubId}`, updateHubDTO, {
			headers,
		})
		return requestPromise
	}

	deleteHub(hubId: number): Promise<AxiosResponse<number>> {
		const headers = {}
		const requestPromise = httpRequest.delete(`${RequestBaseURL}${apiVersionURL}/hubs/${hubId}`, {
			headers,
		})
		return requestPromise
	}

	getHubById(id: number): Promise<AxiosResponse<Hub | undefined>> {
		const headers = {}
		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/hubs/${id}`, {
			headers,
		})
		return requestPromise
	}

	getHubBySlug(slug: string): Promise<AxiosResponse<Hub | undefined>> {
		const headers = {}
		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/hubs/slug/${slug}`, {
			headers,
		})
		return requestPromise
	}
}

export const HubsAPI = new HubsAPIPrototype()
