import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function FullScreenSpinner() {
	return (
		<div
			className="flex flex-alignItems-center flex-justifyContent-center"
			style={{ width: '100vw', height: '100vh' }}
		>
			<FontAwesomeIcon icon={['far', 'spinner']} spin={true} size="4x" style={{ opacity: '0.75' }} />
		</div>
	)
}
