import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DOMAIN_ID, DomainAPI } from '../../services/domain/domain.api'
import { Domain } from '../../services/domain/domain.types'

const initialState: null | Domain.Properties = {
	label: '',
	domainId: -1,
}

export const getDomainProps = createAsyncThunk('domain/get', async () => {
	const res = await DomainAPI.get(DOMAIN_ID)
	return res.data
})

export const domainSlice = createSlice({
	name: 'domain',
	initialState,
	reducers: {
		setDomainProps: (state, action: PayloadAction<Domain.Properties>) => {
			return action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getDomainProps.fulfilled, (state, action) => {
			return action.payload
		})
	},
})

export const { setDomainProps } = domainSlice.actions

export default domainSlice.reducer
