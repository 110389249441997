import { Chip } from '@components/chip/chip'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'
import { LicensedState } from 'src/services/licensed-states/licenses-states.types'

import { LicensedStatesService } from '../../services/licensed-states/licensed-states.service'
import { LicensedRegionsService } from '../../services/regions/regions.service'
import { Utilities } from '../../services/utilities.service'
import { useAgentSearchDispatch } from './state/agent-search__state'
import { AgentSearchState } from './state/agent-search__state.types'

interface AgentSearchActiveFiltersProps {
	searchParams: AgentSearchState.SearchParams
}

type ConnectedProps = {
	licensedStates: LicensedState[]
}

function AgentSearchActiveFiltersPrototype(props: AgentSearchActiveFiltersProps & ConnectedProps) {
	const searchDispatch = useAgentSearchDispatch()

	function getSpecialtyLabel(): string {
		if (!props.searchParams.specialties) {
			return ''
		}
		const specialtyLabels = props.searchParams.specialties.map((specialty) => specialty.specialtyName)
		let label = ''
		specialtyLabels.forEach((thisLabel, index) => {
			label += `${thisLabel}${index < specialtyLabels.length - 1 ? ', ' : ''}`
		})
		return label
	}

	function getLanguageSpecialtyLabel(): string {
		if (!props.searchParams.languageSpecialties) {
			return ''
		}
		const specialtyLabels = props.searchParams.languageSpecialties.map(
			(languageSpecialty) => languageSpecialty.languageName,
		)
		let label = ''
		specialtyLabels.forEach((thisLabel, index) => {
			label += `${thisLabel}${index < specialtyLabels.length - 1 ? ', ' : ''}`
		})
		return label
	}

	function getLocationLabel(): string {
		if (!props.searchParams.geography) {
			return ''
		}
		if (props.searchParams.geography) {
			return `${props.searchParams.geography.formattedAddress}`
		}
		return ``
	}

	function getNameLabel(): string {
		return `${props.searchParams.firstName ? `${props.searchParams.firstName} ` : ''}${props.searchParams.lastName ? props.searchParams.lastName : ''}`
	}

	return (
		<div className="flex flex-alignItems-center flex-wrap col-xs-12 pb-10">
			{(props.searchParams.firstName || props.searchParams.lastName) && (
				<Chip
					label={`Name: ${getNameLabel()}`}
					color={'var(--colorAdjust60)'}
					className="mr-10"
					onClickRemove={() => {
						searchDispatch([
							{
								type: 'set-first-name',
								payload: undefined,
							},
							{
								type: 'set-last-name',
								payload: undefined,
							},
						])
					}}
				/>
			)}
			{props.searchParams.email && (
				<Chip
					label={`Email: ${props.searchParams.email}`}
					color={'var(--colorAdjust60)'}
					className="mr-10"
					onClickRemove={() => {
						searchDispatch([
							{
								type: 'set-email',
								payload: undefined,
							},
						])
					}}
				/>
			)}
			{props.searchParams.geography.srcLat && (
				<Chip
					label={`Location: ${getLocationLabel()}`}
					color={'var(--colorAdjust60)'}
					className="mr-10"
					onClickRemove={() => {
						searchDispatch([
							{
								type: 'set-geography',
								payload: {
									address1: '',
									address2: '',
									city: '',
									mailingState: '',
									zip: '',
									srcLat: null,
									srcLong: null,
									radius: props.searchParams.geography.radius,
									formattedAddress: '',
								},
							},
						])
					}}
				/>
			)}
			{props.searchParams.phone && (
				<Chip
					label={`Phone: ${Utilities.formatPhoneNum(props.searchParams.phone)}`}
					color={'var(--colorAdjust60)'}
					className="mr-10"
					onClickRemove={() => {
						searchDispatch([
							{
								type: 'set-phone',
								payload: undefined,
							},
						])
					}}
				/>
			)}
			{props.searchParams.specialties && (
				<Chip
					label={`Specialties: ${getSpecialtyLabel()}`}
					color={'var(--colorAdjust60)'}
					className="mr-10"
					onClickRemove={() => {
						searchDispatch([
							{
								type: 'set-specialties',
								payload: [],
							},
						])
					}}
				/>
			)}
			{props.searchParams.languageSpecialties && (
				<Chip
					label={`Languages: ${getLanguageSpecialtyLabel()}`}
					color={'var(--colorAdjust60)'}
					className="mr-10"
					onClickRemove={() => {
						searchDispatch([
							{
								type: 'set-languageSpecialties',
								payload: [],
							},
						])
					}}
				/>
			)}
			{props.searchParams.licensedStateId && (
				<Chip
					label={`Licensed State: ${LicensedStatesService.getStateFromId(props.licensedStates, props.searchParams.licensedStateId)?.stateName}`}
					color={'var(--colorAdjust60)'}
					className="mr-10"
					onClickRemove={() => {
						searchDispatch([
							{
								type: 'set-licensed-state-id',
								payload: undefined,
							},
						])
					}}
				/>
			)}
			{props.searchParams.regionId && (
				<Chip
					label={`Region: ${LicensedRegionsService.getRegionFromId(props.searchParams.regionId)?.regionName}`}
					color={'var(--colorAdjust60)'}
					className="mr-10"
					onClickRemove={() => {
						searchDispatch([
							{
								type: 'set-licensed-region-id',
								payload: undefined,
							},
						])
					}}
				/>
			)}
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		licensedStates: state.licensedStates,
	}
}

export const AgentSearchActiveFilters = connect(mapStateToProps)(AgentSearchActiveFiltersPrototype)
