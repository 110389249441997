import { currentSessionSlice } from '@redux/reducers/current-session-reducer'
import { store } from '@redux/store'
import { AxiosError } from 'axios'

import { globalNavigate } from '../../routes/history-component'
import { TokenService } from '../token/token.service'

export class RequestErrorHandlingService {
	public static getErrorMessage(err: AxiosError): string {
		const token = store.getState().currentSession.token

		/** Get route specific errors */
		if (err.config?.url?.includes(`/login`)) {
			if (err.response?.status === 401) {
				return `Please check your username and password`
			}
		}

		if (err.response?.status === 401) {
			const currentTime = new Date().getTime()
			const tokenExpiration = token?.exp
			const tokenJwt = token?.jwt

			/** If users token has expired, redirect them to the login page */
			if (tokenJwt && tokenExpiration && tokenExpiration < currentTime) {
				/** log user out */
				if (globalNavigate) {
					globalNavigate('/')
				}
				TokenService().clear()
				store.dispatch(currentSessionSlice.actions.clearToken())
				return `You have been logged out`
			}
			if (!tokenJwt) {
				/** log user out */
				if (globalNavigate) {
					globalNavigate('/')
				}
				TokenService().clear()
				store.dispatch(currentSessionSlice.actions.clearToken())
				return `Please log in to view this content`
			}
		}

		if (err.message) {
			return err.message
		}

		return `An error has occurred`
	}
}
