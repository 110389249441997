import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { GenericDeleteConfirmationModal } from 'src/modals/generic-delete-confirmation/generic-delete-confirmation'
import { globalNavigate } from 'src/routes/history-component'
import { settingsRoutePath } from 'src/routes/routes.constant'
import { AdAccountAPI } from 'src/services/advertising/ad-account.api'

import { useAdvertisingAccount } from './state/ad-account__state'

export function AdvertisingAccountIndexRoute() {
	const navigate = useNavigate()
	const location = useLocation()
	const advertisingAccountState = useAdvertisingAccount()
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
	const currentRouteId = getCurrentRoute()

	/** ============================ */
	/** Methods */

	function getCurrentRoute(): string {
		const pathParts = location.pathname.split('/')
		const routeId = pathParts[pathParts.length - 1]
		return routeId
	}

	/** ============================ */
	/** Render Component */

	return (
		<RouteWrapperV2>
			<RouteSubnavigation
				isCustom={false}
				title={advertisingAccountState.modifiedState.organizationName}
				backButton={{
					label: 'Accounts',
					onClick: () => {
						navigate(`${settingsRoutePath}/advertising`)
					},
				}}
				routes={[
					{
						label: 'General',
						icon: 'cog',
						onClick: () => {
							navigate(`general`)
						},
						id: 'general',
						isActive: currentRouteId === 'general',
					},
					{
						label: 'Advertisements',
						icon: 'rectangle-ad',
						onClick: () => {
							navigate(`advertisements`)
						},
						id: 'advertisements',
						isActive: currentRouteId === 'advertisements',
					},
					{
						label: 'Billing',
						icon: 'money-bill',
						onClick: () => {
							navigate(`billing`)
						},
						id: 'billing',
						isActive: currentRouteId === 'billing',
					},
					{
						label: 'Delete Account',
						icon: 'trash',
						style: { color: 'red', marginTop: '20px' },
						onClick: () => {
							setShowDeleteConfirmationModal(true)
						},
						id: 'delete-account',
						isActive: false,
					},
				]}
			/>

			<RouteBodyV2 className="flex flex-column flex-alignItems-start">
				<Outlet />
			</RouteBodyV2>
			{showDeleteConfirmationModal && (
				<GenericDeleteConfirmationModal
					itemLabel={advertisingAccountState.modifiedState.organizationName}
					onDelete={() => {
						AdAccountAPI.deleteAccount(advertisingAccountState.modifiedState).then(() => {
							setShowDeleteConfirmationModal(false)
							globalNavigate(`${settingsRoutePath}/advertising`)
						})
					}}
					onClose={() => {
						setShowDeleteConfirmationModal(false)
					}}
				/>
			)}
		</RouteWrapperV2>
	)
}
