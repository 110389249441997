import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function HomeSearchGrid() {
	return (
		<>
			<div className="flex flex-wrap text-center col-xs-12">
				<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'sun-bright']} size="3x" className="color__accent mb-10" />
					<strong>Edge to Edge HD Photos</strong>
				</div>
				<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'message-exclamation']} size="3x" className="color__accent mb-10" />
					<strong>Push Notifications</strong>
				</div>
				<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'magnifying-glass']} size="3x" className="color__accent mb-10" />
					<strong>Saved Searches</strong>
				</div>
				<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'hand-pointer']} size="3x" className="color__accent mb-10" />
					<strong>1 Touch Sharing</strong>
				</div>
			</div>

			<div className="flex flex-wrap text-center col-xs-12 mb-20">
				<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'filter']} size="3x" className="color__accent mb-10" />
					<strong>Filters</strong>
				</div>
				<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'merge']} size="3x" className="color__accent mb-10" />
					<strong>Direct MLS Feed</strong>
				</div>
			</div>
		</>
	)
}
