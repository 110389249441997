import './forgot-password.scss'

import { LoKationButton } from '@components/button/button'
import { LoginRouteWrapper } from '@components/login-route-wrapper/login-route-wrapper'
import { TextInput } from '@components/text-input/text-input'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastService } from 'src/services/toast/toast.service'

import { UserAPI } from '../../services/user/user.api'

export default function ForgotPasswordRoute() {
	const [email, setEmail] = useState('')
	const navigate = useNavigate()

	function sendResetInstructions(): void {
		UserAPI.sendResetPasswordEmail({
			email,
		}).then((res) => {
			ToastService().create({
				type: 'SUCCESS',
				body: `Email reset instructions will be sent to your inbox soon`,
			})
			navigate(`/`)
		})
	}

	return (
		<LoginRouteWrapper
			isLoginRoute={false}
			bodyClassName="flex flex-column flex-justifyContent-center flex-fillSpace flex-alignItems-center col-xs-12"
		>
			<div className="flex flex-alignItems-center flex-column clamp-width-for-login-routes">
				<h2 className="text-center">Forgot password</h2>
				<p className="mb-40 text-center">
					Enter the email address associated with this account and we will send instructions to reset your
					password
				</p>
				<div style={{ width: '100%' }}>
					<TextInput
						width={`100%`}
						dataType="text"
						placeholder="Email"
						className="mb-20"
						value={email}
						onChange={(updatedValue) => {
							setEmail(String(updatedValue))
						}}
						type={'email'}
					/>
				</div>

				<LoKationButton
					label="Send Reset Instructions"
					variant="contained"
					size={'lg'}
					onClick={sendResetInstructions}
					className={`col-xs-12`}
				/>
			</div>
		</LoginRouteWrapper>
	)
}
