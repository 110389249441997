import { TextInput } from '@components/text-input/text-input'

import { EditHubSectionProps } from './edit-hub'

export function EditHubBodySection(props: EditHubSectionProps) {
	return (
		<>
			<TextInput
				label="Body"
				dataType="text"
				value={props.mergedHubProps.body}
				width="100%"
				rows={16}
				className="mb-20"
				onChange={(updatedValue) => {
					props.updateHubProp('body', updatedValue)
				}}
			/>

			<TextInput
				label="Sidebar"
				dataType="text"
				value={props.mergedHubProps.sidebar}
				width="100%"
				rows={3}
				className="mb-20"
				onChange={(updatedValue) => {
					props.updateHubProp('sidebar', updatedValue)
				}}
			/>
		</>
	)
}
