import { AxiosResponse } from 'axios'

import { httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { SortParam } from '../utility.types'
import {
	FindLeadDTO,
	FindLeadsInDomainRequest,
	FindLeadsInDomainResponse,
	FullLeadProps,
	LeadProps,
} from './lead.types'

class LeadAPIPrototype {
	getLeadDetails(email: string): Promise<AxiosResponse<FullLeadProps>> {
		const headers: { [key: string]: any } = {}

		const requestPromise = httpRequest.get(`${RequestBaseURL}/api/v1/leads/${email}`, {
			headers,
		})
		return requestPromise
	}

	updateLeadProperties(leadId: number, leadProps: Partial<LeadProps>): Promise<AxiosResponse<LeadProps>> {
		const headers = {}
		const requestPromise = httpRequest.patch(`${RequestBaseURL}/api/v1/leads/${leadId}`, leadProps, {
			headers,
		})
		return requestPromise
	}

	deleteLead(email: string): Promise<AxiosResponse<FullLeadProps>> {
		const headers: { [key: string]: any } = {}

		const requestPromise = httpRequest.post(`${RequestBaseURL}/api/v1/leads/${email}/delete`, {
			headers,
		})
		return requestPromise
	}

	getAllLeadsInDomain(
		page: number,
		size: number,
		sort?: SortParam<FindLeadDTO>[],
	): Promise<AxiosResponse<FindLeadsInDomainRequest>> {
		const headers = {}
		const params = Utilities.createSearchParamsFromObj({ page, size, sort })

		const requestPromise = httpRequest.get(`${RequestBaseURL}/api/v1/leads?`, {
			headers,
			params,
		})
		return requestPromise
	}

	findLeadsInDomain(props: FindLeadsInDomainRequest): Promise<AxiosResponse<FindLeadsInDomainResponse>> {
		let url = `${RequestBaseURL}/api/v1/leads/list/search`

		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props })

		if (props.query.firstName) {
			params.append('first', props.query.firstName)
		}

		if (props.query.lastName) {
			params.append('last', props.query.lastName)
		}

		if (props.query.phone) {
			params.append('phone', props.query.phone)
		}

		if (props.query.email) {
			params.append('email', props.query.email)
		}

		if (props.query.state) {
			params.append('state', props.query.state)
		}

		console.info(`Searching page ${props.page}`, params)

		const headers = {}
		const requestPromise = httpRequest.get(url, {
			headers,
			params,
		})
		return requestPromise
	}
}

export const LeadAPI = new LeadAPIPrototype()
