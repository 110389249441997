import { Modal } from '@components/modal/modal'
import { connect } from 'react-redux'
import { EndUserProps } from 'src/services/user/user.types'

import { RootState } from '../../../redux/store'
import { Calendar } from '../../../services/calendar/calendar.types'
import { AdminItemEditorProps } from '../../../services/utility.types'
import { EditCalendarEventService } from './edit-calendar.event.service'
import { EditCalendarEventInner } from './edit-calendar-event__inner'
import { EditCalendarEventProvider } from './state/edit-calendar-event__state'

interface EditCalendarEventRouteProps {
	event: Calendar.Event | null
	dismissModal: () => void
	onDelete?: (event: Calendar.Event) => void
	onCreate?: (event: Calendar.Event) => void
	onUpdate?: (event: Calendar.Event) => void
}

interface ConnectedProps {
	user: EndUserProps | null
	calendars: Calendar.Calendar[]
}

function EditCalendarEventRoutePrototype(props: EditCalendarEventRouteProps & ConnectedProps) {
	const eventProps = getCalendarEventProps()
	/** ================================= */
	/** Methods */

	function getCalendarEventProps(): Calendar.Event | null {
		if (!props.user) {
			return null
		}

		if (props.calendars.length === 0) {
			return null
		}

		return props.event
			? props.event
			: EditCalendarEventService().getNewEventProps({
					calendar: props.calendars[0],
					user: props.user,
				})
	}

	/** ================================= */
	/** Render Component */

	if (!eventProps) {
		return <></>
	}

	return (
		<Modal
			maxWidth={1000}
			maxHeight={1000}
			fixedHeight={true}
			className="flex flex-column"
			onClose={props.dismissModal}
		>
			<EditCalendarEventProvider event={eventProps}>
				<EditCalendarEventInner
					dismissModal={props.dismissModal}
					onDelete={props.onDelete}
					onCreate={props.onCreate}
					onUpdate={props.onUpdate}
				/>
			</EditCalendarEventProvider>
		</Modal>
	)
}

function mapStateToProps(state: RootState, ownProps: AdminItemEditorProps<Calendar.Event>) {
	return {
		calendars: state.calendar,
		user: state.user,
	}
}

export const EditCalendarEventRoute = connect(mapStateToProps)(EditCalendarEventRoutePrototype)
