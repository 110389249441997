import {
	faCcAmex,
	faCcApplePay,
	faCcDinersClub,
	faCcDiscover,
	faCcJcb,
	faCcMastercard,
	faCcPaypal,
	faCcStripe,
	faCcVisa,
	IconDefinition,
} from '@fortawesome/free-brands-svg-icons'

import { CardBrands } from './card.types'

// Make sure cardIcons is of type Record<CardBrand, IconDefinition>
const cardIcons: Record<CardBrands, IconDefinition> = {
	visa: faCcVisa,
	mastercard: faCcMastercard,
	discover: faCcDiscover,
	amex: faCcAmex,
	jcb: faCcJcb,
	diners: faCcDinersClub,
	applepay: faCcApplePay,
	stripe: faCcStripe,
	paypal: faCcPaypal,
}

// Make sure the cardBrand parameter is of type CardBrand
export const getCardIcon = (cardBrand: CardBrands) => {
	// Return the FontAwesome icon based on the card brand
	return cardIcons[cardBrand] || null
}
