import { AgentLicenseCardService } from '@components/agent-license-card/agent-license-card.service'
import _ from 'lodash'
import { createContext, useContext, useReducer } from 'react'

import { AgentLicense, EndUserProps } from '../../../../services/user/user.types'
import { UserProfileState } from './user-profile__state.types'

function initialState(licenses: AgentLicense.CompleteEntry[], endUserId: number): UserProfileState.LocalState {
	return {
		modifiedProps: {},
		agentLicenses: licenses,
		modifiedProfilePhoto: null,
		endUserId,
		signature: '',
		licenseAgreements: [],
	}
}

const UserProfileContext = createContext(initialState([], -1))
const UserProfileDispatchContext = createContext({} as UserProfileState.DispatchParams)

function UserProfileReducer(state: UserProfileState.LocalState, action: UserProfileState.Action) {
	switch (action.type) {
		case 'update-property': {
			const updatedState = _.cloneDeep(state)
			updatedState.modifiedProps[action.payload.key] = action.payload.value
			return updatedState
		}
		case 'add-license': {
			const updatedState = _.cloneDeep(state)
			if (!action.payload) {
				const newLicense = AgentLicenseCardService().createNewLicenseProps({
					endUserId: state.endUserId,
					plan: 'BLUE',
				})
				updatedState.agentLicenses.push(newLicense)
			} else {
				updatedState.agentLicenses.push(action.payload)
			}
			return updatedState
		}
		case 'remove-license': {
			const updatedState = _.cloneDeep(state)
			updatedState.agentLicenses = updatedState.agentLicenses.filter(
				(license) => license.licenseInformationId !== action.payload,
			)
			return updatedState
		}
		case 'update-license': {
			const updatedState = _.cloneDeep(state)

			const licenseIndex = updatedState.agentLicenses.findIndex(
				(license) => license.licenseInformationId === action.payload.licenseId,
			)
			if (licenseIndex > -1) {
				updatedState.agentLicenses[licenseIndex] = action.payload.license
			}

			return updatedState
		}
		case 'update-profile-photo': {
			const updatedState = _.cloneDeep(state)
			updatedState.modifiedProfilePhoto = action.payload
			return updatedState
		}
		case 'update-signature': {
			let updatedState = _.cloneDeep(state)
			updatedState.signature = action.payload
			return updatedState
		}
		case 'set-contract-agreement-state': {
			let updatedState = _.cloneDeep(state)
			const licenseIndex = updatedState.licenseAgreements.findIndex(
				(agreement) => agreement.stateId === action.payload.stateId,
			)
			if (licenseIndex > -1) {
				updatedState.licenseAgreements[licenseIndex] = action.payload
			} else {
				updatedState.licenseAgreements.push(action.payload)
			}
			return updatedState
		}
	}
}

export function UserProfileProvider(props: { userToEdit: EndUserProps | null; children: React.ReactNode }) {
	const [state, dispatch] = useReducer(
		UserProfileReducer,
		initialState(
			props.userToEdit ? props.userToEdit.licenseInformation : [],
			props.userToEdit ? props.userToEdit.endUserId : -1,
		),
	)

	return (
		<UserProfileContext.Provider value={state}>
			<UserProfileDispatchContext.Provider value={dispatch}>{props.children}</UserProfileDispatchContext.Provider>
		</UserProfileContext.Provider>
	)
}

export function useUserProfile() {
	return useContext(UserProfileContext)
}

export function useUserProfileDispatch() {
	return useContext(UserProfileDispatchContext)
}
