import _ from 'lodash'
import { ChatGptSettingsAPI } from 'src/services/chat-gpt-settings/chat-gpt-settings.api'

import { DOMAIN_ID, DomainAPI } from '../../../services/domain/domain.api'
import { Domain } from '../../../services/domain/domain.types'
import { LandingPageAPI } from '../../../services/landing-page/landing-page.api'
import { LoginCarouselAPI } from '../../../services/login-carousel/login-carousel.api'
import { LoginCarouselNode } from '../../../services/login-carousel/login-carousel.types'
import { MLSBoardAPI } from '../../../services/mls-board/mls-board.api'
import { MLSBoard } from '../../../services/mls-board/mls-board.types'
import { ReferralTypeAPI } from '../../../services/registration/referral-type.api'
import { Registration } from '../../registration/registration.types'
import { DomainAdminState } from './state/domain-admin__state.types'

export function DomainAdminService() {
	function saveChanges(domainAdminState: DomainAdminState.LocalState): Promise<void> {
		return new Promise((resolve) => {
			const savePromises: Promise<unknown>[] = []

			if (domainAdminState.modified.general) {
				savePromises.push(DomainAPI.update(DOMAIN_ID, domainAdminState.modified.general))
			}

			if (domainAdminState.modified.chatGptSettings) {
				savePromises.push(ChatGptSettingsAPI.update(DOMAIN_ID, domainAdminState.modified.chatGptSettings))
			}

			/** ========================================================== */
			/** Reconcile Carousel */
			const reconciledCarousel = reconcileCarousel(domainAdminState)
			if (reconciledCarousel.create.length > 0) {
				reconciledCarousel.create.forEach((nodeToCreate) => {
					savePromises.push(LoginCarouselAPI.createNode(nodeToCreate))
				})
			}
			if (reconciledCarousel.update.length > 0) {
				reconciledCarousel.update.forEach((noteToUpdate) => {
					savePromises.push(LoginCarouselAPI.updateNode(noteToUpdate.loginCarouselNodeId, noteToUpdate))
				})
			}
			if (reconciledCarousel.delete.length > 0) {
				reconciledCarousel.delete.forEach((nodeToDelete) => {
					savePromises.push(LoginCarouselAPI.deleteNode(nodeToDelete.loginCarouselNodeId))
				})
			}

			/** ========================================================== */
			/** Reconcile MLS Board */
			const reconciledMlsBoard = reconcileMlsBoard(domainAdminState)
			if (reconciledMlsBoard.create.length > 0) {
				reconciledMlsBoard.create.forEach((nodeToCreate) => {
					if (nodeToCreate.licensedState) {
						savePromises.push(MLSBoardAPI().addOption(nodeToCreate))
					}
				})
			}
			if (reconciledMlsBoard.update.length > 0) {
				reconciledMlsBoard.update.forEach((noteToUpdate) => {
					if (noteToUpdate.licensedState) {
						savePromises.push(MLSBoardAPI().patchOption(noteToUpdate))
					}
				})
			}
			if (reconciledMlsBoard.delete.length > 0) {
				reconciledMlsBoard.delete.forEach((nodeToDelete) => {
					savePromises.push(MLSBoardAPI().deleteOption(nodeToDelete.mlsBoardId))
				})
			}

			/** ========================================================== */
			/** Reconcile Referral Types */
			const reconciledReferralTypes = reconcileReferralTypes(domainAdminState)
			if (reconciledReferralTypes.create.length > 0) {
				reconciledReferralTypes.create.forEach((nodeToCreate) => {
					savePromises.push(ReferralTypeAPI().addOption(nodeToCreate))
				})
			}
			if (reconciledReferralTypes.update.length > 0) {
				reconciledReferralTypes.update.forEach((noteToUpdate) => {
					savePromises.push(ReferralTypeAPI().patchOption(noteToUpdate))
				})
			}
			if (reconciledReferralTypes.delete.length > 0) {
				reconciledReferralTypes.delete.forEach((nodeToDelete) => {
					savePromises.push(ReferralTypeAPI().deleteOption(nodeToDelete.referralTypeId))
				})
			}

			/** ========================================================== */
			/** Reconcile Landing Page Resources */
			const reconciledLandingPageResources = reconcileLandingPageResources(domainAdminState)
			if (reconciledLandingPageResources.create.length > 0) {
				reconciledLandingPageResources.create.forEach((nodeToCreate) => {
					savePromises.push(LandingPageAPI.createResources(DOMAIN_ID, nodeToCreate))
				})
			}
			if (reconciledLandingPageResources.update.length > 0) {
				reconciledLandingPageResources.update.forEach((noteToUpdate) => {
					savePromises.push(LandingPageAPI.updateResources(DOMAIN_ID, noteToUpdate))
				})
			}
			if (reconciledLandingPageResources.delete.length > 0) {
				reconciledLandingPageResources.delete.forEach((nodeToDelete) => {
					savePromises.push(LandingPageAPI.deleteResources(DOMAIN_ID, nodeToDelete.landingPageResourceId))
				})
			}

			/** ========================================================== */
			/** Reconcile Landing Page Hubs */
			const reconciledLandingPageHubs = reconcileLandingPageHubs(domainAdminState)
			if (reconciledLandingPageHubs.create.length > 0) {
				reconciledLandingPageHubs.create.forEach((nodeToCreate) => {
					savePromises.push(LandingPageAPI.createHub(DOMAIN_ID, nodeToCreate))
				})
			}
			if (reconciledLandingPageHubs.update.length > 0) {
				reconciledLandingPageHubs.update.forEach((noteToUpdate) => {
					savePromises.push(LandingPageAPI.updateHub(DOMAIN_ID, noteToUpdate))
				})
			}
			if (reconciledLandingPageHubs.delete.length > 0) {
				reconciledLandingPageHubs.delete.forEach((nodeToDelete) => {
					savePromises.push(LandingPageAPI.deleteHub(DOMAIN_ID, nodeToDelete.landingPageHubId))
				})
			}

			Promise.all(savePromises).then(() => {
				resolve()
			})
		})
	}

	function reconcileCarousel(domainAdminState: DomainAdminState.LocalState): {
		create: LoginCarouselNode[]
		update: LoginCarouselNode[]
		delete: LoginCarouselNode[]
	} {
		const toCreate: LoginCarouselNode[] = []
		const toUpdate: LoginCarouselNode[] = []
		const toDelete: LoginCarouselNode[] = []

		/** Find carousel nodes that need to be updated */
		domainAdminState.original.loginCarousel.forEach((originalNode) => {
			domainAdminState.modified.loginCarousel.forEach((modifiedNode) => {
				const isSameId = originalNode.loginCarouselNodeId === modifiedNode.loginCarouselNodeId
				if (isSameId && !_.isEqual(originalNode, modifiedNode)) {
					toUpdate.push(modifiedNode)
				}
			})
		})

		/** Find carousel nodes that need to be deleted */
		domainAdminState.original.loginCarousel.forEach((originalNode) => {
			const modifiedNodeIds = domainAdminState.modified.loginCarousel.map(
				(modifiedNode) => modifiedNode.loginCarouselNodeId,
			)
			if (!modifiedNodeIds.includes(originalNode.loginCarouselNodeId)) {
				toDelete.push(originalNode)
			}
		})

		/** Find carousel nodes that need to be created */
		domainAdminState.modified.loginCarousel.forEach((originalNode) => {
			if (originalNode.loginCarouselNodeId < 0) {
				toCreate.push(originalNode)
			}
		})

		return { create: toCreate, update: toUpdate, delete: toDelete }
	}

	function reconcileMlsBoard(domainAdminState: DomainAdminState.LocalState): {
		create: MLSBoard.BoardOption[]
		update: MLSBoard.BoardOption[]
		delete: MLSBoard.BoardOption[]
	} {
		const toCreate: MLSBoard.BoardOption[] = []
		const toUpdate: MLSBoard.BoardOption[] = []
		const toDelete: MLSBoard.BoardOption[] = []

		/** Find nodes that need to be updated */
		domainAdminState.original.mlsBoardOptions.forEach((originalNode) => {
			domainAdminState.modified.mlsBoardOptions.forEach((modifiedNode) => {
				const isSameId = originalNode.mlsBoardId === modifiedNode.mlsBoardId
				if (isSameId && !_.isEqual(originalNode, modifiedNode)) {
					toUpdate.push(modifiedNode)
				}
			})
		})

		/** Find nodes that need to be deleted */
		domainAdminState.original.mlsBoardOptions.forEach((originalNode) => {
			const modifiedNodeIds = domainAdminState.modified.mlsBoardOptions.map(
				(modifiedNode) => modifiedNode.mlsBoardId,
			)
			if (!modifiedNodeIds.includes(originalNode.mlsBoardId)) {
				toDelete.push(originalNode)
			}
		})

		/** Find nodes that need to be created */
		domainAdminState.modified.mlsBoardOptions.forEach((originalNode) => {
			if (originalNode.mlsBoardId < 0) {
				toCreate.push(originalNode)
			}
		})

		return { create: toCreate, update: toUpdate, delete: toDelete }
	}

	function reconcileReferralTypes(domainAdminState: DomainAdminState.LocalState): {
		create: Registration.ReferralTypeOption[]
		update: Registration.ReferralTypeOption[]
		delete: Registration.ReferralTypeOption[]
	} {
		const toCreate: Registration.ReferralTypeOption[] = []
		const toUpdate: Registration.ReferralTypeOption[] = []
		const toDelete: Registration.ReferralTypeOption[] = []

		/** Find nodes that need to be updated */
		domainAdminState.original.referralTypeOptions.forEach((originalNode) => {
			domainAdminState.modified.referralTypeOptions.forEach((modifiedNode) => {
				const isSameId = originalNode.referralTypeId === modifiedNode.referralTypeId
				if (isSameId && !_.isEqual(originalNode, modifiedNode)) {
					toUpdate.push(modifiedNode)
				}
			})
		})

		/** Find nodes that need to be deleted */
		domainAdminState.original.referralTypeOptions.forEach((originalNode) => {
			const modifiedNodeIds = domainAdminState.modified.referralTypeOptions.map(
				(modifiedNode) => modifiedNode.referralTypeId,
			)
			if (!modifiedNodeIds.includes(originalNode.referralTypeId)) {
				toDelete.push(originalNode)
			}
		})

		/** Find nodes that need to be created */
		domainAdminState.modified.referralTypeOptions.forEach((originalNode) => {
			if (originalNode.referralTypeId < 0) {
				toCreate.push(originalNode)
			}
		})

		return { create: toCreate, update: toUpdate, delete: toDelete }
	}

	function reconcileLandingPageResources(domainAdminState: DomainAdminState.LocalState): {
		create: Omit<Domain.ResourceRef, 'landingPageResourceId'>[]
		update: Domain.ResourceRef[]
		delete: Domain.ResourceRef[]
	} {
		const toCreate: Omit<Domain.ResourceRef, 'landingPageResourceId'>[] = []
		const toUpdate: Domain.ResourceRef[] = []
		const toDelete: Domain.ResourceRef[] = []

		/** Find nodes that need to be updated */
		domainAdminState.original.landingPageResources.forEach((originalNode) => {
			domainAdminState.modified.landingPageResources.forEach((modifiedNode) => {
				const isSameId = originalNode.landingPageResourceId === modifiedNode.landingPageResourceId
				if (isSameId && !_.isEqual(originalNode, modifiedNode) && modifiedNode.resource) {
					toUpdate.push(modifiedNode)
				}
			})
		})

		/** Find nodes that need to be deleted */
		domainAdminState.original.landingPageResources.forEach((originalNode) => {
			const modifiedNodeIds = domainAdminState.modified.landingPageResources.map(
				(modifiedNode) => modifiedNode.landingPageResourceId,
			)
			if (!modifiedNodeIds.includes(originalNode.landingPageResourceId) && originalNode.resource) {
				toDelete.push(originalNode)
			}
		})

		/** Find nodes that need to be created */
		domainAdminState.modified.landingPageResources.forEach((originalNode) => {
			if (originalNode.landingPageResourceId < 0 && originalNode.resource) {
				const nodeWithoutId: Omit<Domain.ResourceRef, 'landingPageResourceId'> = _.omit(
					originalNode,
					'landingPageResourceId',
				)
				toCreate.push(nodeWithoutId)
			}
		})

		return { create: toCreate, update: toUpdate, delete: toDelete }
	}

	function reconcileLandingPageHubs(domainAdminState: DomainAdminState.LocalState): {
		create: Omit<Domain.HubRef, 'landingPageHubId'>[]
		update: Domain.HubRef[]
		delete: Domain.HubRef[]
	} {
		const toCreate: Omit<Domain.HubRef, 'landingPageHubId'>[] = []
		const toUpdate: Domain.HubRef[] = []
		const toDelete: Domain.HubRef[] = []

		/** Find nodes that need to be updated */
		domainAdminState.original.landingPageHubs.forEach((originalNode) => {
			domainAdminState.modified.landingPageHubs.forEach((modifiedNode) => {
				const isSameId = originalNode.landingPageHubId === modifiedNode.landingPageHubId
				if (isSameId && !_.isEqual(originalNode, modifiedNode) && modifiedNode.hub) {
					toUpdate.push(modifiedNode)
				}
			})
		})

		/** Find nodes that need to be deleted */
		domainAdminState.original.landingPageHubs.forEach((originalNode) => {
			const modifiedNodeIds = domainAdminState.modified.landingPageHubs.map(
				(modifiedNode) => modifiedNode.landingPageHubId,
			)
			if (!modifiedNodeIds.includes(originalNode.landingPageHubId) && originalNode.hub) {
				toDelete.push(originalNode)
			}
		})

		/** Find nodes that need to be created */
		domainAdminState.modified.landingPageHubs.forEach((originalNode) => {
			if (originalNode.landingPageHubId < 0 && originalNode.hub) {
				const nodeWithoutId: Omit<Domain.HubRef, 'landingPageHubId'> = _.omit(originalNode, 'landingPageHubId')
				toCreate.push(nodeWithoutId)
			}
		})

		return { create: toCreate, update: toUpdate, delete: toDelete }
	}

	return { saveChanges }
}
