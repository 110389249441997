import { LoKationButton } from '@components/button/button'
import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'

import { AddOnServiceList } from '../../routes/administration/user-profile/components/subscription-components/add-on-service-list'
import BgImage from './kvcore-bg.jpg'
import { KVCoreIconGrid } from './kvcore-grid'
import Logo from './kvcore-logo.png'

export function LoKationResourceKVCorePlatform() {
	return (
		<div className="flex-lg overflow-y__scroll">
			<div className="col-xs-12 col-lg-8 pr-20-sm flex flex-column flex-alignItems-start overflow-y__scroll pb-20">
				<div className="text-center col-xs-12">
					<img src={Logo} style={{ width: '200px' }} className="mb-20" />
					<h4>Your Force Multiplier</h4>
				</div>
				<HorizontalRule className="mt-20" />

				<KVCoreIconGrid />

				<HorizontalRule className="mb-10" />

				<p>
					Normally this program carries a $500 setup fee and a $500 monthly fee. LoKation Agents can take part
					of the KVcore for a $99 setup fee and $50 per month. No Contract, No obligations. First 30 days
					free.
				</p>

				<div className="flex flex-wrap">
					<LoKationButton
						variant="outlined"
						size="sm"
						label="Learn more"
						className="mr-10 mb-10"
						onClick={() => {
							window.open('https://www.insiderealestate.com/new-users', '_blank')
						}}
					/>
					<LoKationButton
						variant="outlined"
						size="sm"
						label="Sample Public Facing IDX Website"
						className="mr-10 mb-10"
						onClick={() => {
							window.open('https://matthewsalvatoriello.lokationre.com/', '_blank')
						}}
					/>
					{/* <LoKationButton
						variant="outlined"
						size="sm"
						label="Agent Marketing Slideshow"
						className="mr-10 mb-10"
						onClick={() => {
							window.open(
								'https://agent.lokationre.com/images/marketing/AgentMarketingSlideDeck.pdf',
								'_blank',
							)
						}}
					/> */}
					<LoKationButton
						variant="outlined"
						size="sm"
						label="Overview Training"
						className="mr-10 mb-10"
						onClick={() => {
							window.open('https://lokation.thinkific.com/courses/kvcore-overview-training', '_blank')
						}}
					/>
					<LoKationButton
						variant="outlined"
						size="sm"
						label="KvCore Tips and Tricks"
						className="mr-10 mb-10"
						onClick={() => {
							window.open('https://lokation.thinkific.com/courses/kvcore-tips-and-tricks', '_blank')
						}}
					/>
				</div>

				<AddOnServiceList serviceIds={['KV_CORE']} />
			</div>
			<div
				className="col-xs-12 col-lg-4 bg-color__adjust-10"
				style={{
					backgroundImage: `url(${BgImage})`,
					backgroundSize: `cover`,
				}}
			></div>
		</div>
	)
}
