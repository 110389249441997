import { LokationResource } from '../../services/resources/resources.types'
import { MultiSelectDropdown } from '../multiselect-dropdown/multiselect-dropdown'

export interface ResourceSelectorDropdownProps {
	selectedResources: LokationResource[]
	options: LokationResource[]
	onSelect: (selectedOptions: LokationResource[]) => void
}

export function ResourceSelectorDropdown(props: ResourceSelectorDropdownProps) {
	return (
		<MultiSelectDropdown<LokationResource>
			selectedOptions={props.selectedResources}
			options={props.options}
			onSelect={props.onSelect}
			optionIdKey={'resourceId'}
			optionLabelKey={'title'}
		/>
	)
}
