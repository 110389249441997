import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TokenProps, TokenService } from 'src/services/token/token.service'
import { UserAPI } from 'src/services/user/user.api'

export interface CurrentSessionSliceProps {
	token: TokenProps | null
}

function initialState(): CurrentSessionSliceProps {
	const token = TokenService().getToken()
	const parsedToken = token ? TokenService().parseToken(token) : null

	return {
		token: parsedToken,
	}
}

export const refreshTokenExpiration = createAsyncThunk(
	'auth-tokens/refresh-token-expiration',
	async (props: { userId: number }, { rejectWithValue }) => {
		const res = await UserAPI.getUserDetails(props.userId)
		if (res.status > 199 && res.status < 300) {
			return res.data
		} else {
			return rejectWithValue(res)
		}
	},
)

export const currentSessionSlice = createSlice({
	name: 'currentSession',
	initialState,
	reducers: {
		setToken: (state, action: PayloadAction<string>) => {
			const parsedToken = TokenService().parseToken(action.payload)
			state.token = parsedToken
		},
		clearToken: (state, action: PayloadAction<undefined>) => {
			state.token = null
		},
	},
	extraReducers: (builder) => {
		builder.addCase(refreshTokenExpiration.fulfilled, (state, action) => {
			return state
		})
	},
})

export const { setToken, clearToken } = currentSessionSlice.actions

export default currentSessionSlice.reducer
