import { CustomForms } from './custom-forms.types'

export const customFormsState: CustomForms.Form[] = [
	{
		formId: 1,
		formName: 'Accessadoctor Registration',
		displayLabel: 'Register',
		submitEmail: ['email@address.com'],
		submitLabel: 'Sign Up',
		fields: [
			{
				defaultValue: '',
				fieldKey: 'full-name',
				fieldLabel: 'Full name',
				fieldType: 'STRING',
				formFieldId: 10,
				required: true,
				displayOrder: 0,
			},
			{
				defaultValue: '',
				fieldKey: 'phone-number',
				fieldLabel: 'Phone number',
				fieldType: 'PHONE',
				formFieldId: 11,
				required: true,
				displayOrder: 1,
			},
			{
				defaultValue: '',
				fieldKey: 'email',
				fieldLabel: 'Email address',
				fieldType: 'EMAIL',
				formFieldId: 12,
				required: true,
				displayOrder: 2,
			},
		],
	},
	{
		formId: 2,
		formName: 'Back at You! Registration',
		displayLabel: 'Register',
		submitEmail: ['email@address.com'],
		submitLabel: 'Sign Up',
		fields: [
			{
				defaultValue: '',
				fieldKey: 'agent-name',
				fieldLabel: 'Agent name',
				fieldType: 'STRING',
				formFieldId: 13,
				required: true,
				displayOrder: 0,
			},
			{
				defaultValue: '',
				fieldKey: 'phone-number',
				fieldLabel: 'Phone number',
				fieldType: 'PHONE',
				formFieldId: 14,
				required: true,
				displayOrder: 1,
			},
			{
				defaultValue: '',
				fieldKey: 'office',
				fieldLabel: 'Office',
				fieldType: 'SINGLE_SELECT_DROPDOWN',
				displayOrder: 2,
				options: [
					{
						label: 'Georgia',
						value: 'GEORIGA',
					},
					{
						label: 'Colorado',
						value: 'COLORADO',
					},
					{
						label: 'Southeast Florida',
						value: 'FLORIDA_SOUTHEAST',
					},
					{
						label: 'East Florida',
						value: 'FLORIDA_EAST',
					},
					{
						label: 'Northeast Florida',
						value: 'FLORIDA_NORTHEAST',
					},
					{
						label: 'Northwest Florida',
						value: 'FLORIDA_NORTHWEST',
					},
				],
				formFieldId: 15,
				required: true,
			},
		],
	},
	{
		formId: 3,
		formName: 'Cynosure Registration',
		displayLabel: 'Register',
		submitEmail: ['email@address.com'],
		submitLabel: 'Sign Up',
		fields: [
			{
				defaultValue: false,
				fieldKey: 'is-checked',
				fieldLabel: 'Check me!',
				fieldType: 'CHECKBOX',
				formFieldId: 16,
				required: true,
				displayOrder: 0,
			},
		],
	},
	{
		formId: 4,
		formName: 'Dotloop Registration',
		displayLabel: 'Register',
		submitEmail: ['email@address.com'],
		submitLabel: 'Sign Up',
		fields: [
			{
				defaultValue: false,
				fieldKey: 'is-checked',
				fieldLabel: 'Check me!',
				fieldType: 'CHECKBOX',
				formFieldId: 17,
				required: true,
				displayOrder: 0,
			},
		],
	},
	{
		formId: 5,
		formName: 'HomeActions Registration',
		displayLabel: 'Register',
		submitEmail: ['email@address.com'],
		submitLabel: 'Sign Up',
		fields: [
			{
				defaultValue: false,
				fieldKey: 'website',
				fieldLabel: 'Website',
				fieldType: 'STRING',
				formFieldId: 18,
				required: true,
				displayOrder: 0,
			},
			{
				defaultValue: [],
				fieldKey: 'plan',
				fieldLabel: 'Plan',
				fieldType: 'SINGLE_SELECT_RADIO',
				options: [
					{
						label: 'Monthly Plan - $60 per month + No Set Up Fee, No Annual Contract (Normally $79/month + $99 Set-up Fee).',
						value: 'MONTHLY',
					},
					{
						label: 'Annual Plan - $640 Annually for 13 months (1 month FREE with No Set-up Fee! Normally $780/year)',
						value: 'ANNUAL',
					},
				],
				formFieldId: 19,
				required: true,
				displayOrder: 1,
			},
		],
	},
	{
		formId: 6,
		formName: 'kvCore Registration',
		displayLabel: 'Register',
		submitEmail: ['email@address.com'],
		submitLabel: 'Sign Up',
		fields: [
			{
				defaultValue: false,
				fieldKey: 'is-checked',
				fieldLabel: 'Check me!',
				fieldType: 'CHECKBOX',
				formFieldId: 20,
				required: true,
				displayOrder: 0,
			},
		],
	},
	{
		formId: 7,
		formName: 'Mortgage Pre-approval',
		displayLabel: 'Register',
		submitEmail: ['email@address.com'],
		submitLabel: 'Sign Up',
		fields: [
			{
				defaultValue: '',
				fieldKey: 'full-name',
				fieldLabel: 'Full name',
				fieldType: 'STRING',
				formFieldId: 21,
				required: true,
				displayOrder: 0,
			},
			{
				defaultValue: '',
				fieldKey: 'phone-number',
				fieldLabel: 'Phone number',
				fieldType: 'PHONE',
				formFieldId: 22,
				required: true,
				displayOrder: 1,
			},
			{
				defaultValue: '',
				fieldKey: 'email',
				fieldLabel: 'Email',
				fieldType: 'STRING',
				formFieldId: 23,
				required: true,
				displayOrder: 2,
			},
		],
	},
	{
		formId: 8,
		formName: 'Opcity Registration',
		displayLabel: 'Register',
		submitEmail: ['email@address.com'],
		submitLabel: 'Sign Up',
		fields: [
			{
				defaultValue: false,
				fieldKey: 'is-checked',
				fieldLabel: 'Check me!',
				fieldType: 'CHECKBOX',
				formFieldId: 24,
				required: true,
				displayOrder: 0,
			},
		],
	},
	{
		formId: 9,
		formName: 'Property Management License Agreement',
		displayLabel: 'Sign Agreement',
		submitEmail: ['email@address.com'],
		submitLabel: 'Sign Up',
		fields: [
			{
				defaultValue: '',
				fieldKey: 'full-name',
				fieldLabel: 'Full name',
				fieldType: 'STRING',
				formFieldId: 25,
				required: true,
				displayOrder: 0,
			},
			{
				defaultValue: '',
				fieldKey: 'email',
				fieldLabel: 'Email',
				fieldType: 'STRING',
				formFieldId: 26,
				required: true,
				displayOrder: 1,
			},
			{
				defaultValue: 0,
				fieldKey: 'numUnits',
				fieldLabel: 'Number of units you currently manage',
				fieldType: 'NUMBER',
				formFieldId: 27,
				required: true,
				displayOrder: 2,
			},
			{
				defaultValue: '',
				fieldKey: 'signature',
				fieldLabel: 'Signature',
				fieldType: 'STRING',
				formFieldId: 28,
				required: true,
				displayOrder: 3,
			},
		],
	},
	{
		formId: 10,
		formName: 'Zip-Your-Flyer Registration',
		displayLabel: 'Register',
		submitEmail: ['email@address.com'],
		submitLabel: 'Sign Up',
		fields: [
			{
				defaultValue: false,
				fieldKey: 'is-checked',
				fieldLabel: 'Check me!',
				fieldType: 'CHECKBOX',
				formFieldId: 29,
				required: true,
				displayOrder: 0,
			},
		],
	},
	{
		formId: 11,
		formName: 'Luxury Membership Registration',
		displayLabel: 'Register',
		submitEmail: ['email@address.com'],
		submitLabel: 'Sign Up',
		fields: [
			{
				defaultValue: '',
				fieldKey: 'membership',
				fieldLabel: 'Luxury Membership',
				fieldType: 'SINGLE_SELECT_RADIO',
				options: [
					{
						label: `Annual Pay in Full Now (10% Savings) - 1,350.00 USD`,
						value: 'ANNUAL',
					},
					{
						label: `Monthly Payments $300 Now, $100 a month for the remaining 11 months - 400.00 USD`,
						value: 'MONTHLY',
					},
					{
						label: `Team Member: Annual Pay in Full Now (10% Savings) - 324.00 USD`,
						value: 'TEAM_MEMBER_ANNUAL',
					},
					{
						label: `Team Member: Monthly Payments ($30 Monthly) - 30.00 USD`,
						value: 'TEAM_MEMBMER_MONTHLY',
					},
				],
				formFieldId: 31,
				required: true,
				displayOrder: 0,
			},
			{
				defaultValue: 0,
				fieldKey: 'card-number',
				fieldLabel: 'Card number',
				fieldType: 'NUMBER',
				formFieldId: 32,
				required: true,
				displayOrder: 1,
			},
			{
				defaultValue: 0,
				fieldKey: 'security-number',
				fieldLabel: 'Security number',
				fieldType: 'NUMBER',
				formFieldId: 33,
				required: true,
				displayOrder: 2,
			},
			{
				defaultValue: 0,
				fieldKey: 'exp-month',
				fieldLabel: 'Expiry month',
				fieldType: 'NUMBER',
				formFieldId: 34,
				required: true,
				displayOrder: 3,
			},
			{
				defaultValue: 0,
				fieldKey: 'exp-year',
				fieldLabel: 'Expiry year',
				fieldType: 'NUMBER',
				formFieldId: 35,
				required: true,
				displayOrder: 4,
			},
			{
				defaultValue: '',
				fieldKey: 'signature',
				fieldLabel: 'Signature',
				fieldType: 'STRING',
				formFieldId: 30,
				required: true,
				displayOrder: 5,
			},
		],
	},
	{
		formId: 12,
		formName: 'Property Management License Agreement',
		displayLabel: 'Sign Agreement',
		submitEmail: ['email@address.com'],
		submitLabel: 'Sign Up',
		fields: [
			{
				defaultValue: '',
				fieldKey: 'full-name',
				fieldLabel: 'Full name',
				fieldType: 'STRING',
				formFieldId: 36,
				required: true,
				displayOrder: 0,
			},
			{
				defaultValue: '',
				fieldKey: 'email',
				fieldLabel: 'Email',
				fieldType: 'STRING',
				formFieldId: 37,
				required: true,
				displayOrder: 1,
			},
			{
				defaultValue: 0,
				fieldKey: 'phone',
				fieldLabel: 'Phone number',
				fieldType: 'PHONE',
				formFieldId: 38,
				required: true,
				displayOrder: 2,
			},
			{
				defaultValue: '',
				fieldKey: 'association',
				fieldLabel: 'Which association or MLS are you with',
				fieldType: 'STRING',
				formFieldId: 39,
				required: true,
				displayOrder: 3,
			},
			{
				defaultValue: '',
				fieldKey: 'headshot',
				fieldLabel: 'Submit headshot',
				fieldType: 'STRING',
				formFieldId: 40,
				required: true,
				displayOrder: 4,
			},
		],
	},
]
