import { LoKationButton } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalHeader } from '@components/modal/modal-header'
import { useNavigate } from 'react-router-dom'

import { EndUserProps } from '../../services/user/user.types'

interface LegacySubscriberWelcomeModalProps {
	dismissModal: () => void
	currentUser: EndUserProps | null
}

function LegacySubscriberWelcomeModal({ dismissModal, currentUser }: LegacySubscriberWelcomeModalProps) {
	const getUserId = currentUser?.endUserId
	const navigate = useNavigate()

	const handleClick = () => {
		navigate(`/administration/users/${getUserId}`, {
			state: { activeTab: 'billingComponent' },
		})
		sessionStorage.setItem('hasSeenLegacyModal', 'true')
	}

	return (
		<>
			<Modal
				onClose={dismissModal}
				maxWidth={800}
				maxHeight={800}
				fixedHeight={false}
				className="primary overflow-y__scroll"
			>
				<ModalHeader title="">
					<></>
				</ModalHeader>
				<ModalBody className="">
					<h3 className="mb-10">Introducing the New Sphere!</h3>
					<div className="mb-10">
						We're excited to announce our latest system designed to elevate your experience. The New Sphere
						is an ever-evolving platform, continuously upgrading with new features and cutting-edge
						technology.
					</div>
					<div className="mb-10">
						Your feedback is invaluable to us. If you come across any issues, errors, or have feature
						requests, simply click the Profile Menu on the top right, then select "Feedback." We're eager to
						hear from you and use your insights to shape the New Sphere's future.
					</div>
					<div className="mb-10">
						Together, we'll create a platform that caters to your needs. Join us on this incredible journey
						of constant improvement and innovation.
					</div>
					<div className="mb-20">
						Please take a few moments and explore the different methods for payment that are now available
						to you and add your preferred payment method as we migrate into this new system.
					</div>
					<LoKationButton
						className="mt-10"
						variant="outlined"
						label="Go to Billing"
						size={'sm'}
						onClick={handleClick}
						icon="arrow-circle-right"
						iconPosition="right"
					/>
				</ModalBody>
			</Modal>
		</>
	)
}

export default LegacySubscriberWelcomeModal
