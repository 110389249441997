import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TagAPI } from '../../services/tags/tag.api'
import { LokationTag } from '../../services/tags/tag.types'

const initialState: LokationTag[] = []

export const getTags = createAsyncThunk('tags/getTags', async () => {
	const res = await TagAPI.getAllTags({ page: 0, size: 100 })
	return res.data.items
})

export const tagsSlice = createSlice({
	name: 'tags',
	initialState,
	reducers: {
		setTags: (state, action: PayloadAction<LokationTag[]>) => {
			return action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getTags.fulfilled, (state, action) => {
			state = action.payload
			return state
		})
	},
})

export const { setTags } = tagsSlice.actions

export default tagsSlice.reducer
