import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { LicensedState } from './licenses-states.types'

export class LicensedStatesAPI {
	static getLicensedStates(): Promise<AxiosResponse<LicensedState[]>> {
		const headers = {}
		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/licensedStates`, {
			headers,
		})
		return requestPromise
	}
}
