import './checkout-form-styles.scss'

import { LoKationButton } from '@components/button/button'
import { TextInput } from '@components/text-input/text-input'
import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { FormEvent, useState } from 'react'
import { useEffect } from 'react'
import { StripeCouponAPI } from 'src/services/stripe/stripe.coupon.api'
import { StripeTypes } from 'src/services/stripe/stripe.types'
import { ToastService } from 'src/services/toast/toast.service'

import { AnalyticsService } from '../../../../services/analytics/analytics.service'
import { StripeAPI } from '../../../../services/stripe/stripe.api'
import { globalNavigate } from '../../../history-component'
import { RegistrationService } from '../../registration.service'
import { useRegistration, useRegistrationDispatch } from '../../state/registration__state'

function CheckoutForm() {
	const regDispatch = useRegistrationDispatch()

	const regState = useRegistration()

	const stripe = useStripe()
	const elements = useElements()
	const totalServicesCosts = RegistrationService().getCosts(regState.fields)
	const pricingIds = RegistrationService().getPriceIds(regState.fields)
	const [proratedCost, setProratedCost] = useState(0)
	const [couponCode, setCouponCode] = useState<string>('')
	const [couponResponse, setCouponResponse] = useState<StripeTypes.CouponValidatedResponse | null>(null)

	let hasBluePlan = false
	let monthlyCost = 0
	let quarterlyCost = 0
	let totalDue = 0

	const promotionCode = localStorage.getItem('promotionCode')

	// Get today's date
	const today = new Date()

	// Add 60 days
	const futureDate = new Date(today)
	futureDate.setDate(futureDate.getDate() + 60)

	// Format the date as MM-DD-YYYY
	const futureDateString =
		(futureDate.getMonth() + 1).toString().padStart(2, '0') +
		'-' +
		futureDate.getDate().toString().padStart(2, '0') +
		'-' +
		futureDate.getFullYear()

	useEffect(() => {
		const paymentElementWrapper = document.querySelector('[data-testid="payment-element"]')
		if (paymentElementWrapper) {
			const usBankAccountRadioButton = paymentElementWrapper.querySelector('input[value="us_bank_account"]')
			if (usBankAccountRadioButton) {
				;(usBankAccountRadioButton as HTMLInputElement).click()
			}
		}
		if (regState.paymentFormSubmit) {
			processCheckoutForm()
		}
	}, [regState.paymentFormSubmit])

	useEffect(() => {
		if (regState.isPaymentSuccessful !== 'success') {
			resetPaymentElement()
		}
	}, [regState.isPaymentSuccessful])

	useEffect(() => {
		calculateProratedCost()
	}, [quarterlyCost, regState.fields.licenseInfo])

	useEffect(() => {
		if (regState.isPaymentSuccessful !== 'success') {
			resetPaymentElement()
		}
	}, [regState.isPaymentSuccessful])

	const processCheckoutForm = async () => {
		if (!stripe || !elements) {
			return
		}

		if (totalServicesCosts.optionalServicesCosts.initialTotal > 0) {
			await stripe
				.confirmPayment({
					elements,
					confirmParams: {},
					redirect: 'if_required',
				})
				.then(async function (result) {
					console.log(result)
					if (result.error) {
						console.log('Payment failed!')
						regDispatch({ type: 'set-payment-form-submit', payload: false })
					} else {
						console.log('Payment Succeeded!')

						const paymentMethodId = result.paymentIntent?.payment_method as string

						AnalyticsService().pushEvent({
							event_category: 'navigation',
							event_label: 'user_registered',
							value: {
								email: regState.fields.email,
								first_name: regState.fields.firstName,
								last_name: regState.fields.lastName,
							},
						})

						if (paymentMethodId) {
							// Attach the payment method to the customer
							StripeAPI.attachPaymentMethod(paymentMethodId)
								.then((res) => {
									// console.log(res.success)
									console.log('payment method was added')
									StripeAPI.setDefaultPaymentMethod(paymentMethodId)
										.then((res) => {
											console.log('default payment method was set')
											// Get the price IDs for the subscription
											const priceIdsArray = pricingIds.priceIds
											const futurePriceIdsArray = pricingIds.futurePriceIds
											// Create the new subscription
											StripeAPI.createNewSubscription(priceIdsArray, futurePriceIdsArray)
												.then((res) => {
													// StripeAPI.createNewSubscription(priceIdsArray).then((res) => {
													regDispatch({
														type: 'set-is-payment-successful',
														payload: 'success',
													})
													console.log('Subscription was created')
													globalNavigate(`/create-account-success`)
												})
												.catch((error) => {
													console.log('there was an error creating the subscriptions')
													regDispatch({ type: 'set-payment-form-submit', payload: false })
													regDispatch({
														type: 'set-is-payment-successful',
														payload: 'failed',
													})
													console.log(error)
												})
										})
										.catch((error) => {
											console.log('there was an error setting the default payment method')
											console.log(error)
										})
								})
								.catch((error) => {
									console.log('payment method was not added')
									console.log(error)
								})
						}
					}
				})
		} else {
			await stripe
				.confirmSetup({
					elements,
					confirmParams: {},
					redirect: 'if_required',
				})
				.then(async function (result) {
					console.log(result)
					if (result.error) {
						console.log('Payment failed!')
						regDispatch({ type: 'set-payment-form-submit', payload: false })
						ToastService().create({
							type: 'ERROR',
							body: `Payment failed`,
						})
					} else {
						console.log('Payment Succeeded!')

						const paymentMethodId = result.setupIntent?.payment_method as string
						console.log(paymentMethodId)

						if (paymentMethodId) {
							StripeAPI.setDefaultPaymentMethod(paymentMethodId)
								.then((res) => {
									console.log('default payment method was set')
									// Get the price IDs for the subscription
									const priceIdsArray = pricingIds.priceIds
									const futurePriceIdsArray = pricingIds.futurePriceIds

									const emptyEndUserId = null
									// Create the new subscription

									let couponToUse
									if (couponCode && !promotionCode) {
										couponToUse = couponCode
									} else if (promotionCode) {
										couponToUse = promotionCode
									}
									console.log('Promo Code: ', couponToUse)
									StripeAPI.createNewSubscription(
										priceIdsArray,
										futurePriceIdsArray,
										emptyEndUserId,
										couponToUse,
									)
										.then((res) => {
											// StripeAPI.createNewSubscription(priceIdsArray).then((res) => {
											console.log('Subscription was created')
											regDispatch({ type: 'set-is-payment-successful', payload: 'success' })
											globalNavigate(`/create-account-success`)
										})
										.catch((error) => {
											console.log('there was an error creating the subscriptions')
											regDispatch({ type: 'set-payment-form-submit', payload: false })
											regDispatch({ type: 'set-is-payment-successful', payload: 'failed' })
											// toast.error(`Error creating subscription. Please contact an administrator`)
											console.log(error)
										})
								})
								.catch((error) => {
									console.log('there was an error setting the default payment method')
									console.log(error)
								})
						}
					}
				})
		}
	}

	const handleCheckoutFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		processCheckoutForm()
	}

	const [paymentElementKey, setPaymentElementKey] = useState(0)

	const resetPaymentElement = () => {
		setPaymentElementKey((prevKey) => prevKey + 1)
	}

	function calculateProratedCost() {
		let quarterlyCostForProration = quarterlyCost

		const startDate = new Date()

		const billingDates = [
			new Date(startDate.getFullYear(), 0, 16),
			new Date(startDate.getFullYear(), 3, 16),
			new Date(startDate.getFullYear(), 6, 16),
			new Date(startDate.getFullYear(), 9, 16),
		]

		// Find the next billing date
		let nextBillingDate = billingDates.find((date) => date > startDate)
		if (!nextBillingDate) {
			nextBillingDate = new Date(startDate.getFullYear() + 1, 0, 16)
		}

		const oneDay = 24 * 60 * 60 * 1000 // milliseconds in a day
		const daysUntilNextBilling = (nextBillingDate.getTime() - startDate.getTime()) / oneDay
		const daysInQuarter = 365 / 4 // Approximate days in a quarter

		if (quarterlyCost > 50) {
			quarterlyCostForProration = 50
		}

		const initialProration = Number(((quarterlyCostForProration / daysInQuarter) * daysUntilNextBilling).toFixed(2))
		setProratedCost(initialProration)
		console.log(proratedCost)
	}

	function couponValidation(coupon: string) {
		StripeCouponAPI.validateCoupon(coupon).then((res) => {
			setCouponResponse(res)
		})
	}

	return (
		<div>
			<form onSubmit={handleCheckoutFormSubmit}>
				<div className="mt-20">
					<PaymentElement
						key={paymentElementKey}
						data-testid="payment-element"
						options={{
							paymentMethodOrder: ['card', 'apple_pay', 'google_pay'],
							layout: {
								type: 'tabs',
								defaultCollapsed: false,
								radios: true,
								spacedAccordionItems: false,
							},
						}}
					/>
				</div>
				{/* <button className="button contained lg primary mr-10 mt-20">Pay</button> */}
			</form>

			{promotionCode === 'LOKATION60' && (
				<>
					<div className="mb-5 mt-20">
						Promo code{' '}
						<span className="promoCode">
							{' '}
							<FontAwesomeIcon icon="tag" /> LOKATION60
						</span>{' '}
						applied
					</div>
					<em>You will not be billed for your Lokation Plan until {futureDateString}</em>
				</>
			)}

			<div className="col-12 mt-20">
				<div className="flex flex-column-sm-down" style={{ maxWidth: '860px' }}>
					<div className="flex-fillSpace pr-20">
						<h4 className="mt-40 mb-20">Purchase Summary</h4>
						<div className="border-bottom-thin pb-10">
							<h6>Agent License Plans</h6>
						</div>

						<div className="flex flex-column mb-30">
							{regState.fields.licenseInfo.map((license) => {
								if (!license.licensedState) {
									return (
										<div>
											<em>You have not added any licenses</em>
										</div>
									)
								}
								const stateEnum = license.licensedState
								if (!stateEnum || !license.plan) {
									return <></>
								}
								const licenseProps = RegistrationService().findLicensePlans(
									stateEnum.abbreviation,
									license.plan,
								)
								if (!licenseProps) {
									return <></>
								}

								if (licenseProps.plan === 'BLUE') {
									if (hasBluePlan) {
										licenseProps.price = '$20/month'
										licenseProps.recurringCost = 20
									} else {
										hasBluePlan = true
									}
								}

								if (licenseProps.subscriptionType === 'monthly') {
									monthlyCost += licenseProps.recurringCost
								}

								if (quarterlyCost < 50) {
									quarterlyCost += licenseProps.complianceFeeRecurringCost
								} else {
									quarterlyCost = 50
								}

								totalDue += licenseProps.recurringCost
								// console.log(quarterlyCost)
								// console.log(licenseProps)
								// Calculate the number of quarterly fees
								return (
									<div className="col-xs-12 py-10" key={licenseProps.licenseEnum}>
										<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween">
											<div>
												<strong>{licenseProps.label}</strong>
											</div>
											<div>
												<em>{licenseProps.price}</em>
											</div>
										</div>
									</div>
								)
							})}

							<div
								className="flex flex-justifyContent-end col-xs-12  py-10"
								style={{ maxWidth: '860px' }}
							>
								<div style={{ fontSize: '.9em' }}>
									<em>
										The quarterly compliance fee is billed on January 15, April 15, July 15, and
										October 15. If you join the company mid-quarter, the first charge is prorated
										based on the remaining days until the next billing date.
									</em>{' '}
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-8 billing-breakdown p-20">
						<div>
							<div className="flex flex-alignItems-end">
								<TextInput
									width={'100%'}
									dataType="text"
									label="Coupon Code"
									value={couponCode}
									onChange={(updatedValue) => {
										setCouponCode(updatedValue)
									}}
									className={`mb-10 mr-10`}
									icon={{
										name: 'trash-can',
										onClick: () => {
											setCouponCode('')
											setCouponResponse(null)
										},
									}}
								/>
								<LoKationButton
									variant="contained"
									size="lg"
									label="Apply"
									onClick={() => {
										if (couponCode !== '') {
											couponValidation(couponCode)
										}
									}}
									className="mb-10"
								/>
							</div>
							<div className="border-bottom-thin pb-10 mb-20">
								{couponResponse && couponResponse.valid && (
									<>
										<em>
											You will receive{' '}
											{couponResponse.amountOff
												? '$' + (couponResponse.amountOff / 100).toFixed(2)
												: couponResponse.percentOff + '%'}{' '}
											off
										</em>
										{/* <div className="mb-5 mt-20">
											Coupon code{' '}
											<span className="promoCode">
												{' '}
												<FontAwesomeIcon icon="tag" /> {couponResponse.name}
											</span>{' '}
											applied
										</div> */}
									</>
								)}
							</div>
							<div>
								{totalServicesCosts.licensePlansCosts.initialMonthly > 0 && (
									<div className="flex flex-justifyContent-spaceBetween col-xs-12 mb-10">
										<em className="mr-10">Monthly cost</em>{' '}
										<div className="cost-num">${monthlyCost}</div>
									</div>
								)}

								{totalServicesCosts.licensePlansCosts.initialYearly > 0 && (
									<div className="flex flex-justifyContent-spaceBetween col-xs-12 mb-10">
										<em className="mr-10">Annual cost</em>{' '}
										<div className="cost-num">
											${totalServicesCosts.licensePlansCosts.initialYearly}
										</div>
									</div>
								)}
								<div className="flex flex-justifyContent-spaceBetween col-xs-12 pb-10">
									<div className="mr-10">
										<em>Prorated Quarterly Compliance Fee</em>{' '}
									</div>{' '}
									<div className="cost-num">{'$' + proratedCost.toFixed(2)}</div>
								</div>
								{couponResponse && couponResponse.valid && (
									<>
										<div className="flex flex-justifyContent-spaceBetween col-xs-12 pb-10">
											<div className="mr-10">
												<em>Coupon Discount</em>{' '}
											</div>{' '}
											<div className="cost-num">
												{couponResponse.amountOff
													? `$${(couponResponse.amountOff / 100).toFixed(2)}`
													: `${couponResponse.percentOff}% ($${((99 * couponResponse.percentOff) / 100).toFixed(2)})`}

												{/* <div className="mb-5 mt-20">
											Coupon code{' '}
											<span className="promoCode">
												{' '}
												<FontAwesomeIcon icon="tag" /> {couponResponse.name}
											</span>{' '}
											applied
										</div> */}
											</div>
										</div>
									</>
								)}
								<div className="flex flex-justifyContent-spaceBetween pt-10">
									<strong className="mr-10">
										<em>Total due</em>
									</strong>{' '}
									{(!couponResponse || !couponResponse.valid) &&
										`$${(totalDue + proratedCost).toFixed(2)}`}
									{couponResponse && couponResponse.valid && couponResponse.amountOff != null && (
										<div className="cost-num">
											${(totalDue + proratedCost - couponResponse.amountOff / 100).toFixed(2)}
										</div>
									)}
									{couponResponse && couponResponse.valid && couponResponse.percentOff != null && (
										<div className="cost-num">
											$
											{(totalDue + proratedCost - 99 * (couponResponse.percentOff / 100)).toFixed(
												2,
											)}
										</div>
									)}
								</div>
							</div>
						</div>
						<div>
							<>
								<LoKationButton
									variant={'contained'}
									size={'lg'}
									label={
										regState.paymentFormSubmit && regState.isPaymentSuccessful !== 'success'
											? 'Completing Registration...'
											: 'Complete Registration'
									}
									disabled={regState.paymentFormSubmit && regState.isPaymentSuccessful !== 'success'}
									onClick={() => {
										regDispatch({ type: 'set-payment-form-submit', payload: true })
									}}
									style={{ width: '100%' }}
								/>
								{regState.paymentFormSubmit && regState.isPaymentSuccessful !== 'success' && (
									<FontAwesomeIcon icon={faSpinner} spin={true} size={'1x'} className="mr-2 ml-10" />
								)}
							</>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CheckoutForm
