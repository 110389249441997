import _ from 'lodash'
import { createContext, useContext, useReducer } from 'react'
import { AdvertisingTypes } from 'src/services/advertising/advertising.types'

import { AdvertisingAccountState } from './ad-account__state.types'

function initialState(originalState: AdvertisingTypes.Account): AdvertisingAccountState.LocalState {
	return {
		originalState,
		modifiedState: originalState,
	}
}

const AdvertisingAccountContext = createContext(
	initialState({
		organizationName: '',
		address1: '',
		city: '',
		mailingState: '',
		contactFullname: '',
		contactPhone: '',
		contactEmail: '',
		stripeAccountId: '',
		address2: '',
		zip: '',
		accountId: 0,
	}),
)
const AdvertisingAccountDispatchContext = createContext({} as AdvertisingAccountState.DispatchParams)

function UserProfileReducer(state: AdvertisingAccountState.LocalState, action: AdvertisingAccountState.Action) {
	switch (action.type) {
		case 'update-property': {
			const updatedState = _.cloneDeep(state)
			updatedState.modifiedState = { ...updatedState.modifiedState, [action.payload.key]: action.payload.value }
			return updatedState
		}
		case 'update-account': {
			const updatedState = _.cloneDeep(state)
			updatedState.modifiedState = action.payload
			return updatedState
		}
	}
}

export function AdvertisingAccountProvider(props: {
	accountToEdit: AdvertisingTypes.Account
	children: React.ReactNode
}) {
	const [state, dispatch] = useReducer(UserProfileReducer, initialState(props.accountToEdit))

	return (
		<AdvertisingAccountContext.Provider value={state}>
			<AdvertisingAccountDispatchContext.Provider value={dispatch}>
				{props.children}
			</AdvertisingAccountDispatchContext.Provider>
		</AdvertisingAccountContext.Provider>
	)
}

export function useAdvertisingAccount() {
	return useContext(AdvertisingAccountContext)
}

export function useAdvertisingAccountDispatch() {
	return useContext(AdvertisingAccountDispatchContext)
}
