import { LoKationButton } from '@components/button/button'
import { LoginRouteWrapper } from '@components/login-route-wrapper/login-route-wrapper'
import { TextInput } from '@components/text-input/text-input'
import { useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { ToastService } from 'src/services/toast/toast.service'

import { UserAPI } from '../../services/user/user.api'

export interface ResetPasswordParams {
	userId: null | number
	resetToken: null | string
}

export default function ResetPasswordRoute() {
	let resetPasswordParams = useLoaderData() as ResetPasswordParams
	const [userId] = useState(resetPasswordParams.userId)
	const [resetToken] = useState(resetPasswordParams.resetToken)
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const navigate = useNavigate()

	function resetPassword(): void {
		if (userId && resetToken) {
			UserAPI.resetPassword({
				endUserId: userId,
				password,
				repeatPassword: confirmPassword,
				token: resetToken,
			}).then((res) => {
				ToastService().create({
					type: 'SUCCESS',
					body: `Your password has been reset`,
				})
				navigate(`/`)
			})
		}
	}

	function validateOriginalPassword(value: string): boolean {
		return value.length > 0
	}

	function validateRepeatPassword(value: string): boolean {
		return value === password
	}

	function isResetFormValid(): boolean {
		if (!password) {
			return false
		}
		if (!confirmPassword) {
			return false
		}
		if (password !== confirmPassword) {
			return false
		}
		return true
	}

	const canUserReset = typeof userId === 'number' && typeof resetToken === 'string'

	return (
		<LoginRouteWrapper
			isLoginRoute={false}
			bodyClassName="flex flex-column flex-justifyContent-center flex-fillSpace flex-alignItems-center col-xs-12"
		>
			<div className="flex flex-alignItems-center flex-column clamp-width-for-login-routes">
				<h2 className="text-center mb-20">Reset password</h2>

				{canUserReset && (
					<>
						<div style={{ width: '100%' }}>
							<TextInput
								width={`100%`}
								dataType={'text'}
								placeholder="Password"
								className="mb-20"
								value={password}
								onChange={(updatedValue) => {
									setPassword(String(updatedValue))
								}}
								type={'password'}
								validation={{
									isValid: (value) => {
										return {
											isValid: validateOriginalPassword(value),
											showValidationFlag: true,
										}
									},
									message: `Must contain a password`,
								}}
							/>
							<TextInput
								width={`100%`}
								dataType={'text'}
								placeholder="Confirm Password"
								className="mb-20"
								value={confirmPassword}
								onChange={(updatedValue) => {
									setConfirmPassword(String(updatedValue))
								}}
								type={'password'}
								validation={{
									isValid: (value) => {
										return {
											isValid: validateRepeatPassword(value),
											showValidationFlag: true,
										}
									},
									message: `This does not match password`,
								}}
							/>
						</div>

						<LoKationButton
							label="Reset Password"
							variant="contained"
							size={'lg'}
							disabled={!isResetFormValid()}
							onClick={resetPassword}
							className={`col-xs-12`}
						/>
					</>
				)}
				{!canUserReset && (
					<>
						<strong>You cannot reset your password at this time</strong>
					</>
				)}
			</div>
		</LoginRouteWrapper>
	)
}
