import { ModalTypes } from './modal.types'

export function ModalBody(props: ModalTypes.Body) {
	function getClass(): string {
		let classes: string[] = []
		let classString = ''

		classes.push(`flex-fillSpace overflow-y__scroll`)

		classes.push(`modal-body__pt`)
		classes.push(`modal-body__pb`)
		classes.push(`modal-body__pl`)
		classes.push(`modal-body__pr`)

		if (props.omitPadding) {
			props.omitPadding.forEach((direction) => {
				switch (direction) {
					case 'top':
						classes = classes.filter((thisClass) => thisClass !== 'modal-body__pt')
						break
					case 'bottom':
						classes = classes.filter((thisClass) => thisClass !== 'modal-body__pb')
						break
					case 'left':
						classes = classes.filter((thisClass) => thisClass !== 'modal-body__pl')
						break
					case 'right':
						classes = classes.filter((thisClass) => thisClass !== 'modal-body__pr')
						break
					case 'all':
						classes = classes.filter((thisClass) => thisClass !== 'modal-body__pt')
						classes = classes.filter((thisClass) => thisClass !== 'modal-body__pb')
						classes = classes.filter((thisClass) => thisClass !== 'modal-body__pl')
						classes = classes.filter((thisClass) => thisClass !== 'modal-body__pr')
						break
				}
			})
		}

		if (props.className) {
			classString = `${props.className} `
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	return <div className={getClass()}>{props.children}</div>
}
