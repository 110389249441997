import './detailed-resource-admin__card.scss'

import { IconButton } from '@components/icon-button/icon-button'

import { LokationResource } from '../../../../services/resources/resources.types'
import { Utilities } from '../../../../services/utilities.service'

interface DetailedResourceAdminCardProps {
	onClick: () => void
}

export function DetailedResourceAdminCard(props: { resource: LokationResource } & DetailedResourceAdminCardProps) {
	return (
		<div className={`detailed-resource-admin__card child`}>
			<div className="flex">
				<div className="detailed-resource-card-item">
					<div>{Utilities.truncateString(props.resource.title, 100)}</div>
				</div>

				<div className="detailed-resource-card-item">
					<div>{Utilities.truncateString(props.resource.description, 100)}</div>
				</div>

				<div className="detailed-resource-card-item" style={{ wordBreak: 'break-all' }}>
					{props.resource.url && <div>{props.resource.url}</div>}
				</div>

				<div className="detailed-resource-card-item" style={{ alignContent: 'start' }}>
					{props.resource.licensedStates.map((state, index) => {
						if (index < props.resource.licensedStates.length - 1) {
							return <div>{state.stateName},&nbsp;</div>
						}
						return <div>{state.stateName}</div>
					})}
				</div>

				<div className="detailed-resource-card-item" style={{ alignContent: 'start' }}>
					{props.resource.tags.map((state, index) => {
						if (index < props.resource.tags.length - 1) {
							return <div>{state.label},&nbsp;</div>
						}
						return <div>{state.label}</div>
					})}
				</div>

				<div className="flex">
					<IconButton
						style={{
							padding: '4px',
						}}
						onClick={props.onClick}
						icon={'pencil'}
					/>
				</div>
			</div>
		</div>
	)
}
