import { LokationTag } from '../../services/tags/tag.types'
import { MultiSelectDropdown } from '../multiselect-dropdown/multiselect-dropdown'

export interface TagSelectorDropdownProps {
	selectedTags: Array<LokationTag>
	options: LokationTag[]
	onSelect: (selectedOptions: LokationTag[]) => void
}

export function TagSelectorDropdown(props: TagSelectorDropdownProps) {
	return (
		<MultiSelectDropdown<LokationTag>
			selectedOptions={props.selectedTags}
			options={props.options}
			onSelect={props.onSelect}
			optionIdKey={'tagId'}
			optionLabelKey={'label'}
		/>
	)
}
