import './user-profile.scss'

import { LoKationButton } from '@components/button/button'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppDispatch } from '@redux/hooks'
import { CurrentSessionSliceProps } from '@redux/reducers/current-session-reducer'
import { RootState } from '@redux/store'
import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { UserUpdateService } from 'src/services/user/user-update-service'

import { UserService } from '../../../services/user/user.service'
import { EndUserProps } from '../../../services/user/user.types'
import AddOnServices from './components/add-on-services'
import AdminTools from './components/admin-tools/admin-tools'
import AgentLicenses from './components/agent-licenses'
import BillingComponent from './components/billing'
import DocsAndForms from './components/docs-and-forms'
import PersonalInformation from './components/personal-information'
import { SidebarNavigation } from './components/profile-sidebar-nav'
import Subscriptions from './components/subscriptions'
import { useUserProfile } from './state/user-profile__state'
import { UserProfileService } from './user-profile.service'

interface UserProfileTabState {
	activeTab?: string
}

interface ConnectedProps {
	currentUser: EndUserProps | null
	currentSession: CurrentSessionSliceProps
}

function UserProfileEditorPrototype(props: { userToEdit: EndUserProps } & ConnectedProps) {
	const userProfileState = useUserProfile()
	const token = props.currentSession.token
	const mergedUserProps = UserProfileService().applyModifiedStateToOriginalProps(
		userProfileState.modifiedProps,
		props.userToEdit,
	)
	const canUserEdit = props.currentUser ? UserService.isUserAdmin(props.currentUser) : false
	const dispatch = useAppDispatch()

	const [activeComponent, setActiveComponent] = useState('personalInfo')
	const location = useLocation()
	const userProfileBodyRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const state = location.state as UserProfileTabState
		if (state?.activeTab) {
			setActiveComponent(state.activeTab)
		}
	}, [location])

	/** Scroll to the top whenever the route changes */
	useEffect(() => {
		if (userProfileBodyRef.current) {
			userProfileBodyRef.current.scrollTo({ top: 0 })
		}
	}, [activeComponent])

	const handleNavigationClick = (component: string) => {
		setActiveComponent(component)
	}

	return (
		<RouteWrapperV2>
			<SidebarNavigation activeComponent={activeComponent} onNavigationClick={handleNavigationClick} />

			<RouteBodyV2 ref={userProfileBodyRef}>
				{!canUserEdit && activeComponent === 'personalInfo' && (
					<div
						className="border-thin px-20 py-10 mb-30 flex flex-alignItems-center border-radius__std"
						style={{ maxWidth: `800px` }}
					>
						<FontAwesomeIcon icon={'pen'} className={`mr-15`}></FontAwesomeIcon>
						<strong>
							Certain profile fields may only be edited by an administrator due to billing and accounting
							requirements. Please contact LoKation (
							<a href="mailto:support@lokationre.com">support@lokationre.com</a>) in order to request
							changes if necessary.
						</strong>
					</div>
				)}

				{activeComponent === 'personalInfo' && (
					<section id="personal-info">
						<PersonalInformation userToEdit={props.userToEdit} currentUser={props.currentUser} />
					</section>
				)}

				{activeComponent === 'agentLicenses' && (
					<section id="agent-licenses">
						<AgentLicenses userToEdit={props.userToEdit} currentUser={props.currentUser} />
					</section>
				)}

				{activeComponent === 'billingComponent' && (
					<section id="billing-component">
						<BillingComponent userToEdit={props.userToEdit} currentUser={props.currentUser} />
					</section>
				)}

				{activeComponent === 'subscriptions' && (
					<section id="subscriptions">
						<Subscriptions userToEdit={props.userToEdit} currentUser={props.currentUser} />
					</section>
				)}

				{activeComponent === 'addOnServices' && (
					<section id="addOnServices">
						<AddOnServices userToEdit={props.userToEdit} currentUser={props.currentUser} />
					</section>
				)}

				{activeComponent === 'docsAndForms' && (
					<section id="docsAndForms">
						<DocsAndForms userToEdit={props.userToEdit} currentUser={props.currentUser} />
					</section>
				)}

				{activeComponent === 'adminTools' && (
					<section id="adminTools">
						<AdminTools userToEdit={props.userToEdit} currentUser={props.currentUser} />
					</section>
				)}

				{!['docsAndForms', 'addOnServices', 'subscriptions', 'billingComponent'].includes(activeComponent) && (
					<LoKationButton
						size={'lg'}
						label="Save Changes"
						className="mb-20"
						onClick={() => {
							const currentUserId = token?.id
							if (!currentUserId) {
								return
							}
							UserUpdateService(dispatch, mergedUserProps, userProfileState, currentUserId)
						}}
						variant="contained"
					/>
				)}
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
		currentSession: state.currentSession,
	}
}

export const UserProfileEditor = connect(mapStateToProps)(UserProfileEditorPrototype)
