import _ from 'lodash'
import { createContext, useContext, useReducer } from 'react'

import { EditCalendarEventState } from './edit-calendar-event__state.types'

function getInitialState(event: EditCalendarEventState.LocalState): EditCalendarEventState.LocalState {
	return event
}

/** @ts-ignore */
const EditCalendarEventContext = createContext(getInitialState())

const EditCalendarEventDispatchContext = createContext({} as EditCalendarEventState.DispatchParams)

function EditCalendarEventReducer(state: EditCalendarEventState.LocalState, actions: EditCalendarEventState.Actions) {
	let updatedState = _.cloneDeep(state)
	actions.forEach((action) => {
		switch (action.type) {
			case 'update-event-props': {
				updatedState = { ...state, ...action.payload }
				break
			}
		}
	})
	return updatedState
}

export function EditCalendarEventProvider(props: { children: JSX.Element; event: EditCalendarEventState.LocalState }) {
	const [state, dispatch] = useReducer(EditCalendarEventReducer, getInitialState(props.event))

	return (
		<EditCalendarEventContext.Provider value={state}>
			<EditCalendarEventDispatchContext.Provider value={dispatch}>
				{props.children}
			</EditCalendarEventDispatchContext.Provider>
		</EditCalendarEventContext.Provider>
	)
}

export function useEditCalendarEvent() {
	return useContext(EditCalendarEventContext)
}

export function useEditCalendarEventDispatch() {
	return useContext(EditCalendarEventDispatchContext)
}
