import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'

import { UserService } from '../../../../services/user/user.service'
import { EndUserProps } from '../../../../services/user/user.types'

type SidebarNavigationProps = {
	activeComponent: string
	onNavigationClick: (component: string) => void
	currentUser: EndUserProps | null
}

function SidebarNavigationPrototype(props: SidebarNavigationProps) {
	const canUserEdit = props.currentUser ? UserService.isUserAdmin(props.currentUser) : false

	return (
		<RouteSubnavigation
			title={`User Profile`}
			isCustom={false}
			routes={[
				{
					label: 'Personal Information',
					onClick: () => props.onNavigationClick('personalInfo'),
					id: 'personalInfo',
					isActive: props.activeComponent === 'personalInfo',
				},
				{
					label: 'Agent Licenses',
					onClick: () => props.onNavigationClick('agentLicenses'),
					id: 'agentLicenses',
					isActive: props.activeComponent === 'agentLicenses',
				},
				{
					label: 'Billing',
					onClick: () => props.onNavigationClick('billingComponent'),
					id: 'billingComponent',
					isActive: props.activeComponent === 'billingComponent',
				},
				{
					label: 'Subscriptions',
					onClick: () => props.onNavigationClick('subscriptions'),
					id: 'subscriptions',
					isActive: props.activeComponent === 'subscriptions',
				},
				{
					label: 'Add-On Services',
					onClick: () => props.onNavigationClick('addOnServices'),
					id: 'addOnServices',
					isActive: props.activeComponent === 'addOnServices',
				},
				{
					label: 'Docs and Forms',
					onClick: () => props.onNavigationClick('docsAndForms'),
					id: 'docsAndForms',
					isActive: props.activeComponent === 'docsAndForms',
				},
				{
					label: 'User Administration',
					onClick: () => props.onNavigationClick('adminTools'),
					id: 'adminTools',
					isActive: props.activeComponent === 'adminTools',
					isVisible: canUserEdit,
				},
			]}
		/>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const SidebarNavigation = connect(mapStateToProps)(SidebarNavigationPrototype)
