import './login-route-background.scss'

import { store } from '@redux/store'

export function LoginRouteBackground(props: {
	image?: string
	outerClassName?: string
	innerClassName?: string
	children?: React.ReactNode
}) {
	function getGradient(): string {
		const themeProps = store.getState().theme

		return `linear-gradient(145deg, ${themeProps.buttonBgColor} 0%, ${themeProps.colorAccent} 100%)`
	}

	return (
		<div className={`flex login__img-bg__wrapper ${props.outerClassName ? props.outerClassName : ''}`}>
			<div className="login__img-bg" style={{ backgroundImage: `url(${props.image})` }}></div>
			<div
				className={`login__img-gradient ${props.innerClassName ? props.innerClassName : ''}`}
				style={{ background: getGradient() }}
			></div>
			<div className="login__carousel-body">{props.children && <>{props.children}</>}</div>
		</div>
	)
}
