import './hub-admin__card.scss'

import { ColorBlock } from '@components/color-block/color-block'

import { Hub } from '../../../../services/hubs/hubs.types'
import { Utilities } from '../../../../services/utilities.service'

interface HubAdminCardProps {
	onClick: () => void
}

export function HubAdminCard(props: { hub: Hub } & HubAdminCardProps) {
	return (
		<div className={`hub-admin__card`} onClick={props.onClick}>
			<div className="flex flex-column">
				<div className="flex flex-alignItems-center">
					<ColorBlock color={props.hub.color} className="mr-10" />
					<strong>{props.hub.title}</strong>
				</div>
				<div>{Utilities.truncateString(props.hub.description, 200)}</div>
			</div>
		</div>
	)
}
