import { LokationTag } from '../tags/tag.types'
import { ValidationResult } from '../validation/validation.types'
import { FAQ } from './faq.types'

export class FAQService {
	public static validate(faq: Partial<FAQ>): ValidationResult {
		let result: ValidationResult = {
			isValid: true,
			messages: [],
		}

		if (!faq.question || (typeof faq.question === 'string' && faq.question.length === 0)) {
			result.isValid = false
			result.messages.push(`Question cannot be empty`)
		}
		if (!faq.answer || (typeof faq.answer === 'string' && faq.answer.length === 0)) {
			result.isValid = false
			result.messages.push(`Answer cannot be empty`)
		}
		if (!faq.tags || (faq.tags && faq.tags.length === 0)) {
			result.isValid = false
			result.messages.push(`Must contain at least one tag`)
		}

		return result
	}

	public static doesFAQIncludeTag(item: FAQ, selectedTags: LokationTag[]): boolean {
		const selectedTagIds = selectedTags.map((tag) => tag.tagId)
		let isTagInItem = false
		item.tags.forEach((tag) => {
			if (selectedTagIds.includes(tag.tagId)) {
				isTagInItem = true
			}
		})
		if (isTagInItem) {
			return true
		}

		return false
	}
}
