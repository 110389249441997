import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UIState {
	viewMode: 'FULL' | 'MOBILE'
	isMobileTrayOpen: boolean
	isTrayDocked: boolean
	showUserOnboardingModal: boolean
	showUserOnboardingTutorial: boolean
}

const cachedTrayState = localStorage.getItem(`desktop-tray__is-expanded`) as string | undefined

const initialState: UIState = {
	viewMode: 'FULL',
	isMobileTrayOpen: false,
	isTrayDocked: cachedTrayState === 'true' ? true : false,
	showUserOnboardingModal: false,
	showUserOnboardingTutorial: false,
}

export const uiSlice = createSlice({
	name: 'ui',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		dockTray: (state) => {
			state.isTrayDocked = true
		},
		undockTray: (state) => {
			state.isTrayDocked = false
		},
		setViewModeFull: (state) => {
			state.viewMode = 'FULL'
		},
		setViewModeMobile: (state) => {
			state.viewMode = 'MOBILE'
		},
		setMobileTrayState: (state, action: PayloadAction<boolean>) => {
			state.isMobileTrayOpen = action.payload
		},
		setShowUserOnboardingModal: (state, action: PayloadAction<boolean>) => {
			state.showUserOnboardingModal = action.payload
		},
		setShowUserOnboardingTutorial: (state, action: PayloadAction<boolean>) => {
			state.showUserOnboardingTutorial = action.payload
		},
	},
})

export const { dockTray, undockTray, setViewModeFull, setViewModeMobile, setMobileTrayState } = uiSlice.actions

export default uiSlice.reducer
