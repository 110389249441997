import { MessagesAdminInner } from './messages-admin__inner'
import { MessagesAdminProvider } from './state/messages-admin__state'

export function MessagesAdminProviderWrapper() {
	return (
		<MessagesAdminProvider>
			<MessagesAdminInner />
		</MessagesAdminProvider>
	)
}
