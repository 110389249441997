import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { UIState } from '@redux/reducers/ui-reducer'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DomainTheme } from 'src/services/theme/theme.types'

import { UserService } from '../../../services/user/user.service'
import { EndUserProps } from '../../../services/user/user.types'
import { Stripe__Route__Coupons } from './routes/coupons/stripe__route__coupons'

type ConnectedProps = {
	userProfile: EndUserProps | null
	ui: UIState
	theme: DomainTheme.Theme
}

function StripeAdministrationPrototype(props: ConnectedProps) {
	const navigate = useNavigate()

	const isUserAdmin = props.userProfile ? UserService.isUserAdmin(props.userProfile) : false
	const [activeComponent, setActiveComponent] = useState('coupons')

	useEffect(() => {
		if (!isUserAdmin) {
			navigate('/hubs')
			// setSearchApiNames([])
		}
	}, [isUserAdmin])

	return (
		<>
			<RouteWrapperV2>
				<RouteSubnavigation
					title={`Stripe`}
					backButton={{
						label: 'Administration',
						onClick: () => {
							navigate('/administration')
						},
					}}
					isCustom={false}
					routes={[
						{
							label: 'Coupons',
							icon: 'tag',
							onClick: () => {
								setActiveComponent(`coupons`)
							},
							id: 'coupons',
							isActive: activeComponent === 'coupons',
						},
					]}
				/>

				<RouteBodyV2 id="form-admin__route-body">
					{/* <div className="mb-20 opacity-50">{apiStatusAdminHook.totalElements} Results</div> */}

					<div className="">
						{activeComponent === 'coupons' && (
							<section id="coupons">
								<Stripe__Route__Coupons />
							</section>
						)}
					</div>
				</RouteBodyV2>
			</RouteWrapperV2>
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
		ui: state.ui,
		theme: state.theme,
	}
}

export const StripeAdministrationRoute = connect(mapStateToProps)(StripeAdministrationPrototype)
