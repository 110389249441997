import Bellhop from './bellhop.png'

export function LoKationResourceBellhopRoute() {
	return (
		<div className="flex flex-column overflow-y__scroll">
			<div className="flex flex-wrap">
				<div className="col-xs-12 col-sm-6 pr-20-sm">
					<img src={Bellhop} style={{ height: '60px' }} />
					<h5>The Modern Alternative to Traditional Moving Companies</h5>
					<p>
						You gave them he best home buying experience, but the journey of owning a new home doesn't end
						when the papers are signed. Bellhop ensures your clients have the best moving experience, too!
					</p>
					<ul>
						<li>Easy, reliable booking</li>
						<li>The nation's best reviews</li>
						<li>10% off for your clients</li>
					</ul>
					<p>... and happy homeowners lead to more referrals!</p>
				</div>
				<div className="col-xs-12 col-sm-6 pl-20-sm">
					<iframe
						title="Bellhop Video"
						className="col-xs-12 height__100pct"
						src="https://player.vimeo.com/video/623716220?api=1&autoplay=0&loop=0&mute=0&controls=1&portrait=1&byline=1&title=1&color=00adef"
					></iframe>
				</div>

				<div className="flex mt-40">
					<button
						className="button contained lg primary mr-10"
						onClick={() => {
							window.open(
								'https://www.getbellhops.com/book/service/?utm_source=LoKation&utm_content=partnerships',
								'_blank',
							)
						}}
					>
						Get Moving
					</button>
				</div>
			</div>
		</div>
	)
}
