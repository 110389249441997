import { Hub } from '../../services/hubs/hubs.types'
import { MultiSelectDropdown } from '../multiselect-dropdown/multiselect-dropdown'

export interface HubSelectorDropdownProps {
	selectedHubs: Hub[]
	options: Hub[]
	onSelect: (selectedOptions: Hub[]) => void
}

export function HubSelectorDropdown(props: HubSelectorDropdownProps) {
	return (
		<MultiSelectDropdown<Hub>
			selectedOptions={props.selectedHubs}
			options={props.options}
			onSelect={props.onSelect}
			optionIdKey={'hubId'}
			optionLabelKey={'title'}
		/>
	)
}
