import { LokationTag } from '../tags/tag.types'
import { ValidationResult } from '../validation/validation.types'
import { NewsFeed } from './news-feed.types'

export class NewsFeedService {
	public static validate(story: Partial<NewsFeed.Story>): ValidationResult {
		let result: ValidationResult = {
			isValid: true,
			messages: [],
		}

		if (!story.title || (typeof story.title === 'string' && story.title.length === 0)) {
			result.isValid = false
			result.messages.push(`Title cannot be empty`)
		}
		if (!story.description || (typeof story.description === 'string' && story.description.length === 0)) {
			result.isValid = false
			result.messages.push(`Body cannot be empty`)
		}
		if (!story.tags || story.tags.length === 0) {
			result.isValid = false
			result.messages.push(`You must select at least one tag`)
		}
		return result
	}

	public static doesStoryIncludeTag(item: NewsFeed.Story, selectedTags: LokationTag[]): boolean {
		const selectedTagIds = selectedTags.map((tag) => tag.tagId)
		let isTagInItem = false
		item.tags.forEach((tag) => {
			if (selectedTagIds.includes(tag.tagId)) {
				isTagInItem = true
			}
		})
		if (isTagInItem) {
			return true
		}

		return false
	}
}
