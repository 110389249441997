import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import ReactQuill from 'react-quill'

import { ImageSelectorModal } from '../../modals/image-selector/image-selector'

export function RichTextEditorToolbar(props: { quillRef: ReactQuill | null }) {
	const [showImageModal, setShowImageModal] = useState(false)
	const [quillCursorPos, setQuillCursorPos] = useState(0)

	function getImageButton(): JSX.Element {
		return (
			<button>
				<FontAwesomeIcon
					icon={['far', 'image']}
					style={{ width: '14px' }}
					onClick={() => {
						// Capture the position of the quill cursor
						if (props.quillRef) {
							const quillInstance = props.quillRef.getEditor()
							const thisCursorPos = quillInstance.getSelection()?.index
							if (typeof thisCursorPos === 'number') {
								setQuillCursorPos(thisCursorPos)
							}
						}

						setShowImageModal(true)
					}}
				/>
			</button>
		)
	}

	function insertImage(url: string): void {
		if (props.quillRef) {
			const quillInstance = props.quillRef.getEditor()
			if (quillInstance) {
				quillInstance.insertEmbed(quillCursorPos, 'image', url)
				quillInstance.setSelection(quillCursorPos + 1, quillCursorPos + 1)
			}
		}
	}

	return (
		<>
			<div id="rte__toolbar">
				<span className="ql-formats">
					<select className="ql-header" defaultValue={''} onChange={(e) => e.persist()}>
						<option value="3" />
						<option value="4" />
						<option value="5" />
						<option selected />
					</select>
				</span>
				<span className="ql-formats">
					<button className="ql-bold"></button>
					<button className="ql-italic"></button>
					<button className="ql-underline"></button>
				</span>
				<span className="ql-formats">
					<select className="ql-color"></select>
				</span>
				<span className="ql-formats">
					<button className="ql-script" value="sub"></button>
					<button className="ql-script" value="super"></button>
				</span>
				<span className="ql-formats">
					<button className="ql-blockquote"></button>
					<button className="ql-code-block"></button>
				</span>
				<span className="ql-formats">
					<button className="ql-list" value="ordered"></button>
					<button className="ql-list" value="bullet"></button>
					<button className="ql-indent" value="-1"></button>
					<button className="ql-indent" value="+1"></button>
				</span>
				<span className="ql-formats">
					<button className="ql-direction" value="rtl"></button>
					<select className="ql-align"></select>
				</span>
				<span className="ql-formats">
					<button className="ql-link"></button>
					{getImageButton()}
				</span>
				<span className="ql-formats">
					<button className="ql-clean"></button>
				</span>
			</div>
			{showImageModal && (
				<ImageSelectorModal
					onCloseModal={() => {
						setShowImageModal(false)
					}}
					onSelectImage={(url) => {
						insertImage(url)
					}}
				/>
			)}
		</>
	)
}
