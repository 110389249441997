import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { HubsAPI } from '../../services/hubs/hubs.api'
import { Hub } from '../../services/hubs/hubs.types'

const initialState: Hub[] = []

export const getHubs = createAsyncThunk('hubs/get', async () => {
	const res = await HubsAPI.getAllHubs({ page: 0, size: 100 })
	return res.data.items
})

export const hubsSlice = createSlice({
	name: 'hubs',
	initialState,
	reducers: {
		setHubs: (state, action: PayloadAction<Hub[]>) => {
			return action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getHubs.fulfilled, (state, action) => {
			state = action.payload
			return state
		})
	},
})

export const { setHubs } = hubsSlice.actions

export default hubsSlice.reducer
