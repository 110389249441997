import React, { useState } from 'react'
import { ExternalApiDetailsTypes } from 'src/services/external-api-details/external-api.types'

import { LoKationButton } from '../button/button'
import { CheckboxList } from '../checkbox-list/checkbox-list'
import { CollapsibleContent, CollapsibleContentStates } from '../collapsible-content/collapsible-content'

export interface ItemSearchStatusCodeFilterProps {
	selectedStatusCodes: ExternalApiDetailsTypes.StatusCode[]
	onChange: (selectedTags: ExternalApiDetailsTypes.StatusCode[]) => void
	style?: React.CSSProperties
	className?: string
}

export function ItemSearchStatusCodeFilter(props: ItemSearchStatusCodeFilterProps) {
	const [state, setState] = useState<CollapsibleContentStates>('OPEN')

	const statusCodes: ExternalApiDetailsTypes.StatusCode[] = [
		{ id: 200, label: '200 - OK' },
		{ id: 400, label: '400 - Bad Request' },
		{ id: 404, label: '404 - Not Found' },
		{ id: 500, label: '500 - Internal Server Error' },
	]

	return (
		<CollapsibleContent label="Status Codes" state={state} setState={setState}>
			<>
				<div className={`base-search-filter ${props.className ? props.className : ''}`} style={props.style}>
					<CheckboxList
						onChange={props.onChange}
						selectedItems={props.selectedStatusCodes}
						allItems={statusCodes}
						itemIdKey="id"
						itemLabelKey="label"
						optionClassName="col-xs-12"
					/>
				</div>
				<div>
					<LoKationButton
						variant="text"
						size="sm"
						label="Select None"
						className="mr-20"
						onClick={() => {
							props.onChange([])
						}}
					/>
					<LoKationButton
						variant="text"
						size="sm"
						label="Select All"
						onClick={() => {
							props.onChange(statusCodes)
						}}
					/>
				</div>
			</>
		</CollapsibleContent>
	)
}
