import ThinkificExternalSSO from './thinkific-external-sso'

export function ThinkificWritingOffersRoute() {
	return (
		<div className="flex-lg overflow-y__scroll" style={{ width: '100%' }}>
			<div className="col-xs-12 col-lg-12 pr-20-sm flex flex-wrap flex-alignItems-center flex-justifyContent-center overflow-y__scroll">
				<div className="col-12 col-md-8">
					<ThinkificExternalSSO
						returnTo={'writingOffer'}
						heading="Contract Overview & Writing an Offer"
						description="Preparation is the Key to Success! Knowing the As-Is Contract for Sale and Purchase will go a long way to better represent your clients and win more deals. Our LoKation Brokers walk you through the details."
					/>
				</div>
			</div>
		</div>
	)
}
