import _ from 'lodash'

class FileManagementServicePrototype {
	uploadFileFromExplorer(supportedFileTypes: string, decodeString: boolean, multiple: boolean): Promise<File[]> {
		return new Promise((resolve, reject) => {
			const fileUploadElt = document.createElement('input')
			fileUploadElt.setAttribute('type', 'file')
			if (multiple) {
				fileUploadElt.setAttribute('multiple', 'multiple')
			}
			fileUploadElt.setAttribute('accept', supportedFileTypes)
			fileUploadElt.addEventListener('change', (fileUploadEvt) => {
				const uploadedFiles = (fileUploadEvt.target as HTMLInputElement).files

				if (!uploadedFiles) {
					reject()
					return
				}

				if (decodeString) {
					this.decodeTextFile(uploadedFiles).then((decodedFile) => {
						resolve(decodedFile)
					})
				} else {
					const files: File[] = []

					/** Extract files into an array */
					// ES Lint thinks I should do a for-of loop but I cannot because "files" is not an array
					// eslint-disable-next-line @typescript-eslint/prefer-for-of
					for (let i = 0; i < uploadedFiles.length; i++) {
						files.push(uploadedFiles[i])
					}
					resolve(files)
				}
			})
			fileUploadElt.click()
		})
	}

	private decodeTextFile(fileList: FileList): Promise<File[]> {
		return new Promise((resolve) => {
			const loadFilePromises: Promise<File>[] = []

			/** Extract files into an array */
			const files: File[] = []
			// ES Lint thinks I should do a for-of loop but I cannot because "fileList" is not an array
			// eslint-disable-next-line @typescript-eslint/prefer-for-of
			for (let i = 0; i < fileList.length; i++) {
				files.push(fileList[i])
			}

			/** Create a promise for each file in the array that is resolved when the file has been decoded */
			files.forEach((file) => {
				const loadPromise = new Promise<File>((loadPromiseResolve) => {
					const reader = new FileReader()
					const fileName = file.name
					reader.readAsText(file)

					reader.addEventListener('load', () => {
						if (typeof reader.result === 'string') {
							const decodedString = _.unescape(reader.result)
							const fileBlob = new Blob([decodedString], { type: 'text/plain' })
							const newFile = new File([fileBlob], fileName, { type: 'text/plain' })
							loadPromiseResolve(newFile)
						}
					})
				})
				loadFilePromises.push(loadPromise)
			})

			/** Resolve the parent method once all files have been decoded */
			Promise.all(loadFilePromises).then((res) => {
				resolve(res)
			})
		})
	}

	convertFileToBase64(file: File): Promise<string> {
		return new Promise((resolve) => {
			const fileReader = new FileReader()
			fileReader.readAsDataURL(file)
			fileReader.addEventListener('load', (evt) => {
				const base64 = evt.target?.result

				if (typeof base64 !== 'string') {
					throw new Error(`Could not convert file to base64`)
				}

				resolve(base64)
			})
		})
	}
}

export const FileManagementService = new FileManagementServicePrototype()
