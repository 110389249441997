import { EmailNotificationComponent } from '@components/email-notification/email-notification'
import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'

export function LoKationResourceCloudCMA() {
	return (
		<div className="flex flex-column overflow-y__scroll">
			<div className="flex flex-wrap">
				<div className="col-xs-12 col-sm-6 pr-20-sm">
					<div className="text-center col-xs-12">
						<img
							src="https://agent.lokationre.com/images/marketing/cloudcma/Cloud-CMA-Logo-e1498138437776.png"
							style={{ height: '60px' }}
						/>
					</div>
					<HorizontalRule className="mt-20" />

					<p>
						LoKation has partnered with Cloud CMA to deliver our agents a tool to build the best Comparative
						Market Analysis reports and interactive presentations that WIN MORE LISTINGS. A solid
						comparative market analysis is one of the most important tools you can utilize as a real estate
						professional. With the amount of agents competing for business it is imperative you set yourself
						apart. If you don't stand out, you don't stand a chance!{' '}
					</p>
					<strong>
						Create unlimited reports/presentations for just $20/month through LoKation. Click the button
						below to get started.
					</strong>
				</div>
				<div className="col-xs-12 col-sm-6 pl-20-sm">
					<img
						src="https://agent.lokationre.com/images/marketing/cloudcma/cloudcma.png"
						style={{ width: '100%' }}
					/>
				</div>

				<div className="flex flex-alignItems-center mt-40">
					<EmailNotificationComponent
						serviceToNotify={'cloudcma'}
						title="Register for Cloud CMA"
						noStyles={true}
						buttonLabel="Sign Up"
					/>
					<button
						className="button contained lg primary ml-10"
						onClick={() => {
							window.open('https://lokation.thinkific.com/courses/cloud-cma-training', '_blank')
						}}
					>
						Cloud CMA Overview Training
					</button>
				</div>
			</div>
		</div>
	)
}
