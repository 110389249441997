import { store } from '@redux/store'
import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { GetItemsParams, GetItemsResponse } from '../utility.types'
import { FAQ, FAQSearchParams } from './faq.types'

export class FAQAPI {
	static getFAQs(props: GetItemsParams<FAQ>): Promise<AxiosResponse<{ items: FAQ[] }>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props })
		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/faqs/`, {
			headers,
			params,
		})
		return requestPromise
	}

	public static findFAQs(
		props: GetItemsParams<FAQ>,
		itemTypeProps: FAQSearchParams,
	): Promise<AxiosResponse<GetItemsResponse<FAQ>>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props, ...itemTypeProps })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/faqs/search`, {
			headers,
			params,
		})
		return requestPromise
	}

	static getFAQById(id: number): Promise<AxiosResponse<FAQ | undefined>> {
		const headers = {}
		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/faqs/${id}`, {
			headers,
		})
		return requestPromise
	}

	static createFAQ(faq: Omit<FAQ, 'faqId'>): Promise<AxiosResponse<FAQ>> {
		const userProps = store.getState().user
		if (!userProps) {
			throw new Error(`User not found. FAQ cannot be created`)
		}

		const headers = {}
		const requestPromise = httpRequest.post(`${RequestBaseURL}${apiVersionURL}/faqs/`, faq, {
			headers,
		})
		return requestPromise
	}

	static updateFAQ(faqId: number, updateFAQDTO: Partial<FAQ>): Promise<AxiosResponse<FAQ>> {
		const userProps = store.getState().user
		if (!userProps) {
			throw new Error(`User not found. FAQ cannot be updated`)
		}

		const headers = {}
		const requestPromise = httpRequest.patch(`${RequestBaseURL}${apiVersionURL}/faqs/${faqId}`, updateFAQDTO, {
			headers,
		})
		return requestPromise
	}

	static deleteFAQ(faqId: number): Promise<AxiosResponse<void>> {
		const headers = {}
		const requestPromise = httpRequest.delete(`${RequestBaseURL}${apiVersionURL}/faqs/${faqId}`, {
			headers,
		})
		return requestPromise
	}
}
