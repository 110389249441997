import { LoKationButton } from '@components/button/button'
import { ModalHeader } from '@components/modal/modal-header'
import { AxiosResponse } from 'axios'
import { useState } from 'react'
import { ToastService } from 'src/services/toast/toast.service'

import { GenericDeleteConfirmationModal } from '../../generic-delete-confirmation/generic-delete-confirmation'

export interface AdminEditorTitleProps<T> {
	deleteItemFunction: (itemId: number) => Promise<AxiosResponse<unknown>>
	item?: T
	itemIdKey: keyof T
	itemLabel: string
	onDelete: (item: T) => void
}

export function AdminEditorTitle<T>(props: AdminEditorTitleProps<T>) {
	const isNewItem = !props.item
	const title = `${isNewItem ? `Create` : `Edit`} ${props.itemLabel}`
	const [showGenericDeleteModal, setShowGenericDeleteModal] = useState(false)

	function handleDeleteConfirmation() {
		// Perform the deletion operation
		if (props.item) {
			const itemId = props.item[props.itemIdKey]
			if (typeof itemId === 'number') {
				props.deleteItemFunction(itemId).then(() => {
					if (props.item) {
						props.onDelete(props.item)
					}
				})
			}
		}
		setShowGenericDeleteModal(false)
		ToastService().create({ type: 'SUCCESS', body: `${props.itemLabel} has been deleted` })
	}

	function deleteItemPrompt() {
		const id = props.itemLabel
		if (id) {
			setShowGenericDeleteModal(true)
		}
	}

	return (
		<ModalHeader title={title}>
			{!isNewItem && <LoKationButton variant="outlined" size="sm" label="Delete" onClick={deleteItemPrompt} />}

			{showGenericDeleteModal && (
				<GenericDeleteConfirmationModal
					itemLabel={props.itemLabel}
					onDelete={handleDeleteConfirmation}
					onClose={() => {
						setShowGenericDeleteModal(false)
					}}
				/>
			)}
		</ModalHeader>
	)
}
