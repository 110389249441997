import { AxiosResponse } from 'axios'

import { Registration } from '../../routes/registration/registration.types'
import { apiVersionURL, httpRequest, httpRequestNoErrors, RequestBaseURL } from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { SortParam } from '../utility.types'
import {
	CheckForEmailResponse,
	EndUserFirstLast,
	EndUserLanguageSpecialty,
	EndUserLocation,
	EndUserOffboardingStatus,
	EndUserProps,
	EndUserSpecialty,
	EndUserUpdateProfileProps,
	FindUserDTO,
	FindUsersInDomainRequest,
	FindUsersInDomainResponse,
	GetDomainUsersResponse,
	LoginProps,
} from './user.types'
class UserAPIPrototype {
	registerUser(
		userDetails: Registration.FormForRegistrationRequest,
		userImage: string | null,
	): Promise<AxiosResponse<EndUserProps>> {
		userDetails.profilePicture = userImage
		const requestPromise = httpRequest.post(`${RequestBaseURL}/api/v1/register`, userDetails)
		return requestPromise
	}

	login(props: LoginProps): Promise<AxiosResponse<void>> {
		const headers = {}
		const requestPromise = httpRequest.post(`${RequestBaseURL}/api/v1/login`, props, {
			headers,
			validateStatus: (status) => {
				// Treat 403 as a valid response
				return (status >= 200 && status < 300) || status === 403
			},
		})
		return requestPromise
	}

	logout(): Promise<AxiosResponse<any>> {
		const headers = {}
		const requestPromise = httpRequest
			.post(
				`${RequestBaseURL}/api/v1/logout`,
				{},
				{
					headers,
				},
			)
			.then((res) => {
				localStorage.removeItem(`jwt`)
				return res
			})
		return requestPromise
	}

	sendResetPasswordEmail(props: { email: string }): Promise<AxiosResponse<any>> {
		const headers = {}
		const requestPromise = httpRequest.post(
			`${RequestBaseURL}${apiVersionURL}/requestResetPassword?emailAddress=${props.email}`,
			{},
			{
				headers,
			},
		)
		return requestPromise
	}

	resetPassword(props: {
		endUserId: number
		password: string
		repeatPassword: string
		token: string
	}): Promise<AxiosResponse<any>> {
		const headers = {}
		const requestPromise = httpRequest.post(`${RequestBaseURL}${apiVersionURL}/resetPassword`, props, {
			headers,
		})
		return requestPromise
	}

	getUserDetails(userId: number): Promise<AxiosResponse<EndUserProps>> {
		const headers: { [key: string]: any } = {}

		const requestPromise = httpRequest.get(`${RequestBaseURL}/api/v1/endUsers/${userId}`, {
			headers,
		})
		return requestPromise
	}

	updateUserPhoto(userId: number, photo: string): Promise<AxiosResponse<string>> {
		const headers: { [key: string]: any } = {
			'Content-Type': 'text/plain',
		}

		const requestPromise = httpRequestNoErrors.post(
			`${RequestBaseURL}/api/v1/endUsers/${userId}/profilePicture`,
			photo,
			{
				headers,
			},
		)
		return requestPromise
	}

	getUserPhoto(userId: number): Promise<AxiosResponse<string>> {
		const requestPromise = httpRequestNoErrors.get(`${RequestBaseURL}/api/v1/endUsers/${userId}/profilePicture`)
		return requestPromise
	}

	getUserPhotoURL(userId: number): string {
		return `${RequestBaseURL}/api/v1/endUsers/${userId}/profilePicture`
	}

	updateUserProperties(
		userId: number,
		userProps: Partial<EndUserUpdateProfileProps>,
	): Promise<AxiosResponse<EndUserUpdateProfileProps>> {
		const headers = {}
		const requestPromise = httpRequest.patch(`${RequestBaseURL}/api/v1/endUsers/${userId}`, userProps, {
			headers,
		})
		return requestPromise
	}

	getAllUsersInDomain(
		page: number,
		size: number,
		sort?: SortParam<FindUserDTO>[],
	): Promise<AxiosResponse<GetDomainUsersResponse>> {
		const headers = {}
		const params = Utilities.createSearchParamsFromObj({ page, size, sort })

		const requestPromise = httpRequest.get(`${RequestBaseURL}/api/v1/endUsers?`, {
			headers,
			params,
		})
		return requestPromise
	}

	findUsersInDomain(props: FindUsersInDomainRequest): Promise<AxiosResponse<FindUsersInDomainResponse>> {
		let url = `${RequestBaseURL}/api/v1/endUsers/search`

		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props })

		if (props.query.first) {
			params.append('first', props.query.first)
		}

		if (props.query.last) {
			params.append('last', props.query.last)
		}

		if (props.query.phone) {
			params.append('phone', props.query.phone)
		}

		if (props.query.email) {
			params.append('email', props.query.email)
		}

		if (props.query.licensedStateId) {
			params.append('licensedStateId', String(props.query.licensedStateId))
		}

		if (props.query.regionId) {
			params.append('regionId', String(props.query.regionId))
		}

		if (props.query.specialtyIds) {
			props.query.specialtyIds.forEach((specialtyId) => {
				params.append('specialtyIds', String(specialtyId))
			})
		}

		if (props.query.languageSpecialtyIds) {
			props.query.languageSpecialtyIds.forEach((languageSpecialtyId) => {
				params.append('languageSpecialtyIds', String(languageSpecialtyId))
			})
		}

		if (props.query.geography && props.query.geography.srcLat && props.query.geography.srcLong) {
			params.append('srcLat', String(props.query.geography.srcLat))
			params.append('srcLon', String(props.query.geography.srcLong))
			params.append('radius', String(props.query.geography.radius))
		}

		if (props.query.showDisabled) {
			params.append('showDisabled', String(props.query.showDisabled))
		}

		console.info(`Searching page ${props.page}`, params)

		const headers = {}
		const requestPromise = httpRequest.get(url, {
			headers,
			params,
		})
		return requestPromise
	}

	checkForEmail(email: string): Promise<AxiosResponse<CheckForEmailResponse>> {
		try {
			const params = {
				email: email,
			}
			const response = httpRequest.get(`${RequestBaseURL}/api/v1/endUsers/checkEmail`, { params: params })
			return response
		} catch (error) {
			console.log(error)
			throw new Error('Error with email.')
		}
	}

	getSpecialties(): Promise<AxiosResponse<EndUserSpecialty[]>> {
		const headers: { [key: string]: any } = {}

		const requestPromise = httpRequest.get(`${RequestBaseURL}/api/v1/specialties`, {
			headers,
		})
		return requestPromise
	}

	getLanguageSpecialties(): Promise<AxiosResponse<EndUserLanguageSpecialty[]>> {
		const headers: { [key: string]: any } = {}

		const requestPromise = httpRequest.get(`${RequestBaseURL}/api/v1/languageSpecialties`, {
			headers,
		})
		return requestPromise
	}

	getAllEndUserLocations(showDisabled?: boolean): Promise<AxiosResponse<EndUserLocation[]>> {
		// const headers: { [key: string]: any } = {}

		// const requestPromise = httpRequest.get(`${RequestBaseURL}/api/v1/endUsers/locations`, {
		// 	headers,
		// })

		const params = Utilities.createSearchParamsFromObj({})

		if (showDisabled) {
			params.append('showDisabled', String(showDisabled))
		}

		let url = `${RequestBaseURL}/api/v1/endUsers/locations`

		const headers = {}
		const requestPromise = httpRequest.get(url, {
			headers,
			params,
		})

		return requestPromise
	}

	findUsersInDomainAdmin(props: FindUsersInDomainRequest): Promise<AxiosResponse<FindUsersInDomainResponse>> {
		let url = `${RequestBaseURL}/api/v1/endUsers/admin_search`

		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props })

		if (props.query.first) {
			params.append('first', props.query.first)
		}

		if (props.query.enabled !== undefined) {
			params.append('enabled', String(props.query.enabled))
		}

		if (props.query.last) {
			params.append('last', props.query.last)
		}

		if (props.query.phone) {
			params.append('phone', props.query.phone)
		}

		if (props.query.email) {
			params.append('email', props.query.email)
		}

		if (props.query.licensedStateId) {
			params.append('licensedStateIds', String(props.query.licensedStateId))
		}

		if (props.query.regionId) {
			params.append('regionIds', String(props.query.regionId))
		}

		if (props.query.specialtyIds) {
			props.query.specialtyIds.forEach((specialtyId) => {
				params.append('specialtyIds', String(specialtyId))
			})
		}

		if (props.query.mlsBoardIds) {
			props.query.mlsBoardIds.forEach((mlsBoardId) => {
				params.append('mlsBoardIds', String(mlsBoardId))
			})
		}

		if (props.query.geography && props.query.geography.srcLat && props.query.geography.srcLong) {
			params.append('srcLat', String(props.query.geography.srcLat))
			params.append('srcLon', String(props.query.geography.srcLong))
			params.append('radius', String(props.query.geography.radius))
		}

		if (props.query.languageSpecialtyIds) {
			params.append('languageSpecialtyIds', String(props.query.languageSpecialtyIds))
		}

		if (props.query.hasPaymentMethod !== undefined) {
			params.append('hasPaymentMethod', String(props.query.hasPaymentMethod))
		}

		console.info(`Searching page ${props.page}`, params)

		const headers = {}
		const requestPromise = httpRequest.get(url, {
			headers,
			params,
		})
		return requestPromise
	}

	sendOffboardingInstructions(endUserId: number): Promise<AxiosResponse<any>> {
		const headers = {}
		const requestPromise = httpRequest.post(
			`${RequestBaseURL}${apiVersionURL}/offboarding-instructions/${endUserId}`,
			{},
			{
				headers,
			},
		)
		return requestPromise
	}

	getOffboardingStatus(endUserId: number): Promise<AxiosResponse<EndUserOffboardingStatus>> {
		const requestPromise = httpRequestNoErrors.get(
			`${RequestBaseURL}${apiVersionURL}/offboarding-status/${endUserId}`,
		)
		return requestPromise
	}

	getUserListForReferralSearch(): Promise<AxiosResponse<EndUserFirstLast[]>> {
		const headers: { [key: string]: any } = {}

		const requestPromise = httpRequest.get(`${RequestBaseURL}/api/v1/endUsers/first-last-list`, {
			headers,
		})
		return requestPromise
	}
}

export const UserAPI = new UserAPIPrototype()
