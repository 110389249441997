import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { NavLink } from 'react-router-dom'

export interface DesktopAppTrayButtonProps {
	label: string
	onClick?: (evt: React.MouseEvent<HTMLElement>) => void
	icon: IconName
	path?: string
	expanded: boolean
	id?: string
}

export function DesktopAppTrayButton(props: DesktopAppTrayButtonProps) {
	if (!props.path) {
		return (
			<button
				className={`app-tray__button`}
				id={props.id}
				onClick={(evt) => {
					if (props.onClick) {
						props.onClick(evt)
					}
				}}
			>
				<FontAwesomeIcon
					icon={['far', props.icon]}
					title={props.label}
					style={{ width: '20px', height: '20px' }}
				/>
				{props.expanded && <div className="app-tray__button__label">{props.label}</div>}
			</button>
		)
	}

	return (
		<NavLink
			end
			to={props.path}
			onClick={(evt) => {
				if (props.onClick) {
					props.onClick(evt)
				}
			}}
			id={props.id}
			tabIndex={0}
			className={({ isActive }) => (isActive ? 'app-tray__button active' : 'app-tray__button')}
		>
			<FontAwesomeIcon icon={['far', props.icon]} title={props.label} style={{ width: '20px', height: '20px' }} />
			{props.expanded && <div className="app-tray__button__label">{props.label}</div>}
		</NavLink>
	)
}
