import './theme-administration.scss'

import { LoKationButton } from '@components/button/button'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { useAppDispatch } from '@redux/hooks'
import { updateTheme } from '@redux/reducers/theme-reducer'
import { RootState } from '@redux/store'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ColorService } from '../../../services/color/color.service'
import useAdminRedirect from '../../../services/hooks/user-admin-redirect'
import { defaultTheme } from '../../../services/theme/default-theme/default-theme'
import { DomainThemeAPI } from '../../../services/theme/theme.api'
import { DomainThemeService } from '../../../services/theme/theme.service'
import { DomainTheme } from '../../../services/theme/theme.types'
import { EndUserProps } from '../../../services/user/user.types'
import { settingsRoutePath } from '../../routes.constant'
import { BackToAdminButton } from '../components/back-to-admin-button'
import { ThemeAdministrationBody } from './theme-administration__body'
import { ThemeAdministrationPreviewPane } from './theme-administration__preview'

function ThemeAdministrationRoutePrototype(props: { userProfile: EndUserProps | null }) {
	const [modifiedTheme, setModifiedTheme] = useState<DomainTheme.Theme | null>(null)
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	useAdminRedirect(props.userProfile)

	/** Load theme into state (Note: the controller supports multiple themes, but we are only allowing the user to create one theme right now) */
	useEffect(() => {
		DomainThemeAPI.getThemes().then((res) => {
			if (res.data.items.length > 0) {
				setModifiedTheme(res.data.items[0])
			} else {
				setModifiedTheme(defaultTheme)
			}
		})
	}, [])

	function saveThemeChanges(): void {
		if (modifiedTheme) {
			/** If theme has never been created, create the theme. If created already, update its record  */
			if (modifiedTheme.themeId === -1) {
				DomainThemeAPI.createTheme(modifiedTheme)
				const completeThemeProps = DomainThemeService.generateThemeProps(modifiedTheme)
				DomainThemeService.writeThemePropsToCSS(completeThemeProps)
			} else {
				dispatch(updateTheme(modifiedTheme))
			}
			navigate(`${settingsRoutePath}/`)
		}
	}

	function updateThemeProp(key: keyof DomainTheme.Theme, value: string | number): void {
		if (modifiedTheme) {
			const updatedTheme = { ...modifiedTheme, [key]: value }
			setModifiedTheme(updatedTheme)
		}
	}

	function handleThemeModeChange(mode: DomainTheme.ThemeMode): void {
		if (modifiedTheme) {
			const updatedTheme = { ...modifiedTheme, mode }
			const modeBaseColor = mode === 'LIGHT' ? '#ffffff' : '#000000'

			if (ColorService.getToneDifference(modifiedTheme.pageBgColor, modeBaseColor) > 75) {
				updatedTheme.pageBgColor = modeBaseColor
			}

			if (ColorService.getToneDifference(modifiedTheme.modalBgColor, modeBaseColor) > 75) {
				updatedTheme.modalBgColor = modeBaseColor
			}

			setModifiedTheme(updatedTheme)
		}
	}

	function handleResetTheme(): void {
		const modifiedDefaultTheme = _.cloneDeep(defaultTheme)
		if (modifiedTheme) {
			modifiedDefaultTheme.themeId = modifiedTheme.themeId
		}
		setModifiedTheme(modifiedDefaultTheme)
	}

	return (
		<RouteWrapperV2>
			<RouteBodyV2>
				<BackToAdminButton />
				<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center">
					<h1 className="mb-20">Theme Administration</h1>
				</div>
				<div className="flex">
					<div>
						{modifiedTheme && (
							<ThemeAdministrationBody
								modifiedTheme={modifiedTheme}
								handleThemeModeChange={handleThemeModeChange}
								updateThemeProp={updateThemeProp}
								handleResetTheme={handleResetTheme}
							/>
						)}
					</div>
					{modifiedTheme && <ThemeAdministrationPreviewPane theme={modifiedTheme} className="show-lg" />}
				</div>
				<div className="admin-footer__save">
					<LoKationButton
						variant="outlined"
						label="Cancel"
						size="lg"
						className="mr-10"
						onClick={() => {
							navigate(`${settingsRoutePath}/`)
						}}
					/>
					<LoKationButton
						variant="contained"
						label="Save and Close"
						size="lg"
						onClick={() => {
							saveThemeChanges()
						}}
					/>
				</div>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
	}
}

export const ThemeAdministrationRoute = connect(mapStateToProps)(ThemeAdministrationRoutePrototype)
