import ContentLoader from 'react-content-loader'

interface GenericContentLoaderProps {
	height: number
	width: number | 'fill'
	className?: string
	style?: React.CSSProperties
}
export function GenericContentLoader(props: GenericContentLoaderProps) {
	function getWidth(): string {
		if (typeof props.width === 'number') {
			return `${props.width}px`
		} else {
			return `100%`
		}
	}

	function getStyle(): React.CSSProperties {
		let style: React.CSSProperties = {}
		style.width = getWidth()
		style.height = `${props.height}px`

		if (props.style) {
			style = { ...style, ...props.style }
		}

		return style
	}

	return (
		<ContentLoader
			className={`${props.className ? props.className : ''}`}
			style={getStyle()}
			viewBox={`0 0 ${props.width} ${props.height}`}
			backgroundColor="var(--colorAdjustAlpha10)"
			foregroundColor="var(--colorAdjustAlpha20)"
			preserveAspectRatio="none"
		>
			<rect x="0" y="0" rx="5" ry="5" width={getWidth()} height={props.height} />
		</ContentLoader>
	)
}
