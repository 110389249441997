import { RootState } from '@redux/store'
import { connect } from 'react-redux'

import { LicensedState } from '../../services/licensed-states/licenses-states.types'
import { RegistrationRoute } from './registration'
import { RegistrationProvider } from './state/registration__state'

function RegistrationProviderWrapperPrototype(props: { licensedStates: LicensedState[] }) {
	return (
		<RegistrationProvider licensedStates={props.licensedStates}>
			<RegistrationRoute />
		</RegistrationProvider>
	)
}

function mapStateToProps(state: RootState) {
	return {
		licensedStates: state.licensedStates,
	}
}

export const RegistrationProviderWrapper = connect(mapStateToProps)(RegistrationProviderWrapperPrototype)
