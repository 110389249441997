import { LoKationButton } from '@components/button/button'
import { store } from '@redux/store'
import { useEffect, useRef, useState } from 'react'

import CoverImg from './marketing-center.png'

export default function BreakthroughBrokerRoute() {
	const userProps = store.getState().user
	const formSubmitBtn = useRef<HTMLInputElement>(null)

	const payload = {
		website: 'http://lokationagent.com',
		email: userProps?.email,
		firstName: userProps?.firstName,
		lastName: userProps?.lastName,
		companyName: 'LoKation',
		profilePhoto: 'https://lokationagent.com/static/media/lokation__mark.4b89d700b187d31a8c4524270136f505.svg',
		address1: userProps?.address1,
		address2: userProps?.address2,
		city: userProps?.city,
		state: userProps?.mailingState,
		zip: userProps?.zip,
	}

	useEffect(() => {
		if (formSubmitBtn.current) {
			formSubmitBtn.current.click()
		}
	}, [])

	const [payloadStr, setPayloadStr] = useState(JSON.stringify(payload, null, 2))

	return (
		<div className="flex flex-column overflow-y__scroll px-40 px-10-md-down">
			<div className="text-center col-xs-12">
				<img src={CoverImg} style={{ maxWidth: '400px', width: '100%' }} />
				<p className="text-center">
					<strong>The LoKation Marketing Center</strong> simplifies your marketing efforts by offering a
					comprehensive solution for all your marketing needs. From designing flyers to printing brochures,
					the LoKation Marketing Center streamlines the process with its user-friendly interface.
				</p>
				<LoKationButton
					variant="contained"
					size="lg"
					label="Sign In"
					onClick={() => {
						if (formSubmitBtn.current) {
							formSubmitBtn.current.click()
						}
					}}
				/>
			</div>

			<form
				action="https://www.lokationmarketingcenter.com/sso"
				method="post"
				target="_blank"
				style={{ display: 'none' }}
			>
				<label>Sample JSON Payload </label>
				<textarea
					name="json_file"
					id="json_file"
					style={{ resize: 'none' }}
					value={payloadStr}
					onChange={(e) => setPayloadStr(e.target.value)}
				/>
				<br />
				<label>Secret Key </label>
				<input type="text" name="secret_key" value="O4sfS6YTl2kclokSRm9xbnO" required />
				<input type="submit" value="Submit" ref={formSubmitBtn}></input>
			</form>
		</div>
	)
}
