import _ from 'lodash'
import { createContext, useContext, useReducer } from 'react'

import { NewUserOnboardingState } from './new-user-onboarding__state.types'

function getInitialState(props: { route?: NewUserOnboardingState.Routes }): NewUserOnboardingState.LocalState {
	const initialState: NewUserOnboardingState.LocalState = {
		route: props.route ? props.route : 'WELCOME',
		config: null,
		userTasks: null,
	}
	return initialState
}

const NewUserOnboardingContext = createContext(getInitialState({}))
const NewUserOnboardingDispatchContext = createContext({} as NewUserOnboardingState.DispatchParams)

function NewUserOnboardingReducer(state: NewUserOnboardingState.LocalState, actions: NewUserOnboardingState.Actions) {
	let updatedState = _.cloneDeep(state)
	actions.forEach((action) => {
		switch (action.type) {
			case 'set-route': {
				updatedState.route = action.payload
				break
			}
			case 'load-config':
				updatedState.config = action.payload
				break
			case 'set-question-response':
				updatedState.config?.questions.forEach((question) => {
					if (question.questionIndex === action.payload.questionIndex) {
						question.userResponse = action.payload.userResponse
					}
				})
				break
			case 'set-user-tasks':
				updatedState.userTasks = action.payload
				break
		}
	})
	return updatedState
}

export function NewUserOnboardingProvider(props: { children: React.ReactNode; route?: NewUserOnboardingState.Routes }) {
	const [state, dispatch] = useReducer(NewUserOnboardingReducer, getInitialState({ route: props.route }))

	return (
		<NewUserOnboardingContext.Provider value={state}>
			<NewUserOnboardingDispatchContext.Provider value={dispatch}>
				{props.children}
			</NewUserOnboardingDispatchContext.Provider>
		</NewUserOnboardingContext.Provider>
	)
}

export function useNewUserOnboarding() {
	return useContext(NewUserOnboardingContext)
}

export function useNewUserOnboardingDispatch() {
	return useContext(NewUserOnboardingDispatchContext)
}
