import { RegistrationService } from 'src/routes/registration/registration.service'
import { AgentLicense, EndUserProps } from 'src/services/user/user.types'

import { LicensePlanMangementTypes } from './add-new-agent-license.types'

class LicensePlanManagementServicePrototype {
	public getDefaultLicenseProps(endUser: EndUserProps, existingLicenses: AgentLicense.Entry[]): AgentLicense.Entry {
		const defaultPlan = this.getUserHasBluePlan(existingLicenses) ? 'BLUE' : null
		return {
			licenseInformationId: -1,
			licenseNumber: '',
			licensedState: null,
			ppUserId: '',
			region: null,
			endUser: endUser,
			plan: defaultPlan,
			licenseAgreement: null,
		}
	}

	/** Checks to see whether the user has any other licenses with a 'BLUE' plan */
	public getUserHasBluePlan(existingLicenses: AgentLicense.Entry[]): boolean {
		if (!existingLicenses) {
			return false
		}

		return existingLicenses.some((license) => {
			return license.plan === 'BLUE' && license.licenseInformationId > 0
		})
	}

	public calculatePriceIds(license: AgentLicense.Entry): LicensePlanMangementTypes.GetPriceIdsResponse {
		const planPriceId = RegistrationService()
			.getLicensePlans()
			.find(
				(licensePlan) =>
					licensePlan.plan === license.plan && licensePlan.state === license.licensedState?.abbreviation,
			)?.recurringPriceId

		const complianceFeePriceId = RegistrationService()
			.getLicensePlans()
			.find(
				(licensePlan) =>
					licensePlan.plan === license.plan && licensePlan.state === license.licensedState?.abbreviation,
			)?.complianceFeeRecurringPriceId

		const priceIds: string[] = []
		const futurePriceIds: string[] = []

		if (planPriceId) {
			priceIds.push(...planPriceId)
		}
		if (complianceFeePriceId) {
			futurePriceIds.push(...complianceFeePriceId)
		}

		return { priceIds, futurePriceIds }
	}

	public getUTCTimeMinus10Hours() {
		const nowUTC = new Date()
		const tenHoursInMilliseconds = 10 * 60 * 60 * 1000 // 10 hours in milliseconds
		return new Date(nowUTC.getTime() - tenHoursInMilliseconds)
	}

	public calculateCompliaceFeeDifference(oldFees: number): number {
		const feeDiff = 50 - oldFees

		if (feeDiff > 0) {
			const startDateUTC = this.getUTCTimeMinus10Hours()

			const billingDates = [
				new Date(Date.UTC(startDateUTC.getUTCFullYear(), 0, 15)),
				new Date(Date.UTC(startDateUTC.getUTCFullYear(), 3, 15)),
				new Date(Date.UTC(startDateUTC.getUTCFullYear(), 6, 15)),
				new Date(Date.UTC(startDateUTC.getUTCFullYear(), 9, 15)),
			]
			// Find the next billing date
			let nextBillingDate = billingDates.find((date) => date > startDateUTC)
			if (!nextBillingDate) {
				// If there's no next billing date this year, use next year
				nextBillingDate = new Date(Date.UTC(startDateUTC.getUTCFullYear() + 1, 0, 15))
			}
			const oneDay = 24 * 60 * 60 * 1000 // milliseconds in a day
			const daysUntilNextBilling = (nextBillingDate.getTime() - startDateUTC.getTime()) / oneDay
			const daysInQuarter = 365 / 4 // Approximate days in a quarter
			const proration = Number(((feeDiff / daysInQuarter) * daysUntilNextBilling).toFixed(2))

			return proration
		}

		return 0
	}

	public calculateExistingComplianceFees(licenses: AgentLicense.Entry[]): number {
		let totalFees = 0
		licenses.forEach((license) => {
			const complianceFee = RegistrationService()
				.getLicensePlans()
				.find(
					(licensePlan) =>
						licensePlan.plan === license.plan && licensePlan.state === license.licensedState?.abbreviation,
				)?.complianceFeeRecurringCost
			if (complianceFee) {
				totalFees += complianceFee
			}
		})
		return totalFees
	}

	public calculateTotalComplianceFees(licenses: AgentLicense.Entry[]): number {
		let totalFees = 0
		licenses.forEach((license) => {
			const complianceFee = RegistrationService()
				.getLicensePlans()
				.find(
					(licensePlan) =>
						licensePlan.plan === license.plan && licensePlan.state === license.licensedState?.abbreviation,
				)?.complianceFeeRecurringCost

			if (complianceFee) {
				totalFees += complianceFee
			}
		})

		return totalFees
	}
}

export const LicensePlanManagement = new LicensePlanManagementServicePrototype()
