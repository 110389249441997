import { store } from '@redux/store'

import { ColorService } from '../../services/color/color.service'
import { ColorBlock } from '../color-block/color-block'

interface ColorPickerSuggestionsBarProps {
	onChange: (value: string) => void
}

export function ColorPickerSuggestionsBar(props: ColorPickerSuggestionsBarProps) {
	const themeProps = store.getState().theme

	const color1 = themeProps.buttonBgColor
	const color2 = ColorService.adjustHexBrightness(themeProps.buttonBgColor, 75)
	const color3 = ColorService.changeHue(themeProps.buttonBgColor, 75)
	const color4 = themeProps.colorAccent
	const color5 = ColorService.adjustHexBrightness(themeProps.colorAccent, 75)
	const color6 = ColorService.changeHue(themeProps.colorAccent, 75)
	const color7 = ColorService.mixColors(themeProps.buttonBgColor, themeProps.colorAccent, 0.5, 'hex')
	const color8 = ColorService.mixColors(themeProps.appTrayBgColor, themeProps.colorAccent, 0.5, 'hex')

	function createColorBlock(color: string): React.ReactNode {
		return (
			<ColorBlock
				color={color}
				size={26}
				radius={26}
				onClick={() => {
					props.onChange(color)
				}}
			/>
		)
	}

	return (
		<div
			className="mt-5 p-5 flex flex-wrap flex-justifyContent-spaceBetween col-xs-12"
			style={{ backgroundColor: '#ffffff', borderRadius: '18px' }}
		>
			{createColorBlock(color1)}
			{createColorBlock(color2)}
			{createColorBlock(color3)}
			{createColorBlock(color4)}
			{createColorBlock(color5)}
			{createColorBlock(color6)}
			{createColorBlock(color7)}
			{createColorBlock(color8)}
		</div>
	)
}
