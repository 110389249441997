import { LoKationButton } from '@components/button/button'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useState } from 'react'
import { connect } from 'react-redux'
import { StripeAddPaymentMethodWrapper } from 'src/routes/administration/user-profile/components/billing-components/stripe-add-payment-method-wrapper'
import StripePaymentMethods from 'src/routes/administration/user-profile/components/billing-components/stripe-payment-methods'
import { StripePublicKey } from 'src/services/stripe/stripeConfig'

import { RootState } from '../../redux/store'
import { EndUserProps } from '../../services/user/user.types'

const stripePromise = loadStripe(StripePublicKey)

interface UpdatePaymentMethodRouteProps {
	currentUser: EndUserProps | null
}

export function UpdatePaymentMethodRoutePrototype({ currentUser }: UpdatePaymentMethodRouteProps) {
	const [isAddPaymentButtonVisible, setIsAddPaymentButtonVisible] = useState(true)
	const [paymentMethodAdded, setPaymentMethodAdded] = useState(false)
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<'NEW' | null>('NEW')

	const handlePaymentMethodAddition = () => {
		// If the payment method addition is successful
		setIsAddPaymentButtonVisible(true)
		setPaymentMethodAdded(true)
	}

	return (
		<>
			<RouteWrapperV2>
				<RouteBodyV2>
					<h1 className="mb-20">Update Payment Method</h1>

					{currentUser && (
						<div style={{ maxWidth: `800px` }} className="mb-40">
							<Elements stripe={stripePromise}>
								<StripePaymentMethods
									setPaymentMethodAdded={setPaymentMethodAdded}
									paymentMethodAdded={paymentMethodAdded}
									userToEdit={currentUser}
									currentUser={currentUser}
								/>
								{isAddPaymentButtonVisible && (
									<LoKationButton
										className="mt-10"
										variant="outlined"
										label="Add payment method"
										size={'sm'}
										onClick={() => {
											setSelectedPaymentMethod('NEW')
										}}
									/>
								)}
								{selectedPaymentMethod === 'NEW' && (
									<StripeAddPaymentMethodWrapper
										dismissModal={() => setSelectedPaymentMethod(null)}
										onPaymentMethodAddition={handlePaymentMethodAddition}
										userToEdit={currentUser}
									/>
								)}
							</Elements>
						</div>
					)}
				</RouteBodyV2>
			</RouteWrapperV2>
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const UpdatePaymentMethodRoute = connect(mapStateToProps)(UpdatePaymentMethodRoutePrototype)
