import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'

import { NewUserOnboardingModalInner } from './new-user-onboarding'
import { UserOnboardingModalTypes } from './new-user-onboarding.types'
import { NewUserOnboardingProvider } from './state/new-user-onboarding__state'

export function NewUserOnboardingModal(props: UserOnboardingModalTypes.Modal) {
	return (
		<RouteWrapperV2>
			<RouteBodyV2>
				<NewUserOnboardingProvider route={props.route}>
					<NewUserOnboardingModalInner {...props} />
				</NewUserOnboardingProvider>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}
