import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { GetItemsParams } from '../utility.types'
import { AdvertisingTypes } from './advertising.types'

/** APIs for the CRUD of an image associated with an advertisement */
export namespace AdvertisementImageAPI {
	export function getAdImages(
		adId: number,
		props: GetItemsParams<AdvertisingTypes.AdvertisementImage>,
	): Promise<AxiosResponse<AdvertisingTypes.AdvertisementImage[]>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/advertisements/${adId}/images/`, {
			headers,
			params,
		})
		return requestPromise
	}

	export function getAdvertisementImage(
		advertisementId: number,
		advertisementImageId: number,
	): Promise<AxiosResponse<string>> {
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({})
		const headers = {
			Accept: 'image/jpeg',
		}

		const requestPromise = httpRequest.get(
			`${RequestBaseURL}${apiVersionURL}/advertisements/${advertisementId}/images/${advertisementImageId}`,
			{
				headers,
				params,
			},
		)

		return requestPromise
	}

	export function createAdImage(
		adId: number,
		imageToCreate: AdvertisingTypes.AdvertisementImage,
	): Promise<AxiosResponse<AdvertisingTypes.AdvertisementImage>> {
		const headers = {}

		const payload: Partial<AdvertisingTypes.AdvertisementImage> = {
			...imageToCreate,
		}
		delete payload.advertisementImageId

		const requestPromise = httpRequest.post(
			`${RequestBaseURL}${apiVersionURL}/advertisements/${adId}/images/`,
			payload,
			{
				headers,
			},
		)
		return requestPromise
	}

	export function updateAdImage(
		adId: number,
		imageToUpdate: AdvertisingTypes.AdvertisementImage,
	): Promise<AxiosResponse<AdvertisingTypes.AdvertisementImage>> {
		const headers = {}

		const payload: Partial<AdvertisingTypes.AdvertisementImage> = {
			...imageToUpdate,
		}
		delete payload.advertisementImageId

		const requestPromise = httpRequest.put(
			`${RequestBaseURL}${apiVersionURL}/advertisements/${adId}/images/${imageToUpdate.advertisementImageId}`,
			payload,
			{
				headers,
			},
		)
		return requestPromise
	}

	export function deleteAdImage(adId: number, imageId: number): Promise<AxiosResponse<null>> {
		const headers = {}

		const requestPromise = httpRequest.delete(
			`${RequestBaseURL}${apiVersionURL}/advertisements/${adId}/images/${imageId}`,
			{
				headers,
			},
		)
		return requestPromise
	}
}
