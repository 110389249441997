import { CSVService } from '../../../services/csv/csv.service'
import { ResourcesAPI } from '../../../services/resources/resources.api'
import { LokationResource } from '../../../services/resources/resources.types'

const SEARCH_SIZE = 40

export function ResourcesAdminService() {
	function exportResourcesToCSV(): void {
		/** Makes as many requests as needed in order to retrieve a list of all resources */
		function createRequest(
			searchPage: number,
			previousResults: LokationResource[],
			resolve: (value: LokationResource[]) => void,
		): void {
			ResourcesAPI.getResources({
				page: searchPage,
				size: SEARCH_SIZE,
				sort: [
					{
						property: 'title',
						direction: 'asc',
					},
				],
			}).then((res) => {
				if (res.data.items.length === SEARCH_SIZE) {
					createRequest(searchPage + 1, [...previousResults, ...res.data.items], resolve)
				} else {
					resolve([...previousResults, ...res.data.items])
				}
			})
		}

		/** Get all resources in domain */
		const getAllResources = new Promise<LokationResource[]>((resolve) => {
			createRequest(0, [], resolve)
		})

		/** Construct CSV from resources */
		getAllResources.then((res) => {
			const rows: { [key: string]: unknown }[] = []

			res.forEach((resource) => {
				rows.push({
					Title: resource.title,
					Description: resource.description,
					'Resource URL': resource.url,
					States: String(resource.licensedStates.map((state) => state.stateName)),
					Tags: String(resource.tags.map((tag) => tag.label)),
				})
			})

			const CSVFile = CSVService().generateCSVFromArray({
				headers: ['Title', 'Description', 'Resource URL', 'States', 'Tags'],
				rows: rows,
			})

			CSVService().createCSVFileFromString('Resources__file.csv', CSVFile)
		})
	}

	return { exportResourcesToCSV }
}
