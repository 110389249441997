import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'

import calendarReducer from './reducers/calendar-reducer'
import currentSessionReducer from './reducers/current-session-reducer'
import domainReducer from './reducers/domain-reducer'
import hubsReducer from './reducers/hubs-reducer'
import languageSpecialtiesReducer from './reducers/language-specialties-reducer'
import licensedRegionsReducer from './reducers/licensed-regions-reducer'
import licensedStatesReducer from './reducers/licensed-states-reducer'
import loginCarouselReducer from './reducers/login-carousel-reducer'
import messagingServiceReducer from './reducers/messaging-service-reducer'
import modalReducer from './reducers/modal-reducer'
import specialtiesReducer from './reducers/specialties-reducer'
import tagsReducer from './reducers/tags-reducer'
import themeReducer from './reducers/theme-reducer'
import uiReducer from './reducers/ui-reducer'
import userProfilePhotoReducer from './reducers/user-profile-photo-reducer'
import userReducer from './reducers/user-reducer'

export const store = configureStore({
	reducer: {
		domain: domainReducer,
		ui: uiReducer,
		user: userReducer,
		licensedStates: licensedStatesReducer,
		licensedRegions: licensedRegionsReducer,
		modals: modalReducer,
		hubs: hubsReducer,
		theme: themeReducer,
		tags: tagsReducer,
		specialties: specialtiesReducer,
		languageSpecialties: languageSpecialtiesReducer,
		calendar: calendarReducer,
		loginCarousel: loginCarouselReducer,
		messagingService: messagingServiceReducer,
		userProfilePhoto: userProfilePhotoReducer,
		currentSession: currentSessionReducer,
	},
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
