class ColorServicePrototype {
	public isValidHexColor(hex: string): boolean {
		const hexRegex = new RegExp(/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i)
		return hexRegex.test(hex)
	}

	/**
	 * Converts a hexidecimal color into an RGBA string
	 *
	 * @param {String} hex
	 * @param {Number} alpha
	 */
	public hexToRGBA(hex: string, alpha: number): string {
		let c
		if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
			c = hex.substring(1).split('')
			if (c.length === 3) {
				c = [c[0], c[0], c[1], c[1], c[2], c[2]]
			}
			c = '0x' + c.join('')
			// ToDo: return string should be rewritten to not use bitwise operations
			/** @ts-ignore */
			return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')'
		}
		throw new Error('Bad Hex')
	}

	public rgbToHex(r: number, g: number, b: number): string {
		function componentToHex(c: number) {
			var hex = c.toString(16)
			return hex.length === 1 ? '0' + hex : hex
		}
		return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
	}

	/**
	 * Returns a new hexedecimal color based on the color passed in and a value determining the amount to brighten or darken it
	 *
	 * @param {String} color Hexedecimal color string
	 * @param {Number} amount Amount to brighten or darken the color
	 */
	public adjustHexBrightness(color: string, amount: number): string {
		if (color === '#000000' || color === '#000') {
			return '#222222'
		}

		if (color === '#ffffff' || color === '#fff') {
			return '#dddddd'
		}

		let usePound = false

		if (color[0] === '#') {
			color = color.slice(1)
			usePound = true
		}

		let R = parseInt(color.substring(0, 2), 16)
		let G = parseInt(color.substring(2, 4), 16)
		let B = parseInt(color.substring(4, 6), 16)

		// to make the colour less bright than the input
		// change the following three "+" symbols to "-"
		R = R + amount
		G = G + amount
		B = B + amount

		if (R > 255) {
			R = 255
		} else if (R < 0) {
			R = 0
		}

		if (G > 255) {
			G = 255
		} else if (G < 0) {
			G = 0
		}

		if (B > 255) {
			B = 255
		} else if (B < 0) {
			B = 0
		}

		const RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16)
		const GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16)
		const BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16)

		return (usePound ? '#' : '') + RR + GG + BB
	}

	/**
	 * Returns a value representing the darkness of a color. Ranges from 0 (black) to 255 (white)
	 *
	 * @param {String} color Hexedecimal color
	 */
	public getColorToneValue(color: string): number {
		let colorValue: any = color

		// Variables for red, green, blue values
		let r
		let g
		let b

		// Check the format of the color, HEX or RGB?
		if (colorValue.match(/^rgb/)) {
			// If HEX --> store the red, green, blue values in separate variables
			colorValue = colorValue.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

			r = colorValue[1]
			g = colorValue[2]
			b = colorValue[3]
		} else {
			colorValue = +('0x' + colorValue.slice(1).replace(colorValue.length < 5 && /./g, '$&$&'))

			// ToDo: const r, g and b should be rewritten to not use bitwise operations
			// tslint:disable-next-line: no-bitwise
			r = colorValue >> 16
			// tslint:disable-next-line: no-bitwise
			g = (colorValue >> 8) & 255
			// tslint:disable-next-line: no-bitwise
			b = colorValue & 255
		}

		const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

		return hsp
	}

	/**
	 * Evaluates the darkness of a color and returns 'light', 'mid' or 'dark'
	 *
	 * @param {String} color Hexedecimal color
	 */
	public getColorTone(color: string): 'light' | 'dark' {
		const hsp = this.getColorToneValue(color)

		// Using the HSP value, determine whether the color is light or dark
		if (hsp >= 127 && hsp <= 255) {
			return 'light'
		} else {
			return 'dark'
		}
	}

	/**
	 * Returns a number representing the difference between the darkness of two colors. Larger numbers represent more contrast
	 *
	 * @param {String} colorA Hexedecimal Color
	 * @param {String} colorB Hexedecimal Color
	 */
	public getToneDifference(colorA: string, colorB: string): number {
		const hspA = this.getColorToneValue(colorA)
		const hspB = this.getColorToneValue(colorB)

		return Math.abs(hspA - hspB)
	}

	// note: amountToMix ranges from 0.0 to 1.0
	public mixColors(hexA: string, hexB: string, amountToMix: number, output: 'rgb' | 'hex'): string {
		const rgbA = this.hexToRGBA(hexA, 1).replace('rgba(', '').replace(')', '').split(',')
		const rgbB = this.hexToRGBA(hexB, 1).replace('rgba(', '').replace(')', '').split(',')

		//colorChannelA and colorChannelB are ints ranging from 0 to 255
		function colorChannelMixer(colorChannelA: number, colorChannelB: number, amountToMix: number) {
			var channelA = colorChannelA * amountToMix
			var channelB = colorChannelB * (1 - amountToMix)
			return channelA + channelB
		}

		var r = Math.trunc(colorChannelMixer(parseFloat(rgbA[0]), parseFloat(rgbB[0]), amountToMix))
		var g = Math.trunc(colorChannelMixer(parseFloat(rgbA[1]), parseFloat(rgbB[1]), amountToMix))
		var b = Math.trunc(colorChannelMixer(parseFloat(rgbA[2]), parseFloat(rgbB[2]), amountToMix))

		if (output === 'hex') {
			return this.rgbToHex(r, g, b)
		} else {
			return `rgb(${r},${g},${b})`
		}
	}

	public changeHue(hex: string, degree: number): string {
		var hsl = this.rgbToHSL(hex)
		hsl.h += degree
		if (hsl.h > 360) {
			hsl.h -= 360
		} else if (hsl.h < 0) {
			hsl.h += 360
		}
		return this.hslToRGB(hsl)
	}

	// exepcts a string and returns an object
	private rgbToHSL(rgb: any): any {
		// strip the leading # if it's there
		rgb = rgb.replace(/^\s*#|\s*$/g, '')

		// convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
		if (rgb.length === 3) {
			rgb = rgb.replace(/(.)/g, '$1$1')
		}

		var r = parseInt(rgb.substr(0, 2), 16) / 255,
			g = parseInt(rgb.substr(2, 2), 16) / 255,
			b = parseInt(rgb.substr(4, 2), 16) / 255,
			cMax = Math.max(r, g, b),
			cMin = Math.min(r, g, b),
			delta = cMax - cMin,
			l = (cMax + cMin) / 2,
			h = 0,
			s = 0

		if (delta === 0) {
			h = 0
		} else if (cMax === r) {
			h = 60 * (((g - b) / delta) % 6)
		} else if (cMax === g) {
			h = 60 * ((b - r) / delta + 2)
		} else {
			h = 60 * ((r - g) / delta + 4)
		}

		if (delta === 0) {
			s = 0
		} else {
			s = delta / (1 - Math.abs(2 * l - 1))
		}

		return {
			h: h,
			s: s,
			l: l,
		}
	}

	// expects an object and returns a string
	private hslToRGB(hsl: any): any {
		function normalize_rgb_value(color: any, m: any): any {
			color = Math.floor((color + m) * 255)
			if (color < 0) {
				color = 0
			}
			return color
		}

		var h = hsl.h,
			s = hsl.s,
			l = hsl.l,
			c = (1 - Math.abs(2 * l - 1)) * s,
			x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
			m = l - c / 2,
			r,
			g,
			b

		if (h < 60) {
			r = c
			g = x
			b = 0
		} else if (h < 120) {
			r = x
			g = c
			b = 0
		} else if (h < 180) {
			r = 0
			g = c
			b = x
		} else if (h < 240) {
			r = 0
			g = x
			b = c
		} else if (h < 300) {
			r = x
			g = 0
			b = c
		} else {
			r = c
			g = 0
			b = x
		}

		r = normalize_rgb_value(r, m)
		g = normalize_rgb_value(g, m)
		b = normalize_rgb_value(b, m)

		return this.rgbToHex(r, g, b)
	}
}

export const ColorService = new ColorServicePrototype()
