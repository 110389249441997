import { AxiosResponse } from 'axios'

import { store } from '../../redux/store'
import { apiVersionURL, httpRequest, httpRequestNoErrors, RequestBaseURL } from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { GetItemsParams } from '../utility.types'
import { Calendar } from './calendar.types'

export class CalendarAPI {
	static getCalendars(
		props: GetItemsParams<Calendar.Calendar>,
	): Promise<AxiosResponse<{ items: Calendar.Calendar[] }>> {
		const headers = {}

		const params = Utilities.createSearchParamsFromObj({ ...props })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/calendars/`, {
			headers,
			params,
		})
		return requestPromise
	}

	static getCalendarICSURL(calendarId: number): string {
		return `${RequestBaseURL}${apiVersionURL}/calendars/${calendarId}/calendar.ics`
	}

	static getCalendarICS(calendarId: number): Promise<AxiosResponse<string>> {
		return httpRequestNoErrors.get(this.getCalendarICSURL(calendarId))
	}

	/** Gets the ICS file for all merged calendars */
	static getMergedCalendarsICSURL(): string {
		return `${RequestBaseURL}${apiVersionURL}/calendars/calendar.ics`
	}

	/** Gets the ICS file for all merged calendars */
	static getMergedCalendarsICS(): Promise<AxiosResponse<string>> {
		return httpRequestNoErrors.get(this.getMergedCalendarsICSURL())
	}

	// public static findCalendars(props: GetItemsParams<Calendar.Calendar>, itemTypeProps: CalendarSearchParams): Promise<AxiosResponse<{ items: Calendar.Calendar[] }>> {

	//     const headers = {}
	//     /** Set params */
	//     const params = new URLSearchParams()
	//     params.append('page', String(props.page))
	//     params.append('size', String(props.size))
	//     if (itemTypeProps.tags) {
	//         itemTypeProps.tags.forEach((tagId) => {
	//             params.append('tags', String(tagId))
	//         })
	//     }
	//     if (props.sort) {
	//         params.append('sort', `${props.sort.property},${props.sort.direction === "ascending" ? 'asc' : 'desc'}`)
	//     }

	//     const requestPromise = httpRequest.get(
	//         `${RequestBaseURL}${apiVersionURL}/calendars/search`,
	//         {
	//             headers,
	//             params
	//         },
	//     )
	//     return requestPromise
	// }

	static getCalendarById(id: number): Promise<AxiosResponse<Calendar.Calendar | undefined>> {
		const headers = {}
		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/calendars/${id}`, {
			headers,
		})
		return requestPromise
	}

	static createCalendar(calendar: Omit<Calendar.Calendar, 'calendarId'>): Promise<AxiosResponse<Calendar.Calendar>> {
		const userProps = store.getState().user
		if (!userProps) {
			throw new Error(`User not found. Calendar cannot be created`)
		}

		const headers = {}
		const requestPromise = httpRequest.post(`${RequestBaseURL}${apiVersionURL}/calendars/`, calendar, {
			headers,
		})
		return requestPromise
	}

	static updateCalendar(
		calendarId: number,
		updateCalendarDTO: Partial<Calendar.Calendar>,
	): Promise<AxiosResponse<Calendar.Calendar>> {
		const userProps = store.getState().user
		if (!userProps) {
			throw new Error(`User not found. Calendar cannot be updated`)
		}

		const headers = {}
		const requestPromise = httpRequest.patch(
			`${RequestBaseURL}${apiVersionURL}/calendars/${calendarId}`,
			updateCalendarDTO,
			{
				headers,
			},
		)
		return requestPromise
	}

	static deleteCalendar(calendarId: number): Promise<AxiosResponse<void>> {
		const headers = {}
		const requestPromise = httpRequest.delete(`${RequestBaseURL}${apiVersionURL}/calendars/${calendarId}`, {
			headers,
		})
		return requestPromise
	}
}
