// import { DateTime } from 'luxon'

import { Utilities } from '../utilities.service'
import { AdvertisingTypes } from './advertising.types'

export namespace AdvertisingService {
	export function getDefaultAdProps(accountId: number): AdvertisingTypes.Advertisement {
		// let currentDateObj = DateTime.now().toUTC()

		const newAd: AdvertisingTypes.Advertisement = {
			advertisementId: 0 - Utilities.generateRandomInt(1, 1000000),
			accountId,
			applicableHubs: [],
			audienceLocations: [],
			audienceSpecialties: [],
			specialtyAgnostic: true,
			audienceRegions: [],
			hubHeaderAdvertisement: {},
			placement: 'HUB_HEADER',
			enabled: false,
			name: '',
			endDate: '',
			startDate: '',
		}
		return newAd
	}

	/** Converts [YYYY, MM, DD] into 'YYYY-MM-DD' */
	export function convertDateArrayIntoString(dateArray: number[]): string {
		const year = String(dateArray[0])
		const month = dateArray[1] < 10 ? `0${dateArray[1]}` : `${dateArray[1]}`
		const day = dateArray[2] < 10 ? `0${dateArray[2]}` : `${dateArray[2]}`
		return `${year}-${month}-${day}`
	}

	/** Converts 'YYYY-MM-DD' into [YYYY, MM, DD] */
	export function convertDateStringIntoArray(dateString: string): number[] {
		const splitDate = dateString.split('-')
		return [parseFloat(splitDate[0]), parseFloat(splitDate[1]), parseFloat(splitDate[2])]
	}
}
