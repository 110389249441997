import './floating-action-button.scss'

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface FloatingActionButtonProps {
	icon: IconName | null
	label?: string
	onClick: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
	top?: number
	right?: number
	bottom?: number
	left?: number
	className?: string
}

export function FloatingActionButton(props: FloatingActionButtonProps) {
	function getStyle(): React.CSSProperties {
		const style: React.CSSProperties = {}

		if (typeof props.top === 'number') {
			style.top = `${props.top}px`
		}

		if (typeof props.right === 'number') {
			style.right = `${props.right}px`
		}

		if (typeof props.bottom === 'number') {
			style.bottom = `${props.bottom}px`
		}

		if (typeof props.left === 'number') {
			style.left = `${props.left}px`
		}

		return style
	}

	return (
		<div
			className={`floating-action-button ${props.label ? 'has-label' : ''} ${props.className ? props.className : ''}`}
			style={getStyle()}
			onClick={(evt) => {
				props.onClick(evt)
			}}
		>
			{props.label && <strong className="mr-10">{props.label}</strong>}
			{props.icon && <FontAwesomeIcon icon={['fas', props.icon]} />}
		</div>
	)
}
