import { createSlice } from '@reduxjs/toolkit'

export interface ModalReducerState {
	[key: string]: string | boolean | number
}

const initialState: ModalReducerState = {}

interface UpdateVisibilityAction {
	payload: {
		id: string
		state: boolean
	}
}

export const modalSlice = createSlice({
	name: 'ui',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		updateVisibility: (state, action: UpdateVisibilityAction) => {
			state[action.payload.id] = action.payload.state
		},
	},
})

export const { updateVisibility } = modalSlice.actions

export default modalSlice.reducer
