// AddOnServices.tsx

import { EndUserProps } from '../../../../services/user/user.types'
import { useUserProfile } from '../state/user-profile__state'
import { UserProfileService } from '../user-profile.service'
import { AddOnServiceList } from './subscription-components/add-on-service-list'

interface AddOnServicesProps {
	userToEdit: EndUserProps
	currentUser: EndUserProps | null
}

function AddOnServices(props: AddOnServicesProps) {
	const userProfileState = useUserProfile()
	const mergedUserProps = UserProfileService().applyModifiedStateToOriginalProps(
		userProfileState.modifiedProps,
		props.userToEdit,
	)

	return (
		<>
			<div className="pb-30">
				<h2 className="mb-20 text-bold">Add-On Services</h2>
				<div style={{ maxWidth: `800px` }} className="mb-40">
					<AddOnServiceList
						serviceIds={[
							'KV_CORE',
							'HOME_SEARCH',
							'DOTLOOP',
							'BACK_AT_YOU',
							'ACCESSADOCTOR',
							'TOOLKIT_CMA',
						]}
						currentUser={props.currentUser}
						userToEdit={mergedUserProps}
					/>

					<h3 className="mt-20">LoKation Luxury®</h3>
					<p>Specialized resources tailored to meet the demands of high-end clientele</p>

					<AddOnServiceList
						serviceIds={['LUXURY_NONMEMBER_ANNUAL', 'LUXURY_NONMEMBER_MONTHLY']}
						disableAllIfSubscribed={true}
						currentUser={props.currentUser}
						userToEdit={mergedUserProps}
					/>

					{/* <p>
						Have a Team? Add Team Members below at a fraction of the cost. They will receive the same Tech
						Stack of kvCore, Luxury Home Search App, Lokation Luxury Marketing Center and Social Media
						Platforms. This includes full use of the logos and all other Lux branding. Please note they will
						not get the custom Listing Package or Onboarding, but will receive a one-page Bio to highlight
						them as a Team Member of yours. This is required if the Team Leader is in Luxury.
					</p>

					<p>
						<strong>
							Looking to add more than one team member? Email{' '}
							<a href="mailto:info@lokationluxury.com">info@lokationluxury.com</a>
						</strong>
					</p>

					<AddOnServiceList
						serviceIds={['LUXURY_MEMBER_ANNUAL', 'LUXURY_MEMBER_MONTHLY']}
						currentUser={props.currentUser}
						userToEdit={mergedUserProps}
					/> */}
				</div>
			</div>
		</>
	)
}

export default AddOnServices
