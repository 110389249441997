import './toggle-button.scss'

import { Tooltip } from '../tooltip/tooltip'

export interface ToggleButtonOption<T> {
	value: T & string
	label: string | JSX.Element
}

interface ToggleButtonProps<T> {
	options: ToggleButtonOption<T>[]
	selectedOption: T & string
	onSelect: (selectedOption: T & string) => void
	disabled?: boolean
	tooltipText?: string
}

export function ToggleButton<T>(props: ToggleButtonProps<T>) {
	return (
		<div className={`flex ${props.disabled ? 'toggle-button-disabled' : ''}`}>
			{props.options.map((option) => {
				const isSelected = option.value === props.selectedOption
				return (
					<div
						className={`toggle-button ${isSelected ? 'selected' : ''}`}
						key={option.value}
						onClick={() => {
							if (!props.disabled) {
								props.onSelect(option.value)
							}
						}}
					>
						{!isSelected && props.tooltipText && props.disabled ? (
							<Tooltip tooltipName={option.label.toString()} body={props.tooltipText} />
						) : (
							option.label
						)}
					</div>
				)
			})}
		</div>
	)
}
