import './badge.scss'

export interface BadgeProps {
	value: number | null
	className?: string
}

export function Badge(props: BadgeProps) {
	function getBadgeClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`badge`)

		if (props.value) {
			classes.push('is-not-empty')
		} else {
			classes.push(`is-empty`)
		}

		if (props.className) {
			classes.push(props.className)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	return <div className={getBadgeClass()}>{props.value ? props.value : ''}</div>
}
