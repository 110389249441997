import './user-admin__card.scss'

import { faUserSlash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { EndUserProps } from '../../../../services/user/user.types'

interface UserAdminCardProps {
	onClick: () => void
	user: EndUserProps
}

export function UserAdminCard(props: UserAdminCardProps) {
	return (
		<div className={`user-admin__card flex flex-justifyContent-spaceBetween`} onClick={props.onClick}>
			<div className="flex flex-column-xs-down">
				<div style={{ width: '200px' }}>
					{props.user.firstName} {props.user.lastName}
				</div>
				<div className="color__accent" style={{ width: '200px' }}>
					{props.user.email}
				</div>
			</div>
			<div className="">{props.user.enabled ? '' : <FontAwesomeIcon icon={faUserSlash} />}</div>
		</div>
	)
}
