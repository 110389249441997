import { LoKationButton } from '@components/button/button'
import { RootState } from '@redux/store'
import { useEffect, useRef } from 'react'
import { QRCode } from 'react-qrcode-logo'
import { connect } from 'react-redux'
import { DomainTheme } from 'src/services/theme/theme.types'

import { EndUserProps } from '../../services/user/user.types'
import QRLogo from './lokation-qr-logo.png'

interface QRCodeProps {
	currentUser: EndUserProps | null
	theme: DomainTheme.CompleteTheme
}

function QRCodePrototype({ currentUser, theme }: QRCodeProps) {
	const canvasRef = useRef<HTMLCanvasElement | null>(null)

	const handleDownload = () => {
		if (canvasRef.current) {
			const link = document.createElement('a')
			link.href = canvasRef.current.toDataURL('image/png')
			link.download = 'qr-code.png'
			link.click()
		}
	}

	useEffect(() => {
		if (canvasRef.current && currentUser) {
			const canvas = canvasRef.current
			const context = canvas.getContext('2d')
			const qrCodeCanvas = document.querySelector('canvas')
			if (context && qrCodeCanvas) {
				// Clear the canvas
				canvas.width = qrCodeCanvas.width
				canvas.height = qrCodeCanvas.height
				context.clearRect(0, 0, canvas.width, canvas.height)

				// Draw the QR code
				context.drawImage(qrCodeCanvas, 0, 0)

				// Draw the logo with a white border
				const logo = new Image()
				logo.src = QRLogo
				logo.onload = () => {
					const logoSize = 200
					const borderSize = 10
					const logoX = (canvas.width - logoSize) / 2
					const logoY = (canvas.height - logoSize) / 2
					const borderX = logoX - borderSize
					const borderY = logoY - borderSize
					const borderSizeTotal = logoSize + 2 * borderSize

					// Draw white border
					context.fillStyle = 'white'
					context.fillRect(borderX, borderY, borderSizeTotal, borderSizeTotal)

					// Draw logo on top of the white border
					context.drawImage(logo, logoX, logoY, logoSize, logoSize)
				}
			}
		}
	}, [currentUser])

	return (
		<div className="flex-lg overflow-y__scroll flex flex-justifyContent-center flex-column flex-alignItems-center">
			<div style={{ maxWidth: '400px' }}>
				{currentUser && (
					<>
						<QRCode
							size={1000}
							style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
							value={`https://joinlokation.com/referral/?fname=${currentUser.firstName}&lname=${currentUser.lastName}&referral=${currentUser.endUserId}`}
							logoPaddingStyle="square"
							logoPadding={10}
							logoHeight={200}
							logoWidth={200}
							fgColor={theme.appHeaderBgColor}
							removeQrCodeBehindLogo={true}
							logoImage={QRLogo}
							id="canvas"
						/>
						<canvas ref={canvasRef} style={{ display: 'none' }} />
					</>
				)}
			</div>
			<p style={{ maxWidth: '600px' }}>
				You can use your personalized QR code to refer new agents quickly and easily. Show the QR code to an
				agent who wants to join, and they can scan it with their smartphone to start the join process
				immediately. Alternatively, you can download the QR code by clicking the "Download QR Code" button, then
				share the image via email or text for them to scan later. Keep the QR code handy for quick sharing
				during meetings or networking events.
			</p>
			<LoKationButton
				variant="contained"
				label="Download QR Code"
				size={'sm'}
				onClick={handleDownload}
				className="mt-20"
				style={{ width: `200px` }}
			/>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
		theme: state.theme,
	}
}

export const QrCodeRoute = connect(mapStateToProps)(QRCodePrototype)
