import ThinkificExternalSSO from './thinkific-external-sso'

export function ThinkificRoute() {
	return (
		<div className="flex-lg overflow-y__scroll" style={{ width: '100%' }}>
			<div className="col-xs-12 col-lg-12 pr-20-sm flex flex-wrap flex-alignItems-center flex-justifyContent-center overflow-y__scroll">
				<div className="col-12 col-md-8">
					<ThinkificExternalSSO
						returnTo={'default'}
						heading="Training Library"
						description="A library of all of our historic trainings recorded and archived to learn on-demand....where you need, when you need it."
					/>
				</div>
			</div>
		</div>
	)
}
