import { AxiosResponse } from 'axios'

import { httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { LeadNotesTypes } from './lead-note.types'

class LeadNoteAPIPrototype {
	getLeadNotes(email: string): Promise<AxiosResponse<LeadNotesTypes.Note[]>> {
		const headers: { [key: string]: any } = {}

		const requestPromise = httpRequest.get(`${RequestBaseURL}/api/v1/note/${email}`, {
			headers,
		})
		return requestPromise
	}
	async createNote(email: string, note: string) {
		try {
			const params = {
				note,
			}
			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/note/create/${email}`, null, {
				params: params,
			})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to send Free Transaction Coordinator notification.')
		}
	}
}

export const LeadNoteAPI = new LeadNoteAPIPrototype()
