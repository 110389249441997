import './onboarding-task.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { UserOnboardingTypes } from '../../../../services/user-onboarding/user-onboarding.types'

interface OnboardingTaskProps {
	onClick: () => void
}

export function OnboardingTask(props: OnboardingTaskProps & UserOnboardingTypes.Task) {
	return (
		<div
			className="onboarding-task"
			onClick={() => {
				props.onClick()
			}}
		>
			{!props.isCompleted && <FontAwesomeIcon icon={['far', 'circle']} size="lg" />}
			{props.isCompleted && <FontAwesomeIcon icon={['far', 'check-circle']} size="lg" />}
			<div className="flex-fillSpace ml-10">{props.label}</div>
			<FontAwesomeIcon icon={['fas', 'angle-right']} className="ml-10" />
		</div>
	)
}
