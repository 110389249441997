/* eslint-disable react/jsx-pascal-case */
import { SideSheet__Row } from '@components/side-sheet/side-sheet__row'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import { TextInput } from '@components/text-input/text-input'
import _ from 'lodash'
import { AdvertisingTypes } from 'src/services/advertising/advertising.types'

import { useAdEditor, useAdEditorDispatch } from '../../state/ad-editor__state'
import { AdImageFileUploader } from './ad-image-file-uploader'

type ComponentProps = {
	adImage: AdvertisingTypes.AdvertisementImage
}

export function Ad__SideSheet__Placement__ResourceConfig(props: ComponentProps) {
	const adEditorState = useAdEditor()
	const adEditorDispatch = useAdEditorDispatch()

	if (adEditorState.modifiedAd.placement === 'HUB_HEADER') {
		return <></>
	}

	return (
		<SideSheet__Section icon="image" label="Content" mode="expandable" sectionKey="account__design__content">
			<SideSheet__Row direction="row" label="Resource Title">
				<TextInput
					dataType="text"
					value={
						adEditorState.modifiedAd.sponsoredResourceAdvertisement.resourceTitle
							? adEditorState.modifiedAd.sponsoredResourceAdvertisement.resourceTitle
							: ''
					}
					width={'100%'}
					onChange={(updatedValue) => {
						if (adEditorState.modifiedAd.placement !== 'SPONSORED_RESOURCE') {
							return
						}
						const updatedSponsoredResourceProps = _.cloneDeep(
							adEditorState.modifiedAd.sponsoredResourceAdvertisement,
						)
						updatedSponsoredResourceProps.resourceTitle = updatedValue
						adEditorDispatch({
							type: 'update-property',
							payload: { sponsoredResourceAdvertisement: updatedSponsoredResourceProps },
						})
					}}
				/>
			</SideSheet__Row>
			<SideSheet__Row direction="row" label="Description">
				<TextInput
					dataType="text"
					value={
						adEditorState.modifiedAd.sponsoredResourceAdvertisement.description
							? adEditorState.modifiedAd.sponsoredResourceAdvertisement.description
							: ''
					}
					width={'100%'}
					onChange={(updatedValue) => {
						if (adEditorState.modifiedAd.placement !== 'SPONSORED_RESOURCE') {
							return
						}
						const updatedSponsoredResourceProps = _.cloneDeep(
							adEditorState.modifiedAd.sponsoredResourceAdvertisement,
						)
						updatedSponsoredResourceProps.description = updatedValue
						adEditorDispatch({
							type: 'update-property',
							payload: { sponsoredResourceAdvertisement: updatedSponsoredResourceProps },
						})
					}}
				/>
			</SideSheet__Row>
			<SideSheet__Row direction="row" label="Target URL">
				<TextInput
					dataType="text"
					value={props.adImage.link}
					width={'100%'}
					placeholder="http://www.lokationre.com"
					onChange={(updatedValue) => {
						const updatedAdImage: AdvertisingTypes.AdvertisementImage = {
							...props.adImage,
							link: updatedValue,
						}
						adEditorDispatch({ type: 'update-ad-image', payload: updatedAdImage })
					}}
				/>
			</SideSheet__Row>

			<AdImageFileUploader adImage={props.adImage} />
		</SideSheet__Section>
	)
}
