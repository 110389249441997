import { NavLink } from 'react-router-dom'

interface MobileAppTrayLinkProps {
	href: string
	label: string
	updateIsAppTrayOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function MobileAppTrayLink(props: MobileAppTrayLinkProps) {
	return (
		<NavLink
			end
			to={props.href}
			onClick={() => {
				props.updateIsAppTrayOpen(false)
			}}
			className={({ isActive }) => (isActive ? 'mobile-app-tray__link active' : 'mobile-app-tray__link')}
		>
			{props.label}
		</NavLink>
	)
}
