import './lead-admin__card.scss'

import { LeadProps } from 'src/services/lead/lead.types'

interface LeadAdminCardProps {
	onClick: () => void
	lead: LeadProps
}

export function LeadAdminCard(props: LeadAdminCardProps) {
	return (
		<div className={`lead-admin__card flex flex-justifyContent-spaceBetween`} onClick={props.onClick}>
			<div className="flex flex-column-xs-down">
				<div style={{ width: '200px' }}>
					{props.lead.firstName} {props.lead.lastName}
				</div>
				<div className="color__accent" style={{ width: '200px' }}>
					{props.lead.email}
				</div>
			</div>
		</div>
	)
}
