import { AxiosResponse } from 'axios'

import { UserOnboardingTypes } from './user-onboarding.types'

export function UserOnboardingAPI() {
	/** Get a list of default tasks that should be included in every users onboarding experience */
	function getDefaultTasks(): Promise<AxiosResponse<UserOnboardingTypes.Task[]>> {
		return new Promise((resolve) => {
			const tasks: UserOnboardingTypes.Task[] = [
				{
					label: `Upload your profile photo`,
					isCompleted: false,
					type: 'UPLOAD_PROFILE_PHOTO',
				},
				{
					label: `Add links to your social media accounts on your profile`,
					isCompleted: false,
					type: 'CONNECT_SOCIAL_MEDIA_ACCOUNTS',
				},
			]

			resolve({
				status: 200,
				statusText: '',
				data: tasks,
				headers: {},
				config: {},
			})
		})
	}

	/** Send server a list of tasks that the user must complete to finish onboarding */
	function addUserTasks(tasks: UserOnboardingTypes.Task[]): Promise<AxiosResponse<UserOnboardingTypes.Task[]>> {
		return new Promise((resolve) => {
			resolve({
				status: 200,
				statusText: '',
				data: tasks,
				headers: {},
				config: {},
			})
		})
	}

	function getUserOnboardingConfig(): Promise<AxiosResponse<UserOnboardingTypes.Config>> {
		return new Promise((resolve) => {
			const config: UserOnboardingTypes.Config = {
				questions: [
					{
						type: 'RADIO',
						question: `How many years of experience do you have in the real estate industry?`,
						imageUrl: `https://images.pexels.com/photos/7937670/pexels-photo-7937670.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`,
						questionIndex: 0,
						userResponse: [],

						options: [
							{
								label: `0-1 Years`,
								value: `0`,
								index: 0,
								resultingTasks: [
									{
										label: 'Take a site tour',
										isCompleted: false,
										type: 'SITE_TOUR',
									},
								],
								resultingActions: [],
							},
							{
								label: `2-4 Years`,
								value: `1`,
								index: 1,
								resultingTasks: [],
								resultingActions: [],
							},
							{
								label: `5+ Years`,
								value: `2`,
								index: 2,
								resultingTasks: [],
								resultingActions: [],
							},
						],
					},
					{
						type: 'CHECKBOX',
						question: `What types of properties do you specialize in, and are there specific areas or market segments you focus on?`,
						imageUrl: `https://images.pexels.com/photos/7642090/pexels-photo-7642090.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`,
						userResponse: [],
						questionIndex: 1,
						options: [
							{
								label: `Residential`,
								value: `RESIDENTIAL`,
								index: 0,
								resultingTasks: [],
								resultingActions: [],
							},
							{
								label: `Commerical`,
								value: `COMMERCIAL`,
								index: 1,
								resultingTasks: [],
								resultingActions: [],
							},
							{
								label: `Luxury`,
								value: `LUXURY`,
								index: 2,
								resultingTasks: [
									{
										label: 'Select premium add-ons to further boost your productivity',
										isCompleted: false,
										type: 'SELECT_ADD_ONS',
									},
								],
								resultingActions: [],
							},
						],
					},
				],
			}

			resolve({
				status: 200,
				statusText: '',
				data: config,
				headers: {},
				config: {},
			})
		})
	}

	return { getUserOnboardingConfig, addUserTasks, getDefaultTasks }
}
