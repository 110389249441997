import { httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { PaperlessPipelineTypes } from './paperless-pipeline.types'

class PaperlessPipelineAPIPrototype {
	async getSsoUrls(): Promise<PaperlessPipelineTypes.getSsoUrls> {
		try {
			const response = await httpRequest.get(`${RequestBaseURL}/api/v1/getPpSsoUrl`)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to get SSO URL.')
		}
	}
}

export const PaperlessPipelineAPI = new PaperlessPipelineAPIPrototype()
