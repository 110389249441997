import { store } from '@redux/store'
import ContentLoader from 'react-content-loader'

export function HubFAQLoader(props: { className?: string }) {
	const themeProps = store.getState().theme

	return (
		<ContentLoader
			className={props.className ? props.className : ''}
			speed={2}
			width={300}
			height={25}
			viewBox="0 0 300 25"
			backgroundColor={themeProps.colorAdjustAlpha10}
			foregroundColor={themeProps.colorAdjustAlpha20}
		>
			<rect
				x="0"
				y="0"
				rx={themeProps.elementBorderRadius}
				ry={themeProps.elementBorderRadius}
				width="300"
				height="20"
			/>
		</ContentLoader>
	)
}
