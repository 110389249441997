import { IconButton } from '@components/icon-button/icon-button'
import { Modal } from '@components/modal/modal'
import { useNavigate } from 'react-router-dom'

export function InternalResourceModal(props: { children: React.ReactNode; title: string; fixedHeight?: boolean }) {
	const navigate = useNavigate()

	function closeModal(): void {
		navigate(-1)
	}

	return (
		<Modal
			maxHeight={1400}
			maxWidth={1200}
			fixedHeight={typeof props.fixedHeight === 'boolean' ? props.fixedHeight : false}
			className="flex flex-column p-20"
			onClose={closeModal}
		>
			<>
				<IconButton
					icon={'xmark'}
					style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 100 }}
					onClick={closeModal}
				/>
				<div className="mb-10">
					<strong className="color__adjust-40">{props.title}</strong>
				</div>
				{props.children}
			</>
		</Modal>
	)
}
