import _ from 'lodash'
import { createContext, useContext, useReducer } from 'react'

import { MessagesAdminState } from './messages-admin__state.types'

function initialState(): MessagesAdminState.LocalState {
	return {
		drafts: null,
		sentMessages: null,
		selectedMessage: null,
	}
}

const MessagesAdminContext = createContext(initialState())
const MessagesAdminDispatchContext = createContext({} as MessagesAdminState.DispatchParams)

function MessagesAdminReducer(state: MessagesAdminState.LocalState, action: MessagesAdminState.Action) {
	switch (action.type) {
		case 'get-drafts': {
			let updatedState = _.cloneDeep(state)
			updatedState.drafts = action.payload
			return updatedState
		}
		case 'get-sentMessages': {
			let updatedState = _.cloneDeep(state)
			updatedState.sentMessages = action.payload
			return updatedState
		}
		case 'select-message': {
			let updatedState = _.cloneDeep(state)
			updatedState.selectedMessage = action.payload
			return updatedState
		}
		case 'create-message': {
			let updatedState = _.cloneDeep(state)
			if (!updatedState.drafts) {
				return updatedState
			}
			updatedState.drafts.push(action.payload)
			return updatedState
		}
		case 'update-message': {
			let updatedState = _.cloneDeep(state)
			if (!updatedState.drafts) {
				return updatedState
			}
			updatedState.drafts = updatedState.drafts.filter(
				(draft) => draft.chatMessageDraftId !== action.payload.chatMessageDraftId,
			)
			updatedState.drafts.push(action.payload)
			return updatedState
		}
		case 'delete-message': {
			let updatedState = _.cloneDeep(state)
			if (!updatedState.drafts) {
				return updatedState
			}
			updatedState.drafts = updatedState.drafts.filter((draft) => draft.chatMessageDraftId !== action.payload)
			return updatedState
		}
	}
}

export function MessagesAdminProvider(props: { children: React.ReactNode }) {
	const [state, dispatch] = useReducer(MessagesAdminReducer, initialState())

	return (
		<MessagesAdminContext.Provider value={state}>
			<MessagesAdminDispatchContext.Provider value={dispatch}>
				{props.children}
			</MessagesAdminDispatchContext.Provider>
		</MessagesAdminContext.Provider>
	)
}

export function useMessagesAdmin() {
	return useContext(MessagesAdminContext)
}

export function useMessagesAdminDispatch() {
	return useContext(MessagesAdminDispatchContext)
}
