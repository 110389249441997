import { DropdownOptionProps, DropdownParentOption } from '@components/dropdown/dropdown.types'
import { LicensedState } from 'src/services/licensed-states/licenses-states.types'
import { MLSBoardAPI } from 'src/services/mls-board/mls-board.api'
import { MLSBoard } from 'src/services/mls-board/mls-board.types'
import { LicensedRegion } from 'src/services/regions/regions.types'
import { EndUserLanguageSpecialty, EndUserSpecialty } from 'src/services/user/user.types'

export namespace UserAdminService {
	export function getMLSBoardDropdownOptions(
		mlsBoardOptions: MLSBoard.Complete[],
	): DropdownOptionProps<MLSBoard.Complete>[] {
		// Group by state
		const groupedByState = mlsBoardOptions.reduce<Record<string, DropdownParentOption<MLSBoard.Complete>>>(
			(acc, mlsOption) => {
				// Check if the state exists in the accumulator
				if (!acc[mlsOption.licensedState.abbreviation]) {
					acc[mlsOption.licensedState.abbreviation] = {
						label: mlsOption.licensedState.stateName,
						value: mlsOption.licensedState.abbreviation,
						children: [],
					} as DropdownParentOption<MLSBoard.Complete> // Type assertion here
				}

				// Push the MLS board into the state's children
				;(acc[mlsOption.licensedState.abbreviation] as DropdownParentOption<MLSBoard.Complete>).children.push({
					label: mlsOption.displayValue,
					value: mlsOption,
				})

				return acc
			},
			{},
		)

		return Object.values(groupedByState).sort((a, b) => a.label.localeCompare(b.label))
	}

	export function getSelectedStateOptions(
		licensedStates: LicensedState[],
		searchLicensedStateIds: number[] | undefined,
	): LicensedState[] {
		const selectedOptions: LicensedState[] = []

		licensedStates.forEach((option) => {
			if (searchLicensedStateIds) {
				const isInSelectedOptions = searchLicensedStateIds.includes(option.licensedStateId)
				if (isInSelectedOptions) {
					selectedOptions.push(option)
				}
			}
		})

		return selectedOptions
	}

	export function getSelectedRegionOptions(
		licensedRegions: LicensedRegion[],
		searchRegionIds: number[] | undefined,
	): LicensedRegion[] {
		const selectedOptions: LicensedRegion[] = []

		licensedRegions.forEach((option) => {
			if (searchRegionIds) {
				const isInSelectedOptions = searchRegionIds.includes(option.regionId)
				if (isInSelectedOptions) {
					selectedOptions.push(option)
				}
			}
		})

		return selectedOptions
	}

	export function getSelectedSpecialties(
		specialties: EndUserSpecialty[],
		searchSpecialtyIds: number[] | undefined,
	): EndUserSpecialty[] {
		const selectedOptions: EndUserSpecialty[] = []

		specialties.forEach((option) => {
			if (searchSpecialtyIds) {
				const isInSelectedOptions = searchSpecialtyIds.includes(option.specialtyId)
				if (isInSelectedOptions) {
					selectedOptions.push(option)
				}
			}
		})

		return selectedOptions
	}

	export function getSelectedLanguageSpecialties(
		languageSpecialties: EndUserLanguageSpecialty[],
		searchLanguageSpecialtyIds: number[] | undefined,
	): EndUserLanguageSpecialty[] {
		const selectedOptions: EndUserLanguageSpecialty[] = []

		languageSpecialties.forEach((option) => {
			if (searchLanguageSpecialtyIds) {
				const isInSelectedOptions = searchLanguageSpecialtyIds.includes(option.languageSpecialtyId)
				if (isInSelectedOptions) {
					selectedOptions.push(option)
				}
			}
		})

		return selectedOptions
	}

	export function getSelectedMLSOptions(
		mlsBoardOptions: MLSBoard.Complete[],
		searchMlsBoardIds: number[] | undefined,
	): MLSBoard.Complete[] {
		const selectedOptions: MLSBoard.Complete[] = []

		mlsBoardOptions.forEach((option) => {
			if (searchMlsBoardIds) {
				const isInSelectedOptions = searchMlsBoardIds.includes(option.mlsBoardId)
				if (isInSelectedOptions) {
					selectedOptions.push(option)
				}
			}
		})

		return selectedOptions
	}

	export function getDropdownOptions<T>(
		dropdownOptionsForObject: T[],
		labelKey: keyof T & string,
	): DropdownOptionProps<T>[] {
		return dropdownOptionsForObject
			.filter((option) => typeof option[labelKey] === 'string')
			.map((option) => ({
				value: option,
				label: option[labelKey] as string,
			}))
	}

	export async function fetchMlsBoardOptions() {
		async function getAllMlsBoardOptions(page: number = 0, size: number = 20): Promise<MLSBoard.Complete[]> {
			const response = await MLSBoardAPI().getAllOptions({
				page,
				size,
				sort: [{ property: 'displayValue', direction: 'asc' }],
			})
			if (response.data.totalPages > page + 1) {
				return response.data.items.concat(await getAllMlsBoardOptions(page + 1, size))
			}
			return response.data.items
		}
		return getAllMlsBoardOptions()
	}
}
