import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { LoginCarouselAPI } from '../../services/login-carousel/login-carousel.api'
import { LoginCarouselNode } from '../../services/login-carousel/login-carousel.types'

const initialState: LoginCarouselNode[] = []

export const getLoginCarousel = createAsyncThunk('login-carousel/get', async () => {
	const res = await LoginCarouselAPI.getNodes({ page: 0, size: 20 })
	return res.data.items
})

export const loginCarouselSlice = createSlice({
	name: 'login-carousel',
	initialState,
	reducers: {
		setCarousel: (state, action: PayloadAction<LoginCarouselNode[]>) => {
			return action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getLoginCarousel.fulfilled, (state, action) => {
			return action.payload
		})
	},
})

export const { setCarousel } = loginCarouselSlice.actions

export default loginCarouselSlice.reducer
