import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import { MessagingService } from '../../services/messaging-service/messaging-service.types'
import { ReceivedMessagesAPI } from '../../services/messaging-service/received-messages.api'

export type messagingServiceState = MessagingService.ChatMessage[] | null

const initialState = null as messagingServiceState

export const getUserMessages = createAsyncThunk('messagingService/get', async (arg: { page: number; size: number }) => {
	const res = await ReceivedMessagesAPI().getReceivedMessages({ page: arg.page, size: arg.size })
	return res.data
})

export const messagingServiceSlice = createSlice({
	name: 'messagingService',
	initialState,
	reducers: {
		setAllMessagesRead: (state) => {
			let updatedState = _.cloneDeep(state)
			updatedState?.forEach((message) => {
				/** empty */
			})
			return updatedState
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getUserMessages.fulfilled, (state, action) => {
			return action.payload.messages
		})
	},
})

export const { setAllMessagesRead } = messagingServiceSlice.actions

export default messagingServiceSlice.reducer
