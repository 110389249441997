import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { GetItemsParams } from '../utility.types'
import { MessagingService } from './messaging-service.types'

export function ReceivedMessagesAPI() {
	function getReceivedMessages(
		props: GetItemsParams<MessagingService.ReceivedMessagesSortableProps>,
	): Promise<AxiosResponse<{ messages: MessagingService.ChatMessage[] }>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/receivedMessages/`, {
			headers,
			params,
		})
		return requestPromise
	}

	function deleteMessage(props: {
		messageId: number
	}): Promise<AxiosResponse<{ messages: MessagingService.ChatMessage[] }>> {
		const headers = {}

		const requestPromise = httpRequest.put(
			`${RequestBaseURL}${apiVersionURL}/receivedMessages/${props.messageId}/deleted`,
			{
				headers,
			},
		)
		return requestPromise
	}

	function markMessageAsOpened(props: {
		messageId: number
	}): Promise<AxiosResponse<{ messages: MessagingService.ChatMessage[] }>> {
		const headers = {}

		const requestPromise = httpRequest.put(
			`${RequestBaseURL}${apiVersionURL}/receivedMessages/${props.messageId}/opened`,
			{
				headers,
			},
		)
		return requestPromise
	}

	/** Retrieve messages for user */
	function getUnreadMessages(
		props: GetItemsParams<MessagingService.ReceivedMessagesSortableProps>,
	): Promise<AxiosResponse<MessagingService.ChatMessage[]>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props, opened: false })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/receivedMessages/`, {
			headers,
			params,
		})
		return requestPromise
	}

	/** Retrieves all messages sent to a user, group by the sender */
	function getMessagesBySender(
		props: GetItemsParams<MessagingService.ReceivedMessagesSortableProps>,
		senderId: number,
	): Promise<AxiosResponse<MessagingService.Sender[]>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props, from: senderId })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/receivedMessages/`, {
			headers,
			params,
		})
		return requestPromise
	}

	/** Retrieves messages from a single sender */
	function getMessagesFromSingleSender(senderId: number): Promise<AxiosResponse<MessagingService.Sender>> {
		return new Promise((resolve, reject) => {
			const axiosResponse: AxiosResponse<MessagingService.Sender> = {
				data: {
					endUserId: 0,
					firstName: '',
					lastName: '',
					lastRead: 0,
				},
				status: 200,
				statusText: '',
				headers: {},
				config: {},
			}
			resolve(axiosResponse)
		})
	}

	return {
		getReceivedMessages,
		deleteMessage,
		markMessageAsOpened,
		getUnreadNotifications: getUnreadMessages,
		getMessagesBySender,
		getMessagesFromSingleSender,
	}
}
