/* eslint-disable react/jsx-pascal-case */
import { SideSheet__Row } from '@components/side-sheet/side-sheet__row'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import Switch from '@components/switch/switch'
import { TextInput } from '@components/text-input/text-input'

import { useAdEditor, useAdEditorDispatch } from './state/ad-editor__state'

export function Ad__SideSheet__Tab__Overview() {
	const adEditorState = useAdEditor()
	const adEditorDispatch = useAdEditorDispatch()

	return (
		<>
			<SideSheet__Section label="General" icon="cog" mode="expandable" sectionKey="account__general">
				<SideSheet__Row direction="row" label="Name">
					<TextInput
						type="text"
						dataType="text"
						value={adEditorState.modifiedAd.name}
						width={'400px'}
						onChange={(updatedValue) => {
							adEditorDispatch({ type: 'update-property', payload: { name: updatedValue } })
						}}
					/>
				</SideSheet__Row>
				<SideSheet__Row direction="row" label="Enabled">
					<Switch
						checked={adEditorState.modifiedAd.enabled}
						onChange={(isChecked) => {
							adEditorDispatch({ type: 'update-property', payload: { enabled: isChecked } })
						}}
					/>
				</SideSheet__Row>
				{adEditorState.modifiedAd.advertisementId > 0 && (
					<SideSheet__Row direction="row" label="Advertisement ID">
						<div>{adEditorState.modifiedAd.advertisementId}</div>
					</SideSheet__Row>
				)}
				{adEditorState.modifiedAd.accountId > 0 && (
					<SideSheet__Row direction="row" label="Parent Account ID">
						<div>{adEditorState.modifiedAd.accountId}</div>
					</SideSheet__Row>
				)}
			</SideSheet__Section>
		</>
	)
}
