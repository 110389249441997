import './tooltip.scss'
interface TooltipPopoverProps {
	children: React.ReactNode
	width?: number | 'min-content'
	style?: React.CSSProperties
}

export function TooltipPopover(props: TooltipPopoverProps) {
	function getStyle(): React.CSSProperties {
		const style: React.CSSProperties = { ...props.style }
		if (props.width === 'min-content') {
			style.width = `auto`
		} else {
			style.width = `${props.width ? props.width : 200}px`
		}
		return style
	}

	return (
		<div className="tooltip__popover" style={getStyle()}>
			{props.children}
		</div>
	)
}
