import './checkbox.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

import { ComponentImpl } from '../component.types'

export interface CheckboxProps {
	children?: React.ReactNode
	checked: boolean
	onChange?: (updatedState: boolean) => void
	disabled?: boolean
	className?: string
	style?: React.CSSProperties
	textAlignment?: 'start' | 'center' | 'end'
	validation?: ComponentImpl.Validation<boolean>
	forceRevalidation?: boolean
}

export function Checkbox(props: CheckboxProps) {
	/** ====================================== */
	/** Props and State */
	const [isUserInteracted, setIsUserInteracted] = useState(false)
	const [isValid, setIsValid] = useState(true)
	const [showValidationError, setShowValidationError] = useState(true)
	const [checked, updatedChecked] = useState(props.checked)

	/** ====================================== */
	/** Effects */

	/** Revalidate component when the value of 'forceRevalidation' is changed */
	useEffect(() => {
		const updatedIsValid = isValueValid(checked, isUserInteracted)
		if (updatedIsValid !== isValid) {
			setIsValid(updatedIsValid)
		}
	}, [props.forceRevalidation])

	/** Reset the value of this checkbox when it is changed externally */
	useEffect(() => {
		updatedChecked(props.checked)
	}, [props.checked])

	/** ====================================== */
	/** Methods */

	function isValueValid(value: boolean, hasUserInteracted: boolean): boolean {
		if (props.validation) {
			const validationResult = props.validation.isValid(value, hasUserInteracted)

			if (typeof validationResult.showValidationFlag === 'boolean') {
				setShowValidationError(validationResult.showValidationFlag)
			}

			return validationResult.isValid
		}
		return true
	}

	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`flex flex-noShrink`)

		if (props.textAlignment) {
			classes.push(`flex-alignItems-${props.textAlignment}`)
		} else {
			classes.push(`flex-alignItems-center`)
		}

		if (props.className) {
			classes.push(props.className)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	/** ====================================== */
	/** Render */

	return (
		<div className={getClass()} style={props.style}>
			<div
				className={`checkbox__wrapper ${isValid && showValidationError ? '' : 'is-error'} ${props.disabled ? 'disabled' : ''}`}
				onClick={() => {
					if (!isUserInteracted) {
						setIsUserInteracted(true)
					}

					setIsValid(isValueValid(!checked, true))

					if (props.onChange) {
						props.onChange(!checked)
						updatedChecked(!checked)
					}
				}}
			>
				{checked && <FontAwesomeIcon icon={['far', 'check']} style={{ width: '12px', height: '12px' }} />}
			</div>
			{props.children && <div className="ml-10">{props.children}</div>}
		</div>
	)
}
