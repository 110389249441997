import { useEffect, useState } from 'react'
import { UserAPI } from 'src/services/user/user.api'
import { EndUserFirstLast } from 'src/services/user/user.types'

interface ReferralSearchAutocompleteProps {
	onUserSelected: (user: EndUserFirstLast) => void
	searchValue: string
}

export function ReferralSearchAutocomplete({ onUserSelected, searchValue }: ReferralSearchAutocompleteProps) {
	const [value, setValue] = useState(searchValue)
	const [users, setUsers] = useState<EndUserFirstLast[]>([])
	const [filteredUsers, setFilteredUsers] = useState<EndUserFirstLast[]>([])

	const [debouncedValue, setDebouncedValue] = useState(searchValue)
	const debounceDelay = 250

	// Get users
	useEffect(() => {
		UserAPI.getUserListForReferralSearch().then((res) => {
			setUsers(res.data)
		})
	}, [])

	// Debounce function
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value)
		}, debounceDelay)

		// Clear timeout if value changes
		return () => {
			clearTimeout(handler)
		}
	}, [value, debounceDelay])

	// Filter users based on debounced value
	useEffect(() => {
		if (debouncedValue) {
			const filtered = users.filter((user) =>
				`${user.firstName} ${user.lastName}`.toLowerCase().includes(debouncedValue.toLowerCase()),
			)
			setFilteredUsers(filtered.slice(0, 5))
		} else {
			setFilteredUsers([])
		}
	}, [debouncedValue, users])

	// Update the value when searchValue prop changes
	useEffect(() => {
		setValue(searchValue)
		setDebouncedValue(searchValue)
	}, [searchValue])

	return (
		<>
			<div className="">
				<div>
					{filteredUsers.map((user) => (
						<div
							key={`${user.firstName} ${user.lastName}`}
							onClick={() => {
								onUserSelected(user)
								setValue(`${user.firstName} ${user.lastName}`)
							}}
							style={{
								borderBottom: '1px solid #ccc',
								borderLeft: '1px solid #ccc',
								borderRight: '1px solid #ccc',
								padding: '5px',
								cursor: 'pointer',
							}}
						>
							{user.firstName} {user.lastName}
						</div>
					))}
				</div>
			</div>
		</>
	)
}
