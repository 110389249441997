import { store } from '@redux/store'
import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { GetItemsParams } from '../utility.types'
import { LoginCarouselNode } from './login-carousel.types'

export class LoginCarouselAPI {
	public static getNodes(
		props: GetItemsParams<LoginCarouselNode>,
	): Promise<AxiosResponse<{ items: LoginCarouselNode[] }>> {
		const headers = {}
		const params = Utilities.createSearchParamsFromObj({ ...props })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/loginCarouselNodes/`, {
			headers,
			params,
		})
		return requestPromise
	}

	public static createNode(
		story: Omit<LoginCarouselNode, 'loginCarouselNodeId'>,
	): Promise<AxiosResponse<LoginCarouselNode>> {
		const headers = {}
		const requestPromise = httpRequest.post(`${RequestBaseURL}${apiVersionURL}/loginCarouselNodes/`, story, {
			headers,
		})
		return requestPromise
	}

	public static updateNode(
		nodeId: number,
		updateNodeDTO: Partial<LoginCarouselNode>,
	): Promise<AxiosResponse<LoginCarouselNode>> {
		const userProps = store.getState().user
		if (!userProps) {
			throw new Error(`User not found. Story cannot be created`)
		}

		const headers = {}
		const requestPromise = httpRequest.patch(
			`${RequestBaseURL}${apiVersionURL}/loginCarouselNodes/${nodeId}`,
			updateNodeDTO,
			{
				headers,
			},
		)
		return requestPromise
	}

	public static deleteNode(nodeId: number): Promise<AxiosResponse<LoginCarouselNode>> {
		const headers = {}
		const requestPromise = httpRequest.delete(`${RequestBaseURL}${apiVersionURL}/loginCarouselNodes/${nodeId}`, {
			headers,
		})
		return requestPromise
	}
}
