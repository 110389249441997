import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { Domain } from '../domain/domain.types'

export class LandingPageAPI {
	public static getHubs(domainId: number): Promise<AxiosResponse<{ items: Domain.HubRef[] }>> {
		const headers = {}
		const requestPromise = httpRequest.get(
			`${RequestBaseURL}${apiVersionURL}/domains/${domainId}/landingpagehubs/`,
			{
				headers,
			},
		)
		return requestPromise
	}

	public static createHub(
		domainId: number,
		hubDTO: Omit<Domain.HubRef, 'landingPageHubId'>,
	): Promise<AxiosResponse<{ items: Domain.HubRef }>> {
		const headers = {}
		const requestPromise = httpRequest.post(
			`${RequestBaseURL}${apiVersionURL}/domains/${domainId}/landingpagehubs/`,
			hubDTO,
			{
				headers,
			},
		)
		return requestPromise
	}

	public static updateHub(domainId: number, hubProperties: Domain.HubRef): Promise<AxiosResponse<Domain.HubRef>> {
		const headers = {}
		const requestPromise = httpRequest.patch(
			`${RequestBaseURL}${apiVersionURL}/domains/${domainId}/landingpagehubs/${hubProperties.landingPageHubId}`,
			hubProperties,
			{
				headers,
			},
		)
		return requestPromise
	}

	public static deleteHub(domainId: number, hubRefId: number): Promise<AxiosResponse<void>> {
		const headers = {}
		const requestPromise = httpRequest.delete(
			`${RequestBaseURL}${apiVersionURL}/domains/${domainId}/landingpagehubs/${hubRefId}`,
			{
				headers,
			},
		)
		return requestPromise
	}

	public static getResources(domainId: number): Promise<AxiosResponse<{ items: Domain.ResourceRef[] }>> {
		const headers = {}
		const requestPromise = httpRequest.get(
			`${RequestBaseURL}${apiVersionURL}/domains/${domainId}/landingpageresources/`,
			{
				headers,
			},
		)
		return requestPromise
	}

	public static createResources(
		domainId: number,
		resourceDTO: Omit<Domain.ResourceRef, 'landingPageResourceId'>,
	): Promise<AxiosResponse<{ items: Domain.ResourceRef }>> {
		const headers = {}
		const requestPromise = httpRequest.post(
			`${RequestBaseURL}${apiVersionURL}/domains/${domainId}/landingpageresources/`,
			resourceDTO,
			{
				headers,
			},
		)
		return requestPromise
	}

	public static updateResources(
		domainId: number,
		resourceProps: Domain.ResourceRef,
	): Promise<AxiosResponse<Domain.ResourceRef>> {
		const headers = {}
		const requestPromise = httpRequest.patch(
			`${RequestBaseURL}${apiVersionURL}/domains/${domainId}/landingpageresources/${resourceProps.landingPageResourceId}`,
			resourceProps,
			{
				headers,
			},
		)
		return requestPromise
	}

	public static deleteResources(domainId: number, resourceRefId: number): Promise<AxiosResponse<void>> {
		const headers = {}
		const requestPromise = httpRequest.delete(
			`${RequestBaseURL}${apiVersionURL}/domains/${domainId}/landingpageresources/${resourceRefId}`,
			{
				headers,
			},
		)
		return requestPromise
	}
}
