import { HubSelectorDropdown } from '@components/hub-selector-dropdown/hub-selector-dropdown'
import { SortableCard } from '@components/sortable-card/sortable-card'
import {
	closestCenter,
	DndContext,
	DragEndEvent,
	MouseSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { store } from '@redux/store'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { HubsService } from '../../../../services/hubs/hubs.service'
import { Hub } from '../../../../services/hubs/hubs.types'
import { useDomainAdmin, useDomainAdminDispatch } from '../state/domain-admin__state'

interface FeaturedHubEditorProps {
	className?: string
}

/** Documentation: https://docs.dndkit.com/presets/sortable/usesortable */
export function FeaturedHubEditor(props: FeaturedHubEditorProps) {
	const domainAdminState = useDomainAdmin()
	const domainAdminDispatch = useDomainAdminDispatch()
	const [orderedHubIds, setOrderedHubIds] = useState<number[]>([])

	useEffect(() => {
		const sortedHubs = domainAdminState.modified.landingPageHubs.sort((a, b) =>
			a.displayOrder > b.displayOrder ? 1 : -1,
		)
		setOrderedHubIds(sortedHubs.map((hubRef) => hubRef.landingPageHubId))
	}, [domainAdminState.modified.landingPageHubs])

	const mouseSensor = useSensor(MouseSensor, {
		activationConstraint: {
			distance: 40,
		},
	})
	const pointerSensor = useSensor(PointerSensor)

	const sensors = useSensors(pointerSensor, mouseSensor)

	function handleRemoveHub(hubId: number): void {
		domainAdminDispatch({ type: 'remove-landing-page-hub', payload: hubId })
	}

	function handleAddHub(hub: Hub): void {
		domainAdminDispatch({ type: 'create-landing-page-hub', payload: hub })
	}

	function getSelectedHubs(): Hub[] {
		const selectedHubs: Hub[] = []
		domainAdminState.modified.landingPageHubs.forEach((hubRef) => {
			if (!hubRef.hub) {
				return
			}
			const hubProps = HubsService.getHubFromId(store.getState().hubs, hubRef.hub.hubId)
			if (!hubProps) {
				throw new Error(`Could not find hub`)
			}
			selectedHubs.push(hubProps)
		})
		return selectedHubs
	}

	function getSelectedHubIds(): number[] {
		const hubIds: number[] = []
		domainAdminState.modified.landingPageHubs.forEach((hubRef) => {
			if (hubRef.hub) {
				hubIds.push(hubRef.hub.hubId)
			}
		})
		return hubIds
	}

	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event
		let updatedHubRefs = _.cloneDeep(domainAdminState.modified.landingPageHubs)

		if (!over) {
			return
		}

		if (active.id !== over.id) {
			const oldIndex = updatedHubRefs.findIndex((hubRef) => hubRef.landingPageHubId === active.id)
			const newIndex = updatedHubRefs.findIndex((hubRef) => hubRef.landingPageHubId === over.id)

			updatedHubRefs = arrayMove(updatedHubRefs, oldIndex, newIndex)
			updatedHubRefs.forEach((ref, index) => {
				updatedHubRefs[index].displayOrder = index
			})
			setOrderedHubIds(updatedHubRefs.map((ref) => ref.landingPageHubId))
			domainAdminDispatch({ type: 'update-landing-page-hub', payload: updatedHubRefs })
		}
	}

	return (
		<div className={`${props.className ? props.className : ''}`}>
			<div className="col-xs-12 mb-20">
				<HubSelectorDropdown
					selectedHubs={getSelectedHubs()}
					options={store.getState().hubs}
					onSelect={(options) => {
						const existingHubIds = getSelectedHubIds()
						const newHubs = options.filter(
							(selectedOption) => !existingHubIds.includes(selectedOption.hubId),
						)
						newHubs.forEach((hub) => {
							handleAddHub(hub)
						})
					}}
				/>
			</div>

			<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
				<SortableContext items={orderedHubIds} strategy={verticalListSortingStrategy}>
					{domainAdminState.modified.landingPageHubs.map((resourceRef) => {
						let label = 'Loading'

						const hubProps = resourceRef.hub
						if (hubProps) {
							label = hubProps.title
						}

						return (
							<SortableCard
								key={resourceRef.landingPageHubId}
								label={label}
								id={resourceRef.landingPageHubId}
								onDelete={() => {
									handleRemoveHub(resourceRef.landingPageHubId)
								}}
							/>
						)
					})}
				</SortableContext>
			</DndContext>
			{domainAdminState.modified.landingPageHubs.length === 0 && (
				<div className="bg-color__adjust-alpha-5 p-10 mb-10">No hubs have been added</div>
			)}
		</div>
	)
}
