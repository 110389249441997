import './youtube-embed.scss'

interface YouTubeEmbedProps {
	videoUrl: string
}
export function YouTubeEmbed(props: YouTubeEmbedProps) {
	function youTubeParser(url: string) {
		const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
		const match = url.match(regExp)
		return match && match[7].length === 11 ? match[7] : false
	}
	const embedId = youTubeParser(props.videoUrl)

	return (
		<div className="video-responsive">
			<iframe
				width="853"
				height="480"
				src={`https://www.youtube.com/embed/${embedId}`}
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				title="Embedded youtube"
			/>
		</div>
	)
}
