import { httpRequest, RequestBaseURL } from '../../services/axios/http-common.service'

class GoogleMapAPIPrototype {
	async loadGoogleMaps() {
		try {
			const response = await httpRequest.get(`${RequestBaseURL}/api/v1/google-maps/load-google-maps`)
			return response
		} catch (error) {
			console.log(error)
			throw new Error('Failed to load Google Maps.')
		}
	}

	async fetchAutocompleteSuggestions(input: string) {
		try {
			const response = await httpRequest.get(`${RequestBaseURL}/api/v1/google-maps/autocomplete`, {
				params: {
					input: input,
					components: 'country:US',
					language: 'en-US',
				},
			})
			return response
		} catch (error) {
			console.log(error)
			throw new Error('Error fetching autocomplete suggestions.')
		}
	}

	async fetchPlaceDetails(placeId: string) {
		try {
			const response = await httpRequest.get(`${RequestBaseURL}/api/v1/google-maps/placedetails`, {
				params: { placeId },
			})
			return response
		} catch (error) {
			console.log(error)
			throw new Error('Error fetching place details')
		}
	}
}

export const GoogleMapAPI = new GoogleMapAPIPrototype()
