import './list-item.scss'

import { Avatar } from '../avatar/avatar'
import { Checkbox } from '../checkbox/checkbox'
import { ListItemTypes } from './list-item.types'

export function ListItem(props: ListItemTypes.Component) {
	/** ================================== */
	/** Methods */

	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`list-item`)

		if (props.className) {
			classes.push(props.className)
		}

		if ('selected' in props && props.selected) {
			classes.push(`is-selected`)
		}

		if (props.disabled) {
			classes.push(`is-disabled`)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	function handleClick(): void {
		if (props.onClick) {
			if ('selected' in props) {
				props.onClick(!props.selected)
			} else {
				props.onClick()
			}
		}
	}

	/** ================================== */
	/** Render Component */

	return (
		<div className={getClass()} onClick={handleClick}>
			{'selected' in props && props.includeCheckbox && (
				<Checkbox
					className="mr-20"
					checked={typeof props.selected === 'boolean' ? props.selected : false}
					onChange={handleClick}
				/>
			)}
			{props.avatar && <Avatar className="mr-20" size={props.avatar.size} />}
			{props.body}
		</div>
	)
}
