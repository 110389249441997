// import { DateTime } from 'luxon'
import { DateTime } from 'luxon'
import { AdvertisingTypes } from 'src/services/advertising/advertising.types'

export namespace Ad__SideSheetService {
	export function validateAd(
		ad: AdvertisingTypes.Advertisement,
		adImages: AdvertisingTypes.AdvertisementImage[],
	): string[] {
		const errorStrings: string[] = []

		if (!ad.name) {
			errorStrings.push(`Name is required`)
		}

		if (ad.applicableHubs.length === 0) {
			errorStrings.push(`At least one hub must be selected`)
		}

		const startDate = DateTime.fromISO(ad.startDate)
		const endDate = DateTime.fromISO(ad.endDate)

		if (endDate.toMillis() < startDate.toMillis()) {
			errorStrings.push(`Campaign data cannot end before it starts`)
		}

		if (ad.placement === 'SPONSORED_RESOURCE') {
			if (!ad.sponsoredResourceAdvertisement.description) {
				errorStrings.push(`A description for the ad must be specified`)
			}
			if (!ad.sponsoredResourceAdvertisement.resourceTitle) {
				errorStrings.push(`A title for the ad must be specified`)
			}
		}

		if (adImages.length === 0) {
			errorStrings.push(`At least one ad image must be uploaded`)
		}

		adImages.forEach((adImage) => {
			if (adImage.advertisementImageId < 0 && !adImage.base64Image) {
				errorStrings.push(`An image must be uploaded`)
			}
			if (!adImage.link) {
				errorStrings.push(`A target URL for the ad must be specified`)
			}
		})

		return errorStrings
	}
}
