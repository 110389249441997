import { ModalTypes } from './modal.types'

export function ModalFooter(props: ModalTypes.Footer) {
	return (
		<div
			className={`flex flex-justifyContent-end px-20 pb-20 pt-10 bg-color__adjust-0 ${props.className ? props.className : ''}`}
		>
			{props.children}
		</div>
	)
}
