import { LoKationButton } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UIState } from '@redux/reducers/ui-reducer'
import { RootState } from '@redux/store'
import _ from 'lodash'
import { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { settingsRoutePath } from 'src/routes/routes.constant'
import { AdAccountAPI } from 'src/services/advertising/ad-account.api'
import { AdvertisingTypes } from 'src/services/advertising/advertising.types'

import useAdminRedirect from '../../../services/hooks/user-admin-redirect'
import { EndUserProps } from '../../../services/user/user.types'
import { useItemAdminHook } from '../use-item-admin-hook'
import { AccountCard } from './account__card/account__card'
import { AdvertisingAccountSideSheet } from './side-sheets/account__side-sheet'

type ConnectedProps = {
	userProfile: EndUserProps | null
	ui: UIState
}

function AdvertisingAdministrationRoute__Prototype(props: ConnectedProps) {
	const navigate = useNavigate()
	const [accountSearchString] = useState('')
	const [selectedAccount, setSelectedAccount] = useState<AdvertisingTypes.Account | 'NEW' | null>(null)
	const [showAccountSideSheet, setShowAccountSideSheet] = useState(false)
	const [isPending, setIsPending] = useState(true)

	useAdminRedirect(props.userProfile)

	const accountAdminHook = useItemAdminHook<AdvertisingTypes.Account, AdvertisingTypes.AccountSearchParams>({
		getItemsFunction: (page, size, sort, searchParams) => {
			return AdAccountAPI.findAccounts({ page, size, sort }, { organizationName: searchParams.organizationName })
		},
		searchParams: {
			organizationName: accountSearchString,
		},
		itemIdKey: 'accountId',
		itemTitleKey: 'organizationName',
		setIsPending: (state) => {
			setIsPending(state)
		},
		isItemShownInList: undefined,
	})

	const sortedAccounts = getSortedAccounts()

	function getSortedAccounts(): AdvertisingTypes.Account[] {
		const updatedState = _.cloneDeep(accountAdminHook.items)
		return updatedState.sort((a, b) => (a.organizationName > b.organizationName ? 1 : -1))
	}

	return (
		<>
			<RouteWrapperV2>
				<RouteSubnavigation
					title={`Advertising`}
					backButton={{
						label: 'Administration',
						onClick: () => {
							navigate('/administration')
						},
					}}
					isCustom={false}
					routes={[
						{
							label: 'View Analytics',
							onClick: () => {
								alert(`Connect to Google Analytics`)
							},
							icon: 'chart-line',
							id: `analytics`,
							isActive: false,
						},
					]}
				/>
				<RouteBodyV2 className="col-xs-12" id="advertising-admin__route-body">
					<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center mb-10">
						<h1>Advertising</h1>
						<LoKationButton
							variant="outlined"
							label="Create Account"
							size={'sm'}
							onClick={() => {
								setSelectedAccount('NEW')
								setShowAccountSideSheet(true)
							}}
						/>
					</div>
					<div className="flex flex-column-md-down">
						<div className="flex flex-column flex-fillSpace">
							{!isPending && !accountAdminHook.hasMoreItems && accountAdminHook.items.length === 0 && (
								<div className="flex flex-column flex-fillSpace flex-justifyContent-center flex-alignItems-center">
									<FontAwesomeIcon icon={['far', 'newspaper']} size="4x" className="mb-20" />
									<h3>We cannot find any accounts matching your criteria</h3>
								</div>
							)}

							{sortedAccounts && (
								<InfiniteScroll
									dataLength={sortedAccounts.length}
									next={accountAdminHook.loadNextPageOfItems}
									hasMore={accountAdminHook.hasMoreItems}
									scrollableTarget={
										props.ui.viewMode === 'FULL' ? `advertising-admin__route-body` : undefined
									}
									loader={
										<>
											<GenericContentLoader width={'fill'} height={70} className="mb-10" />
											<GenericContentLoader width={'fill'} height={70} className="mb-10" />
											<GenericContentLoader width={'fill'} height={70} className="mb-10" />
										</>
									}
								>
									{sortedAccounts.map((account) => {
										return (
											<AccountCard
												{...account}
												key={account.accountId}
												onClick={() => {
													setSelectedAccount(account)
													setShowAccountSideSheet(true)
												}}
												onDoubleClick={() => {
													navigate(
														`${settingsRoutePath}/ad-account/${account.accountId}/general`,
													)
												}}
											/>
										)
									})}
								</InfiniteScroll>
							)}
						</div>
					</div>
				</RouteBodyV2>
			</RouteWrapperV2>
			{selectedAccount && showAccountSideSheet && (
				<AdvertisingAccountSideSheet
					account={selectedAccount}
					onClose={() => {
						setShowAccountSideSheet(false)
					}}
					onDelete={() => {
						accountAdminHook.reset()
					}}
					onUpdate={() => {
						accountAdminHook.reset()
					}}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
		ui: state.ui,
	}
}

export const AdvertisingAdministrationRoute = connect(mapStateToProps)(AdvertisingAdministrationRoute__Prototype)
