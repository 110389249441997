export interface AssociativeArray {
	[key: string]: unknown
}

/** Service to aid in constructing a CSV file */
export function CSVService() {
	function createCSVFileFromString(fileName: string, csvString: string): void {
		const link = document.createElement('a')
		link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csvString))
		link.setAttribute('download', `${fileName}.csv`)
		link.click()
	}

	function generateCSVFromArray(props: { headers: string[]; rows: AssociativeArray[] }): string {
		let csvString = ''

		csvString = `${generateHeaders(props.headers)}`

		props.rows.forEach((row) => {
			csvString += generateRow(props.headers, row)
		})

		return csvString
	}

	function generateHeaders(headers: string[]): string {
		let headerString = ''

		headers.forEach((header, index) => {
			const isLastString = index === headers.length - 1
			headerString += `${header}${!isLastString ? ',' : ''}`
		})
		return `${headerString}\r\n`
	}

	function generateRow(headers: string[], row: AssociativeArray): string {
		let rowString = ''
		headers.forEach((header, index) => {
			const isLastString = index === headers.length - 1
			let columnValue = row[header]
			if (typeof columnValue === 'number') {
				columnValue = String(columnValue)
			}
			if (typeof columnValue === 'string') {
				columnValue = columnValue.replace(/\"/g, '""')
			}
			rowString += `"${columnValue ? columnValue : ''}"${!isLastString ? ',' : ''}`
		})
		return `${rowString}\r\n`
	}

	return { createCSVFileFromString, generateCSVFromArray }
}
