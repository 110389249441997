import './form__card.scss'

import { CustomForms } from '../../../../services/custom-forms/custom-forms.types'
import { Utilities } from '../../../../services/utilities.service'

interface FormCardProps {
	onClick: () => void
}

export function FormCard(props: CustomForms.Form & FormCardProps) {
	return (
		<div className="form__card" onClick={props.onClick}>
			<strong>{Utilities.truncateString(props.formName, 100)}</strong>
		</div>
	)
}
