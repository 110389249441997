import _ from 'lodash'
import { useEffect, useState } from 'react'

/** Note: T represents the property keys in your form. K represents the data type for the value of this field */
interface UseFormValidationProps<T extends string, K> {
	fields: UseFormValidationField<T, K>[]
}

/** Note: K represents the data type of the value for this field */
export interface UseFormValidationField<T extends string, K> {
	name: T
	value: K
	validation: (value: K) => boolean
	message: string
}

/** Note: K represents the data type of the value for this field */
export interface ResolvedFormValidationField<T extends string, K> {
	name: T
	value: K
	isValid: boolean
	validation: (value: K) => boolean
	message: string
}

/** Hook to aid hiding and showing of popovers when a user mouses into an element */
export function useFormValidation<T extends string, K = any>(props: UseFormValidationProps<T, K>) {
	/** ======================================================== */
	/** Props and State */
	const [fieldState, setFieldState] = useState<Map<string, ResolvedFormValidationField<T, K>>>(
		mapFields(props.fields),
	)
	const invalidFields = getInvalidFields()
	const isFormValid = invalidFields.length === 0

	/** ======================================================== */
	/** Effects */

	useEffect(() => {
		let updatedFieldState = _.cloneDeep(fieldState)
		let didResultInUpdate = false
		const areMapKeysSame = getAreMapKeysSame(fieldState, props.fields)

		/** If the keys in this form have changed since last render, we need to rebuild the map */
		if (!areMapKeysSame) {
			updatedFieldState = mapFields(props.fields)
		}

		/** Run validation on every field */
		updatedFieldState.forEach((mappedField) => {
			const unmappedField = props.fields.find((thisUnmappedField) => thisUnmappedField.name === mappedField.name)
			if (!unmappedField) {
				console.warn(`Could not get value of field '${mappedField.name}' for validation`)
				return
			}

			const updatedIsValid = mappedField.validation(unmappedField.value)
			if (updatedIsValid !== mappedField.isValid) {
				mappedField.isValid = updatedIsValid
				didResultInUpdate = true
			}
		})

		/** Push updated state if validation results have changed or if keys are added/removed */
		if (didResultInUpdate || !areMapKeysSame) {
			setFieldState(updatedFieldState)
		}
	}, [props.fields])

	/** ======================================================== */
	/** Methods */

	function getAreMapKeysSame(
		mapInState: Map<string, any>,
		updatedFieldState: UseFormValidationField<T, K>[],
	): boolean {
		if (mapInState.size !== updatedFieldState.length) {
			return false
		}

		return updatedFieldState.every((field) => {
			return mapInState.has(field.name)
		})
	}

	function getInvalidFields(): ResolvedFormValidationField<T, K>[] {
		const fields: ResolvedFormValidationField<T, K>[] = []

		fieldState.forEach((field) => {
			if (!field.isValid) {
				fields.push(field)
			}
		})

		return fields
	}

	function getField(name: T): ResolvedFormValidationField<T, K> {
		const evaluatedField = fieldState.get(name)
		const unmappedField = props.fields.find((thisUnmappedField) => thisUnmappedField.name === name)

		if (evaluatedField && unmappedField) {
			evaluatedField.value = unmappedField.value
			return evaluatedField
		}

		console.warn(`Cannot get evaluated field. Name '${name}' not found`)
		return {
			name,
			isValid: true,
			message: 'Error',
			validation: () => {
				return true
			},
			/** @ts-ignore */
			value: 0,
		}
	}

	function mapFields(unmappedFields: UseFormValidationField<T, K>[]): Map<string, ResolvedFormValidationField<T, K>> {
		const mappedFields = new Map<string, ResolvedFormValidationField<T, K>>()
		unmappedFields.forEach((unmappedField) => {
			const evaluatedFormField: ResolvedFormValidationField<T, K> = {
				...unmappedField,
				isValid: unmappedField.validation(unmappedField.value),
			}
			mappedFields.set(unmappedField.name, evaluatedFormField)
		})
		return mappedFields
	}

	function addField(field: UseFormValidationField<T, K>): void {
		const updatedFields = _.cloneDeep(fieldState)
		const evaluatedFormField: ResolvedFormValidationField<T, K> = {
			...field,
			isValid: field.validation(field.value),
		}

		if (updatedFields.has(field.name)) {
			console.warn(`Cannot add field to form validation. Field name '${field.name}' already exists`)
			return
		}

		updatedFields.set(field.name, evaluatedFormField)
		setFieldState(updatedFields)
	}

	function removeField(fieldName: T): void {
		const updatedFields = _.cloneDeep(fieldState)

		if (!updatedFields.has(fieldName)) {
			console.warn(`Cannot remove field from form validation. Field name '${fieldName}' does not exist`)
			return
		}

		updatedFields.delete(fieldName)
		setFieldState(updatedFields)
	}

	function updateField(fieldName: T, property: keyof ResolvedFormValidationField<T, K>, value: K): void {
		const updatedFields = _.cloneDeep(fieldState)

		const fieldToUpdate = updatedFields.get(fieldName)

		if (!fieldToUpdate) {
			console.warn(`Cannot update field for form validation. Field name '${fieldName}' does not exist`)
			return
		}

		/** @ts-ignore */
		fieldToUpdate[property] = value

		updatedFields.delete(fieldName)
		setFieldState(updatedFields)
	}

	return { getField, isFormValid, invalidFields, addField, removeField, updateField, allFields: fieldState }
}
