import React from 'react'

type RouteWithSubBodyProps = {
	children: React.ReactNode
	className?: string
	omitPadding?: PaddingDirections[]
	id?: string
}

type PaddingDirections = 'top' | 'bottom' | 'left' | 'right' | 'all'

export const RouteBodyV2 = React.forwardRef(
	(props: RouteWithSubBodyProps, forwardedRef: React.ForwardedRef<HTMLDivElement>) => {
		function getClass(): string {
			let classes: string[] = []
			let classString = ''

			classes.push(`v2-route__body__inner`)

			classes.push(`v2-route-body__pt`)
			classes.push(`v2-route-body__pb`)
			classes.push(`v2-route-body__pl`)
			classes.push(`v2-route-body__pr`)

			if (props.omitPadding) {
				props.omitPadding.forEach((direction) => {
					switch (direction) {
						case 'top':
							classes = classes.filter((thisClass) => thisClass !== 'v2-route-body__pt')
							break
						case 'bottom':
							classes = classes.filter((thisClass) => thisClass !== 'v2-route-body__pb')
							break
						case 'left':
							classes = classes.filter((thisClass) => thisClass !== 'v2-route-body__pl')
							break
						case 'right':
							classes = classes.filter((thisClass) => thisClass !== 'v2-route-body__pr')
							break
						case 'all':
							classes = classes.filter((thisClass) => thisClass !== 'v2-route-body__pt')
							classes = classes.filter((thisClass) => thisClass !== 'v2-route-body__pb')
							classes = classes.filter((thisClass) => thisClass !== 'v2-route-body__pl')
							classes = classes.filter((thisClass) => thisClass !== 'v2-route-body__pr')
							break
					}
				})
			}

			if (props.className) {
				classString = `${props.className} `
			}

			classes.forEach((thisClass) => {
				classString += `${thisClass} `
			})

			return classString
		}

		return (
			<div className="v2-route__body__outer" ref={forwardedRef} id={props.id ? props.id : undefined}>
				<div className={getClass()}>{props.children}</div>
			</div>
		)
	},
)
