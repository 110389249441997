import './horizontal-rule.scss'

interface HorizontalRuleProps {
	color?: string
	className?: string
}

export function HorizontalRule(props: HorizontalRuleProps) {
	return (
		<div
			className={`horizontal-rule ${props.className ? props.className : ''}`}
			style={{ borderTopColor: props.color ? props.color : 'var(--colorAdjustAlpha20)' }}
		></div>
	)
}
