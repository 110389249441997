import { store } from '@redux/store'

import { LicensedRegion } from './regions.types'

class LicensedRegionsServicePrototype {
	public getRegionsOfState(stateId: number): LicensedRegion[] {
		const allRegions = store.getState().licensedRegions
		return allRegions.filter((region) => {
			return region.licensedStateId === stateId
		})
	}

	public getRegionFromId(regionId: number): LicensedRegion | undefined {
		// return store.getState().licensedRegions.find((region) => region.regionId === regionId)
		console.log(store.getState().licensedRegions.find((region) => region.regionId === regionId))
		return store.getState().licensedRegions.find((region) => region.regionId === regionId)
	}

	public getRegionsFromIds(allRegions: LicensedRegion[], regionIds: number[]): LicensedRegion[] {
		const foundRegions: LicensedRegion[] = []

		regionIds.forEach((regionId) => {
			const foundLocation = allRegions.find((region) => region.regionId === regionId)
			if (foundLocation) {
				foundRegions.push(foundLocation)
			}
		})

		return foundRegions
	}
}

export const LicensedRegionsService = new LicensedRegionsServicePrototype()
