import { useEffect, useState } from 'react'
import { UserAPI } from 'src/services/user/user.api'

import { EndUserProps } from '../../../services/user/user.types'
import { UserProfileProvider } from '../user-profile/state/user-profile__state'
import { EditUserSideSheet } from './edit-user__side-sheet'

interface EditUserSideSheetProviderProps {
	endUserId: number
	currentUser: EndUserProps
	onClose: () => void
}

export function EditUserSideSheetProvider(props: EditUserSideSheetProviderProps) {
	const [mergedUserProps, setMergedUserProps] = useState<EndUserProps | null>(null)

	useEffect(() => {
		UserAPI.getUserDetails(props.endUserId).then((res) => {
			setMergedUserProps(res.data)
		})
	}, [])

	if (!mergedUserProps) {
		return <></>
	}

	return (
		<UserProfileProvider userToEdit={mergedUserProps}>
			<EditUserSideSheet userToEdit={mergedUserProps} currentUser={props.currentUser} onClose={props.onClose} />
		</UserProfileProvider>
	)
}
