import { AppTrayTypes } from '../app-tray.types'

export function DesktopAppTrayHeader(props: { header: AppTrayTypes.Header; isTrayOpen: boolean }) {
	return (
		<div className="app-tray__section-header" key={props.header.label}>
			<>
				{props.isTrayOpen && <>{props.header.label}</>}
				{!props.isTrayOpen && (
					<div
						style={{
							width: '22px',
							height: '3px',
							borderTop: '2px solid var(--colorAdjust60)',
							opacity: '0.5',
						}}
					></div>
				)}
			</>
		</div>
	)
}
