import { AxiosResponse } from 'axios'
import _ from 'lodash'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { GetItemsParams, GetItemsResponse } from '../utility.types'
import { AdvertisingTypes } from './advertising.types'

export namespace AdAccountAPI {
	export function findAccounts(
		props: GetItemsParams<AdvertisingTypes.Account>,
		itemTypeProps: AdvertisingTypes.AccountSearchParams,
	): Promise<AxiosResponse<GetItemsResponse<AdvertisingTypes.Account>>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props, ...itemTypeProps })

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/accounts/search`, {
			headers,
			params,
		})
		return requestPromise
	}

	export function createAccount(
		accountToCreate: AdvertisingTypes.Account,
	): Promise<AxiosResponse<AdvertisingTypes.Account>> {
		const headers = {}

		const payload: Partial<AdvertisingTypes.Account> = _.cloneDeep(accountToCreate)
		delete payload.accountId

		const requestPromise = httpRequest.post(`${RequestBaseURL}${apiVersionURL}/accounts/`, payload, {
			headers,
		})
		return requestPromise
	}

	export function getAccountById(id: number): Promise<AxiosResponse<AdvertisingTypes.Account | null>> {
		const headers = {}

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/accounts/${id}`, {
			headers,
		})
		return requestPromise
	}

	export function updateAccount(
		accountId: number,
		updatedProperties: Partial<AdvertisingTypes.Account>,
	): Promise<AxiosResponse<AdvertisingTypes.Account>> {
		const headers = {}

		const requestPromise = httpRequest.patch(
			`${RequestBaseURL}${apiVersionURL}/accounts/${accountId}`,
			updatedProperties,
			{
				headers,
			},
		)
		return requestPromise
	}

	export function deleteAccount(accountToDelete: AdvertisingTypes.Account): Promise<AxiosResponse<null>> {
		const headers = {}

		const requestPromise = httpRequest.delete(
			`${RequestBaseURL}${apiVersionURL}/accounts/${accountToDelete.accountId}`,
			{
				headers,
			},
		)
		return requestPromise
	}
}
