import { Checkbox } from '@components/checkbox/checkbox'
import { StateSelectorDropdown } from '@components/state-selector-dropdown/state-selector-dropdown'
import { TagSelectorDropdown } from '@components/tag-selector-dropdown/tag-selector-dropdown'
import { TextInput } from '@components/text-input/text-input'
import { store } from '@redux/store'

import { EditStorySectionProps } from './edit-news-feed-story'

export function EditStoryGeneralSection(props: EditStorySectionProps) {
	return (
		<>
			<TextInput
				label="Title"
				dataType="text"
				value={props.mergedStoryProps.title}
				width="100%"
				className="mb-20"
				onChange={(updatedValue) => {
					props.updateStoryProp('title', updatedValue)
				}}
			/>

			<div className="mb-20 flex flex-column">
				<strong className="mb-5">Tags</strong>
				<TagSelectorDropdown
					selectedTags={props.mergedStoryProps.tags}
					options={store.getState().tags}
					onSelect={(selectedTags) => {
						props.updateStoryProp('tags', selectedTags)
					}}
				/>
			</div>

			<div className="mb-20 flex flex-column">
				<strong className="mb-5">Applicable States</strong>
				<Checkbox
					checked={props.mergedStoryProps.stateAgnostic}
					onChange={(checked) => {
						props.updateStoryProp('stateAgnostic', checked)
					}}
					style={{ marginBottom: '8px' }}
				>
					This is applicable to users in all states
				</Checkbox>
				<StateSelectorDropdown
					multiselect={true}
					selectedStates={props.mergedStoryProps.licensedStates}
					options={store.getState().licensedStates}
					onSelect={(selectedStates) => {
						props.updateStoryProp('licensedStates', selectedStates)
					}}
					disabled={props.mergedStoryProps.stateAgnostic}
				/>
			</div>
		</>
	)
}
