export function LoKationResourceGoogleMyBusiness() {
	return (
		<div className="flex flex-column overflow-y__scroll">
			<div className="flex flex-wrap">
				<div className="col-xs-12 col-sm-6 pr-20-sm">
					<h5>Google My Business - Local SEO Concierge</h5>
					<p>
						We have negotiated a significantly reduced rate for GHAX (Growth Hacks) to assist with
						establishing and optimizing your Google My Business Profile and get found by your local farming
						area…GHAX won’t only set-up your entire Google My Business profile and optimize it, they can
						keep you going on a monthly basis with a link audit, all access performance tracker and
						dashboard to see how you can increase the exposure. Eliminate the time and confusion with trying
						to figure out Google's algorithm and grow your find-ability in your local market. Let Google's
						Technology work for you, dont shy away from it. Normal cost is $450+, Lokation Agents only pay
						$327 one time for the initial set up or $37 monthly for the Dashboard and Performance Tracker.
					</p>
					<h5>Step 1 - White Glove Set-Up ($327 One Time Fee) Includes:</h5>
					<ul className="mb-20">
						<li>
							Complete Set Up of Google My Business Location profile (or optimize your current existing
							one)
						</li>
						<li>Local SEO Audit of Local Citations</li>
						<li>Local SEO Audit of Links and Authority </li>
						<li>Local SEO Audit of Search Rankings</li>
						<li>Local SEO Audit of Reviews and Ratings</li>
						<li>Local SEO Audit of Social Channels</li>
						<li>30 Minute Review and Coaching Call</li>
					</ul>
					<h5>Step 2 - Monthly Performance Dashboard ($37/month) Includes:</h5>
					<ul>
						<li>Complete Local SEO Audit</li>
						<li>Anytime Access Dashboard on Your Business Status</li>
						<li>Ongoing Data Collection of Search Rankings</li>
						<li>Ongoing Data Collection of Local Citations</li>
						<li>Ongoing Reputation Manager (Reviews)</li>
						<li>Google My Business Performance Tracking</li>
						<li>Website SEO Grader and Social Channel Performance Tracking.</li>
					</ul>
				</div>
				{/* <div className="col-xs-12 col-sm-6 pl-20-sm flex flex-column flex-alignItems-center flex-justifyContent-center">
					<img
						src="https://agent.lokationre.com/images/_pronounced_gee_hacks_short_for_Growth_Hacks_.png"
						style={{ width: '100%', maxWidth: '300px' }}
					/>
				</div> */}

				<div className="flex mt-40">
					<button
						className="button contained lg primary mr-10"
						onClick={() => {
							window.open('https://lokationjl.krtra.com/t/5b9ASUse2jZa', '_blank')
						}}
					>
						Go Go Google Master
					</button>
					<button
						className="button contained lg primary mr-10"
						onClick={() => {
							window.open(
								'https://lokation.thinkific.com/courses/google-my-business-for-realtors',
								'_blank',
							)
						}}
					>
						Overview Training
					</button>
				</div>
			</div>
		</div>
	)
}
