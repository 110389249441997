import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { defaultTheme } from '../../services/theme/default-theme/default-theme'
import { DomainThemeAPI } from '../../services/theme/theme.api'
import { DomainThemeService } from '../../services/theme/theme.service'
import { DomainTheme } from '../../services/theme/theme.types'

const initialState = DomainThemeService.generateThemeProps(defaultTheme)

export const getTheme = createAsyncThunk('theme/get', async () => {
	const res = await DomainThemeAPI.getThemes()
	const completeTheme =
		res.data.items.length > 0
			? DomainThemeService.generateThemeProps(res.data.items[0])
			: DomainThemeService.generateThemeProps(defaultTheme)
	return completeTheme
})

export const updateTheme = createAsyncThunk('theme/update', async (themeProps: DomainTheme.Theme) => {
	await DomainThemeAPI.updateTheme(themeProps, themeProps.themeId)
	const completeTheme = DomainThemeService.generateThemeProps(themeProps)
	DomainThemeService.writeThemePropsToCSS(completeTheme)
	return completeTheme
})

export const themeSlice = createSlice({
	name: 'theme',
	initialState,
	reducers: {
		setThemeProps: (state, action: PayloadAction<DomainTheme.CompleteTheme>) => {
			return action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getTheme.fulfilled, (state, action) => {
			return action.payload
		})
		builder.addCase(updateTheme.fulfilled, (state, action) => {
			return action.payload
		})
	},
})

export default themeSlice.reducer
