import { Avatar } from '@components/avatar/avatar'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { DateService } from '../../services/date.service'
import { MessagesAPI } from '../../services/messaging-service/messages.api'
import { MessagingServiceService } from '../../services/messaging-service/messaging-service.service'
import { MessagingService } from '../../services/messaging-service/messaging-service.types'

export function MessagesRouteBody() {
	const [chatGroup, setChatGroup] = useState<MessagingService.ChatGroupDetails | null>(null)
	const [chatMessages, setChatMessages] = useState<MessagingService.ChatMessage[] | null>(null)
	const params = useParams()

	/** =============================== */
	/** Effects */

	useEffect(() => {
		if ('chatGroupId' in params && params.chatGroupId) {
			MessagesAPI()
				.getChatGroupById(parseFloat(params.chatGroupId))
				.then((res) => {
					setChatGroup(res.data)
				})
			MessagesAPI()
				.getAllMessagesInChatGroup(parseFloat(params.chatGroupId), { page: 0, size: 50 })
				.then((res) => {
					setChatMessages(res.data.items)
				})
			MessagesAPI().markChatAsReadById(parseFloat(params.chatGroupId))
		}
	}, [params])

	/** =============================== */
	/** Methods */

	function createChatPlaceholder(): React.ReactElement {
		return (
			<>
				<div className="flex flex-alignItems-start">
					<GenericContentLoader width={60} height={60} className="mr-20" style={{ borderRadius: '50%' }} />
					<GenericContentLoader width={600} height={100} className="mb-20" />
				</div>
				<GenericContentLoader width={600} height={100} style={{ marginLeft: '200px' }} className="mb-20" />
			</>
		)
	}

	/** =============================== */
	/** Render Component */

	if (!chatGroup) {
		return (
			<div className="col-xs-12 flex flex-column height__100pct p-20" key={params.chatGroupId}>
				{createChatPlaceholder()}
				{createChatPlaceholder()}
			</div>
		)
	}

	return (
		<div className="col-xs-12" key={params.chatGroupId}>
			<div className="messages-route__body__header">
				<Avatar className="mr-20" size="sm" />
				<h4>{MessagingServiceService().getChatGroupMemberNames(chatGroup.members)}</h4>
			</div>
			<div className="p-20">
				{chatMessages &&
					chatMessages.map((message) => {
						return (
							<div className="flex mb-20" key={message.chatMessageId}>
								<Avatar size="sm" className="mr-10" />
								<div className="messages-route__message">
									<div className="mb-10" dangerouslySetInnerHTML={{ __html: message.message }}></div>
									<em className="color__adjust-60" style={{ fontSize: '9pt' }}>
										Sent {DateService.getFormattedDateFromDateObj(new Date(message.sentTimestamp))}
									</em>
								</div>
							</div>
						)
					})}
			</div>
		</div>
	)
}
