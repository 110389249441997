import './calendar-event__card.scss'

import { Calendar } from '../../../../services/calendar/calendar.types'
import { Utilities } from '../../../../services/utilities.service'

interface CalendarCardProps {
	onClick: () => void
}

export function CalendarCard(props: Calendar.Calendar & CalendarCardProps) {
	return (
		<div className="calendar-event__card" onClick={props.onClick}>
			<div className="flex flex-alignItems-center">
				<strong>{Utilities.truncateString(props.name, 100)}</strong>
			</div>
		</div>
	)
}
