import './new-user-onboarding.scss'

import { Modal } from '@components/modal/modal'
import { useEffect } from 'react'

import { UserOnboardingAPI } from '../../services/user-onboarding/user-onboarding.api'
import { NUOFooter } from './components/footer/footer'
import { NUOHeader } from './components/header/header'
import { NewUserOnboardingService } from './new-user-onboarding.service'
import { UserOnboardingModalTypes } from './new-user-onboarding.types'
import { NUOPromptRoute } from './routes/prompt/prompt'
import { NUOTasksRoute } from './routes/tasks/tasks'
import { NUOWelcomeRoute } from './routes/welcome/welcome'
import { useNewUserOnboarding, useNewUserOnboardingDispatch } from './state/new-user-onboarding__state'

export function NewUserOnboardingModalInner(props: UserOnboardingModalTypes.Modal) {
	const NUOState = useNewUserOnboarding()
	const NUODispatch = useNewUserOnboardingDispatch()
	const allRoutes = NewUserOnboardingService().getRoutes(
		NUOState.config ? NUOState.config.questions : [],
		props.onClose,
	)

	/** ======================================== */
	/** Effects */

	/** Get the configuration for user onboarding */
	useEffect(() => {
		UserOnboardingAPI()
			.getUserOnboardingConfig()
			.then((res) => {
				NUODispatch([{ type: 'load-config', payload: res.data }])
			})
	}, [])

	/** ======================================== */
	/** Methods */

	function hideModal(): void {
		props.onClose()
	}

	/** ======================================== */
	/** Render Component */

	return (
		<Modal
			maxWidth={600}
			maxHeight={700}
			fixedHeight={true}
			onClose={hideModal}
			escapable={false}
			className="flex flex-column"
		>
			<NUOHeader />
			<div className="col-xs-12 flex-fillSpace flex flex-column overflow-y__scroll">
				<div className="px-20 pb-20 flex flex-column height__100pct flex-noShrink">
					{NUOState.route === 'WELCOME' && <NUOWelcomeRoute />}
					{NUOState.config?.questions.map((question) => {
						return (
							<>
								{NUOState.route === `QUESTION_${question.questionIndex}` && (
									<NUOPromptRoute field={question} />
								)}
							</>
						)
					})}
					{NUOState.route === 'TASKS' && <NUOTasksRoute onClose={props.onClose} />}
				</div>
			</div>
			<NUOFooter routes={allRoutes} onClose={props.onClose} />
		</Modal>
	)
}
