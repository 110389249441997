import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Calendar } from '../../services/calendar/calendar.types'
import { Chip } from '../chip/chip'
import { EventCalendarEventDateRange } from './event-calendar__event-date-range'

interface EventCalendarEventProps {
	event: Calendar.Event
	instanceStartDate: Date | null
	instanceEndDate: Date | null
	dismissModal: () => void
}

export function EventCalendarEvent(props: EventCalendarEventProps) {
	return (
		<>
			<div className="mb-10">
				<strong className="color__adjust-40">Event</strong>
			</div>
			<div className="overflow-y__scroll clamp-width-of-images pr-10">
				<h5>{props.event.title}</h5>
				<div className="my-10 flex flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'clock']} className="mr-10" />
					<EventCalendarEventDateRange
						event={props.event}
						instanceStartDate={props.instanceStartDate}
						instanceEndDate={props.instanceEndDate}
					/>
				</div>
				<div className="my-10 flex flex-alignItems-center" style={{ textOverflow: 'ellipsis' }}>
					<FontAwesomeIcon icon={['far', 'tag']} className="mr-10" />
					<div className="flex flex-wrap">
						{props.event.tags.map((tag, index) => {
							return <Chip label={tag.label} color={tag.color} className="mr-5 mb-5" key={tag.tagId} />
						})}
					</div>
				</div>
				{props.event.licensedStates.length > 0 && (
					<div className="my-10 flex flex-alignItems-center" style={{ textOverflow: 'ellipsis' }}>
						<FontAwesomeIcon icon={['far', 'location']} className="mr-10" />
						<div className="flex flex-wrap">
							{props.event.licensedStates.map((licensedState, index) => {
								return (
									<Chip
										label={licensedState.stateName}
										color={`var(--colorAdjust60)`}
										className="mr-5 mb-5"
										key={licensedState.stateName}
									/>
								)
							})}
						</div>
					</div>
				)}

				<div dangerouslySetInnerHTML={{ __html: props.event.description }} />
			</div>
		</>
	)
}
